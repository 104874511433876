import { call, put, takeLatest } from "redux-saga/effects";
import { FETCH_USER_LIST_DATA } from "../store/user-list/constants";
import { FetchedUserData, FetchDataQueryParams } from "../types/pages/UserList";
import { 
    fetchUserListDataLoading,
    fetchUserListDataSuccess,
    fetchUserListDataFail 
} from "../store/user-list/userListReducer";
import { fetchUserListData } from "./api/user-list/fetchUserListData";

function* handleFetchUserListData(action: { type: string, payload: FetchDataQueryParams }) {

    try {

        yield put(fetchUserListDataLoading())
        const result: FetchedUserData = yield call(fetchUserListData, action.payload);
        yield put(fetchUserListDataSuccess(result))

    } catch(error) {
        yield put(fetchUserListDataFail())
    }

}

export default function* userListSaga() {
    yield takeLatest(FETCH_USER_LIST_DATA, handleFetchUserListData);
}
