import styles from "./Buttons.module.css";

interface ActionIconButtonProps extends React.ComponentProps<"button"> {
    customClassName?: string
    buttonText?: string
    icon: React.ReactNode
    actionType: string
    itemId: string
    handleClick: (itemId: string, actionType: string) => void 
}

const ActionIconButton: React.FC<ActionIconButtonProps> = (props) => {

    const { 
        customClassName = "", 
        buttonText, 
        icon, 
        actionType,
        itemId,
        handleClick
    } = props;

    return (
        <button
            className={`${styles.action_icon_button} ${styles[customClassName]}`}
            disabled={props.disabled}
            onClick={() => handleClick(itemId, actionType)}
        >
            {icon}
            {buttonText ? <span>{buttonText}</span> : null}
        </button>
    );

}

export default ActionIconButton;
