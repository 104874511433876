import styles from "./index.module.css";

interface IntegrationItemInfoProps {
    header: string
    description: string
}

const IntegrationItemInfo: React.FC<IntegrationItemInfoProps> = (props) => {

    const { header, description } = props;

    return (
        <div className={styles.info}>
            <h2>{header}</h2>
            <p>{description}</p>
        </div>
    );

}

export default IntegrationItemInfo;