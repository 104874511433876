import { useSelector } from "react-redux";
import { IPackage } from "../../types/models/Package";
import { RootState } from "../../store";
import styles from "./PackageTable.module.css";
import ActionButtons from "./ActionButtons";

interface ResponsivePackageTableProps {
    handleClick: (args: { actionType: string, packageItem: IPackage}) => void
}

const ResponsivePackageTable: React.FC<ResponsivePackageTableProps> = ({ handleClick }) => {

    const packageState = useSelector((state: RootState) => state.packageState);
    const { packageList } = packageState;
   
    return (
        <div className={styles.responsive_pacakge_table}>
            {packageList.map(packageItem => {
                const { _id, title, description, price } = packageItem;
                return (
                    <div 
                        key={_id}
                        className={styles.responsive_package_item}
                    >
                        <div className={styles.responsive_title_actions}>
                            <p className={styles.responsive_package_title}>
                                {title}
                            </p>
                            <ActionButtons 
                                showButtonText={false}
                                packageItem={packageItem}
                                handleClick={handleClick}
                            />
                        </div>
                        <p className={styles.responsive_package_description}>
                            {description}
                        </p>
                        <p className={styles.responsive_package_price}>
                            €{price.toFixed(2)}
                        </p>
                    </div>
                );
            })}
        </div>
    );

}

export default ResponsivePackageTable;