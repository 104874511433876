import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { RootState } from "../../../store";
import { IAppointment } from "../../../types/models/Appointment";
import { FETCH_APPOINTMENT_DATA } from "../../../store/calendar/constants";
import { config } from "../../../Constants";
import {
    toggleActivityByModal, 
    updateState,
    addNewAppointmentInfo,
    updateAppointment 
} from "../../../store/calendar/calendarReducer";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { formatDateTime } from "../../../helpers/date-time/dateTimeHelpers";
import { getFormattedDateTime } from "../../../helpers/date-time/getFormattedDateTime";
import { rescheduleAppointment } from "../../../helpers/calendar/rescheduleAppointment";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";
import styles from "./AppointmentModal.module.css";
import { Modal, ModalBackground, ModalCloseButton, ModalContent } from "../../../components/common/Modal";
import InputField from "../common/InputField";
import CheckboxInputField from "../common/CheckboxInputField";
import EmailCheckbox from "../common/EmailCheckbox";
import SelectDate from "./SelectDate";

const ActivityByModal = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const userState = useSelector((state: RootState) => state.userState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();
    const { user } = userState;
    const appointment = calendarState.appointmentInAction as IAppointment
    const { 
        locationList,
        treatmentList,
        stylistList,
        calendarViewType,
        dayViewDate,
        weekViewDates,
        monthViewDates,
        activityType, 
        activityByLabel, 
        activityBy,
        paymentMethod, 
        paymentAmount,
        selectedDateForCustomRescheduling,
        startTimeForCustomRescheduling,
        couponCode, 
        giftCardCode,
        doNotSendEmailToClient,
        refundAppointmentPrice,
        refundRate,
        cancelReason,
        validationError
    } = calendarState;

    const closeModal = () =>  {
        dispatch(updateState({ name: "activityBy", value: "" }));
        dispatch(toggleActivityByModal({
            activityType: "", 
            activityByLabel: "" 
        }));
        dispatch(addNewAppointmentInfo({
            name: "selectedDateForCustomRescheduling",
            value: null
        }));
        dispatch(addNewAppointmentInfo({
            name: "startTimeForCustomRescheduling",
            value: ""
        }));
        dispatch(updateState({ name: "validationError", value: false }))
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        dispatch(updateState({ name, value }));
    }

    const refetchAppointmentData = () => {

        if(!user) return;

        let dates: Date[] = [];

        if(calendarViewType === "day") dates = [dayViewDate];
        if(calendarViewType === "week") dates = [...weekViewDates];
        if(calendarViewType === "month") dates = [...monthViewDates];

        const endDatePlusOne = new Date(dates[dates.length - 1].getTime() + 86400000);

        const { dateString: startDateString } = formatDateTime(dates[0]);
        const { dateString: endDateString } = formatDateTime(endDatePlusOne);

        dispatch({ 
            type: FETCH_APPOINTMENT_DATA, 
            payload: {
                tenantId: user.tenantId,
                startDateString,
                endDateString
            }
        });

    }

    const callApi = async(uri: string, requestBody: any) => {

        const endpoint = config.url.BACKEND_API_URL + uri;
        const headers = { 
            "auth-token": cookies.get("auth_token") 
        };

        dispatch(toggleActivityByModal({
            activityType: "", 
            activityByLabel: "" 
        }));
        dispatch(updateState({
            name: "appointmentInAction",
            payload: null
        }));

        try {

            const response = await axios.post(endpoint, requestBody, { headers });
            const message: string = response.data.message;

            dispatch(updateAppointment(message));

            refetchAppointmentData();

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(updateAppointment(message));
        }

    }
  
    const handleClick = async (newActivityType: string) => {

        if(validationError) dispatch(updateState({ name: "validationError", value: false }))
        
        if(!activityBy) {
            dispatch(updateState({ name: "validationError", value: true }))
            return
        }

        let uri = "/appointments/cancel";
        const requestBody: any = {
            appointmentId: appointment._id,
            activityBy,
            couponCode,
            giftCardCode,
            paymentMethod, 
            paymentAmount: Number(paymentAmount),
            cancelReason,
            doNotSendEmailToClient,
            refundAppointmentPrice,
            refundRate: refundRate !== "" ? Number(refundRate) : 100  
        }

        if(newActivityType === "Reschedule") {

            if(selectedDateForCustomRescheduling) {

                if(!startTimeForCustomRescheduling) return alert("Please select a star time");

                dispatch(toggleActivityByModal({
                    activityType: "", 
                    activityByLabel: "" 
                }));
                dispatch(updateState({
                    name: "appointmentInAction",
                    payload: null
                }));

                const formattedDate = getFormattedDateTime(selectedDateForCustomRescheduling);
                const { day, monthInNumber, year } = formattedDate;
                const dateTimeString = `${year}-${monthInNumber}-${day}T${startTimeForCustomRescheduling}`

                const args = {
                    appointment_id: appointment._id ?? "",
                    doNotSendEmailToClient,
                    activityBy,
                    selectedStylist: appointment?.stylistItem?.fullName ?? "",
                    selectedStylistId: appointment?.stylistItem?._id ?? "",
                    selectedDateTime: dateTimeString
                }

                const message = await rescheduleAppointment(args);

                dispatch(updateAppointment(message));

                refetchAppointmentData();

                return;

            }

            const foundLocation = locationList.find(item => item._id === appointment.locationItem?._id)
            const foundTreatment = treatmentList.find(item => item._id === appointment.treatmentItem?._id);
            const foundStylist = stylistList.find(item => item._id === appointment.stylistItem?._id);

            if(!foundTreatment || !foundStylist || !foundLocation) return;

            dispatch(addNewAppointmentInfo({ 
                name: "selectedTreatment",
                value: foundTreatment
            }));
            dispatch(addNewAppointmentInfo({ 
                name: "selectedStylist",
                value: foundStylist
            }))
            dispatch(addNewAppointmentInfo({ 
                name: "selectedLocation",
                value: foundLocation
            }))
            dispatch(updateState({ 
                name: "isRescheduling",
                value: true
            }));
            return;
            
        };

        if(newActivityType === "No Show") uri = "/appointments/no-show";
        if(newActivityType === "Pay") uri = "/appointments/add-payment";
        if(newActivityType === "Redeem Coupon") uri = "/appointments/redeem-coupon";
        if(newActivityType === "Redeem Gift Card") uri = "/appointments/redeem-gift-card";

        callApi(uri, requestBody);

    }

    const toggleRefundAppointmentPrice = () => {
        dispatch(updateState({
            name: "refundAppointmentPrice",
            value: !refundAppointmentPrice
        }))
    }

    return (
        <Modal>
            <ModalBackground />
            <ModalContent
                width = {isLargeDevice ? "30%" : "95%"}
            >
                <ModalCloseButton 
                    onClick={closeModal}
                    color={"orange"}
                    topPosition="10px"
                    rightPosition="20px"
                    fontSize="1.5rem"
                >
                    X
                </ModalCloseButton>
                <InputField 
                    labelText={activityByLabel}
                    type="text"
                    name="activityBy"
                    value={activityBy}
                    handleChange={handleInputChange}
                    validationError={validationError}
                    validationErrorMessage = "activity by can't be blank"
                />
                { 
                    activityType === "Reschedule"
                    &&
                    <>
                        <SelectDate />
                        <InputField 
                            labelText="Custom Start Time"
                            type="text"
                            name="startTimeForCustomRescheduling"
                            value={startTimeForCustomRescheduling}
                            handleChange={handleInputChange}
                        />
                    </>
                }
                { 
                    activityType === "Cancel"
                    &&
                    <>
                        <InputField 
                            labelText="Cancel Reason"
                            type="text"
                            name="cancelReason"
                            value={cancelReason}
                            handleChange={handleInputChange}
                            validationError={validationError}
                            validationErrorMessage = "cancel reason can't be blank"
                        />
                        {
                            (appointment.isPartiallyPaidOnline || appointment.isFullyPaidOnline)
                            ?
                            <CheckboxInputField 
                                name="refundAppointmentPrice"
                                value="Refund appointment price"
                                checked={refundAppointmentPrice}
                                handleChange={toggleRefundAppointmentPrice}
                            />
                            :
                            null
                        }
                        {
                            (appointment.isFullyPaidOnline && refundAppointmentPrice)
                            ?
                            <InputField 
                                type="text"
                                name="refundRate"
                                placeholder="Refund Rate (Leave it blank for full refund)"
                                value={refundRate}
                                handleChange={handleInputChange}
                            />
                            :
                            null
                        }
                    </>
                }
                {
                    (activityType === "Cancel" || activityType === "Reschedule")
                    &&
                    <EmailCheckbox />
                }
                <div className = {styles.activity_confirm_button}>
                    <button onClick = {() => handleClick(activityType)}>
                        {activityType}
                    </button>
                </div>
            </ModalContent>
        </Modal>
    );

}

export default ActivityByModal;