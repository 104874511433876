import { RxEnvelopeClosed } from "react-icons/rx";

const EmailIcon = (): JSX.Element => {

    return (
        <RxEnvelopeClosed 
            size="1.3rem"
            color="#99B2C6"
            style={{
                marginRight: "3px"
            }}
        />
    );

}

export default EmailIcon;


