import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { handleChange } from "../../store/reports/reportReducer";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import { getDefaultStartEndDateString } from "../../helpers/reports/getDefaultStartEndDateString";
import { FETCH_REPORT_DATA } from "../../store/reports/constants";
import styles from "./index.module.css";
import SelectDate from "./SelectDate";
import MultiSelectDropdown from "../../components/common/dropdown/MultiSelectDropdown";

const Filters: React.FC = () => {

    const dispatch = useDispatch();
    const reportState = useSelector((state: RootState) => state.reportState);
    const { 
        categoryList,
        locationList,
        stylistList, 
        selectedStartDate,
        selectedEndDate,
        selectedStylistList,
        selectedLocationList,
        selectedCategoryList,
        selectedTreatmentList,
        selectedAppointmentStatuses 
    } = reportState;

    const handleDateChange = (date: Date | null, dateType: string) => {

        let name = "selectedStartDate";
        if(dateType === "end") name = "selectedEndDate";

        dispatch(handleChange({
            name,
            value: date
        }));

    }

    const selectDropdownItem = (args: { item: any, dropdownFor: string }) => {

        switch(args.dropdownFor) {

            case "stylist":
                const stylist = args.item;
                const foundStylist = selectedStylistList.find(item => item._id === stylist._id);
                if(foundStylist) {
                    const filteredSelectedStylistList = selectedStylistList.filter(item => item._id !== foundStylist._id);
                    dispatch(handleChange({
                        name: "selectedStylistList",
                        value: filteredSelectedStylistList
                    }));
                    return;
                }

                dispatch(handleChange({
                    name: "selectedStylistList",
                    value: [...selectedStylistList, stylist]
                }));
                return;


            case "location":
                const location = args.item;
                const foundLocation = selectedLocationList.find(item => item._id === location._id);
                if(foundLocation) {
                    const filteredSelectedLocationList = selectedLocationList.filter(item => item._id !== foundLocation._id);
                    dispatch(handleChange({
                        name: "selectedLocationList",
                        value: filteredSelectedLocationList
                    }));
                    return;
                }

                dispatch(handleChange({
                    name: "selectedLocationList",
                    value: [...selectedLocationList, location]
                }));
                return;



            case "category":
                const category = args.item;
                const foundCategory = selectedCategoryList.find(item => item._id === category._id);
                if(foundCategory) {
                    const filteredSelectedCategoryList = selectedCategoryList.filter(item => item._id !== foundCategory._id);
                    dispatch(handleChange({
                        name: "selectedCategoryList",
                        value: filteredSelectedCategoryList
                    }));
                    return;
                }

                dispatch(handleChange({
                    name: "selectedCategoryList",
                    value: [...selectedCategoryList, category]
                }));
                return;


            // Status
            default:
                const status = args.item;
                const foundStatus = selectedAppointmentStatuses.find(item => item._id === status._id);
                if(foundStatus) {
                    const filteredSelectedAppointmentStatuses = selectedAppointmentStatuses.filter(item => item._id !== foundStatus._id);
                    dispatch(handleChange({
                        name: "selectedAppointmentStatuses",
                        value: filteredSelectedAppointmentStatuses
                    }));
                    return;
                }

                dispatch(handleChange({
                    name: "selectedAppointmentStatuses",
                    value: [...selectedAppointmentStatuses, status]
                }));
                return;

        }

     
    }

    const applyFilters = () => {

        let startDateString = "";
        let endDateString = "";
        let categories = "";
        let locations = "";
        let treatments = "";
        let stylists = "";
        let appointmentStatuses = "";

        if(selectedStartDate && selectedEndDate) {
            const { dateString: selectedStartDateString } = formatDateTime(selectedStartDate);
            const { dateString: selectedEndDateString } = formatDateTime(selectedEndDate);
            startDateString = selectedStartDateString;
            endDateString = selectedEndDateString;
        }

        if(!selectedStartDate || !selectedEndDate) {
            const { defaultStartDateString, defaultEndDateString } = getDefaultStartEndDateString();
            startDateString = defaultStartDateString;
            endDateString = defaultEndDateString;
        }

        if(selectedCategoryList.length) {
            categories = selectedCategoryList.map(item => item._id).join(",");
        }

        if(selectedLocationList.length) {
            locations = selectedLocationList.map(item => item._id).join(",");
        }

        if(selectedTreatmentList.length) {
            treatments = selectedTreatmentList.map(item => item._id).join(",");
        }

        if(selectedStylistList.length) {
            stylists = selectedStylistList.map(item => item._id).join(",");
        }

        if(selectedAppointmentStatuses.length) {
            appointmentStatuses = selectedAppointmentStatuses.map(item => item.title).join(",")
        }

        dispatch({ 
            type: FETCH_REPORT_DATA, 
            payload: { 
                startDateString,
                endDateString,
                categories,
                locations,
                treatments,
                stylists,
                appointmentStatuses,
                fetchOtherData: "false"
            } 
        });

    }

  
    return (
        <div className={styles.filters}>
            <SelectDate 
                date={selectedStartDate}
                dateType="start"
                handleDateChange={handleDateChange}
            />
            <SelectDate 
                date={selectedEndDate}
                dateType="end"
                handleDateChange={handleDateChange}
            />
            <MultiSelectDropdown 
                id="locations"
                width="150px"
                title="Location"
                data={locationList}
                dropdownFor="location"
                nameKey="title"
                onSelectItem={selectDropdownItem}
                selectedItems={selectedLocationList}
            />
            <MultiSelectDropdown 
                id="categories"
                title="Category"
                data={categoryList}
                dropdownFor="category"
                nameKey="title"
                onSelectItem={selectDropdownItem}
                selectedItems={selectedCategoryList}
            />
            <MultiSelectDropdown 
                id="stylists"
                title="Stylist"
                data={stylistList}
                dropdownFor="stylist"
                nameKey="fullName"
                onSelectItem={selectDropdownItem}
                selectedItems={selectedStylistList}
            />
            <MultiSelectDropdown 
                id="status"
                title="Status"
                data={[
                    { _id: 1, title: "Confirmed" },
                    { _id: 2, title: "Cancelled" }
                ]}
                dropdownFor="status"
                nameKey="title"
                onSelectItem={selectDropdownItem}
                selectedItems={selectedAppointmentStatuses}
            />
            <button
                className={styles.filter_button} 
                onClick={applyFilters}
            >
                Apply Filters
            </button>
        </div>
    );

}


export default Filters;