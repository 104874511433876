import { RootState } from "../store";
import { select, call, put, takeLatest } from "redux-saga/effects";
import { 
    FetchedAppointmentClientData, 
    AppointmentClientState
} from "../types/pages/AppointmentClient";
import { fetchAppointmentClients } from "./api/appointment-clients/fetchAppointmentClients";
import { 
    fetchDataLoading,
    fetchDataSuccess, 
    fetchDataFail
} from "../store/appointment-clients/appointmentClientReducer";
import { FETCH_APPOINTMENT_CLIENT_DATA } from "../store/appointment-clients/constants";
import { handleApiError } from "../helpers/error-handlers/handleApiError";

const appointmentClientState = (state: RootState) => state.appointmentClientState;

function* handleFetchAppointmentClientData() {

    const { searchText, skip, limit }: AppointmentClientState = yield select(appointmentClientState);

    try {
        yield put(fetchDataLoading());
        const result: FetchedAppointmentClientData = yield call(fetchAppointmentClients, { searchText, skip, limit });
        yield put(fetchDataSuccess(result));  
    } catch(error: any) {
        const { message } = handleApiError(error)
        yield put(fetchDataFail(message));
    }

}

export default function* appointmentClientSaga() {
    yield takeLatest(FETCH_APPOINTMENT_CLIENT_DATA, handleFetchAppointmentClientData);
}
