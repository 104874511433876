import { useSelector } from "react-redux";
import { IWaitList } from "../../types/models/WaitList";
import { RootState } from "../../store";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./index.module.css";
import WaitListItemInfo from "./WaitListItemInfo";


const WaitListDetails: React.FC = () => {

    const waitListState = useSelector((state: RootState) => state.waitListState);
    const waitListItemInAction = waitListState.waitListItemInAction as IWaitList;
    const { targetDaySession, targetDaySessionStartTime, targetDaySessionEndTime } = waitListItemInAction;

    const { day, month, year, hours, minutes } = formatDateTime(new Date(waitListItemInAction.createdAt * 1000));
    const { 
        day: targetDay, 
        month: targetMonth, 
        year: targetYear 
    } = formatDateTime(new Date(waitListItemInAction.targetAppointmentDate * 1000));

    const daySession = `${targetDaySession} (${targetDaySessionStartTime} - ${targetDaySessionEndTime})`

    const itemInfoList = [
        { title: "Subscription Date", content: `${month} ${day}, ${year} @ ${hours}:${minutes}` },
        { title: "Target Date", content: `${targetMonth} ${targetDay}, ${targetYear}` },
        { title: "Target Session", content: daySession },
        { title: "Client Name", content: waitListItemInAction.clientName },
        { title: "ClientEmail", content: waitListItemInAction.clientEmail },
        { title: "Client Phone", content: waitListItemInAction.clientPhone }
    ];

    return (
        <div className={styles.wait_list_details}>
            {itemInfoList.map(item => {
                return (
                    <WaitListItemInfo 
                        key={item.title}
                        title={item.title}
                        content={item.content}
                    />
                );
            })}
            <div className={styles.wait_list_treatment_stylist_list}>
                <h3>Treatments And Stylists</h3>
                <table>
                    <tbody>
                        {waitListItemInAction.selectedTreatmentStylistList.map(item => {
                            return (
                                <tr key={item.treatment._id}>
                                    <td>{item.treatment.title}</td>
                                    <td>{item.stylist.fullName}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );

}

export default WaitListDetails;