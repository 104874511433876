import styles from "./InputFields.module.css";

interface TextAreaInputFieldProps {
    customClassName?: string
    labelText?: string
    datatype?: string
    rows: number
    columns: number
    name: string 
    value: string 
    validationError?: boolean
    validationErrorMessage?: string
    handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const TextAreaInputField: React.FC<TextAreaInputFieldProps> = (props) => {

    const { 
        customClassName = "",
        rows, 
        columns,
        labelText, 
        datatype,
        name, 
        value, 
        handleChange, 
        validationError, 
        validationErrorMessage, 
    } = props;

    return (
        <div className={`${styles.textarea_input_field} ${styles[customClassName]}`}>
            <label>{labelText}</label>
            <textarea 
                name={name}
                value={value}
                data-type={datatype}
                rows={rows}
                cols={columns}
                onChange={handleChange}
            />
            <p 
                className={styles.textarea_validation_error_message}
                style={{ display: validationError && !value ? "block" : "none" }}
            >
                {validationErrorMessage}
            </p>
        </div>
    );

}

export default TextAreaInputField;
