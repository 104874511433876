import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { addNewAppointmentInfo } from "../../../store/calendar/calendarReducer";
import styles from "./SelectTime.module.css";
import HiddenDiv from "../common/HiddenDiv";

const SelectTime: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { newAppointmentInfo, availableDateTimes } = calendarState;
    const { selectedDateForRescheduling, startTimeForRescheduling } = newAppointmentInfo;

    const selectTime = (choosenTime: string) => {
        dispatch(addNewAppointmentInfo({
            name: "startTimeForRescheduling",
            value: choosenTime
        }))
    } 
    
    return (
        <div className = {styles.select_time}>
            <div className = {styles.available_times}>
                <div className={styles.available_time_list}>
                    {availableDateTimes.map(availableDateTime => {

                        const { dateString, availableTimes } = availableDateTime;
                        
                        return (
                            <div key = {dateString}>
                                {
                                    (selectedDateForRescheduling === dateString)
                                    &&
                                    <div className = {styles.time}>
                                        {
                                            !availableTimes.length
                                            ?
                                            <div className = {styles.no_available_time}>No available time found.</div>
                                            :
                                            availableTimes.map(time => {

                                                const paragraphStyle = { 
                                                    color: startTimeForRescheduling === `${selectedDateForRescheduling}T${time}` ? "blue" : "black" 
                                                };

                                                return (
                                                    <div key = {time} className={styles.time_content} >
                                                        <HiddenDiv 
                                                            handleClick={selectTime}
                                                            handleClickArgs={`${selectedDateForRescheduling}T${time}`}
                                                        />
                                                        <p className = {styles.time_content_text} style = {paragraphStyle}>
                                                            {time}
                                                        </p>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );

}

export default SelectTime;