import { Provider } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import store from "./store";
import Router from "./Router";
import styles from "./App.module.css";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY as string;
const stripePromise = loadStripe(stripePublicKey);

const App = () => {

    return (
        <div className={styles.App}>
            <Elements stripe={stripePromise}>
                <Provider store={store}>
                    <Router />
                </Provider>
            </Elements>
        </div>
    );
    
}

export default App;