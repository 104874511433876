import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { handleChange, closeAddEditDiscount } from "../../store/discounts/discountReducer";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./index.module.css";

const SaveButton: React.FC = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const discountState = useSelector((state: RootState) => state.discountState);
    const { 
        discount,
        discountTitle,
        selectedLocations,
        selectedStylists,
        selectedTreatments,
        offPeakDiscountList,
        lastMinuteDiscountRate,
        lastMinuteDiscountHours,
        discountList
    } = discountState;

    const saveDiscountItem =  async () => {

        // Validations
        if(!discountTitle) {
            dispatch(handleChange({
                name: "validationError",
                value: true
            }));
            return;
        } 

        if(!selectedLocations.length || !selectedStylists.length || !selectedTreatments.length) {
            return alert("Please select location, stylist and treatment");
        }

        let url = "create";
        if(discount) url = "edit";

        const endpoint = config.url.BACKEND_API_URL + "/discounts/" + url;

        dispatch(closeAddEditDiscount());
        dispatch(handleChange({
            name: "actionMessage",
            value: discount ? "Updating..." : "Creating..." 
        }));

        // _id must be removed from each offPeakDiscount item, otherwise it will have a conflict at the backend
        const newOffPeakDiscountList = offPeakDiscountList.map(item => {
            return {
                ...item,
                _id: undefined
            }
        });
        
        const headers = { "auth-token": cookies.get("auth_token") };
        const requestBody = {
            discountId: discount ? discount._id : null,
            discountTitle, 
            selectedLocations, 
            selectedStylists, 
            selectedTreatments, 
            offPeakDiscountList: newOffPeakDiscountList, 
            lastMinuteDiscountRate,
            lastMinuteDiscountHours
        };

        try {

            const response = await axios.post(endpoint, requestBody, { headers });
            const { savedDiscount } = response.data;

            const foundDiscount = discountList.find(item => item._id === savedDiscount._id);

            dispatch(handleChange({
                name: "actionMessage",
                value: discount ? "Updated Successfully" : "Created Successfully"
            }));

            if(foundDiscount) {
                const newDiscountList = discountList.map(item => {
                    if(item._id === savedDiscount._id) return savedDiscount;
                    return item;
                });
                dispatch(handleChange({
                    name: "discountList",
                    value: newDiscountList
                }));
                return;
            }
            
            dispatch(handleChange({
                name: "discountList",
                value: [...discountList, savedDiscount]
            }));
          

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(handleChange({
                name: "actionMessage",
                value: message
            }));
        }
    
    }

    return (
        <button className = {styles.button} onClick = {saveDiscountItem}>
            Save
        </button>
    );

}

export default SaveButton;