import { RiFolderReceivedLine } from "react-icons/ri";
import { MdViewCompact } from "react-icons/md";
import { MdOutlineLegendToggle } from "react-icons/md";
import styles from "./Buttons.module.css";
import EditIcon from "../../icons/EditIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import SellIcon from "../../icons/SellIcon";
import ActionIconButton from "./ActionIconButton";

interface ActionButtonsProps {
    actionTypeList: string[]
    itemId: string
    showButtonText?: boolean
    disableReceiveButton?: boolean
    handleClick: (itemId: string, actionType: string) => void
}

const ActionButtons: React.FC<ActionButtonsProps> = (props) => {

    const { 
        actionTypeList,
        itemId,
        showButtonText = true, 
        disableReceiveButton,
        handleClick 
    } = props;

    return (
        <div className={styles.action_buttons}> 
            {
                actionTypeList.includes("toggle")
                ?
                <ActionIconButton 
                    buttonText={showButtonText ? "Toggle" : ""}
                    icon={
                        <MdOutlineLegendToggle 
                            size={"1.4rem"}
                            color="#FFAAA5"
                        />
                    }
                    actionType={"toggle"}
                    itemId={itemId}
                    handleClick={handleClick}
                />
                :
                null
            }
            {
                actionTypeList.includes("view")
                ?
                <ActionIconButton 
                    buttonText={showButtonText ? "View" : ""}
                    icon={
                        <MdViewCompact 
                            size={"1.4rem"}
                            color="#FFAAA5"
                        />
                    }
                    actionType={"view"}
                    itemId={itemId}
                    handleClick={handleClick}
                />
                :
                null
            }
            {
                actionTypeList.includes("sell")
                ?
                <ActionIconButton 
                    buttonText={showButtonText ? "Sell" : ""}
                    icon={<SellIcon />}
                    actionType={"sell"}
                    itemId={itemId}
                    handleClick={handleClick}
                />
                :
                null
            }
            {
                actionTypeList.includes("receive")
                ?
                <ActionIconButton 
                    buttonText={showButtonText ? "Receive" : ""}
                    icon={
                        <RiFolderReceivedLine 
                            size={"1.4rem"}
                            color={disableReceiveButton ? "lightgray" : "#FFAAA5"}
                        />
                    }
                    actionType={"receive"}
                    itemId={itemId}
                    disabled={disableReceiveButton}
                    handleClick={handleClick}
                />
                :
                null
            }
            {
                actionTypeList.includes("edit")
                ?
                <ActionIconButton 
                    buttonText={showButtonText ? "Edit" : ""}
                    icon={<EditIcon />}
                    actionType="edit"
                    itemId={itemId}
                    handleClick={handleClick}
                />
                :
                null
            }
            {
                actionTypeList.includes("delete")
                ?
                <ActionIconButton 
                    buttonText={showButtonText ? "Delete" : ""}
                    icon={<DeleteIcon />}
                    actionType="delete"
                    itemId={itemId}
                    handleClick={handleClick}
                />
                :
                null
            }
        </div>            
    );

}

export default ActionButtons;