import { AiOutlineMail, AiFillPhone } from "react-icons/ai"
import { ICalendarAppointment } from "../../../types/pages/Calendar";
import ClientAvatarImage from "../../../assets/images/stylist_avatar.png"
import styles from "./AppointmentModal.module.css";
import SectionHeader from "./SectionHeader";
import ResendEmailButton from "./ResendEmailButton";

interface ClientDetailsProps {
    appointment: ICalendarAppointment | null
    canViewClientInfo?: boolean
}

const ClientDetails: React.FC<ClientDetailsProps> = ({ appointment, canViewClientInfo }) => {

    return (
        <div className = {styles.client_details}>
            <SectionHeader 
                headerText = "Client"
            />
            <div className = {styles.client_details_content}>
                <div className = {styles.client_photo}>
                    <img 
                        src = {ClientAvatarImage}
                        alt = {appointment?.clientName}
                    />
                </div>
                <div className = {styles.client_info}>
                    <h4 style = {{ marginBottom: canViewClientInfo ? "5px" : "0px" }}>
                        {appointment?.clientName}
                    </h4>
                    {
                        canViewClientInfo
                        ?
                        <div className = {styles.client_info_content}>
                            <div className = {styles.client_phone}>
                                <div className = {styles.client_info_icon}>
                                    <AiFillPhone />
                                </div>
                                <span>{appointment?.clientPhone}</span>
                            </div>
                            <div className = {styles.client_email}>
                                <div className = {styles.client_info_icon}>
                                    <AiOutlineMail />
                                </div>
                                <span>{appointment?.clientEmail}</span>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                {appointment?.clientName !== "blank" && <ResendEmailButton />}
            </div>
            {
                appointment?.clientNote
                ?
                <p className={styles.client_note}>
                    * {appointment.clientNote}
                </p>
                :
                null
            }
            {
                appointment?.packages && appointment.packages.length > 0
                ?
                <p className={styles.client_note}>
                    * This client has installment dues of {appointment.packages.length} package(s).
                </p>
                :
                null
            }
        </div>
    );

}

export default ClientDetails;