import { RootState } from "../store";
import { select, call, put, takeLatest } from "redux-saga/effects";
import { EmailState, FetchedEmailAPIData } from "../types/pages/Email";
import { fetchEmailAPIData } from "./api/emails/fetchEmailAPIData";
import { 
    fetchDataLoading,
    fetchDataSuccess, 
    fetchDataFail
} from "../store/emails/emailReducer";
import { FETCH_FOLLOW_UP_EMAIL_DATA } from "../store/emails/constants";
import { handleApiError } from "../helpers/error-handlers/handleApiError";

const emailState = (state: RootState) => state.emailState;

function* handleFetchEmailAPIData() {

    const { searchText, skip, limit, fetchLocationAndTreatmentData }: EmailState = yield select(emailState);

    try {
        yield put(fetchDataLoading());
        const result: FetchedEmailAPIData = yield call(fetchEmailAPIData, { 
            searchText, 
            skip, 
            limit,
            fetchLocationAndTreatmentData 
        });
        yield put(fetchDataSuccess(result));  
    } catch(error: any) {
        const { message } = handleApiError(error)
        yield put(fetchDataFail(message));
    }

}

export default function* emailSaga() {
    yield takeLatest(FETCH_FOLLOW_UP_EMAIL_DATA, handleFetchEmailAPIData);
}
