import { all } from "redux-saga/effects";
import tenantProfileSaga from "./tenantProfileSaga";
import locationSaga from "./locationSaga";
import treatmentSaga from "./treatmentSaga";
import userListSaga from "./userListSaga";
import calendarSaga from "./calendarSaga";
import couponSaga from "./couponSaga";
import discountSaga from "./discountSaga";
import reportSaga from "./reportSaga";
import packageSaga from "./packageSaga";
import waitListSaga from "./waitListSaga";
import giftCardSaga from "./giftCardSaga";
import productSaga from "./productSaga";
import appointmentClientSaga from "./appointmentClientSaga";
import emailSaga from "./emailSaga";

export default function* rootSaga() {

    yield all([
        tenantProfileSaga(),
        locationSaga(),
        treatmentSaga(),
        userListSaga(),
        calendarSaga(),
        couponSaga(),
        discountSaga(),
        reportSaga(),
        packageSaga(),
        waitListSaga(),
        giftCardSaga(),
        productSaga(),
        appointmentClientSaga(),
        emailSaga()
    ]);

}
