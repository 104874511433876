import styles from "./index.module.css";

interface IntegrationItemLogoProps {
    logo: React.ReactNode
}

const IntegrationItemLogo: React.FC<IntegrationItemLogoProps> = ({ logo }) => {

    return (
        <div className={styles.logo}>
            {logo}
        </div>  
    );

}

export default IntegrationItemLogo;