import axios from "axios";
import { EmailState } from "../../../types/pages/Email";
import { config } from "../../../Constants";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";

type Payload = Pick<EmailState, "searchText" | "skip" | "limit" | "fetchLocationAndTreatmentData">

export const fetchEmailAPIData = async(payload: Payload) => {

    const { searchText, skip, limit, fetchLocationAndTreatmentData } = payload;

    const params = `searchText=${searchText}&skip=${skip}&limit=${limit}&fetchLocationAndTreatmentData=${fetchLocationAndTreatmentData}`;
    
    const endpoint = `${config.url.BACKEND_API_URL}/emails/followup-emails?${params}`;
    const authConfig = getAuthTokenConfig();
  
    const response = await axios.get(endpoint, authConfig);
    return response.data;

}