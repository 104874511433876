import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser, UserState } from "../../types/models/User";

const initialState: UserState = {
    loading: false,
    error: false,
    user: null
}

const userListSlice = createSlice({
    name: "user",
    initialState,
    reducers: {

        fetchUserDataLoading: (state) => {
            return { ...state, loading: true }
        },

        fetchUserDataSuccess: (state, action: PayloadAction<IUser>) => {
            return {
                ...state,
                loading: false,
                user: action.payload
            }
        },

        fetchUserDataFail: (state) => {
            return {
                ...state,
                loading: false,
                error: true
            }
        },
        
        removeUser: (state) => {
            return {
                ...state,
                user: null
            }
        }

    }
})


export const {
    fetchUserDataLoading,
    fetchUserDataSuccess,
    fetchUserDataFail,
    removeUser
} = userListSlice.actions;

export default userListSlice.reducer;