import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { 
    ClientItem,
    CalendarState,
    FetchedData
} from "../../types/pages/Calendar";
import { IUser } from "../../types/models/User";
import { ILocation } from "../../types/models/Location";
import { calculateMonthDates } from "../../helpers/calendar/calculateMonthDates";
import { calculateWeekDates } from "../../helpers/calendar/calculateWeekDates";

const initialState: CalendarState = {
    loading: true,
    error: false,
    appointmentFetchError: null,
    validationError: false,
    locationList: [],
    selectedLocationList: [],
    selectedLocationIds: "",
    categoryList: [],
    treatmentList: [],
    stylistList: [],
    selectedStylistList: [],
    selectedStylistIds: "",
    appointmentList: [],
    availableDateTimes: [],
    dragRescheduleAppointmentId: null,
    dragRescheduleInfo: {
        isDropped: false,
        stylist: null,
        date: null,
        startTime: "",
        rescheduledBy: ""
    },
    selectedDateForCustomRescheduling: null,
    startTimeForCustomRescheduling: "",
    dayViewDate: new Date(),
    weekViewDates: calculateWeekDates(),
    monthViewDates: calculateMonthDates(),
    calendarViewType: "day",
    clickSelectedStartTime: "",
    clickSelectedEndTime: "",
    selectedTimes: [],
    appointmentInAction: null,
    showActivityByModal: false,
    activityType: "", 
    activityByLabel: "", 
    activityBy: "",
    couponCode: "",
    giftCardCode: "",
    paymentMethod: "",
    paymentAmount: "",
    doNotSendEmailToClient: false,
    refundAppointmentPrice: true,
    refundRate: "",
    isDeleting: false,
    cancelReason: "",
    isBookingAppointment: false,
    isBookingAppointmentForSearchedClient: false,
    isRescheduling: false,
    clientList: [],
    clientInAction: null,
    clientSearchText: "",
    clientAppointments: [],
    newAppointmentType: "regular",
    isRepeatingAppointment: false,
    repeatingAppointmentDateType: "everyday",
    repeatingAppointmentStartDate: null,
    repeatingAppointmentEndDate: null,
    appointmentDateList: [],
    newAppointmentInfo: {
        newAppointmentTitle: "",
        selectedLocation: null, 
        selectedCategory: null, 
        selectedTreatment: null,
        appointment_date: null, 
        selectedDateForRescheduling: "",
        startTime: "", 
        startTimeForRescheduling: "",
        endTime: "",
        selectedStylist: null, 
        paymentMethod: "",
        clientName: "",
        clientEmail: "",
        clientPhone: "",
        subscribeToMailchimp: false,
        couponCode: "",
        isCouponRedeemed: false,
        couponDiscountAmount: 0
    },
    changeTreatmentInfo: {
        appointmentId: null,
        appointmentTreatmentId: null,
        treatmentId: null,
        stylistId: null,
        paymentMethod: ""
    },
    serverResponseMessage: ""
}


const calendarSlice = createSlice({
    name: "calendar",
    initialState,
    reducers: {

        fetchDataSuccess: (state, action: PayloadAction<FetchedData>) => {
            // By default we need to fetch appointments of all locations. Because we are fetching
            // active locations only, but appointments collection has appointments of both deleted
            // and active locations. Instead of fetching locations at the backend to filter appointments
            //  we can simply pass all the active location ids from here. But we won't show selected locations
            // to the user when they first land on the calendar page. When they filter locations only appointments
            // of those locations will be fetched and they can see the selected locations. 
            const { locationList, categoryList, treatmentList, stylistList } = action.payload;
            return {
                ...state,
                loading: false,
                locationList,
                selectedLocationIds: locationList.map(item => item._id).join(","),
                selectedStylistList: stylistList,
                selectedStylistIds: stylistList.map(item => item._id).join(","),
                categoryList,
                treatmentList,
                stylistList
            }
        },

        fetchDataFail: (state) => {
            return {
                ...state,
                loading: false,
                error: true
            }
        },

        // selectedLocationIds can never be empty. But the reason why we are making it empty
        // because user might be revisiting calendar page and it can happen that before leaving
        // calendar page they filtered locations. But this empty string will be replaced when
        // locations are fetched inside fetchAppointmentDataSuccess action
        setSomeInitialState: (state) => {
            return {
                ...state,
                loading: true,
                clientSearchText: "",
                clientAppointments: [],
                selectedLocationList: [],
                selectedStylistList: [],
                selectedLocationIds: "",
                selectedStylistIds: "",
                calendarViewType: "day",
                dayViewDate: new Date(),
                weekViewDates: calculateWeekDates(),
                monthViewDates: calculateMonthDates()
            }
        },

        updateState: (state, action: PayloadAction<any>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            }
        },

        updateCalendarDates: (state, action: PayloadAction<{ name: string, value: Date | Date[] }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            }
        },

        updateSelectedLocationList: (state, action: PayloadAction<ILocation[]>) => {

            const newSelectedLocationList = action.payload;

            let selectedLocationIds = newSelectedLocationList.map(item => item._id).join(",");

            const matchedStylistList: IUser[] = [];

            state.stylistList.forEach(stylist => {
                const isWorkScheduleLocationMatch = stylist.workSchedule.some(schedule => {
                    const isLocationMatch = newSelectedLocationList.some(location => {
                        return location._id === schedule.location._id;
                    });
                    return isLocationMatch;
                });

                if(isWorkScheduleLocationMatch) matchedStylistList.push(stylist);
            });

            let selectedStylistIds = matchedStylistList.map(stylist => stylist._id).join(",");

            // selectedLocationIds can never be empty. If user unselects all the locations after
            // selecting some then we should update it based on locations api data.
            if(!newSelectedLocationList.length) {
                selectedLocationIds = state.locationList.map(location => location._id).join(",");
                selectedStylistIds = state.stylistList.map(stylist => stylist._id).join(",");
            }
            
            return {
                ...state,
                selectedLocationList: newSelectedLocationList,
                selectedStylistList: !matchedStylistList.length ? state.stylistList : matchedStylistList,
                selectedLocationIds,
                selectedStylistIds
            }

        },

        toggleActivityByModal: (state, action: PayloadAction<{ activityType: string, activityByLabel: string}>) => {
            const { activityType, activityByLabel } = action.payload;
            return {
                ...state,
                activityByLabel,
                activityType,
                showActivityByModal: !state.showActivityByModal
            }
        },

        openAddAppointmentModal: (
            state, 
            action: PayloadAction<{ 
                startTime: string, 
                endTime: string, 
                appointment_date: Date, 
                stylist: IUser
            }>
        ) => {

            const { startTime, endTime, appointment_date, stylist } = action.payload;

            const weekDay = appointment_date.toLocaleString("en-us", { weekday: "long" });
            const foundWorkSchedule = stylist.workSchedule.find(item => item.day === weekDay);

            let newSelectedLocation = null;

            if(foundWorkSchedule) {
                const foundLocation = state.locationList.find(item => item._id === foundWorkSchedule.location._id);
                newSelectedLocation = foundLocation ?? null;
            }

            return {
                ...state,
                isBookingAppointment: true,
                newAppointmentInfo: {
                    ...state.newAppointmentInfo,
                    startTime,
                    endTime,
                    appointment_date,
                    selectedLocation: newSelectedLocation,
                    selectedStylist: stylist
                }
            }

        },

        closeAddAppointmentModal: (state) => {
            return {
                ...state,
                activityBy: "",
                validationError: false,
                isBookingAppointment: false,
                doNotSendEmailToClient: false,
                newAppointmentType: "regular",
                isBookingAppointmentForSearchedClient: false,
                isRepeatingAppointment: false,
                repeatingAppointmentDateType: "everyday",
                repeatingAppointmentStartDate: null,
                repeatingAppointmentEndDate: null,
                appointmentDateList: [],
                newAppointmentInfo: {
                    newAppointmentTitle: "",
                    selectedLocation: null, 
                    selectedCategory: null, 
                    selectedTreatment: null,
                    appointment_date: null, 
                    selectedDateForRescheduling: "",
                    startTime: "", 
                    startTimeForRescheduling: "",
                    endTime: "",
                    selectedStylist: null, 
                    paymentMethod: "",
                    clientName: "",
                    clientEmail: "",
                    clientPhone: "",
                    subscribeToMailchimp: false,
                    couponCode: "",
                    isCouponRedeemed: false,
                    couponDiscountAmount: 0
                }
            }
        },

        addNewAppointmentInfo: (state, action: PayloadAction<{ name: string, value: any }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                newAppointmentInfo: {
                    ...state.newAppointmentInfo,
                    [name]: value
                }
            }
        },

        fillClientInfo: (state, action: PayloadAction<ClientItem>) => {
            const { name, email, phone } = action.payload
            return {
                ...state,
                newAppointmentInfo: {
                    ...state.newAppointmentInfo,
                    clientName: name,
                    clientEmail: email,
                    clientPhone: phone
                }
            }
        },

        bookClientAppointment: (state, action: PayloadAction<ClientItem>) => {
            const { name, email, phone } = action.payload
            return {
                ...state,
                newAppointmentInfo: {
                    ...state.newAppointmentInfo,
                    clientName: name,
                    clientEmail: email,
                    clientPhone: phone
                },
                isBookingAppointment: true,
                clientSearchText: "",
                clientInAction: null,
                clientAppointments: [],
                isBookingAppointmentForSearchedClient: true
            }
        },

        applyCouponDiscount: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                newAppointmentInfo: {
                    ...state.newAppointmentInfo,
                    isCouponRedeemed: true,
                    couponDiscountAmount: action.payload
                }
            }
        },

        updateAppointment: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                appointmentInAction: null,
                activityBy: "",
                showActivityByModal: false,
                isRescheduling: false,
                activityType: "",
                activityByLabel: "",
                couponCode: "",
                giftCardCode: "",
                paymentMethod: "", 
                paymentAmount: "",
                selectedDateForCustomRescheduling: null,
                startTimeForCustomRescheduling: "",
                clientInAction: null,
                clientSearchText: "",
                clientAppointments: [],
                doNotSendEmailToClient: false,
                refundAppointmentPrice: true,
                refundRate: "",
                serverResponseMessage: action.payload,
            }
        },

        chooseRepeatingAppointmentDateType: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                repeatingAppointmentDateType: action.payload,
                repeatingAppointmentStartDate: null,
                repeatingAppointmentEndDate: null,
                appointmentDateList: []
            }
        },

        closeChangeTreatmentModal: (state) => {
            return {
                ...state,
                changeTreatmentInfo: {
                    appointmentId: null,
                    appointmentTreatmentId: null,
                    treatmentId: null,
                    stylistId: null,
                    paymentMethod: ""
                }
            }
        },

    }
})

export const {
    fetchDataSuccess,
    fetchDataFail,
    updateState,
    setSomeInitialState,
    updateCalendarDates,
    updateSelectedLocationList,
    toggleActivityByModal,
    addNewAppointmentInfo,
    fillClientInfo,
    applyCouponDiscount,
    updateAppointment,
    openAddAppointmentModal,
    closeAddAppointmentModal,
    bookClientAppointment,
    chooseRepeatingAppointmentDateType,
    closeChangeTreatmentModal
} = calendarSlice.actions;

export default calendarSlice.reducer;