import styles from "./index.module.css";

interface ExportCSVButtonProps {
    exportToCSV: () => void
}

const ExportCSVButton: React.FC<ExportCSVButtonProps> = ({ exportToCSV }) => {

    return (
        <div className={styles.export_csv_btn}>
            <button onClick={exportToCSV}>
                Export To CSV
            </button>
        </div>     
    );
    
}

export default ExportCSVButton;