import React, { useEffect, useState } from "react";
import { IUser } from "../../types/models/User";
import { StartEndHourMinutes } from "../../types/pages/Calendar";
import { timeSlotGroups } from "../../data/calendar/timeSlotGroups";
import styles from "./Calendar.module.css";

interface TimeSlotProps {
    date: Date
    selectedStylistList: IUser[]
    stylist: IUser
    handleMouseUp: (stylist: IUser) => void
    handleMouseDown: (startEndHourMinute: StartEndHourMinutes, stylist: IUser, date: Date) => void
    handleMouseEnter: (startEndHourMinute: StartEndHourMinutes) => void
    handleDragOver: (event: React.DragEvent) => void
    handleDrop: (startHourMinutes: string, stylistId: string, date: Date) => void
}

const TimeSlotGroup: React.FC<TimeSlotProps> = ({ 
    date, 
    selectedStylistList,
    stylist,
    handleMouseUp,
    handleMouseDown,
    handleMouseEnter,
    handleDragOver,
    handleDrop 
}) => {

    const [availableTimes, setAvailableTimes] = useState<string[]>([])

    useEffect(() => {

        const availableTimeList: string[] = []

        const foundStylist = selectedStylistList.find(item => item._id === stylist._id);
        const stylistWorkSchedule = foundStylist?.workSchedule ?? [];
        const weekDay = date.toLocaleString("en-us", { weekday: "long" });

        for(let x = 0; x < timeSlotGroups.length; x++) {

            const timeSlot = timeSlotGroups[x];

            for(let y = 0; y < timeSlot.startEndHourMinutes.length; y++) {

                const { startHourMinutes } = timeSlot.startEndHourMinutes[y];

                const indexOfColon = startHourMinutes.indexOf(":");

                const timeSlotStartHours = Number(startHourMinutes.substring(0, indexOfColon));
                const timeSlotStartMinutes = Number(startHourMinutes.substring(indexOfColon + 1, startHourMinutes.length));

                let totalMinutes = timeSlotStartHours * 60 + timeSlotStartMinutes;

                for(let z = 0; z < stylistWorkSchedule.length; z++) {
                    const { day, startTime, endTime } = stylistWorkSchedule[z];

                    if(!(day === weekDay)) continue;

                    const startTimeHours = Number(startTime.substring(0, startTime.indexOf(":")));
                    const startTimeMinutes = Number(startTime.substring(startTime.indexOf(":") + 1, startTime.length));
        
                    const endTimeHours = Number(endTime.substring(0, endTime.indexOf(":")));
                    const endTimeMinutes = Number(endTime.substring(endTime.indexOf(":") + 1, endTime.length));
        
                    const startTimeInMinutes = startTimeHours * 60 + startTimeMinutes;
                    const endTimeInMinutes = endTimeHours * 60 + endTimeMinutes;

                    if(totalMinutes >= startTimeInMinutes && totalMinutes < endTimeInMinutes) {
                        availableTimeList.push(startHourMinutes);
                    }
                     
                }

            }
        }

        setAvailableTimes(availableTimeList);

    }, [date, stylist._id, selectedStylistList])

    return (
        <>
            {timeSlotGroups.map(timeSlotG => {
                return (
                    <div key={timeSlotG.hour} className={styles.timeslot_group} >
                        {timeSlotG.startEndHourMinutes.map((hourMinutes, index) => {
                            const isAvailable = availableTimes.includes(hourMinutes.startHourMinutes);
                            return (
                                <div 
                                    key={index}
                                    className={`${styles.timeslot} ${isAvailable ? styles.timeslot_available : ""}`}
                                    onMouseDown={() => handleMouseDown(hourMinutes, stylist, date)}
                                    onMouseUp={() => handleMouseUp(stylist)}
                                    onMouseEnter={() => handleMouseEnter(hourMinutes)}
                                    onDragOver={handleDragOver}
                                    onDrop={() => handleDrop(hourMinutes.startHourMinutes, stylist._id, date)}
                                >
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </>                
    );

}

export default React.memo(TimeSlotGroup);