import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPackage } from "../../types/models/Package";
import { PackageState, FetchedData, SelectedTreatmentWithSessions } from "../../types/pages/Package";

const initialState: PackageState = {
    loading: false,
    error: false,
    validationError: false,
    fetchTreatmentData: true,
    packageList: [],
    treatmentList: [],
    packageItemInAction: null,
    newPackageInfo: {
        title: "",
        description: "",
        price: "",
        highlights: [],
        selectedTreatmentList: [],
        areAllTreatmentsSelected: false,
        allTreatmentsSessionCount: ""
    },
    packageBuyInfo: {
        paymentMethod: "",
        clientName: "",
        clientEmail: "",
        clientPhone: ""
    },
    searchText: "",
    skip: 0, 
    limit: 5,
    totalPackageCount: 0,
    isBuying: false,
    isAdding: false,
    isEditing: false,
    isDeleting: false,
    actionMessage: ""
};

const packageSlice = createSlice({
    name: "packages",
    initialState,
    reducers: {

        fetchDataLoading: (state) => {
            return { ...state, loading: true };
        },

        fetchDataSuccess: (state, action: PayloadAction<FetchedData>) => {

            const { packageList, totalPackageCount, treatmentList } = action.payload;

            return {
                ...state,
                loading: false,
                packageList,
                treatmentList: treatmentList ?? state.treatmentList,
                totalPackageCount,
                fetchTreatmentData: false
            };

        },

        fetchDataFail: (state) => {
            return {
                ...state,
                loading: false,
                error: true
            };
        },

        updateState: (state, action: PayloadAction<{ name: string, value: any }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            }
        },

        addNewPackageInfo: (
            state, 
            action: PayloadAction<{ 
                name: string, 
                value: string | boolean | string[] | SelectedTreatmentWithSessions[] 
            }>
        ) => {

            const { name, value } = action.payload;

            return {
                ...state,
                newPackageInfo: {
                    ...state.newPackageInfo,
                    [name]: value
                }
            };

        },

        addPackageBuyInfo: (state, action: PayloadAction<{ name: string, value: string }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                packageBuyInfo: {
                    ...state.packageBuyInfo,
                    [name]: value
                }
            };

        },

        selectAllTreatment: (state, action: PayloadAction<{ areAllTreatmentsSelected: boolean, allTreatmentsSessionCount: string }>) => {

            const { areAllTreatmentsSelected, allTreatmentsSessionCount } = action.payload;

            return {
                ...state,
                newPackageInfo: {
                    ...state.newPackageInfo,
                    areAllTreatmentsSelected, 
                    allTreatmentsSessionCount
                }
            };

        },

        openEditPackageModal: (state, action: PayloadAction<IPackage>) => {

            const packageItem = action.payload;
            const { title, description, price, highlights, treatmentList } = packageItem;

            const newSelectedTreatments = treatmentList.map(item => {
                return {
                    ...item,
                    sessions: item.sessions.toString()
                }
            });

            const firstTreatmentSessionCount = treatmentList[0].sessions.toString();

            const areSessionCountsDifferent = treatmentList.some(item => {
                return item.sessions.toString() !== firstTreatmentSessionCount;
            });

            const isAllSelected = treatmentList.length >= state.treatmentList.length;

            const selectAllCondition = isAllSelected && !areSessionCountsDifferent;

            return { 
                ...state, 
                isEditing: true,
                packageItemInAction: packageItem,
                newPackageInfo: { 
                    areAllTreatmentsSelected: selectAllCondition,
                    allTreatmentsSessionCount: selectAllCondition ? firstTreatmentSessionCount : "",
                    title,
                    description,
                    highlights,
                    price: price.toString(),
                    selectedTreatmentList: selectAllCondition ? [] : newSelectedTreatments
                } 
            }
        },

        closeAddEditPackageModal: (state) => {
            return { 
                ...state, 
                isAdding: false,
                isEditing: false,
                packageItemInAction: null,
                newPackageInfo: {
                    title: "",
                    description: "",
                    price: "",
                    highlights: [],
                    selectedTreatmentList: [],
                    areAllTreatmentsSelected: false,
                    allTreatmentsSessionCount: ""
                } 
            }
        },

        togglePackageBuyModal: (state, action: PayloadAction<null | IPackage>) => {
            return {
                ...state,
                packageItemInAction: action.payload,
                isBuying: !state.isBuying,
                packageBuyInfo: {
                    paymentMethod: "",
                    clientName: "",
                    clientEmail: "",
                    clientPhone: ""
                }
            }
        },

        toggleDeletePrompt: (state, action: PayloadAction<null | IPackage>) => {
            return {
                ...state,
                packageItemInAction: action.payload,
                isDeleting: !state.isDeleting
            }
        }

    }
})

export const {
    fetchDataLoading,
    fetchDataSuccess,
    fetchDataFail,
    updateState,
    addNewPackageInfo,
    addPackageBuyInfo,
    selectAllTreatment,
    openEditPackageModal,
    closeAddEditPackageModal,
    togglePackageBuyModal,
    toggleDeletePrompt
} = packageSlice.actions;

export default packageSlice.reducer;