import { useParams } from "react-router-dom";
import useCreateStripeAccountLink from "../../hooks/backend_api/stripe/useCreateStripeAccountLink";
import styles from "./index.module.css";

const StripeRefreshUrl = () => {

    const { accountId } = useParams();
    const { loading, createStripeAccountLink } = useCreateStripeAccountLink();

    return (
        <div className={styles.stripe_refresh_url}>
            <div className={styles.success_try_again_message}>
                <p>Oops! Something went wrong and your link was expired.</p>
                <button 
                    disabled={loading}
                    onClick={() => createStripeAccountLink(accountId)}
                >
                    Try again
                </button> 
            </div>
        </div>
    );

}

export default StripeRefreshUrl;