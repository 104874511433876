import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { CouponTreatment } from "../../types/models/Coupon";
import { addNewCouponInfo, selectAllTreatment } from "../../store/coupons/couponReducer";
import InputField from "./InputField";
import CheckboxInputField from "./CheckboxInputField";
import styles from "./SelectTreatment.module.css";
import { SelectedTreatmentWithDiscountRate } from "../../types/pages/Coupon";

const SelectTreatment: React.FC = () => {

    const dispatch = useDispatch();
    const couponState = useSelector((state: RootState) => state.couponState);
    const { newCouponInfo, treatmentList } = couponState;
    const { 
        selectedTreatmentsWithDiscountRate, 
        areAllTreatmentsSelected,
        allTreatmentsDiscountRate 
    } = newCouponInfo;


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, treatmentItem: CouponTreatment) => {

        const { name, checked, value } = event.target;

        switch(name) {

            // It means user is checking/unchecking "Select All" treatments checkbox input field.
            case "selectAlltreatment":

                // Don't let user check "Select All" if he/she has checked one or more individual treatments
                if(selectedTreatmentsWithDiscountRate.length) return;

                const payload = { areAllTreatmentsSelected: checked, allTreatmentsDiscountRate: "" };
                dispatch(selectAllTreatment(payload))
                return;


            // It means user is adding all treatments discount rate
            case "allTreatmentsDiscountRate":

                // Don't let user add discount rate if he/she hasn't checked "Select All" or individual treatments
                if(!areAllTreatmentsSelected || selectedTreatmentsWithDiscountRate.length) return;

                const newPayload = { areAllTreatmentsSelected, allTreatmentsDiscountRate: value };
                dispatch(selectAllTreatment(newPayload));
                return;



            // It means user is checking/unchecking individual treatment checkbox input field.
            case "treatment":

                // Don't let user check individual treatments if he/she has already checked "Select All"
                if(areAllTreatmentsSelected) return;

                if(checked) {
                    const newTreatment: SelectedTreatmentWithDiscountRate = { 
                        treatmentItem, 
                        discountRate: "" 
                    };
                    const newSelectedTreatments = [...selectedTreatmentsWithDiscountRate, newTreatment];
                    dispatch(addNewCouponInfo({
                        name: "selectedTreatmentsWithDiscountRate",
                        value: newSelectedTreatments
                    }))
                    return;
                }
    
                const filteredSelectedTreatments = selectedTreatmentsWithDiscountRate.filter(item => {
                    return item.treatmentItem._id !== treatmentItem._id;
                });
                dispatch(addNewCouponInfo({
                    name: "selectedTreatmentsWithDiscountRate",
                    value: filteredSelectedTreatments
                }))
                return;


            // It means user is adding discount rate to a selected treatment
            default:

                // Don't let user check individual treatments if he/she has already checked "Select All"
                if(areAllTreatmentsSelected) return;

                const newSelectedTreatments = selectedTreatmentsWithDiscountRate.map(item => {
                    if(item.treatmentItem._id === treatmentItem._id) {
                       return { ...item, discountRate: value };
                    }
                    return item;
                });

                dispatch(addNewCouponInfo({
                    name: "selectedTreatmentsWithDiscountRate",
                    value: newSelectedTreatments
                }))

        }

    }

    return (
        <div className = {styles.select_treatment}>
            <h2>Choose treatment</h2>
            <div className = {styles.treatment_item}>
                <CheckboxInputField 
                    name = "selectAlltreatment"
                    value = "Select All"
                    checked = {areAllTreatmentsSelected}
                    handleChange = {handleChange}
                />
                <InputField 
                    customClassName = "select_treatment_input"
                    type = "text"
                    name = "allTreatmentsDiscountRate"
                    value = {allTreatmentsDiscountRate}
                    handleChange = {handleChange}
                />
            </div>
            {treatmentList.map(treatment => {
                const { _id, title } = treatment;
                const foundSelectedTreatment = selectedTreatmentsWithDiscountRate.find(item => {
                    return item.treatmentItem._id === _id
                });
                return (
                    <div key = {_id} className = {styles.treatment_item}>
                        <CheckboxInputField 
                            name = "treatment"
                            value = {title}
                            checked = {!!foundSelectedTreatment}
                            handleChange = {handleChange}
                            handleChangeArgs = {treatment}
                        />
                        <InputField 
                            customClassName = "select_treatment_input"
                            type = "text"
                            name = "discountRate"
                            value = {foundSelectedTreatment ? foundSelectedTreatment.discountRate.toString() : ""}
                            handleChange = {handleChange}
                            handleChangeArgs = {treatment}
                        />
                    </div>
                );
            })}
        </div>
    );

}


export default SelectTreatment;