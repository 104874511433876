import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { FETCH_FOLLOW_UP_EMAIL_DATA } from "../../store/emails/constants";
import { 
    updateState, 
    addNewFollowUpEmailInfo,
    closeAddEditFollowUpEmailModal
} from "../../store/emails/emailReducer";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./index.module.css";
import InputField from "../../components/common/input-fields/InputField";
import ValidationErrorMessage from "../../components/common/messages/ValidationErrorMessage";
import SelectLocation from "./SelectLocation";
import SelectTreatment from "./SelectTreatment";
import SaveButton from "./SaveButton";

const AddEditFollowUpEmail: React.FC = () => {

    const dispatch = useDispatch();
    const emailState = useSelector((state: RootState) => state.emailState);
    const {
        isAdding,
        locationList,
        followUpEmailItemInAction,
        newFollowUpEmailInfo,
        validationError, 
    } = emailState;

    const [value, setValue] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    useEffect(() => {
        setValue(newFollowUpEmailInfo.emailText)
    }, [newFollowUpEmailInfo.emailText])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        dispatch(addNewFollowUpEmailInfo({ name, value }));
    }

    const saveFollowUpEmail = async(event: React.MouseEvent<HTMLButtonElement> | SubmitEvent) => {
        
        event.preventDefault();

        if(errorMsg) setErrorMsg(undefined);

        const { title, followUpHours, locationList, treatmentList } = newFollowUpEmailInfo;

        if(!title || !value || !followUpHours) {
            dispatch(updateState({
                name: "validationError",
                value: true
            }));
            return;
        }

        if(!locationList.length || !treatmentList.length) {
            let message = "Please select at least one treatment";
            if(locationList.length > 1) {
                message = "Please select at least one location and treatment"
            }
            setErrorMsg(message);
        }

        const requestBody = {
            followUpEmailId: followUpEmailItemInAction?._id,
            title,
            followUpHours: parseInt(followUpHours),
            emailText: value,
            locationList,
            treatmentList
        };

        let url = isAdding ? "/create-followup-email" : "/edit-followup-email"
        const endpoint = config.url.BACKEND_API_URL + "/emails" + url;
        const authConfig = getAuthTokenConfig();

        dispatch(closeAddEditFollowUpEmailModal());
        setLoading(true);
        dispatch(updateState({
            name: "actionMessage",
            value: "Saving..."
        }));

        try {

            await axios.post(endpoint, requestBody, authConfig);
            setLoading(false);
            dispatch(updateState({
                name: "actionMessage",
                value: "Saved"
            }));

            dispatch({ type: FETCH_FOLLOW_UP_EMAIL_DATA })
          
        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(updateState({
                name: "actionMessage",
                value: message
            }));
            setLoading(false);
        }

    }

    return (
        <div className={styles.add_edit_followup_email}>
            <h2 className={styles.add_edit_header}>
                {isAdding ? "Create New Email" : "Edit Email"}
            </h2>
            <form>
                <InputField 
                    labelText="Title"
                    name="title"
                    value={newFollowUpEmailInfo.title}
                    required={true}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="title can't be blank"
                />
                <InputField 
                    labelText="Follow Up Hours"
                    name="followUpHours"
                    value={newFollowUpEmailInfo.followUpHours}
                    required={true}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="follow up hours can't be blank"
                />
                <label>Email Content</label>
                <ReactQuill 
                    theme="snow" 
                    value={value} 
                    onChange={setValue} 
                    style={{
                        height: "200px",
                        marginBottom: "70px"
                    }}
                />
                {locationList.length > 1 ? <SelectLocation /> : null}
                <SelectTreatment />
                {errorMsg ? <ValidationErrorMessage message={errorMsg}/> : null}
                <SaveButton 
                    disabled={loading}
                    onClick={saveFollowUpEmail}
                />
            </form>
        </div>
    );

}

export default AddEditFollowUpEmail;