import styles from "./index.module.css";
import InputField from "../../components/common/input-fields/InputField";

interface NewContentProps {
    content: Record<string, any>,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    addContent: () => void
}

const NewContent: React.FC<NewContentProps> = ({ content, handleChange, addContent }) => {

    return (
        <div className={styles.new_content_item}>
            <InputField 
                labelText="Hours Before"
                type="number" 
                name="hoursBeforeAppointmentTime" 
                value={content.hoursBeforeAppointmentTime} 
                handleChange={handleChange} 
            />
            <InputField 
                labelText="Charge (%)"
                type="number" 
                name="applicableAppointmentChargeRate" 
                value={content.applicableAppointmentChargeRate} 
                handleChange={handleChange} 
            />
            <button onClick={addContent}>
                Add
            </button>
        </div>
    );
}

export default NewContent;
