import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeUser } from "../store/user/userReducer";
import Cookies from "universal-cookie";
import LogoutIcon from "../components/icons/LogoutIcon";
import IconButton from "../components/common/buttons/IconButton";


const LogoutButton: React.FC = () => {

    const cookies = new Cookies();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const logout = () => {

        // Must specify path to remove cookie
        cookies.remove("auth_token", { path: "/" }); 

        localStorage.removeItem("tenantId");
        localStorage.removeItem("userId");
        dispatch(removeUser());
        navigate("/");
        
    }


    return (
        <IconButton 
            customClassName = "logout_icon_btn"
            buttonText = "Logout"
            icon = {<LogoutIcon />} 
            handleClick = {logout}
        />
    );

}


export default LogoutButton;