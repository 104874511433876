import styles from "./index.module.css";

interface TableMessageTRProps {
    colSpan: number
    message: React.ReactNode
}

const TableMessageTR: React.FC<TableMessageTRProps> = ({ colSpan, message }) => {

    return (
        <tr>
            <td colSpan={colSpan} className={styles.table_message_tr}>
                {message}
            </td>
        </tr>
    );

}

export default TableMessageTR;