import { useSelector } from "react-redux";
import { IGiftCard } from "../../types/models/GiftCard";
import { RootState } from "../../store";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./GiftCardTable.module.css";
import ActionButtons from "./ActionButtons";

interface ResponsiveGiftCardTableProps {
    handleClick: (giftCard: IGiftCard) => void
}

const ResponsiveGiftCardTable: React.FC<ResponsiveGiftCardTableProps> = ({ handleClick }) => {

    const giftCardState = useSelector((state: RootState) => state.giftCardState);
    const { giftCardList } = giftCardState;
   
    return (
        <div className={styles.responsive_gift_card_table}>
            {giftCardList.map(giftCard => {

                 const { code, buyDate, amount, usedAmount, buyerInfo } = giftCard;

                 const { day, month, year } = formatDateTime(new Date(buyDate * 1000));
                
                return (
                    <div 
                        key={code}
                        className={styles.responsive_gift_card_item}
                    >
                        <div className={styles.responsive_code_actions}>
                            <p className={styles.responsive_gift_card_code}>{code}</p>
                            <ActionButtons 
                                showButtonText= {false}
                                giftCard={giftCard}
                                handleClick={handleClick}
                            />
                        </div>
                        <div className={styles.responsive_other_info}>
                            <p>Buy Date: {`${day} ${month}, ${year}`}</p>
                            <p>Amount: €{amount.toFixed(2)}</p>
                            <p>Amount Available: €{(amount - usedAmount).toFixed(2)}</p>
                            <p>{buyerInfo.fullName}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );

}

export default ResponsiveGiftCardTable;
