import { getFormattedDateTime } from "../../../helpers/date-time/getFormattedDateTime";

interface IProps {
    text: string
    paymentDate: number
    paymentMethod: string
}

const PaymentDate: React.FC<IProps> = ({ text, paymentDate, paymentMethod }) => {

    const { day, month, year, hours, minutes } = getFormattedDateTime(new Date(paymentDate * 1000));

    return (  
        <tr>
            <td>{text}</td>
            {
                paymentMethod
                ?
                <td>
                    {`By ${paymentMethod} on ${day} ${month}, ${year} @ ${hours}:${minutes}`}
                </td>
                :
                <td>
                    {`${day} ${month}, ${year} - ${hours}:${minutes}`}
                </td>
            }
        </tr>
    );

}

export default PaymentDate;