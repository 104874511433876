import { useEffect, useState } from "react";
import { IUser } from "../types/models/User";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import styles from "./TopNav.module.css";
import { MainLogo } from "../components/icons/MainLogo";
import ResponsiveProfileShortcut from "./ResponsiveProfileShortcut";
import NavItemList from "./NavItemList";
import MyProfileNavItem from "./MyProfileNavItem";
import LogoutButton from "./LogoutButton";
import UserInfo from "./UserInfo";

interface TopNavProps {
    user: IUser | null
    currentUrl: string
    myProfileUrl: string
}

const TopNav: React.FC<TopNavProps> = ({ user, currentUrl, myProfileUrl }) => {

    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false);

    useEffect(() => {
        setShowMenu(false);
        setShowProfileMenu(false);
    }, [currentUrl])

    const toggleMenu = () => {
        setShowMenu(!showMenu);
        setShowProfileMenu(false);
    }

    const toggleProfileMenu = () => {
        setShowProfileMenu(!showProfileMenu);
        setShowMenu(false);
    }
    
    return (
        <nav className={styles.topnav}>
            <div className={styles.menu_bar_btn}>
                <div 
                    className={styles.menu_btn_hidden_div} 
                    onClick={toggleMenu}
                >
                </div>
                <HiOutlineMenuAlt1 size={"1.5rem"}/>
            </div>
            <MainLogo 
                marginBottom="0px"
                logoWidth="40px"
            />
            <ResponsiveProfileShortcut 
                user={user}
                toggleProfileMenu={toggleProfileMenu}
            />
            {
                (showMenu && (user?.stripeSubscription || user?.isOnTrialPeriod)) 
                ?
                <div className={styles.menu}>
                    <NavItemList 
                        currentUrl={currentUrl}
                    />
                </div>
                : 
                null
            }
            {
                showProfileMenu 
                ?
                <div className={styles.profile_menu}>
                    <UserInfo 
                        user={user}
                    />
                    <div className={styles.profile_menu_urls}>
                        <MyProfileNavItem 
                            currentUrl={currentUrl}
                            myProfileUrl={myProfileUrl}
                        />
                    </div>
                    <LogoutButton />
                </div>
                : 
                null
            }
        </nav>
    );
}

export default TopNav;