import { call, put, takeLatest } from "redux-saga/effects";
import { FETCH_LOCATION_DATA } from "../store/locations/constants";
import { FetchLocationDataQueryParams, FetchedLocationData } from "../types/pages/Location";
import { 
    fetchLocationDataLoading,
    fetchLocationDataSuccess, 
    fetchLocationDataFail
} from "../store/locations/locationReducer";
import { fetchLocationData } from "./api/locations/fetchLocationData";


function* handleFetchLocationData(action: { type: string, payload: FetchLocationDataQueryParams }) {

    try {

        yield put(fetchLocationDataLoading())
        const result: FetchedLocationData = yield call(fetchLocationData, action.payload);
        yield put(fetchLocationDataSuccess(result))

    } catch(error) {
        yield put(fetchLocationDataFail())
    }

}


export default function* locationSaga() {
    yield takeLatest(FETCH_LOCATION_DATA, handleFetchLocationData);
}