const prod = {
    url: {
        BACKEND_API_URL: "https://resources.bookitu.com/api",
        IMAGE_API_URL: "https://media.bookitu.com/assets"
    }
}

const dev = {
    url: {
        BACKEND_API_URL: "http://localhost:5000/api",
        IMAGE_API_URL: "http://localhost:4000/assets"
    }
}

export const config = process.env.NODE_ENV === "development" ? dev : prod;
