import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { config } from "../../Constants";
import { RootState } from "../../store";
import { UserAuth } from "../../auth/UserAuth";
import { FETCH_APPOINTMENT_CLIENT_DATA } from "../../store/appointment-clients/constants";
import { 
    updateState, 
    openEditAppointmentClientModal,
    closeAddEditAppointmentClientModal,
    toggleAppointmentClientDeletePrompt 
} from "../../store/appointment-clients/appointmentClientReducer";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./index.module.css";
import Modal from "../../components/common/modal/Modal";
import ActionMessage from "../../components/common/messages/ActionMessage";
import DataTable from "./DataTable";
import AddEditAppointmentClient from "./AddEditAppointmentClient";
import DeleteAppointmentClient from "./DeleteAppointmentClient";

const AppointmentClientList = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const clientEmail = searchParams.get("clientEmail");
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const appointmentClientState = useSelector((state: RootState) => state.appointmentClientState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const {
        appointmentClientInAction,
        searchText, 
        isAdding, 
        isEditing, 
        isDeleting,
        actionMessage 
    } = appointmentClientState;

    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // User must be on trial period or must have bought a sub plan to access the products
        if(!user.stripeSubscription && !user.isOnTrialPeriod) {
            navigate("/");
            return;
        }

        const delayAPICall = setTimeout(() => {
            dispatch({ type: FETCH_APPOINTMENT_CLIENT_DATA })
        }, 500);

        return () => clearTimeout(delayAPICall);
        
    }, [user, searchText, dispatch, navigate]);


    useEffect(() => {

        if(!clientEmail) return;

        (async function(){
            
            const endpoint = config.url.BACKEND_API_URL + "/clients/appointment-clients/" + clientEmail;
            const authConfig = getAuthTokenConfig();

            try {

                const response = await axios.get(endpoint, authConfig);
                dispatch(openEditAppointmentClientModal(response.data.appointmentClient));
                searchParams.delete("clientEmail");
                setSearchParams(searchParams);

            } catch(error) {
                const { message } = handleApiError(error);
                alert(message);
            }

        })();

    }, [clientEmail, dispatch, searchParams, setSearchParams])

    const closeModal = () => {
      
        if(isAdding || isEditing) {
            dispatch(closeAddEditAppointmentClientModal());
            return;
        }

        if(actionMessage) {
            dispatch(updateState({
                name: "actionMessage",
                value: null
            }));
            return;
        }

        dispatch(toggleAppointmentClientDeletePrompt(null));
        
    }

    return (
        <div className={styles.appointment_clients_page}>
            <DataTable />
            {
                (isAdding || isEditing)
                ?
                <Modal 
                    modalContent={<AddEditAppointmentClient />}
                    onCloseModal={closeModal}
                />
                :
                null
            }
           
            {
                actionMessage
                ?
                <ActionMessage 
                    actionMessage={actionMessage}
                    hideActionMessage={closeModal}
                />
                :
                null
            }
            {(isDeleting && appointmentClientInAction) ? <DeleteAppointmentClient /> : null}
        </div>
    );

}

export default AppointmentClientList;