import styles from "./AppointmentModal.module.css";

const SectionHeader: React.FC<{ headerText: string}> = ({ headerText }) => {

    return (
        <div className = {styles.section_header}>
            <h4>{headerText}</h4>
        </div>

    );

}

export default SectionHeader;