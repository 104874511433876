import React from "react";
import { IUser } from "../../types/models/User";
import { ICalendarAppointment } from "../../types/pages/Calendar";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./Calendar.module.css";
import MonthViewEvents from "./MonthViewEvents";

interface MonthViewProps {
    selectedStylistList: IUser[]
    appointmentList: ICalendarAppointment[]
    weekViewDates: Date[]
    monthViewDates: Date[]
    switchToDayView: (date: Date) => void
}

const MonthView: React.FC<MonthViewProps> = (props) => {

    const { 
        selectedStylistList, 
        appointmentList, 
        weekViewDates,
        monthViewDates,
        switchToDayView
    } = props;

    const currentMonth = monthViewDates[20].getMonth();

    return (  
        <div className={styles.month_view}>
            {selectedStylistList.map(stylist => {

                const stylistAppointments = appointmentList.filter(item => item.stylistItem?._id === stylist._id);

                return (
                    <div className = {styles.month_times_container} key = {stylist._id}>
                        <div className = {styles.week_stylist_name_container}>
                            <div className = {styles.week_stylist_name}>
                                {stylist.fullName}
                            </div>
                            <div className = {styles.week_dates_container}>
                                {weekViewDates.map((weekDate, index) => {
                                    const dayName = weekDate.toLocaleString("en-us", {  weekday: "short" });
                                    return (
                                        <div key = {index} className={styles.week_date}>
                                            {dayName}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className = {styles.month_dates_container} >
                            {monthViewDates.map((monthDate, index) => {

                                const { dateString: monthDateString } = formatDateTime(monthDate);

                                const dateAppointments = stylistAppointments.filter(item => {
                                    const { dateString: appointmentDateString } = formatDateTime(new Date(item.appointment_date * 1000))
                                    return monthDateString === appointmentDateString;
                                });

                                const dateMonth = monthDate.getMonth();
                  
                                return (
                                    <div 
                                        key = {index}
                                        className = {styles.month_date}
                                        onClick={() => switchToDayView(monthDate)}
                                        style = {{
                                            backgroundColor: currentMonth !== dateMonth ? "lightgray" : "white"
                                        }}
                                    >
                                        <p className={styles.month_date_date}>
                                            {monthDate.getDate()}
                                        </p>
                                        <MonthViewEvents 
                                            date = {monthDate}
                                            events={dateAppointments}
                                        />
                                        {
                                            dateAppointments.length > 2
                                            ?
                                            <span onClick={() => switchToDayView(monthDate)}>
                                                + {dateAppointments.length - 2} more
                                            </span>
                                            :
                                            null
                                        }
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );

}

export default React.memo(MonthView);
