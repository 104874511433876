import { useSelector } from "react-redux";
import { RootState } from "../../store";
import styles from "./index.module.css";
import InputField from "./InputField";

interface BuyerInfoProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void
}

const BuyerInfo: React.FC<BuyerInfoProps> = ({ handleChange }) => {

    const giftCardState = useSelector((state: RootState) => state.giftCardState);
    const { giftCardBuyer, validationError } = giftCardState;
    const { fullName, email, phone, address } = giftCardBuyer;

    return (
        <div className={styles.buyer_info}>
            <h3>Buyer Info</h3>
            <InputField 
                labelText="Full Name"
                datatype="buyer"
                type="text"
                name="fullName"
                value={fullName}
                handleChange={handleChange}
                validationError={validationError}
                validationErrorMessage="full name can't be blank"
            />
            <div className={styles.email_phone}>
                <InputField 
                    customClassName="email_phone_input"
                    labelText="Email Address"
                    datatype="buyer"
                    type="email"
                    name="email"
                    value={email}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="email can't be blank"
                />
                <InputField 
                    customClassName="email_phone_input"
                    labelText="Phone"
                    datatype="buyer"
                    type="text"
                    name="phone"
                    value={phone}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="phone can't be blank"
                />
            </div>
            <InputField 
                labelText="Address"
                datatype="buyer"
                type="text"
                name="address"
                value={address}
                handleChange={handleChange}
            />
        </div>
    );
}


export default BuyerInfo;