import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./ResponsiveItemDetails.module.css";
import ItemHeader from "./ItemHeader";
import ItemContent from "./ItemContent";

const ResponsiveClientTable: React.FC = () => {

    const reportState = useSelector((state: RootState) => state.reportState);
    const { clientList } = reportState;

    return (
        <>
            {clientList.map(client => {

                const { 
                    name, 
                    email, 
                    phone, 
                    lastVisitDate, 
                    firstVisitDate,
                    totalAppointmentCount,
                    cancelledAppointmentCount 
                } = client;

                const { 
                    day: lastVisitDay, 
                    month: lastVisitMonth, 
                    year: lastVisitYear 
                } = formatDateTime(new Date(lastVisitDate * 1000));

                const { 
                    day: firstVisitDay, 
                    month: firstVisitMonth, 
                    year: firstVisitYear 
                } = formatDateTime(new Date(firstVisitDate * 1000));
            
                return (
                    <div 
                        key = {email}
                        className = {styles.responsive_appointment_item}
                    >
                        <ItemHeader 
                            title={name}
                            content={totalAppointmentCount.toString()}
                        />
                        <ItemContent 
                            title={email}
                            content={cancelledAppointmentCount.toString()}
                            otherInfo={[
                                phone,
                                `First Visit: ${firstVisitMonth} ${firstVisitDay}, ${firstVisitYear}`,
                                `Last Visit: ${lastVisitMonth} ${lastVisitDay}, ${lastVisitYear}`
                            ]}
                        />
                        
                    </div>
                );
            })}
            
        </>
    );

}

export default ResponsiveClientTable;