import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { FaRegEdit } from "react-icons/fa";
import { IoSaveOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { config } from "../../../Constants";
import { IAppointment } from "../../../types/models/Appointment";
import { updateState } from "../../../store/calendar/calendarReducer";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";
import styles from "./AppointmentModal.module.css";
import SectionHeader from "./SectionHeader";
import TextAreaInputField from "../../../components/common/input-fields/TextAreaInputField";

type AppointmentNoteProps = {
    appointment: IAppointment
    appointmentList: IAppointment[]
}

const AppointmentNote: React.FC<AppointmentNoteProps> = ({ appointment, appointmentList }) => {

    const dispatch = useDispatch();

    const loremIpsum = `Write your note here.`;
    const [note, setNote] = useState<string>();
    const [showAppointmentNoteInput, setShowAppointmentNoteInput] = useState(false);
    const [validationError, setValidationError] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        setNote(value);
    }

    const handleEditButtonClick = () => {
        setShowAppointmentNoteInput(true);
        setNote(appointment.note)
    }

    const handleCancelButtonClick = () => {
        setShowAppointmentNoteInput(false);
        setNote(undefined);
    }

    const saveAppointmentNote = async () => {

        if(validationError) setValidationError(false);
        if(!note) return setValidationError(true);

        const endpoint = config.url.BACKEND_API_URL + "/appointments/edit-appointment-note";
        const authConfig = getAuthTokenConfig();
        const requestBody = {
            appointmentId: appointment._id,
            note
        };

        setShowAppointmentNoteInput(false);

        try {

            await axios.post(endpoint, requestBody, authConfig);
            const newAppointmentList = appointmentList.map(item => {
                if(item._id === appointment._id) return { ...item, note }
                return item;
            });

            dispatch(updateState({
                name: "appointmentList",
                value: newAppointmentList
            }));

            dispatch(updateState({
                name: "appointmentInAction",
                value: { ...appointment, note }
            }));

            setNote(undefined);

        } catch(error) {
            alert("Something went wrong");
        }

    }
    

    return (
        <div className={styles.appointment_note}>
            <SectionHeader 
                headerText="Note"
            />
            <div className={styles.appointment_note_content}>
                {
                    !showAppointmentNoteInput
                    &&
                    <div className={styles.appointment_note_text}>
                        <p>{appointment.note ?? loremIpsum}</p>
                        <button 
                            className={styles.appointment_note_edit_button} 
                            onClick={handleEditButtonClick}
                        >
                            <FaRegEdit />
                        </button>
                    </div>
                }
                {
                    showAppointmentNoteInput
                    &&
                    <div className={styles.edit_appointment_note}>
                        <TextAreaInputField 
                            rows={3} 
                            columns={3}
                            name="appointmentNote" 
                            value={note ?? ""}
                            handleChange={handleChange} 
                            validationError={validationError}
                            validationErrorMessage="note can't be blank" 
                        />
                        <div className={styles.appointment_note_buttons}>
                            <button onClick={saveAppointmentNote}>
                                <IoSaveOutline />
                            </button>
                            <button onClick={handleCancelButtonClick}>
                                <IoMdClose />
                            </button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );

}

export default AppointmentNote;