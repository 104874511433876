import { IGiftCard } from "../../types/models/GiftCard";
import styles from "./Button.module.css";

interface IconButtonProps {
    customClassName?: string
    buttonText?: string
    icon: React.ReactNode
    handleClick: (giftCard: IGiftCard) => void 
    giftCard: IGiftCard
}

const IconButton: React.FC<IconButtonProps> = (props) => {

    const { customClassName = "", buttonText, icon, handleClick, giftCard } = props;

    return (
        <button
            className={`${styles.icon_button} ${styles[customClassName]}`}
            onClick={() => handleClick(giftCard)}
        >
            {icon}
            {buttonText ? <span>{buttonText}</span> : null}
        </button>
    );

}


export default IconButton;