import { BsCheckLg } from "react-icons/bs";
import useClickOutside from "../../../hooks/useClickOutside";
import styles from "./Dropdown.module.css";
import DropdownHiddenDiv from "./DropdownHiddenDiv";
import ArrowDown from "../../icons/ArrowDown";
import DropdownErrorMessage from "./DropdownErrorMessage";
import DropdownLabel from "./DropdownLabel";

interface MultiSelectDropdownProps {
    id?: string // For useClickOutside exception
    title?: string
    customClassName?: string
    dropdownFor?: string
    label?: string
    validationErrorMessage?: string
    fontSize?: string
    width?: string
    data: Record<string, any>[]
    nameKey: string 
    selectedItems: { [key: string]: any }[] 
    onSelectItem: (args: any) => void
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = (props) => {

    const { 
        id = "multi_select_dropdown",
        title,
        customClassName = "", 
        dropdownFor = "", 
        label,
        validationErrorMessage = "",
        fontSize,
        width,
        data, 
        nameKey, 
        selectedItems, 
        onSelectItem 
    } = props;

    const isSelected = selectedItems.length > 0;
    const { elementRef, isVisible, setIsVisible } = useClickOutside({ 
        exceptions: [id] 
    });
    const toggleDropdown = () => setIsVisible(!isVisible);

    return (
        <div 
            className={styles.dropdown}
            style={{
                minWidth: width ?? "120px"
            }}
        >
            {
                label
                ?
                <DropdownLabel 
                    label={label}
                />
                : null
            }
            <div 
                className={`${styles.dropdown_list} ${styles[customClassName]}`}
                style={{
                    border: isSelected ? "2px solid black" : "1px solid rgba(0, 0, 0, 0.13)"
                }}
            >
                <DropdownHiddenDiv 
                    elementRef={elementRef}
                    handleClick={toggleDropdown}
                />
                <p
                    className={styles.dropdown_title} 
                    style={{
                        fontSize: fontSize ?? "1rem"
                    }}
                >
                    {title} {isSelected ? <span>{`(${selectedItems.length})`}</span> : null}
                </p>
                <ArrowDown />
                <div 
                    className={styles.multi_select_dropdown_item_list} 
                    id={id}
                    style={{ 
                        display: isVisible ? "block" : "none" 
                    }}
                >
                    <ul>
                        {data.map(item => {

                            const foundSelectedItem = selectedItems.find(selectedItem => selectedItem._id === item._id);

                            return (
                                <li 
                                    key={item._id} 
                                    onClick= {() => onSelectItem({ item, dropdownFor })}
                                    style={{
                                        fontSize: fontSize ?? "1rem"
                                    }}
                                >
                                    <span>{item[nameKey]}</span>
                                    {
                                        foundSelectedItem 
                                        ? 
                                        <BsCheckLg 
                                            color="green" 
                                        /> 
                                        : 
                                        null
                                    }
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            {
                validationErrorMessage 
                ? 
                <DropdownErrorMessage 
                    errorMessage={validationErrorMessage}
                /> 
                : 
                null
            }
        </div>
    );

}


export default MultiSelectDropdown;