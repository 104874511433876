import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { handleChange } from "../../store/discounts/discountReducer";
import Checkbox from "./Checkbox";
import styles from "./index.module.css";

const LastMinuteDiscount = () => {

    const dispatch = useDispatch();
    const discountState = useSelector((state: RootState) => state.discountState);
    const { lastMinuteDiscountRate, lastMinuteDiscountHours } = discountState;

    // State for toggling last minute discount section
    const [showDiscountItem, setShowDiscountItem] = useState(false);

    const handleCheckboxInputChange = () => setShowDiscountItem(!showDiscountItem);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = event.target;

        // Convert the value into number
        const newValue = Number(value);

        // As input field type is text so user can put anything there. But, we will only accept numbers
        if(newValue && isNaN(newValue)) return alert("Discount rate must be a number");

        dispatch(handleChange({
            name,
            value: newValue
        }));

    }

    return (
        <div className = {styles.last_minute_discount}>
            <Checkbox 
                name = "showDiscountItem"
                value = "Enable last minute discount"
                checked = {showDiscountItem}
                handleChange = {handleCheckboxInputChange}
            />
            {
                showDiscountItem
                &&
                <div className={styles.last_minute_discount_content}>
                    <div className={styles.percentage_hours}>
                        <input 
                            type="text"
                            name="lastMinuteDiscountRate"
                            value={lastMinuteDiscountRate}
                            onChange={handleInputChange}
                        />
                        <p> % when booking</p>
                        <input 
                            type="text"
                            name="lastMinuteDiscountHours"
                            value={lastMinuteDiscountHours}
                            onChange={handleInputChange}
                        />
                    </div>
                    <p>opening hours before the appointment time.</p>
                </div>
            }
        </div>
    );

}

export default LastMinuteDiscount;