import { IFreelanceStylistInvoice } from "../../types/models/FreelanceStylistInvoice";
import { formatDateTime } from "../date-time/dateTimeHelpers";

export const getStylistInvoiceCSVContent = (freelanceStylistInvoiceList: IFreelanceStylistInvoice[]) => {
 
    const headers = "Number, Stylist, Period, Amount, Fee, Status";
   
    const data = freelanceStylistInvoiceList.map(item => {

        const { createdAt, invoiceNumber, stylistItem, invoiceAmount, invoiceFeeAmount, isPaid } = item;

        const { month, year } = formatDateTime(new Date(createdAt * 1000));
        const period = `${month} ${year}`;
        const status = isPaid ? "Paid" : "Not Paid";

        return `${invoiceNumber}, ${stylistItem.fullName}, ${period}, ${invoiceAmount.toFixed(2)}, ${invoiceFeeAmount.toFixed(2)}, ${status}`;

    });

    return `${headers}\n${data.join("\n")}`;

}