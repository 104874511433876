import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { RootState } from "../../../store";
import { config } from "../../../Constants";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";

const useFetchUpcomingInvoice = (stripePriceId?: string, vatID?: string) => {
    
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
    const [stripeUpcomingInvoice, setStripeUpcomingInvoice] = useState<any>(null);

    useEffect(() => {

        if(!user) return;

        const fetchStripeUpcomingInvoice = async() => {

            try {
    
                setLoading(true);
    
                let params1 = `country=${user?.companyAddress.isoCountryCode}&postal_code=${user?.companyAddress.postalCode}`;
                let params2 = `&stripePriceId=${stripePriceId}&vatID=${vatID}`;
    
                const params = params1 + params2;
    
                const options = {
                    method: "get",
                    url: `${config.url.BACKEND_API_URL}/subscriptions/upcoming-invoice?${params}`
                };
    
                const response = await axios(options);
                const invoice = response.data.upcomingInvoice;
                setStripeUpcomingInvoice(invoice);
                setLoading(false);
                setErrorMsg(null);
                
            } catch (error) {
                let { message } = handleApiError(error);
                if(message.includes("eu_vat")) message = "Invalid VAT ID";
                setErrorMsg(message);
                setLoading(false);
            } 
    
        }

        const delayApiCall = setTimeout(() =>  fetchStripeUpcomingInvoice(), 500)

        return () => clearTimeout(delayApiCall);

    }, [user, stripePriceId, vatID]); 

    return { loading, errorMsg, stripeUpcomingInvoice };

}

export default useFetchUpcomingInvoice;
