import { IAppointment } from "../../types/models/Appointment";
import { formatDateTime } from "../date-time/dateTimeHelpers";

export const getAppointmentCSVContent = (appointmentList: IAppointment[]) => {
  
    const headers = "Date, Category, Treatment Title, Treatment Price, Price After Discount, Status, Stylist, Payment Method";

    const data = appointmentList.map(appointment => {

        const { 
            treatmentCategory,
            treatmentTitle,
            treatmentPrice,
            priceAfterDiscount,
            status,
            stylist,
            paymentMethod
        } = appointment;

        const { day, month, year } = formatDateTime(new Date(appointment.appointment_date * 1000));
        const date = `${day} ${month} ${year}`;

        const data1 = `${date}, ${treatmentCategory}, ${treatmentTitle}, ${treatmentPrice.toFixed(2)},`;
        const data2 = `${priceAfterDiscount.toFixed(2)}, ${status}, ${stylist}, ${paymentMethod}`;

        return data1 + data2;
        
    });

    return `${headers}\n${data.join("\n")}`;

}