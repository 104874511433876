import axios from "axios";
import Cookies from "universal-cookie";
import { FetchDataQueryParams, FetchedData } from "../../../types/pages/Report";
import { config } from "../../../Constants";

export const fetchData = async(payload: FetchDataQueryParams) => {

    const { 
        startDateString,
        endDateString,
        categories = "",
        locations = "",
        treatments = "",
        stylists = "",
        appointmentStatuses = "",
        fetchOtherData
    } = payload;

    const params = `startDateString=${startDateString}&endDateString=${endDateString}`
    const params2 = `&categories=${categories}&locations=${locations}&treatments=${treatments}`;
    const params3 = `&stylists=${stylists}&appointmentStatuses=${appointmentStatuses}&fetchOtherData=${fetchOtherData}`;
    const queryParams = params + params2 + params3;

    const cookies = new Cookies();

    const headers = { "auth-token": cookies.get("auth_token") };

    const endpoint = config.url.BACKEND_API_URL + `/reports?${queryParams}`;
  
    const response = await axios.get(endpoint, { headers });

    const data: FetchedData = response.data;

    return data;

}