import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { updateState } from "../../store/gift-card/giftCardReducer";
import styles from "./index.module.css";
import InputField from "./InputField";
import Button from "./Button";

interface SearchAddProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void
}

const SearchAdd: React.FC<SearchAddProps> = ({ handleChange }) => {

    const dispatch = useDispatch();
    const giftCardState = useSelector((state: RootState) => state.giftCardState);
    const { searchText } = giftCardState;
    
    const openModal = () => dispatch(updateState({ name: "isBuying", value: true }));

    return (
        <div className={styles.search_add_btn}>
            <InputField
                customClassName="search_input" 
                type="text"
                name="searchText"
                value={searchText}
                placeholder="Search"
                handleChange={handleChange}
            />
            <Button
                customClassName="add_btn"
                buttonText="Buy Gift Card"
                handleClick={openModal}
            />
        </div>
    );

}

export default SearchAdd;
