import React, { useState, useCallback } from "react";
import axios from "axios";
import { config } from "../../../Constants";
import { IPolicy } from "../types";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";

type Return = {
    loading: boolean
    errorMsg: string | undefined
    policyList: IPolicy[]
    setPolicyList: React.Dispatch<React.SetStateAction<IPolicy[]>>
    fetchPolicies: (tenantId?: string) => Promise<void>
}

export const useFetchPolicies = (): Return => {

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [policyList, setPolicyList] = useState<IPolicy[]>([]);
    
    const fetchPolicies = useCallback(async(tenantId?: string) => {

        const endpoint = config.url.BACKEND_API_URL + "/policies/" + tenantId;
        const axiosConfig = getAuthTokenConfig();

        try {

            setLoading(true);

            const response = await axios.get(endpoint, axiosConfig);
            setPolicyList(response.data.policies);

            setLoading(false);

        } catch(error: any) {
            setLoading(false);
            const { message } = handleApiError(error);
            setErrorMsg(message)
        }

    }, [])

    return {
        loading,
        errorMsg,
        policyList,
        setPolicyList,
        fetchPolicies
    };

}