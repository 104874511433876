import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { updateState, updateSearchText } from "../../store/locations/locationReducer";
import { FETCH_LOCATION_DATA } from "../../store/locations/constants";
import styles from "./index.module.css";
import InputField from "../../components/common/input-fields/InputField";
import Button from "./Button";

const SearchAdd = () => {

    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const locationState = useSelector((state: RootState) => state.locationState);
    const { locationList, locationDataFetchInfo } = locationState;
    const { searchText, skip, limit } = locationDataFetchInfo;
    const [isSearchTextBackspacedToEmpty, setIsSearchTextBackspacedToEmpty] = useState(false);
    
    useEffect(() => {

        if(!isSearchTextBackspacedToEmpty && !searchText) return; 
      
        // Calling setTimeout directly will make api call for each character typed. But assigning
        // this function to a variable re-initializes the timer. So, just one api call will be made
        // when user stops typing.
        const delayAPICall = setTimeout(() => {
            dispatch({ 
                type: FETCH_LOCATION_DATA,
                payload: { 
                    tenantId: user?.tenantId, 
                    searchText, 
                    skip, 
                    limit 
                } 
            });
        }, 500);

        if(isSearchTextBackspacedToEmpty) setIsSearchTextBackspacedToEmpty(false);

        return () => clearTimeout(delayAPICall);

    }, [searchText])


    const searchLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        dispatch(updateSearchText(value))
        if(!value) setIsSearchTextBackspacedToEmpty(true);
    }

    // If user is on trial period he/she can create as many locations as he/she likes. But if not on trial period
    // anymore, can create as per the limit of bought subscription plan 
    const checkLocationLimit = (): boolean => {
        const subPlanId = user?.stripeSubscription?.subscriptionPlan.subscriptionId;
        return (
            (!user?.isOnTrialPeriod) &&
            ((subPlanId === 100 && locationList.length >= 1) ||
            (subPlanId === 101 && locationList.length >= 1) ||
            (subPlanId === 102 && locationList.length >= 4))
        );
    }

    const openModal = () => {
        dispatch(updateState({
            name: "isAdding",
            value: true
        }));
    } 

    const isLocationLimitOver = checkLocationLimit();
   
    return (
        <div className={styles.search_add_btn}>
            <InputField
                customClassName="search_input" 
                type="text"
                name="search"
                value={searchText}
                placeholder="Search"
                handleChange={searchLocation}
            />
            <Button
                customClassName="add_btn"
                buttonText="Add New Location"
                disabled={isLocationLimitOver}
                handleClick={openModal}
            />
        </div>
    );

}


export default SearchAdd;