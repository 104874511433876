import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { MdDelete } from "react-icons/md";
import { RootState } from "../../store";
import { PurchaseOrderProduct } from "../../types/pages/Product";
import { config } from "../../Constants";
import { 
    updateState, 
    addNewPurchaseOrderInfo,
    closePurchaseOrderModal 
} from "../../store/products/productReducer";
import { convertHexToRgb } from "../../helpers/others/convertHexToRgb";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./PurchaseOrder.module.css";
import InputField from "../../components/common/input-fields/InputField";
import ValidationErrorMessage from "../../components/common/messages/ValidationErrorMessage";
import SaveButton from "./SaveButton";

const PurchaseOrder: React.FC = () => {

    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userState);
    const productState = useSelector((state: RootState) => state.productState);
    const {
        validationError,
        newPurchaseOrderInfo,
        purchaseOrderProductList,
        savedPurchaseOrder
    } = productState;

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, product: PurchaseOrderProduct) => {

        const { name, value } = event.target;

        if(Object.keys(newPurchaseOrderInfo).some(keyName => keyName === name)) {
            dispatch(addNewPurchaseOrderInfo({ name, value }));
            return;
        }

        const foundProduct = purchaseOrderProductList.find(item => item.productId === product.productId);
        if(!foundProduct) return;

        const newProduct = {
            ...foundProduct,
            [name]: value
        };

        const updatedPurchaseOrderProductList = purchaseOrderProductList.map(item => {

            if(item.productId === product.productId) {

                const totalPrice = parseInt(newProduct.quantity) * Number(newProduct.price);
                return {
                    ...newProduct,
                    totalPrice: isNaN(totalPrice) ? 0 : totalPrice
                }
            }

            return item;

        });

        dispatch(updateState({
            name: "purchaseOrderProductList",
            value: updatedPurchaseOrderProductList
        }));

    }

    const removeProductFromPO = (productId: string) => {

        // When user removes the last product, purchase order modal should close
        if(purchaseOrderProductList.length === 1) {
            dispatch(closePurchaseOrderModal());
            return;
        };

        const filteredPurchaseOrderProductList = purchaseOrderProductList.filter(item => item.productId !== productId);

        dispatch(updateState({
            name: "purchaseOrderProductList",
            value: filteredPurchaseOrderProductList
        }));

    }

    const saveNewPO = async(event: React.MouseEvent<HTMLButtonElement> | SubmitEvent) => {
        
        if(errorMsg) setErrorMsg(undefined);

        const { 
            issuedToPartyName,
            issuedToPartyEmail,
            issuedToPartyPhone,
            issuedToPartyAddress
        } = newPurchaseOrderInfo;

        if(!issuedToPartyName) {
            dispatch(updateState({
                name: "validationError",
                value: true
            }));
            return;
        }

        setLoading(true);

        const isEmpty = purchaseOrderProductList.some(item => {
            return !item.price || !item.quantity || item.totalPrice <= 0
        });
        if(isEmpty) {
            setErrorMsg("price or quantity is invalid");
            setLoading(false);
            return;
        }

        const newPOProductList = purchaseOrderProductList.map(item => {
            const { productId, name, price, quantity, totalPrice } = item;
            return {
                productItem: productId,
                name,
                price: Number(price),
                quantity: parseInt(quantity),
                totalPrice
            }
        });

        const requestBody = {
            issuedToPartyName,
            issuedToPartyEmail: issuedToPartyEmail === "" ? null : issuedToPartyEmail,
            issuedToPartyPhone: issuedToPartyPhone === "" ? null : issuedToPartyPhone,
            issuedToPartyAddress: issuedToPartyAddress === "" ? null : issuedToPartyAddress,
            productList: newPOProductList
        }

        const endpoint = config.url.BACKEND_API_URL + "/products/purchase-order/create";
        const authConfig = getAuthTokenConfig();

        try {

            const response = await axios.post(endpoint, requestBody, authConfig);
            setLoading(false);
            dispatch(updateState({
                name: "savedPurchaseOrder",
                value: response.data.savedPurchaseOrder
            }));
          
        } catch(error) {
            const { message } = handleApiError(error);
            setErrorMsg(message);
            setLoading(false);
        }

    }

    const downloadPurchaseOrderPDF = () => {

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const doc = new jsPDF(orientation, unit, size);

        // Define header dimensions and padding
        const headerHeight = 90;
        const margin = 40;
        const padding = 20;

        // Define header colors
        const headerBackgroundColor = convertHexToRgb('#475467'); // Light grey background
        const headerTextColor = convertHexToRgb('#FFFFFF'); // Black text
        const tableHeaderFooterBGColor = convertHexToRgb("#f8efee");

        // Draw header background
        doc.setFillColor(headerBackgroundColor[0], headerBackgroundColor[1], headerBackgroundColor[2]);

        const pageSize = doc.internal.pageSize.getWidth() - (2 * margin);
        doc.rect(margin, margin, pageSize, headerHeight, "F"); // "F" for filled rectangle

        // Define the texts for the left and right sides of the header
        const leftTexts = [
            { text: 'Ordered By:', fontSize: 14 },
            { text: userState.user?.companyName ?? "", fontSize: 14 },
            { text: userState.user?.companyAddress.country ?? "", fontSize: 14 }
        ];

        const rightTexts = [
            { text: 'Ordered To:', fontSize: 14 },
            { text: newPurchaseOrderInfo.issuedToPartyName, fontSize: 14 },
            { text: newPurchaseOrderInfo.issuedToPartyAddress, fontSize: 14 }
        ];

        // Set the initial positions
        let leftYPosition = margin + padding + 10;
        const leftXPosition = margin + padding;
        let rightYPosition = margin + padding + 10;
        const rightXPosition = 100 + doc.internal.pageSize.getWidth() / 2;

        // Set text color
        doc.setTextColor(headerTextColor[0], headerTextColor[1], headerTextColor[2]);

        // Add left side texts to the header
        leftTexts.forEach(item => {
            doc.setFontSize(item.fontSize);
            doc.text(item.text, leftXPosition, leftYPosition);
            leftYPosition += item.fontSize + 4; // Adjust line height based on font size
        });
    
        // Add right side texts to the header
        rightTexts.forEach(item => {
            doc.setFontSize(item.fontSize);
            doc.text(item.text, rightXPosition, rightYPosition);
            rightYPosition += item.fontSize + 4; // Adjust line height based on font size
        });

        const headers = [["Product", "Qty", "Amount"]];

        const data = savedPurchaseOrder?.productList.map(item => {
            return [item.name, item.quantity, `€${item.totalPrice.toFixed(2)}`]
        });

        const totalQty = savedPurchaseOrder?.productList.reduce((total, product) =>  total + product.quantity, 0);
        const totalPrice = savedPurchaseOrder?.productList.reduce((total, product) => total + product.totalPrice, 0);

        if(!Array.isArray(data)) return;

        data.push(["Total", totalQty ?? 0, "€" + totalPrice?.toFixed(2)])
        
        let content = {
            startY: 150,
            head: headers,
            body: data,
            headStyles: {
                fillColor: tableHeaderFooterBGColor,
                textColor: [0, 0, 0],
                cellPadding: { left: 20, right: 20, top: 10, bottom: 10 }
            },
            bodyStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                cellPadding: { left: 20, right: 20, top: 10, bottom: 10 }
            },
            alternateRowStyles: { fillColor: [255, 255, 255] },
            didParseCell: function (tableData: any) {
                // Check if it's the last row
                if(tableData.row.index === data.length - 1) {
                    tableData.cell.styles.fontStyle = "bold";
                    tableData.cell.styles.fontSize = 12;
                    tableData.cell.styles.fillColor = tableHeaderFooterBGColor;
                }
            }
        };

        //@ts-ignore
        doc.autoTable(content);
        doc.save("purchase-order.pdf")
       
    }

    return (
        <div className={styles.purchase_order}>
            <h2>Create Purchase Order</h2>
            {
                savedPurchaseOrder
                ?
                <div className={styles.purchase_order_success}>
                    <h3>Your purchase order has been successfully created.</h3>
                    <SaveButton 
                        buttonText="Download PDF"
                        onClick={downloadPurchaseOrderPDF}
                    />
                </div>
                :
                <>
                    <div className={styles.input_fields_container}>
                        <InputField 
                            labelText="Party Name"
                            name="issuedToPartyName"
                            value={newPurchaseOrderInfo.issuedToPartyName}
                            required={true}
                            handleChange={handleChange}
                            validationError={validationError}
                            validationErrorMessage="party name can't be blank"
                        />
                        <InputField 
                            labelText="Party Email"
                            name="issuedToPartyEmail"
                            value={newPurchaseOrderInfo.issuedToPartyEmail}
                            handleChange={handleChange}
                        />
                        <InputField 
                            labelText="Party Phone"
                            name="issuedToPartyPhone"
                            value={newPurchaseOrderInfo.issuedToPartyPhone}
                            handleChange={handleChange}
                        />
                        <InputField 
                            labelText="Party Address"
                            name="issuedToPartyAddress"
                            value={newPurchaseOrderInfo.issuedToPartyAddress}
                            handleChange={handleChange}
                        />
                    </div>
                    <div className={styles.purchase_order_products}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {purchaseOrderProductList.map(item => {
                                    return (
                                        <tr key={item.productId}>
                                            <td>{item.name}</td>
                                            <td>
                                                <InputField 
                                                    customClassName="purchase_order_input"
                                                    name="quantity"
                                                    type="number"
                                                    value={item.quantity}
                                                    handleChange={handleChange}
                                                    args={item}
                                                />
                                            </td>
                                            <td>
                                                <InputField 
                                                    customClassName="purchase_order_input"
                                                    name="price"
                                                    type="number"
                                                    value={item.price}
                                                    handleChange={handleChange}
                                                    args={item}
                                                />
                                            </td>
                                            <td>€{item.totalPrice.toFixed(2)}</td>
                                            <td>
                                                <button onClick={() => removeProductFromPO(item.productId)}>
                                                    <MdDelete />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    {errorMsg ? <ValidationErrorMessage message={errorMsg}/> : null}
                    <SaveButton 
                        disabled={loading}
                        onClick={saveNewPO}
                    />
                </>
            }
        </div>
    );

}

export default PurchaseOrder;
