const Amount: React.FC<{ title: string, value: string }> = ({ title, value }) => {

    return (
        <tr>
            <td>{title}</td>
            <td>{value}</td>
        </tr>
    );

}

export default Amount;