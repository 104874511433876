import { useSelector } from "react-redux";
import { IProductSale } from "../../types/models/Product";
import { RootState } from "../../store";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./DataTable.module.css";
import ProductSalesItemInfo from "./ProductSalesItemInfo";

const ProductSalesDetails: React.FC = () => {

    const productState = useSelector((state: RootState) => state.productState);
    const productSaleInAction = productState.productSaleInAction as IProductSale;

    const { day, month, year, hours, minutes } = formatDateTime(new Date(productSaleInAction.createdAt * 1000));

    const itemInfoList = [
        { title: "Selling Date", content: `${month} ${day}, ${year} @ ${hours}:${minutes}` },
        { title: "Order ID", content: productSaleInAction.orderId },
        { title: "Product Name", content: productSaleInAction.product.name },
        { title: "Quantity", content: productSaleInAction.quantity.toString() },
        { title: "Total Price", content: `€${productSaleInAction.price.toString()}` },
        { title: "Payment Method", content: productSaleInAction.paymentMethod ?? "N/A" },
    ];

    const buyerInfoList = [
        { title: "Name", content: productSaleInAction.buyerInfo.name ?? "N/A" },
        { title: "Email", content: productSaleInAction.buyerInfo.email ?? "N/A" },
        { title: "Phone", content: productSaleInAction.buyerInfo.phone ?? "N/A" },
        { title: "Address", content: productSaleInAction.buyerInfo.address ?? "N/A" },
        { title: "City", content: productSaleInAction.buyerInfo.city ?? "N/A" },
        { title: "Zip Code", content: productSaleInAction.buyerInfo.zipCode ?? "N/A" },
        { title: "Country", content: productSaleInAction.buyerInfo.country ?? "N/A" }
    ];

    return (
        <div className={styles.product_sales_details}>
            <h2>Order Info</h2>
            <div className={styles.product_sales_details_content}>
                {itemInfoList.map(item => {
                    return (
                        <ProductSalesItemInfo 
                            key={item.title}
                            title={item.title}
                            content={item.content}
                        />
                    );
                })}
            </div>
            <h2>Buyer Info</h2>
            <div className={styles.product_sales_details_content}>
                {buyerInfoList.map(item => {
                    return (
                        <ProductSalesItemInfo 
                            key={item.title}
                            title={item.title}
                            content={item.content}
                        />
                    );
                })}
            </div>
        </div>
    );

}

export default ProductSalesDetails;