import { IAppointmentClientSearched } from "../../types/models/AppointmentClient";
import styles from "./BuyPackage.module.css";

interface ClientListProps {
    elementRef: React.RefObject<HTMLDivElement>
    clientList: IAppointmentClientSearched[]
    handleClick: (event: React.MouseEvent<HTMLLIElement>, client: IAppointmentClientSearched) => void
}

const ClientList: React.FC<ClientListProps> = ({ elementRef, clientList, handleClick }) => {

    return (    
        <div ref={elementRef} className={styles.client_list}>
            <ul>
                {clientList.map(client => {
                    return (
                        <li key={client.email} onClick={(event) => handleClick(event, client)}>
                            {client.name} - {client.phone}
                        </li>
                    );
                })}
            </ul>
        </div>
    );

}

export default ClientList;