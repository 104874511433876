import { useState, useCallback } from "react";
import axios from "axios";
import { getAuthTokenConfig } from "../helpers/others/getAuthTokenConfig";
import { IAppointmentClientSearched } from "../types/models/AppointmentClient";
import { config } from "../Constants";
import { handleApiError } from "../helpers/error-handlers/handleApiError";

const useFetchSearchedClients = () => {
    
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [clientList, setClientList] = useState<IAppointmentClientSearched[]>([])

    const fetchClients = useCallback(async(searchText: string) => {

        /*
            When user searchs for phone numbers he/she might want to start with + icon. But searchText containing some
            special characters like + will cause the server crash. So we need to remove the special characters to do
            the search. But actual input field value will contain the special characters, if there is any.
        */
        const specialCharacterFreeSearchText = searchText.replace(/[^a-zA-Z0-9.@ ]/g, "");

        if(specialCharacterFreeSearchText.length < 2) return;

        const queryParams = `searchText=${specialCharacterFreeSearchText}`
        const endpoint = config.url.BACKEND_API_URL + "/appointment-clients/search?" + queryParams;
        const authConfig = getAuthTokenConfig();

        setLoading(true);

        try {

            const response = await axios.get(endpoint, authConfig);
            const clientList = response.data.clientList;
            setClientList(clientList);
            setLoading(false);

        } catch(error) {
            const { message } = handleApiError(error);
            setErrorMsg(message);
            setLoading(false);
        }

    }, [])

    return {
        loading,
        errorMsg,
        clientList,
        setClientList,
        fetchClients
    }

}


export default useFetchSearchedClients;