import { formatDateTime } from "../date-time/dateTimeHelpers";

type Args = {
    startDate: Date
    endDate: Date
}

export const calculateAppointmentDateList = (args: Args) => {

    const { startDate, endDate } = args;
    if(!startDate || !endDate) return [];

    const appointmentDateList: string[]  = [];

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate); 

    const oneDayInMS = 86400000;
    const startDateInMS = startDateObj.getTime();
    const endDateInMS = endDateObj.getTime();

    const differenceInDays = Math.floor((endDateInMS - startDateInMS) / oneDayInMS);

    for(let x = 0; x <= differenceInDays; x++) {

        const currentIterationDate = new Date(startDateInMS + (oneDayInMS * x));

        const { dateString } = formatDateTime(currentIterationDate)

        appointmentDateList.push(dateString);

    }

    return appointmentDateList;

}