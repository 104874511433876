import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { config } from "../../Constants";
import { RootState } from "../../store";
import { FETCH_COUPON_DATA } from "../../store/coupons/constants";
import { 
    addNewCouponInfo, 
    closeAddCouponModal, 
    closeEditCouponModal,
    handleChange 
} from "../../store/coupons/couponReducer";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import useWindowWidth from "../../hooks/useWindowWidth";
import { Modal, ModalBackground, ModalContent } from "../../components/common/Modal";
import styles from "./index.module.css";
import InputField from "./InputField";
import Button from "./Button";
import SelectTreatment from "./SelectTreatment";

const AddEditCoupon = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const couponState = useSelector((state: RootState) => state.couponState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();
    const { 
        treatmentList,
        newCouponInfo, 
        validationError, 
        isAdding, 
        isEditing, 
        couponItemInAction,
        searchText, 
        skip, 
        limit
    } = couponState;
    const { 
        code, 
        maximumUseLimit, 
        expiresOn, 
        selectedTreatmentsWithDiscountRate,
        areAllTreatmentsSelected,
        allTreatmentsDiscountRate 
    } = newCouponInfo;

    const closeModal = () => {
        if(isAdding) {
            dispatch(closeAddCouponModal());
            return;
        }

        dispatch(closeEditCouponModal());
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        dispatch(addNewCouponInfo({ name, value }));
    }

    const selectExpiryDate = (date: Date) => {
        dispatch(addNewCouponInfo({
            name: "expiresOn",
            value: date
        }));
    } 

    const saveCoupon = async() => {

        if(!code || !maximumUseLimit || !expiresOn) {
            dispatch(handleChange({
                name: "validationError",
                value: true
            }));
            return;
        }

        if(!areAllTreatmentsSelected) {

            // If user forgot to select at least one treatment
            if(!selectedTreatmentsWithDiscountRate.length) {
                return alert("Please select at least one treatment with discount rate");
            }

            // If user selected individual treatments but forgot to put the discount rate
            const isDiscountRateMissing = selectedTreatmentsWithDiscountRate.some(item => {
                return !item.discountRate || isNaN(Number(item.discountRate))
            });

            if(isDiscountRateMissing) return alert("discount rate is missing or it's not correct");

        }

        const expiresOnDateInNumber = Math.round(expiresOn!.getTime() / 1000); 
        let treatmentsWithDiscountRate = selectedTreatmentsWithDiscountRate.map(item => {
            return {
                ...item,
                discountRate: Number(item.discountRate),
                treatmentItem: item.treatmentItem._id.toString()
            }
        });

        if(areAllTreatmentsSelected) {

            if(!allTreatmentsDiscountRate || isNaN(Number(allTreatmentsDiscountRate))) {
                return alert("Please enter the discount rate")
            }

            treatmentsWithDiscountRate = treatmentList.map(item => {
                return {
                    treatmentItem: item._id,
                    discountRate: Number(allTreatmentsDiscountRate)
                }
            });

        }

        dispatch(closeAddCouponModal());

        let endpoint = config.url.BACKEND_API_URL + "/coupons/create";
        let actionMessage = "Creating...";

        if(isEditing) {
            endpoint = config.url.BACKEND_API_URL + "/coupons/edit";
            actionMessage = "Updating...";
            dispatch(closeEditCouponModal());
        } 

        dispatch(handleChange({
            name: "actionMessage",
            value: actionMessage
        }))

        const headers = { "auth-token": cookies.get("auth_token") };
        const requestBody = {
            couponId: couponItemInAction?._id,
            code,
            maximumUseLimit: parseInt(maximumUseLimit),
            expiresOn: expiresOnDateInNumber,
            treatmentsWithDiscountRate
        }

        try {

            await axios.post(endpoint, requestBody, { headers });
            dispatch(handleChange({
                name: "actionMessage",
                value: "Successfully Saved"
            }));
            
            dispatch({ 
                type: FETCH_COUPON_DATA, 
                payload: { 
                    searchText,
                    skip,
                    limit,
                    fetchTreatmentData: false
                } 
            })

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(handleChange({
                name: "actionMessage",
                value: message
            }));
        }
    
    }

    
    return (
        <Modal>
            <ModalBackground />
            <ModalContent 
                width = {isLargeDevice ? "45%" : "95%"} 
                height="90%"
                backgroundColor="#F9F9FB"
            >
                <Button 
                    customClassName="modal_cls_btn"
                    buttonText="X"
                    handleClick={closeModal}
                />
                <div className = {styles.add_edit_coupon}>
                    <h1>{isAdding ? "Create coupons" : "Edit coupons"}</h1>
                    <div className = {styles.code_use_limit}>
                        <div className = {styles.input_field_container}>
                            <InputField 
                                labelText="Code"
                                type = "text"
                                name = "code"
                                value = {code}
                                handleChange = {handleInputChange}
                                validationError = {validationError}
                                validationErrorMessage="code can't be blank"
                            />
                        </div>
                        <div className = {styles.input_field_container}>
                            <InputField 
                                labelText="Maximum Use Limit"
                                type = "text"
                                name = "maximumUseLimit"
                                value = {maximumUseLimit}
                                handleChange = {handleInputChange}
                                validationError = {validationError}
                                validationErrorMessage="maximum use limit can't be blank"
                            />
                        </div>
                    </div>
                    <div className = {styles.expiry_date}>
                        <label>Active Date</label>
                        <div className = {styles.select_date_input}>
                            <DatePicker 
                                selected = {expiresOn}
                                onChange = {selectExpiryDate} 
                            />
                        </div>
                    </div>
                    <SelectTreatment />
                    <div className={styles.save_btn_wrapper}>
                        <Button 
                            customClassName="save_btn"
                            buttonText="Save"
                            handleClick={saveCoupon}
                        />
                    </div>
                </div>
            </ModalContent>
        </Modal>
    );

}


export default AddEditCoupon;