import { useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { config } from "../../../Constants";
import { IUser } from "../../../types/models/User";
import { DAYS_IN_A_WEEK } from "../../../data/constants";
import { getTrialPeriodRemainingDays } from "../../../helpers/others/getTrialPeriodRemainingDays";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";
import styles from "./ChangePlan.module.css";
import Button from "../common/Button";
import DeletePrompt from "../../../components/common/prompts/DeletePrompt";
import LoadingError from "../../../components/common/loading-error/LoadingError";


const ChangePlan: React.FC = () => {

    const cookies = new Cookies();

    const userState = useSelector((state: RootState) => state.userState);
    const user = userState.user as IUser;
    const subscriptionPlan = user.stripeSubscription?.subscriptionPlan;
    const isSubscriptionScheduledForCancellation = user.stripeSubscription?.isScheduledForCancellation;
    const reactivationSuccessMsg = "Your subscription has been reactivated";
    const cancellationSuccessMsg = "Your subscription will be cancelled at the current period end";

    const [isCancelling, setIsCancelling] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [isCancellationReactivationASuccess, setIsCancellationReactivationASuccess] = useState<boolean>(false);

    let foundSubPrice = null;
    if(user.stripeSubscription) {
        foundSubPrice = user.stripeSubscription.subscriptionPlan.prices.find(item => {
            return item.stripePriceId === user.stripeSubscription?.stripePriceId;
        }); 
    }

    const cancelSubscription = async() => {

        setLoading(true);
        setIsCancelling(!isCancelling);

        const endpoint = config.url.BACKEND_API_URL + "/subscriptions/cancel-subscription";
        const headers = { "auth-token": cookies.get("auth_token") };
        const requestBody = { 
            tenantId: user.tenantId,
            stripeSubscriptionId: user.stripeSubscription?.stripeSubscriptionId 
        };

        try {

            await axios.post(endpoint, requestBody, { headers });
            setLoading(false);
            setIsCancellationReactivationASuccess(true);

        } catch(error: any) {
            const { message } = handleApiError(error);
            setLoading(false);
            setErrorMsg(message);
        }

    }

    const reactivateSubscription = async() => {

        setLoading(true);

        const endpoint = config.url.BACKEND_API_URL + "/subscriptions/reactivate-subscription";
        const headers = { "auth-token": cookies.get("auth_token") };
        const requestBody = { 
            tenantId: user.tenantId,
            stripeSubscriptionId: user.stripeSubscription?.stripeSubscriptionId 
        };

        try {

            await axios.post(endpoint, requestBody, { headers });
            setLoading(false);
            setIsCancellationReactivationASuccess(true);

        } catch(error: any) {
            const { message } = handleApiError(error);
            setLoading(false);
            setErrorMsg(message);
        }

    }

    const toggleCancelPrompt = () => {

        if(isSubscriptionScheduledForCancellation) {
            reactivateSubscription();
            return;
        }

        setIsCancelling(!isCancelling);

    } 

    const price = foundSubPrice?.amount ?? 0;
  
    return (
        <>
            <div className = {styles.change_plan}>
                {
                    loading
                    ?
                    <LoadingError 
                        message={isSubscriptionScheduledForCancellation ? "Reactivating..."  : "scheduling for cancellation..."}
                    />
                    :
                    errorMsg
                    ?
                    <LoadingError 
                        message={errorMsg}
                    />
                    :
                    isCancellationReactivationASuccess
                    ?
                    <LoadingError 
                        message = {isSubscriptionScheduledForCancellation ? reactivationSuccessMsg : cancellationSuccessMsg}
                    />
                    :
                    <>
                        {
                            (user.isOnTrialPeriod && getTrialPeriodRemainingDays(user.trialPeriodEnd) <= DAYS_IN_A_WEEK)
                            ?
                            <>
                                <h3>You are on trial period, enjoy everything free!</h3>
                                <p className={styles.trial_period_end_soon}>
                                    (Your trial period will end soon. If you wish to keep using the 
                                    application, please buy a plan.)
                                </p>
                            </>
                            :
                            null
                        }

                        {
                            (subscriptionPlan && foundSubPrice)
                            ?
                            <>
                                <p className = {styles.p_header}>
                                    Current Subscription Plan
                                </p>
                                <h3 className = {styles.h3_header}>
                                    €{price.toFixed(2)}
                                </h3>
                                <h3 className = {styles.h3_header}>
                                    {subscriptionPlan.title}
                                </h3>
                                <div className = {styles.cancel_btn_wrapper}>
                                    <Button 
                                        customClassName="sub_cancel_btn"
                                        buttonText={isSubscriptionScheduledForCancellation ? "Reactivate" : "Cancel"}
                                        handleClick={toggleCancelPrompt}
                                    />
                                </div>
                            </>
                            :
                            null
                        }
                    </>
                }
            </div>
            {
                isCancelling
                ?
                <DeletePrompt 
                    warningText = {<p>Are you sure want to cancel <span>{subscriptionPlan?.title}</span></p>}
                    closeDeletePrompt = {toggleCancelPrompt}
                    deleteItem = {cancelSubscription}
                />
                :
                null
            }
        </>
    );

}


export default ChangePlan;


