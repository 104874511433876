import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { updateState, toggleActivityByModal } from "../../../store/calendar/calendarReducer";
import { checkStylistTreatmentExist } from "../../../helpers/others/checkStylistTreatmentExist";
import styles from "./AppointmentModal.module.css";

const AppointmentButtons: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);

    const { 
        appointmentInAction, 
        treatmentList, 
        stylistList,
        changeTreatmentInfo 
    } = calendarState; 

    const handleClick = (activityType: string) => {

        if(activityType === "change_treatment") {
            dispatch(updateState({
                name: "changeTreatmentInfo",
                value: {
                    ...changeTreatmentInfo,
                    appointmentId: appointmentInAction?._id,
                    appointmentTreatmentId: appointmentInAction?.treatmentItem?._id,
                }
            }));
            return;
        }

        // Before letting user reschedule/cancel/no show we must make sure that both stylist and treatment exist.
        // Because stylist or treatment might have been deleted from DB so we don't want any trouble.
        const args = {
            appointment: appointmentInAction,
            treatments: treatmentList,
            stylists: stylistList
        }
        const { message } = checkStylistTreatmentExist(args);
        if(message) return alert(message);

        let activityByLabel = "";
  
        if(activityType === "Reschedule") activityByLabel = "Who is rescheduling?";
        if(activityType === "Cancel") activityByLabel = "Who is cancelling?"
        if(activityType === "No Show") activityByLabel = "Who is updating status to 'No Show'?";

        dispatch(toggleActivityByModal({
            activityType, 
            activityByLabel
        }))

    }

    return (
        <div className = {styles.appointment_buttons}>
            <button 
                className = {styles.reschedule_button} 
                onClick = {() => handleClick("Reschedule")}
            >
                Reschedule
            </button>
            <button 
                className = {styles.cancel_button} 
                onClick = {() => handleClick("Cancel")}
            >
                Cancel
            </button>
            <button 
                className = {styles.noshow_button} 
                onClick = {() => handleClick("No Show")}
            >
                No Show
            </button>
            <button 
                className={styles.change_treatment_button} 
                onClick={() => handleClick("change_treatment")}
            >
                Change Treatment
            </button>
        </div>
    );

}

export default AppointmentButtons;