import useWindowWidth from "../../hooks/useWindowWidth";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import { IClientPackage } from "../../types/models/ClientPackage";
import ViewIconButton from "./ViewIconButton";
import ResponsivePackageTable from "./ResponsivePackageTable";

interface ClientPackageTableProps {
    clientPackageList: IClientPackage[]
    openPackageDetails: (clientPkg: IClientPackage) => void
}

const ClientPackageTable: React.FC<ClientPackageTableProps> = ({ clientPackageList, openPackageDetails }) => {

    const { windowWidth } = useWindowWidth();

    return (
        <>
            {
                windowWidth >= 1000 
                ?
                <table>
                    <thead>
                        <tr>
                            <th>Buy Date</th>
                            <th>Title</th>
                            <th>Price</th>
                            <th>Client Email</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientPackageList.map(clientPkg => {

                            const { 
                                _id,
                                createdAt: { timestamp },
                                title,
                                price,
                                clientEmail,
                                status
                            } = clientPkg;

                            const { day, month, year, hours, minutes } = formatDateTime(new Date(timestamp * 1000));

                            return (
                                <tr key={_id}>
                                    <td>{`${month} ${day}, ${year}`} @ {`${hours}:${minutes}`}</td>
                                    <td>{title}</td>
                                    <td>€{price.toFixed(2)}</td>
                                    <td>{clientEmail}</td>
                                    <td>{status === "active" ? "Active" : "Used Up"}</td>
                                    <td>
                                        <ViewIconButton 
                                            clientPkg={clientPkg}
                                            openPackageDetails={openPackageDetails}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                :
                <ResponsivePackageTable 
                    clientPackageList={clientPackageList}
                    openPackageDetails={openPackageDetails}
                />
            }
        </>
    );

}

export default ClientPackageTable;