import { useSelector, useDispatch } from "react-redux";
import useWindowWidth from "../../hooks/useWindowWidth";
import { RootState } from "../../store";
import { 
    updateState, 
    toggleAppointmentClientDeletePrompt,
    openEditAppointmentClientModal 
} from "../../store/appointment-clients/appointmentClientReducer";
import { FETCH_APPOINTMENT_CLIENT_DATA } from "../../store/appointment-clients/constants";
import styles from "./index.module.css";
import CheckIcon from "../../components/icons/CheckIcon";
import InputField from "../../components/common/input-fields/InputField";
import DropdownList from "./DropdownList";
import TableMessageTR from "./TableMessageTR";
import ResponsiveDataTable from "./ResponsiveDataTable";
import ActionButtons from "./ActionButtons";
import NextPrevView from "./NextPrevView";

const DataTable = () => {

    const { devicesWidth: { isLargeDevice } } = useWindowWidth();

    const dispatch = useDispatch();
    const appointmentClientState = useSelector((state: RootState) => state.appointmentClientState);
    const { 
        loading,
        appointmentClientList,
        totalAppointmentClientCount,
        searchText,
        skip, 
        limit 
    } = appointmentClientState;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        dispatch(updateState({ name, value }));
    }

    const handleDropdownChange = (viewItem: { _id: number, limit: number }) => {

        if(totalAppointmentClientCount <= 10 && searchText === "") return;

        dispatch(updateState({
            name: "limit",
            value: viewItem.limit
        }));
        dispatch(updateState({
            name: "skip",
            value: 0
        }));
        dispatch(updateState({
            name: "searchText",
            value: ""
        }));


        fetchData();

    }

    const handleClick = (actionType: string, appointmentClientId: string ) => {

        if(actionType === "add") {
            dispatch(updateState({
                name: "isAdding",
                value: true
            }));
            return;

        }

        const appointmentClient = appointmentClientList.find(item => item._id === appointmentClientId);
        if(!appointmentClient) return;

        if(actionType === "edit") {
            dispatch(openEditAppointmentClientModal(appointmentClient));
            return;
        }

        dispatch(toggleAppointmentClientDeletePrompt(appointmentClient));
      
    }

    const fetchData = () => {
        setTimeout(() => {
            dispatch({ type: FETCH_APPOINTMENT_CLIENT_DATA })
        }, 100)
    }

    const navigateToNextPrevProducts = (buttonClickType: string) => {

        if(buttonClickType === "next") {

            if(totalAppointmentClientCount <= (skip + limit)) return;

            dispatch(updateState({
                name: "skip",
                value: skip + limit
            }));

            fetchData();

            return;

        }
        
        if(!skip) return;

        dispatch(updateState({
            name: "skip",
            value: skip - limit
        }));

        fetchData();
       
    }

    return (
        <div className={styles.data_table_container}>
            <div className={styles.data_table_header}>
                <div className={styles.search_add}>
                    <InputField
                        customClassName="search_input" 
                        name="searchText"
                        value={searchText}
                        placeholder="Search"
                        handleChange={handleChange}
                    />
                    <button onClick={() => handleClick("add", "add")}>
                        Add New Client
                    </button>
                </div>
                <div className={styles.data_table_dropdown_container}>
                    <DropdownList 
                        customClassName="view_dropdown_list"
                        dropdownListFor="view_item"
                        data={[
                            { _id: 1, limit: 10 },
                            { _id: 2, limit: 20 },
                            { _id: 3, limit: 50 }
                        ]}
                        nameKey="limit"
                        selectedValue={limit}
                        clickHandler={handleDropdownChange}
                    />
                </div>
            </div>
            <div className={styles.data_table}> 
                {
                isLargeDevice
                ?
                <table>
                    <thead>
                        <tr className={styles.heading_tr}>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Newsletters</th>
                            <th>Note</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading
                            ?
                            <TableMessageTR 
                                colSpan={6}
                                message="Loading..."
                            />
                            :
                            appointmentClientList.length < 1
                            ?
                            <TableMessageTR 
                                colSpan={6}
                                message="No Clients To Show"
                            />
                            :
                            appointmentClientList.map(appointmentClient => {

                                const { _id, fullName, email, phone, note, subscribedToMailchimp } = appointmentClient;

                                return (
                                    <tr key={_id} className={styles.data_tr}>
                                        <td>{fullName}</td>
                                        <td>{email}</td>
                                        <td>{phone}</td>
                                        <td>
                                            {
                                                subscribedToMailchimp ?
                                                <CheckIcon 
                                                    color={"green"}
                                                    width={16}
                                                    height={16}
                                                />
                                                :
                                                null
                                            }
                                        </td>
                                        <td>{note}</td>
                                        <td>
                                            <ActionButtons 
                                                itemId={_id}
                                                showButtonText={true}
                                                handleClick={handleClick}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                :
                <ResponsiveDataTable
                    handleClick={handleClick}
                />
            }
            </div>
            <NextPrevView 
                skip={skip}
                limit={limit}
                totalItemCount={totalAppointmentClientCount}
                onNextPrevButtonClick={navigateToNextPrevProducts}
            />
        </div>
    );

}

export default DataTable;
