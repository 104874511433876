import styles from "./Button.module.css";

interface ButtonType {
    customClassName?: string
    buttonText: string
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Button: React.FC<ButtonType> = ({ customClassName = "", buttonText, handleClick }) => {

    return (
        <button 
            className={`${styles[customClassName]}`}
            onClick={handleClick}
        >
            {buttonText}
        </button>
    );

}

export default Button;