import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { toggleDeletePrompt, handleChange } from "../../store/coupons/couponReducer";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import { FETCH_COUPON_DATA } from "../../store/coupons/constants";
import DeletePrompt from "../../components/common/prompts/DeletePrompt";

const DeleteCoupon = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const couponState = useSelector((state: RootState) => state.couponState);
    const { couponItemInAction, searchText, skip, limit } = couponState;


    const closeDeletePrompt = () => {
        dispatch(toggleDeletePrompt(null))
    }

    const deleteCoupon = async() => {

        if(!couponItemInAction) return alert("Please select a coupon");

        dispatch(toggleDeletePrompt(null));
        dispatch(handleChange({
            name: "actionMessage",
            value: "Deleting..."
        }))

        const endpoint = config.url.BACKEND_API_URL + "/coupons/delete/" + couponItemInAction._id;
        const headers = { "auth-token": cookies.get("auth_token") };

        try {

            // Axios delete method is unique. Request body must be specified with data property.  
            // const response = await axios.delete(endpoint, { data: { _id: couponItemInAction?._id }, headers });
            await axios.delete(endpoint, { headers });
            dispatch(handleChange({
                name: "actionMessage",
                value: "Successfully Deleted"
            }))

            dispatch({ 
                type: FETCH_COUPON_DATA, 
                payload: { 
                    searchText,
                    skip,
                    limit,
                    fetchTreatmentData: false
                } 
            })

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(handleChange({
                name: "actionMessage",
                value: message
            }))
        }

    }
    
  
    return (
        <DeletePrompt 
            warningText = {<p>Are you sure want to delete <span>{couponItemInAction?.code}</span></p>}
            closeDeletePrompt={closeDeletePrompt}
            deleteItem = {deleteCoupon}
        />
    );

}


export default DeleteCoupon;