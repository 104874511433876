import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ILocation } from "../../../types/models/Location";
import { NewWorkSchedule } from "../../../types/pages/UserList";
import { RootState } from "../../../store";
import { daysOfTheWeek, workScheduleTimes } from "../../../data/times";
import { addRemoveWorkSchedule } from "../../../store/user-list/userListReducer";
import styles from "./AddEditUser.module.css";
import Button from "../Button";
import DropdownList from "../DropdownList";
import WorkScheduleTable from "./WorkScheduleTable";


type WorkScheduleInfoType = Pick<NewWorkSchedule, "day" | "startTime" | "endTime"> & { location: null | ILocation }


const WorkSchedule: React.FC = () => {

    const dispatch = useDispatch();
    const userListState = useSelector((state: RootState) => state.userListState);
    const { newWorkSchedule, locationList } = userListState;
    const [workScheduleInfo, setWorkScheduleInfo] = useState<WorkScheduleInfoType>({
        location: null,
        day: "",
        startTime: "",
        endTime: ""
    })

    // For stylist work schedule
    const handleDropdownClick = (item: any, args?: { [key: string]: string }) => {

        if(args) {

            let value: string | ILocation = "";

            const { name } = args;

            if(name === "location") value = item;
            if(name === "day") value = item.name;
            if(name === "startTime" || name === "endTime") value = item.time;

            setWorkScheduleInfo(currentValue => {
                return {
                    ...currentValue,
                    [name]: value  
                }
            })
        }
       
    }

    const addWorkSchedule = () => {

        const { location, day, startTime, endTime } = workScheduleInfo;

        // Validations
        const isEmpty = !location || !day || !startTime || !endTime;

        if(isEmpty) return alert("Please fill all fields");

        const foundWeekday = daysOfTheWeek.find(item => item.name === day);
        if(!foundWeekday) return alert("wrong week day");

        const foundStartTime = workScheduleTimes.find(item => item.time === startTime);
        const foundEndTime = workScheduleTimes.find(item => item.time === endTime);

        if(!foundStartTime || !foundEndTime) return alert("Wrong start or end time")

        // Now check to see if user has already added a work schedule with the same day(example - Monday);\
        const isAlreadyAdded = newWorkSchedule.some(item => item.day === day);

        if(isAlreadyAdded) return alert("already added");

        // Now check to see if user has mistakenly selected the same time for both start and end time
        if(startTime === endTime) return alert("can't be same");
    
        // Start hour can't be greater than end hour. For example - user might have selected 11:00 as start time 
        // and 10:00 as end time. Or, if start hour and end hour are same then it must be checked that end minute is 
        // greater than start minute, for example - user might have selected 11:00 as start time and 11:30 as end time. 
        // Though start and end hour are same, end minute(30) is greater then start minute(00)

        // Separating start hour and minute
        const startHour = startTime.substring(0, 2);
        const startMinute = startTime.substring(3, 5);
    
        // Separating end hour and minute
        const endHour = endTime.substring(0, 2);
        const endMinute = endTime.substring(3, 5);
    
        // As said above start hour can't be greater then end hour. If start and end hour are same end minute must be 
        // greater than start minute. Otherwise display an error message 
        if(startHour > endHour || (startHour === endHour && endMinute < startMinute)) {
            return alert("Start time can't be greater than end time");
        }

        const selectedLocation = workScheduleInfo.location as ILocation;
    
        dispatch(addRemoveWorkSchedule([...newWorkSchedule, {...workScheduleInfo, location: selectedLocation}]));
    
    }


    return (
        <div className={styles.work_schedule}>
            <h3>Work Schedule</h3>
            <div className = {styles.work_schedule_content}>
                <div className = {styles.dropdown_container}>
                    <DropdownList 
                        data = {locationList}
                        nameKey = "title"
                        selectedValue = {workScheduleInfo.location ? `${workScheduleInfo.location.title}` : "Select Location"}
                        clickHandler={handleDropdownClick}
                        clickHandlerArgs = {{ name: "location" }}
                    />
                </div>
                <div className = {styles.dropdown_container}>
                    <DropdownList 
                        data = {daysOfTheWeek}
                        nameKey = "name"
                        selectedValue = {workScheduleInfo.day ? `${workScheduleInfo.day}` : "Select Weekday"}
                        clickHandler={handleDropdownClick}
                        clickHandlerArgs = {{ name: "day" }}
                    />
                </div>
                <div className = {styles.dropdown_container}>
                    <DropdownList 
                        data = {workScheduleTimes}
                        nameKey = "time"
                        selectedValue = {workScheduleInfo.startTime ? `${workScheduleInfo.startTime}` : "Select Start Time"}
                        clickHandler={handleDropdownClick}
                        clickHandlerArgs = {{ name: "startTime" }}
                    />
                </div>
                <div className = {styles.dropdown_container}>
                    <DropdownList 
                        data = {workScheduleTimes}
                        nameKey = "time"
                        selectedValue = {workScheduleInfo.endTime ? `${workScheduleInfo.endTime}` : "Select End Time"}
                        clickHandler={handleDropdownClick}
                        clickHandlerArgs = {{ name: "endTime" }}
                    />
                </div>
            </div>
            <div className = {styles.add_workschedule_button}>
                <Button 
                    buttonText = "Add Schedule" 
                    handleClick = {addWorkSchedule}
                />
            </div>
            {newWorkSchedule.length > 0 && <WorkScheduleTable />}
        </div>
    );

}


export default WorkSchedule;