import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { toggleDeletePrompt, updateState } from "../../store/locations/locationReducer";
import { FETCH_LOCATION_DATA } from "../../store/locations/constants";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import DeletePrompt from "../../components/common/prompts/DeletePrompt";


const DeleteLocation = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const locationState = useSelector((state: RootState) => state.locationState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { locationItemInAction, locationDataFetchInfo } = locationState;
    const { searchText, skip, limit } = locationDataFetchInfo;


    const closeDeletePrompt = () => {
        dispatch(toggleDeletePrompt(null))
    }

    const deleteLocation = async() => {

        if(!locationItemInAction) return;

        dispatch(toggleDeletePrompt(null));
        dispatch(updateState({
            name: "actionMessage",
            value: "deleting..."
        }));

        const endpoint = config.url.BACKEND_API_URL + "/locations/delete/" + locationItemInAction._id;
        const axiosConfig = {
            headers: {
                "auth-token": cookies.get("auth_token")
            }
        }

        try {

            // Axios delete method is unique. Request body must be specified with data property.  
            const response = await axios.delete(endpoint, axiosConfig);

            dispatch(updateState({
                name: "actionMessage",
                value: response.data
            }));

            dispatch({ 
                type: FETCH_LOCATION_DATA, 
                payload: { 
                    tenantId: user?.tenantId,
                    searchText,
                    skip,
                    limit,
                } 
            })

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(updateState({
                name: "actionMessage",
                value: message
            }));
        }

    }
    
  
    return (
        <>
            <DeletePrompt 
                warningText = {<p>Are you sure want to delete <span>{locationItemInAction?.title}</span></p>}
                closeDeletePrompt={closeDeletePrompt}
                deleteItem = {deleteLocation}
            />
        </>
    );

}


export default DeleteLocation;