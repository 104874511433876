const PackageIcon = (): JSX.Element => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="24" height="24" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#99B2C6" stroke="#99B2C6">
                <path d="M2135 4978 c-16 -6 -104 -64 -195 -128 -91 -64 -289 -203 -440 -308 -151 -105 -293 -208 -316 -230 -76 -71 -74 -55 -74 -754 l0 -618 -33 0 c-18 0 -52 -10 -75 -22 -71 -39 -884 -612 -928 -656 -78 -76 -75 -24 -72 -1043 3 -871 4 -905 22 -943 23 -46 74 -99 119 -123 30 -17 96 -18 998 -21 l966 -2 56 29 55 29 57 -29 58 -29 951 0 c932 0 952 1 996 21 34 15 140 114 418 392 205 205 383 390 395 412 l22 40 3 864 c3 951 5 917 -58 993 -17 20 -53 48 -82 62 l-52 26 -447 0 -447 0 31 58 32 57 0 870 c0 964 4 899 -66 980 -19 21 -56 49 -84 61 l-50 24 -865 -1 c-592 0 -874 -4 -895 -11z m411 -373 l-277 -215 -335 0 c-184 0 -334 3 -333 8 0 4 134 100 297 214 l297 208 314 0 313 0 -276 -215z m1234 211 c0 -2 -113 -102 -251 -222 -192 -169 -255 -218 -272 -215 -12 2 -175 6 -362 10 l-340 6 270 212 270 212 343 0 c188 1 342 -1 342 -3z m145 -1706 c-5 -29 -49 -77 -258 -288 l-252 -253 -6 823 c-3 453 -7 830 -10 839 -3 12 75 86 261 249 l265 232 3 -784 c1 -430 0 -799 -3 -818z m-710 1085 l25 -24 0 -886 0 -886 -25 -24 -24 -25 -931 0 -931 0 -24 25 -25 24 0 886 0 886 25 24 24 25 931 0 931 0 24 -25z m-2105 -1612 c0 -104 3 -198 6 -210 l6 -23 -318 0 -319 0 54 38 c29 20 162 114 295 209 132 94 249 171 259 172 16 1 17 -14 17 -186z m3458 -25 c-134 -117 -250 -218 -259 -225 -12 -10 -25 -9 -64 2 -39 12 -124 15 -429 15 l-381 0 210 210 210 210 478 0 478 0 -243 -212z m382 -710 l0 -812 -252 -253 -253 -254 -6 813 c-3 447 -8 824 -10 838 -3 21 30 54 256 252 143 125 261 228 263 228 1 0 2 -365 2 -812z m-2846 303 l26 -24 0 -889 0 -889 -25 -24 -24 -25 -928 0 c-714 1 -932 3 -944 13 -39 30 -39 29 -39 930 0 862 0 875 20 900 11 14 30 28 43 30 12 2 432 4 934 3 l911 -1 26 -24z m2110 15 c19 -8 37 -25 44 -42 9 -22 12 -245 10 -902 l-3 -874 -28 -24 -28 -24 -924 0 -924 0 -28 24 -28 24 -3 864 c-2 611 0 874 8 901 20 69 -27 66 973 67 740 0 903 -2 931 -14z"/>
                <path d="M1555 3016 c-37 -17 -80 -62 -94 -99 -6 -16 -11 -81 -11 -146 0 -104 3 -123 23 -162 15 -27 38 -53 63 -67 38 -22 46 -22 266 -20 211 3 228 4 254 24 65 48 69 61 69 227 0 144 -1 154 -24 186 -45 63 -80 71 -311 70 -149 0 -213 -3 -235 -13z m405 -241 l0 -85 -170 0 -170 0 0 85 0 85 170 0 170 0 0 -85z"/>
                <path d="M2675 3005 c-17 -16 -25 -35 -25 -60 0 -25 8 -44 25 -60 24 -24 27 -25 185 -25 158 0 161 1 185 25 27 27 33 79 12 106 -26 33 -55 39 -207 39 -147 0 -152 -1 -175 -25z"/>
                <path d="M2695 2678 c-32 -17 -45 -39 -45 -79 0 -29 6 -42 26 -58 24 -19 40 -21 186 -21 155 0 159 1 183 25 37 36 34 86 -6 119 -30 25 -34 26 -177 26 -87 0 -155 -5 -167 -12z"/>
                <path d="M445 966 c-37 -17 -80 -62 -94 -99 -14 -36 -14 -230 -1 -277 11 -41 61 -94 103 -109 17 -7 113 -11 233 -11 229 0 259 7 306 72 21 30 23 45 26 169 4 158 -5 190 -64 235 l-37 29 -221 2 c-170 2 -228 0 -251 -11z m405 -241 l0 -85 -170 0 -170 0 0 85 0 85 170 0 170 0 0 -85z"/>
                <path d="M1565 955 c-31 -30 -33 -83 -6 -116 18 -23 26 -24 166 -27 81 -2 158 -1 172 2 63 17 85 95 38 141 -24 24 -27 25 -185 25 -158 0 -161 -1 -185 -25z"/>
                <path d="M1565 615 c-17 -16 -25 -35 -25 -60 0 -25 8 -44 25 -60 24 -24 27 -25 185 -25 158 0 161 1 185 25 17 16 25 35 25 60 0 25 -8 44 -25 60 -24 24 -27 25 -185 25 -158 0 -161 -1 -185 -25z"/>
                <path d="M2575 966 c-37 -16 -82 -67 -93 -104 -4 -15 -7 -81 -7 -147 0 -136 10 -165 72 -212 l36 -28 206 -3 c236 -4 259 -1 302 35 54 46 69 93 69 218 0 147 -31 213 -113 244 -43 17 -434 14 -472 -3z m415 -241 l0 -85 -170 0 -170 0 0 85 0 85 170 0 170 0 0 -85z"/>
                <path d="M3695 954 c-34 -37 -33 -78 4 -115 l29 -29 152 0 c89 0 160 4 172 11 29 15 50 72 39 103 -20 50 -42 56 -214 56 l-158 0 -24 -26z"/>
                <path d="M3714 628 c-48 -23 -57 -92 -18 -132 27 -26 27 -26 185 -26 150 0 159 1 184 23 21 17 27 32 27 62 0 30 -6 45 -27 62 -25 22 -34 23 -178 22 -84 0 -162 -5 -173 -11z"/>
            </g>
        </svg>
    );

}

export default PackageIcon;