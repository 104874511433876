import styles from "./Login.module.css";
import InputField from "./InputField";
import SubmitButton from "../../components/login-signup/SubmitButton";

interface LoginFormProps {
    loading: boolean
    email: string
    password: string
    validationError: boolean
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleLogin: (event: SubmitEvent) => void
    setShowForgotPasswordSection: React.Dispatch<React.SetStateAction<boolean>>
}


const LoginForm: React.FC<LoginFormProps> = (props) => {

    const { 
        loading, 
        email, 
        password, 
        validationError, 
        handleChange,
        handleLogin,
        setShowForgotPasswordSection
    } = props;

    return (
        <div className = {styles.login_form}>
            <form>
                <InputField 
                    labelText="Email"
                    type = "email"
                    name = "email"
                    value = {email}
                    placeholder="Enter your email"
                    handleChange = {handleChange}
                    validationError = {validationError}
                    validationErrorMessage = "email is required"
                />
                <InputField 
                    labelText="Password"
                    type = "password"
                    name = "password"
                    value = {password}
                    placeholder="Enter your password"
                    handleChange = {handleChange}
                    validationError = {validationError}
                    validationErrorMessage = "password is required"
                />
                <div className={styles.forgot_password_button}>
                    <span onClick={() => setShowForgotPasswordSection(true)}>
                        Forgot password?
                    </span>
                </div>
                <SubmitButton 
                    buttonText = {loading ? "Signing in..." : "Sign in"}
                    disabled = {loading}
                    handleSubmit = {handleLogin}
                />
            </form>
        </div>
    );
}

export default LoginForm