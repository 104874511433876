import { useSelector, useDispatch } from "react-redux";
import { ITreatment } from "../../types/models/Treatment";
import { RootState } from "../../store";
import { updateViewLimit, openEditTreatmentModal, toggleDeletePrompt } from "../../store/treatments/treatmentReducer";
import { FETCH_DATA } from "../../store/treatments/constants";
import useWindowWidth from "../../hooks/useWindowWidth";
import styles from "./TreatmentTable.module.css";
import DropdownList from "./DropdownList";
import ActionButtons from "./ActionButtons";
import ResponsiveTreatmentTable from "./ResponsiveTreatmentTable";

const TreatmentTable = () => {

    const dispatch = useDispatch();
    const treatmentState = useSelector((state: RootState) => state.treatmentState);
    const userState = useSelector((state: RootState) => state.userState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();
    const { user } = userState;
    const { treatmentList, treatmentDataFetchInfo } = treatmentState;
    const { searchText, skip, limit, totalTreatmentCount } = treatmentDataFetchInfo;

    const handleClick = (args: { actionType: string, treatment: ITreatment}) => {

        const { actionType, treatment } = args;

        if(actionType === "Delete") {
            dispatch(toggleDeletePrompt(treatment));
            return;
        }

        dispatch(openEditTreatmentModal(treatment))

    }

    const handleDropdownChange = (viewItem: { _id: number, limit: number | string }) => {

        if(totalTreatmentCount <= 5) return;

        dispatch(updateViewLimit(viewItem.limit));

        dispatch({ 
            type: FETCH_DATA, 
            payload: { 
                tenantId: user?.tenantId, 
                searchText, 
                skip, 
                limit: viewItem.limit,
                fetchCategoryAndStylistData: "No" 
            } 
        });

    }

    return (
        <div className = {styles.treatment_table}> 
            <div className = {styles.table_header}>
                <h2>Treatments</h2>
                <DropdownList 
                    customClassName="view_dropdown_list"
                    dropdownListFor="view_item"
                    data = {[
                        { _id: 1, limit: 5 },
                        { _id: 2, limit: 10 },
                        { _id: 3, limit: "All" },
                    ]}
                    nameKey="limit"
                    selectedValue = {limit}
                    clickHandler={handleDropdownChange}
                />
            </div>
            {
                isLargeDevice
                ?
                <table>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Treatment Title</th>
                            <th>Duration</th>
                            <th>Price</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {treatmentList.map(treatment => {

                            const { _id, category, title, duration, price } = treatment;

                            return (
                                <tr key = {_id}>
                                    <td className = {styles.category_container_td}>
                                        <div className = {styles.category}>
                                            <div className = {styles.category_icon}>
                                                <img 
                                                    alt = "icon"
                                                    src = {category.icon}
                                                />
                                            </div>
                                            <span>{category.title}</span>
                                        </div>
                                    </td>
                                    <td>{title}</td>
                                    <td>
                                        <span className = {styles.duration}>{duration}</span>
                                    </td>
                                    <td>€{price.toFixed(2)}</td>
                                    <td>
                                        <ActionButtons 
                                            treatment={treatment}
                                            showButtonText = {true}
                                            handleClick={handleClick}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                :
                <ResponsiveTreatmentTable 
                    handleClick={handleClick}
                />
            }
        </div>
    );

}

export default TreatmentTable;