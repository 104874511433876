import { useEffect, useState } from "react";
import axios from "axios";
import { config } from "../../Constants";
import { 
    IClientPackage, 
    ClientPackagePaymentData, 
    ClientPackagePaymentStatus 
} from "../../types/models/ClientPackage";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./index.module.css";
import ClientPackageItemInfo from "./ClientPackageItemInfo";

interface ClientPackageDetailsProps {
    clientPackageList: IClientPackage[]
    clientPkgInAction: IClientPackage
    setClientPackageList: React.Dispatch<React.SetStateAction<IClientPackage[]>> 
    setClientPkgInAction: React.Dispatch<React.SetStateAction<IClientPackage | null>>
}

type ClientPackagePaymentDataExtended = ClientPackagePaymentData & { 
    isPaying: boolean 
    enteredMethod: string
}

const ClientPackageDetails: React.FC<ClientPackageDetailsProps> = ({ 
    clientPackageList,
    clientPkgInAction,
    setClientPkgInAction,
    setClientPackageList 
}) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [successMsg, setSuccessMsg] = useState<string>();
    const [pkgPaymentList, setPkgPaymentList] = useState<ClientPackagePaymentDataExtended[]>([]);

    useEffect(() => {
        const mappedPaymentData = clientPkgInAction.payment.data.map(item => {
            return {
                ...item,
                isPaying: false,
                enteredMethod: ""
            }
        })
        setPkgPaymentList(mappedPaymentData);
    }, [clientPkgInAction, setPkgPaymentList])

    const { 
        day, 
        month, 
        year, 
        hours, 
        minutes 
    } = formatDateTime(new Date(clientPkgInAction.createdAt.timestamp * 1000));
    const paymentMethods = clientPkgInAction?.payment.data.map(item => item.method ?? "TBA").join(", ")

    const itemInfoList = [
        { title: "Buy Date", content: `${month} ${day}, ${year} @ ${hours}:${minutes}` },
        { title: "Title", content: clientPkgInAction.title },
        { title: "Description", content: clientPkgInAction.description },
        { title: "Client Name", content: clientPkgInAction.clientName },
        { title: "ClientEmail", content: clientPkgInAction.clientEmail },
        { title: "Client Phone", content: clientPkgInAction.clientPhone },
        { title: "Price", content: `€${clientPkgInAction.price.toFixed(2)}`},
        { title: "Payment Type", content: clientPkgInAction.payment.type },
        { title: "Payment Methods", content: paymentMethods },
        { title: "Bought Online", content: clientPkgInAction.payment.online ? "Yes" : "No" },
        { title: "Status", content: clientPkgInAction.status === "active" ? "Active" : "Used Up" }
    ];

    const makePayable = (installmentTimestamp: number) => {
        const newPackagePaymentList = pkgPaymentList.map(item => {
            if(item.date.timestamp === installmentTimestamp) {
                return {
                    ...item,
                    isPaying: true
                }
            }
            return item;
        });
        setPkgPaymentList(newPackagePaymentList)
    } 

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, installmentTimestamp: number) => {

        const { value } = event.target;

        const newPackagePaymentList = pkgPaymentList.map(item => {
            if(item.date.timestamp === installmentTimestamp) {
                return {
                    ...item,
                    enteredMethod: value
                }
            }
            return item;
        });

        setPkgPaymentList(newPackagePaymentList);

    }

    const updatePayment = async() => {

        setLoading(true);

        const updatedPaymentData = pkgPaymentList.map(item => {
            return {
                date: item.date,
                type: item.type,
                method: item.isPaying && item.enteredMethod !== "" ? item.enteredMethod : item.method,
                stripePaymentIntentId: item.stripePaymentIntentId,
                paypalPaymentCaptureId: item.paypalPaymentCaptureId,
                online: item.online,
                amount: item.amount,
                status: item.isPaying && item.enteredMethod !== "" ? "paid" : item.status,
            }
        });

        const { _id, tenantId } = clientPkgInAction;

        const endpoint = config.url.BACKEND_API_URL + `/packages/${tenantId}/${_id}/update-payment`;
        const requestBody = {
            paymentData: updatedPaymentData
        };
        const authConfig = getAuthTokenConfig();

        try {

            const response = await axios.put(endpoint, requestBody, authConfig);
            setLoading(false);
            setSuccessMsg("Payment updated");
            const { pkg } = response.data;
            setClientPkgInAction(pkg);

            const newPkgList = clientPackageList.map(item => {
                if(item._id === pkg._id) return pkg;
                return item;
            });

            setClientPackageList(newPkgList);

        } catch(error) {
            setLoading(false);
            const { message } = handleApiError(error);
            setErrorMsg(message);
        }
    }
    
    return (
        <div className={styles.client_pkg_details}>
            {itemInfoList.map(item => {
                return (
                    <ClientPackageItemInfo 
                        key={item.title}
                        title={item.title}
                        content={item.content}
                    />
                );
            })}
            <div className={styles.pkg_treatment_list}>
                <h3>Treatments And Sessions</h3>
                <table>
                    <tbody>
                        {clientPkgInAction.treatmentList.map(item => {
                            return (
                                <tr key={item._id}>
                                    <td>{item.title}</td>
                                    <td>{item.sessionsUsed} used</td>
                                    <td>{item.sessionsLeft} left</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className={styles.pkg_payment_list}>
                <h3>Payment</h3>
                <table>
                    <tbody>
                        {clientPkgInAction.payment.data.map(item => {

                            const { dateString } = formatDateTime(new Date(item.date.timestamp * 1000));
                            const isDue = item.status === ClientPackagePaymentStatus.Due;
                            const pkgPayment = pkgPaymentList.find(pkgPayment => {
                                return pkgPayment.date.timestamp === item.date.timestamp;
                            });
                            
                            return (
                                <tr key={item.date.timestamp}>
                                    <td>{dateString}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.status}</td>
                                    {
                                        isDue
                                        ?
                                        <>
                                            <td>
                                                <button
                                                    className={styles.pkg_pay_button} 
                                                    onClick={() => makePayable(item.date.timestamp)}
                                                >
                                                    Pay
                                                </button>
                                            </td>
                                            {
                                                pkgPayment?.isPaying
                                                ?
                                                <td className={styles.pkg_method_input}>
                                                    <input 
                                                        type="text"
                                                        name="method"
                                                        placeholder="payment method"
                                                        value={pkgPayment.enteredMethod}
                                                        onChange={(event) => handleChange(event, item.date.timestamp)}
                                                    />
                                                </td>
                                                :
                                                null
                                            }
                                        </>
                                        :
                                        null
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {
                    successMsg
                    ?
                    <p className={styles.package_payment_msg}>
                        {successMsg}
                    </p>
                    :
                    errorMsg
                    ?
                    <p className={styles.package_payment_msg}>
                        {errorMsg}
                    </p>
                    :
                    null
                }
                {
                    pkgPaymentList.some(item => item.enteredMethod !== "")
                    ?
                    <div className={styles.update_pkg_payment_button}>
                        <button 
                            disabled={loading}
                            onClick={updatePayment}
                        >
                            Save
                        </button>
                    </div>
                    :
                    null    
            }
            </div>
        </div>
    );

}

export default ClientPackageDetails;