import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { UserAuth } from "../../auth/UserAuth";
import useWindowWidth from "../../hooks/useWindowWidth";
import { 
    FETCH_PRODUCT_DATA,
    FETCH_PRODUCT_SALE_DATA,
    FETCH_PURCHASE_ORDER_DATA 
} from "../../store/products/constants";
import { 
    updateState, 
    closeSaleModal, 
    closePurchaseOrderModal,
    closeAddEditProductModal,
    togglePOProductsReceiveModal 
} from "../../store/products/productReducer";
import styles from "./index.module.css";
import Modal from "../../components/common/modal/Modal";
import InputField from "../../components/common/input-fields/InputField";
import ActionMessage from "../../components/common/messages/ActionMessage";
import DataTable from "./DataTable";
import AddEditProduct from "./AddEditProduct";
import SellProduct from "./SellProduct";
import PurchaseOrder from "./PurchaseOrder";
import DeleteProduct from "./DeleteProduct";
import ReceivePOProducts from "./ReceivePOProducts";
import ProductSalesDetails from "./ProductSalesDetails";

const ProductList = () => {

    const navigate = useNavigate();

    const { windowWidth } = useWindowWidth();

    const dispatch = useDispatch();
    const productState = useSelector((state: RootState) => state.productState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { 
        showTableFor,
        searchText, 
        isAdding, 
        isEditing, 
        isSelling,
        isCreatingPurchaseOrder, 
        savedPurchaseOrder,
        isReceivingPurchaseOrderProducts,
        purchaseOrderInAction,
        productSaleInAction,
        isDeleting,
        purchaseOrderProductList,
        productInAction,
        actionMessage 
    } = productState;

    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // User must be on trial period or must have bought a sub plan to access the products
        if(!user.stripeSubscription && !user.isOnTrialPeriod) {
            navigate("/");
            return;
        }

        const delayAPICall = setTimeout(() => {

            if(showTableFor === "products") {
                dispatch({ type: FETCH_PRODUCT_DATA })
            }

            if(showTableFor === "product_sales") {
                dispatch({ type: FETCH_PRODUCT_SALE_DATA })
            }

            if(showTableFor === "purchase_order") {
                dispatch({ type: FETCH_PURCHASE_ORDER_DATA })
            }
            
        }, 500);

        return () => clearTimeout(delayAPICall);
        
    }, [user, searchText, dispatch, navigate, showTableFor]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        dispatch(updateState({ name, value }));
    }

    const closeModal = () => {

        // If validationError state was set to true from anywhere, it must be false now 
        // to avoid unexpected validation error messages
        dispatch(updateState({
            name: "validationError",
            value: false
        }));

        if(isCreatingPurchaseOrder) {

            // If a purchase order was created then all the states related to creating
            // it must be set to their initial states.  
            if(savedPurchaseOrder) {
                dispatch(closePurchaseOrderModal());
                return;
            }

            // If user selected products and closed the pop up before completing the 
            // purchase order creation then only pop up should close. All the updated
            // states related to creating purchase order must not change
            dispatch(updateState({
                name: "isCreatingPurchaseOrder",
                value: false
            }));

            return;

        }

        if(isReceivingPurchaseOrderProducts) {
            dispatch(togglePOProductsReceiveModal({
                purchaseOrderInAction: null,
                receivedPOProductList: []
            }))
            return;
        }

        if(actionMessage) {
            dispatch(updateState({
                name: "actionMessage",
                value: null
            }));
            return;
        }

        if(isAdding || isEditing) {
            dispatch(closeAddEditProductModal());
            return;
        }

        if(isSelling) {
            dispatch(closeSaleModal());
        }

        if(productSaleInAction) {
            dispatch(updateState({
                name: "productSaleInAction",
                value: null
            }));
        }
        
    }

    const openPurchaseOrderModal = () => {

        if(!purchaseOrderProductList.length) {
            alert("Please add at least one product to create purchase order");
            return;
        }

        dispatch(updateState({ 
            name: "isCreatingPurchaseOrder", 
            value: true 
        }));

    }

    return (
        <div className={styles.products_page}>
            <div className={styles.product_buttons}>
                <button onClick={() => dispatch(updateState({ name: "isAdding", value: true }))}>
                    Create New Product
                </button>
                {
                    showTableFor === "products"
                    ?
                    <button onClick={openPurchaseOrderModal}>
                        Create Purchase Order
                        {
                            purchaseOrderProductList.length > 0
                            ?
                            <span>{purchaseOrderProductList.length}</span>
                            :
                            null
                        }
                    </button>
                    :
                    null
                }
                <button>Export</button>
            </div>
            <div className={styles.search_container}>
                <InputField
                    customClassName="search_input" 
                    name="searchText"
                    value={searchText}
                    placeholder="Search"
                    handleChange={handleChange}
                />
            </div>
            <DataTable />
            {
                (isAdding || isEditing)
                ?
                <Modal 
                    modalContent={<AddEditProduct />}
                    onCloseModal={closeModal}
                />
                :
                null
            }
            {
                (isSelling && productInAction)
                ?
                <Modal 
                    minWidth={windowWidth <= 1000 ? "90%" : productInAction.imageList[0]?.imageUrl ? "55%" : "40%"}
                    modalContent={<SellProduct />}
                    onCloseModal={closeModal}
                />
                :
                null
            }
            {
                (isCreatingPurchaseOrder && purchaseOrderProductList.length > 0)
                ?
                <Modal 
                    modalContent={<PurchaseOrder />}
                    onCloseModal={closeModal}
                    minHeight="30%"
                />
                :
                null
            }
            {
                (isReceivingPurchaseOrderProducts && purchaseOrderInAction)
                ?
                <Modal 
                    modalContent={<ReceivePOProducts />}
                    onCloseModal={closeModal}
                    minHeight="30%"
                />
                :
                null
            }
            {
                productSaleInAction
                ?
                <Modal 
                    modalContent={<ProductSalesDetails />}
                    onCloseModal={closeModal}
                />
                :
                null
            }
            {
                actionMessage
                ?
                <ActionMessage 
                    actionMessage={actionMessage}
                    hideActionMessage={closeModal}
                />
                :
                null
            }
            {(isDeleting && productInAction) ? <DeleteProduct /> : null}
        </div>
    );

}

export default ProductList;
