import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { UserAuth } from "../../auth/UserAuth";
import { FETCH_USER_LIST_DATA } from "../../store/user-list/constants";
import { updateActionMessage } from "../../store/user-list/userListReducer";
import styles from "./index.module.css";
import SearchAdd from "./SearchAdd";
import AddEditUser from "./add-edit-user/AddEditUser";
import UserTable from "./UserTable";
import DeleteUser from "./DeleteUser";
import ActionMessage from "../../components/common/messages/ActionMessage";


const UserList = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const userListState = useSelector((state: RootState) => state.userListState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { 
        userDataFetchInfo, 
        isAdding, 
        isEditing, 
        isDeleting, 
        userItemInAction,
        actionMessage 
    } = userListState;
    const { searchText, skip, limit } = userDataFetchInfo;

    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // User must be on trial period or must have bought a sub plan to access the user-list
        if(!user.stripeSubscription && !user.isOnTrialPeriod) {
            navigate("/");
            return;
        }

        dispatch({ 
            type: FETCH_USER_LIST_DATA, 
            payload: { 
                tenantId: user?.tenantId, 
                searchText, 
                skip, 
                limit,
                fetchLocationData: "Yes" 
            } 
        });
        
    }, [user?._id]);


    const hideActionMessage = () => {
        dispatch(updateActionMessage(""))
    }

  
    return (
        <div className = {styles.locations_page}>
            {
                actionMessage
                &&
                <ActionMessage 
                    actionMessage = {actionMessage}
                    hideActionMessage={hideActionMessage}
                />
            }
            <SearchAdd />
            <UserTable />
            {(isAdding || isEditing) && <AddEditUser />}
            {(isDeleting && userItemInAction) && <DeleteUser />}
        </div>
    );

}


export default UserList;