import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IWaitList } from "../../types/models/WaitList";
import { WaitListState } from "../../types/pages/WaitList";

const initialState: WaitListState = {
    loading: false,
    errorMsg: null,
    waitList: [],
    waitListItemInAction: null,
    isDeleting: false,
    actionMessage: null
}

const waitListSlice = createSlice({
    name: "wait-list",
    initialState,
    reducers: {

        fetchDataLoading: (state) => {
            return { ...state, loading: true }
        },

        fetchDataSuccess: (state, action: PayloadAction<IWaitList[]>) => {
            return {
                ...state,
                loading: false,
                waitList: action.payload,
            }
        },

        fetchDataFail: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        },

        updateState: (state, action: PayloadAction<{ name: string, value: any }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            };
        },

        toggleDeletePrompt: (state, action: PayloadAction<null | IWaitList>) => {
            return {
                ...state,
                waitListItemInAction: action.payload,
                isDeleting: !state.isDeleting
            }
        }

    }
})

export const {
    fetchDataLoading,
    fetchDataSuccess,
    fetchDataFail,
    updateState,
    toggleDeletePrompt
} = waitListSlice.actions;

export default waitListSlice.reducer;