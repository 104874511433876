import { useState, useCallback } from "react";
import axios from "axios";
import { config } from "../../../Constants";
import { IClientPackage } from "../../../types/models/ClientPackage";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";

type FetchQuery = {
    tenantId?: string
    searchText?: string
    skip?: number
    limit?: number | string
}

type Return = {
    loading: boolean
    errorMsg: string | undefined
    clientPackageList: IClientPackage[]
    setClientPackageList: React.Dispatch<React.SetStateAction<IClientPackage[]>>
    fetchClientPackages: () => Promise<void>
}

export const useFetchClientPackages = (fetchQuery: FetchQuery): Return => {

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [clientPackageList, setClientPackageList] = useState<IClientPackage[]>([]);
    const { 
        tenantId,
        searchText = "", 
        skip = 0, 
        limit = "10000" 
    } = fetchQuery;
    const queryParams = `searchText=${searchText}&skip=${skip}&limit=${limit}`

    const fetchClientPackages = useCallback(async() => {

        const endpoint = config.url.BACKEND_API_URL + `/packages/${tenantId}/client-packages?` + queryParams;
        const axiosConfig = getAuthTokenConfig();

        try {

            setLoading(true);

            const response = await axios.get(endpoint, axiosConfig);
            setClientPackageList(response.data.clientPackageList);

            setLoading(false);

        } catch(error: any) {
            setLoading(false);
            const { message } = handleApiError(error);
            setErrorMsg(message)
        }

    }, [queryParams])

    return {
        loading,
        errorMsg,
        clientPackageList,
        setClientPackageList,
        fetchClientPackages
    };

}