import styles from "./index.module.css";

interface CheckboxProps {
    name: string
    value: string
    checked: boolean
    handleChange: () => void
}

const Checkbox: React.FC<CheckboxProps> = ({ name, value, checked, handleChange }) => {

    return (
        <div className = {styles.checkbox_input}>
            <input 
                name = {name}
                type = "checkbox" 
                value = {value} 
                checked = {checked}
                onChange = {handleChange}
            />
            <span className = {styles.checkbox_input_value}>{value}</span>
        </div>
    );

}

export default Checkbox;