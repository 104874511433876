import axios from "axios";
import { ProductState } from "../../../types/pages/Product";
import { config } from "../../../Constants";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";
import { decodeJWTToken } from "../../../helpers/others/decodeJWTToken";

type Payload = Pick<ProductState, "searchText" | "skip" | "limit">

export const fetchProducts = async(payload: Payload) => {

    const { tenantId } = decodeJWTToken("auth_token");

    const { searchText, skip, limit } = payload;

    const param = `searchText=${searchText}&skip=${skip}&limit=${limit}`;
    
    const endpoint = `${config.url.BACKEND_API_URL}/products/tenant/${tenantId}?${param}`;
    const authConfig = getAuthTokenConfig();
  
    const response = await axios.get(endpoint, authConfig);
    return response.data;

}