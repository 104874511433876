import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ICategory } from "../../types/models/Category";
import useWindowWidth from "../../hooks/useWindowWidth";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./index.module.css";
import ResponsiveAppointmentTable from "./ResponsiveAppointmentTable";

const AppointmentTable: React.FC = () => {

    const reportState = useSelector((state: RootState) => state.reportState);
    const { appointmentList } = reportState;
    const { windowWidth } = useWindowWidth();

    return (
        <>
            {
                windowWidth >= 1000
                ?
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Category</th>
                            <th>Treatment Title</th>
                            <th>Price After Discount</th>
                            <th>Status</th>
                            <th>Stylist</th>
                            <th>Payment Method</th>
                        </tr>
                    </thead>
                    <tbody>
                        {appointmentList.map(appointment => {
                            const { 
                                _id,
                                appointment_date,
                                treatmentCategory,
                                treatmentTitle,
                                priceAfterDiscount,
                                stylist,
                                status,
                                paymentMethod
                            } = appointment;

                            const categoryItem = appointment.categoryItem as ICategory;

                            const { day, month, year } = formatDateTime(new Date(appointment_date * 1000));

                            return (
                                <tr key = {_id}>
                                    <td>{`${month} ${day}, ${year}`}</td>
                                    <td className = {styles.category_container_td}>
                                        <div className = {styles.category}>
                                            <div className = {styles.category_icon}>
                                                <img 
                                                    alt="icon"
                                                    src={categoryItem?.icon ?? ""}
                                                />
                                            </div>
                                            <span>{treatmentCategory}</span>
                                        </div>
                                    </td>
                                    <td>{treatmentTitle}</td>
                                    <td>€{priceAfterDiscount.toFixed(2)}</td>
                                    <td>{status}</td>
                                    <td>{stylist}</td>
                                    <td>{paymentMethod}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                :
                <ResponsiveAppointmentTable />
            }
        </>
    );

}

export default AppointmentTable;