import { useEffect, useState } from "react";
import axios from "axios";
import { config } from "../../../Constants";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";

const useGetStripeAccountStatus = (stripeAccountId?: string) => {
    
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [isStripeConnectedAccountActive, setIsStripeConnectedAccountActive] = useState<boolean>(false);

    useEffect(() => {

        if(!stripeAccountId) return;

        (async function() {

            setLoading(true);
    
            const endpoint = `${config.url.BACKEND_API_URL}/users/stripe-account-status/` + stripeAccountId;
            const authConfig = getAuthTokenConfig();
       
            try {
    
                const response = await axios.get(endpoint, authConfig);
                setLoading(false);
                setIsStripeConnectedAccountActive(response.data.isStripeConnectedAccountActive)
    
            } catch (error) {
                setLoading(false);
                let { message } = handleApiError(error);
                setErrorMsg(message);
            } 
    
        })();

    }, [stripeAccountId])

    return { 
        loading, 
        errorMsg,
        isStripeConnectedAccountActive
    };

}

export default useGetStripeAccountStatus;