import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { IPolicy } from "./types";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { UserAuth } from "../../auth/UserAuth";
import { useFetchPolicies } from "./hooks/useFetchPolicies";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./index.module.css";
import Modal from "../../components/common/modal/Modal";
import NewContent from "./NewContent";
import ContentListTable from "./ContentListTable";
import PolicyTable from "./PolicyTable";
import DeletePrompt from "../../components/common/prompts/DeletePrompt";

const PolicyList = () => {

    const { tenantId } = useParams();
    const { 
        loading, 
        errorMsg, 
        policyList,
        setPolicyList, 
        fetchPolicies 
    } = useFetchPolicies();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;

    const [policyInAction, setPolicyInAction] = useState<IPolicy>()
    const [createEditPolicy, setCreateEditPolicy] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [content, setContent] = useState<Record<string, any>>({
        hoursBeforeAppointmentTime: "",
        applicableAppointmentChargeRate: ""
    });
    const [contentList, setContentList] = useState<Record<string, any>[]>([])

    // We need to fetch subscription and invoice data of the user
    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // If logged in user is not Tenant then he/she must be redirected to user profile
        if(user.userType !== "Tenant") {
            navigate(`/user/${user.tenantId}/profile-user`);
        }
        
        if(!tenantId) return;

        fetchPolicies(tenantId);

    }, [user, tenantId, fetchPolicies, navigate, dispatch, ])

    const toggleCreateEditPolicyModal = (policyId: string, actionType?: string) => {

        const foundPolicy = policyList.find(item => item._id === policyId);

        setPolicyInAction(foundPolicy);

        if(actionType === "delete") {
            setIsDeleting(true);
            return;
        }

        const newContentList = foundPolicy?.content.map(item => {
            return {
                hoursBeforeAppointmentTime: `${item?.hoursBeforeAppointmentTime}`,
                applicableAppointmentChargeRate: `${item?.applicableAppointmentChargeRate}`
            }
        })

        setContentList(newContentList ?? [])
    
        setCreateEditPolicy(!createEditPolicy)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setContent(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const addToContentList = () => {
        const isContentAdded = contentList.some(item => {
            return item.hoursBeforeAppointmentTime === content.hoursBeforeAppointmentTime
        });
        if(isContentAdded) return alert("Hours added already")
        setContentList([...contentList, content])
    }

    const removeFromContentList = (content: Record<string, any>) => {
        const filteredContentList = contentList.filter(item => {
            return item.hoursBeforeAppointmentTime !== content.hoursBeforeAppointmentTime
        });
        setContentList(filteredContentList)
    }

    const savePolicy = async() => {

        const isInvalid = contentList.some(item => {
            return isNaN(parseInt(item.hoursBeforeAppointmentTime)) || 
            isNaN(parseInt(item.hoursBeforeAppointmentTime))
        });

        if(isInvalid) return alert("must be number");

        const mappedContentList = contentList.map(item => {
            return {
                hoursBeforeAppointmentTime: parseInt(item.hoursBeforeAppointmentTime),
                applicableAppointmentChargeRate: parseInt(item.applicableAppointmentChargeRate)
            }
        });
    
        const axiosConfig = {
            baseURL: config.url.BACKEND_API_URL + "/policies",
            url: policyInAction ? `/edit/${policyInAction._id}` : `/create/${tenantId}`,
            method: policyInAction ? "put" : "post",
            headers: getAuthTokenConfig().headers,
            data: {
                content: mappedContentList,
                type: "cancel",
                item: "appointment"
            }
        }

        try {

            const response = await axios(axiosConfig);
            const savedPolicy: IPolicy = response.data.policy;

            setCreateEditPolicy(false);
            setContentList([]);
            setContent({
                hoursBeforeAppointmentTime: "",
                applicableAppointmentChargeRate: ""
            })

            if(!policyInAction) {
                setPolicyList([...policyList, savedPolicy]);
                return;
            }

            setPolicyInAction(undefined);

            const newPolicyList = policyList.map(item => {
                if(item._id === savedPolicy._id) return savedPolicy;
                return item;
            });

            setPolicyList(newPolicyList);

        } catch(error) {
            const { message } = handleApiError(error);
            alert(message)
        }

    }

    const closeDeletePrompt = () => {
        setIsDeleting(false)
    }

    const deletePolicy = async() => {

        const endpoint = config.url.BACKEND_API_URL + "/policies/delete/" + policyInAction?._id;
        const axiosConfig = getAuthTokenConfig();

        try {

            await axios.delete(endpoint, axiosConfig);

            const newPolicyList = policyList.filter(item => policyInAction?._id !== item._id);

            setPolicyList(newPolicyList);

            setIsDeleting(false);
            setPolicyInAction(undefined)
         
        } catch(error) {
            const { message } = handleApiError(error);
            alert(message);
            setIsDeleting(false);
            setPolicyInAction(undefined)
        }

    }

    return (
        <div className={styles.policies}>
            <div className={styles.add_button}>
                <button 
                    disabled={policyList.length > 0}
                    onClick={() => setCreateEditPolicy(true)}
                >
                    Create Policy
                </button>
            </div>
            {
                policyList.length > 0
                ?
                <PolicyTable
                    loading={loading}
                    errorMsg={errorMsg}
                    policyList={policyList}
                    toggleCreateEditPolicyModal={toggleCreateEditPolicyModal}
                />
                :
                null
            }
            {
                isDeleting
                ?
                <DeletePrompt 
                    warningText={<p>Are you sure want to delete the policy?</p>}
                    closeDeletePrompt={closeDeletePrompt}
                    deleteItem={deletePolicy}
                />
                :
                null
            }
            {
                createEditPolicy
                ?
                <Modal 
                    modalContent={
                        <div className={styles.create_policy_modal}>
                            <h2>Appointment Cancellation Policy</h2>
                            <p>Add a list of hours and rates related to appointment cancellation which will be displayed to 
                                clients when they book, reschedule or cancel an appointment.
                            </p>
                            <NewContent 
                                content={content}
                                handleChange={handleChange}
                                addContent={addToContentList}
                            />
                            {
                                contentList.length > 0
                                ?
                                <ContentListTable 
                                    contentList={contentList}
                                    removeContent={removeFromContentList}
                                />
                                :
                                null
                            }
                            <div className={styles.save_button}>
                                <button onClick={savePolicy}>
                                    Save Policy
                                </button>
                            </div>
                        </div>
                    }
                    onCloseModal={() => setCreateEditPolicy(false)}
                />
                :
                null
            }
        </div>
    );

}

export default PolicyList;