import { SubscriptionFeature } from "../../../types/models/Subscription";
import styles from "./SubscriptionPlans.module.css";
import CheckIcon from "./CheckIcon";


interface IFeaturesProps {
    features: SubscriptionFeature []
}


const FeatureList: React.FC<IFeaturesProps> = ({ features }) => {

    
    return (
        <div className = {styles.sub_feature_list}>
            {features.map(feature => {
                return (
                    <div key = {feature._id} className = {styles.sub_feature_item}>
                        <CheckIcon
                            width="12"
                            height="12" 
                            color = "#FFAAA5"
                        />
                        <p>{feature.title}</p>
                    </div>
                );
            })}
        </div>
    );
}


export default FeatureList;