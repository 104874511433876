import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { selectLocation } from "../../store/discounts/discountReducer";
import styles from "./index.module.css";
import Header from "./Header";
import CheckboxInputField from "./CheckboxInputField";

const SelectLocation: React.FC = () => {

    const dispatch = useDispatch();
    const discountState = useSelector((state: RootState) => state.discountState);
    const { locationList, selectedLocations } = discountState;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const { value, checked } = event.target;

        // Selecting location
        if(checked) {

            const locationActionType = "select";

            // If user is selecting all locations then payload would be fetched locationList api data
            if(value === "Select All") {
                dispatch(selectLocation({
                    locationActionType,
                    selectedLocations: locationList
                }));
                return;
            } 
            
            // If user is not selecting all then we need to find the location and insert it into 
            // selecteLocations array.
            const foundLocation = locationList.find(location => location.title === value);
            if(!foundLocation) return;
            dispatch(selectLocation({
                locationActionType,
                selectedLocations: [...selectedLocations, foundLocation]
            }));
            return;

        }

        // Unselecting locations
        const locationActionType = "unselect";

        // if user is unselecting all then payload would be empty array
        if(value === "Select All") {
            dispatch(selectLocation({
                locationActionType,
                selectedLocations: []
            }));
            return;
        } 

        // If user is not unselecting all then need to filter the selectedLocations
        const filteredSelectedLocations = selectedLocations.filter(location => location.title !== value);
        dispatch(selectLocation({
            locationActionType,
            selectedLocations: filteredSelectedLocations
        }));

    }

    return (
        <div className = {styles.select_location}>
            <Header text = "Select Location" />
            <CheckboxInputField 
                name = "selectLocation"
                value = "Select All"
                checked = {locationList.length === selectedLocations.length}
                handleChange = {handleInputChange} 
            />
            {locationList.map(location => {
                const isChecked = selectedLocations.some(item => item._id === location._id);
                return (
                    <CheckboxInputField 
                        key = {location._id.toString()}
                        name = "selectLocation"
                        value = {location.title}
                        checked = {isChecked}
                        handleChange = {handleInputChange} 
                    />
                );
            })}
        </div>
    );

}


export default SelectLocation;