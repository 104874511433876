import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { UserAuth } from "../../auth/UserAuth";
import { FETCH_DATA } from "../../store/treatments/constants";
import { updateActionMessage } from "../../store/treatments/treatmentReducer";
import styles from "./index.module.css";
import SearchAdd from "./SearchAdd";
import AddEditTreatment from "./AddEditTreatment";
import TreatmentTable from "./TreatmentTable";
import DeleteTreatment from "./DeleteTreatment";
import ActionMessage from "../../components/common/messages/ActionMessage";

const TreatmentList = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const treatmentState = useSelector((state: RootState) => state.treatmentState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { 
        treatmentDataFetchInfo, 
        isAdding, 
        isEditing, 
        isDeleting, 
        treatmentItemInAction,
        actionMessage
    } = treatmentState;
    const { searchText, skip, limit } = treatmentDataFetchInfo;


    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // User must be on trial period or must have bought a sub plan to access the treatments
        if(!user.stripeSubscription && !user.isOnTrialPeriod) {
            navigate("/");
            return;
        }

        dispatch({ 
            type: FETCH_DATA, 
            payload: { 
                tenantId: user.tenantId, 
                searchText, 
                skip, 
                limit,
                fetchCategoryAndStylistData: "Yes" 
            } 
        });
        
    }, [user?._id]);

    const hideActionMessage = () => {
        dispatch(updateActionMessage(""))
    }

  
    return (
        <div className = {styles.treatments_page}>
            {
                actionMessage
                &&
                <ActionMessage 
                    actionMessage = {actionMessage}
                    hideActionMessage={hideActionMessage}
                />
            }
            <SearchAdd />
            <TreatmentTable />
            {(isAdding || isEditing) && <AddEditTreatment />}
            {(isDeleting && treatmentItemInAction) && <DeleteTreatment />}
        </div>
    );

}


export default TreatmentList;