import { RootState } from "../store";
import { UserState } from "../types/models/User";
import { PackageState } from "../types/pages/Package";
import { select, call, put, takeLatest } from "redux-saga/effects";
import { FETCH_PACKAGE_DATA } from "../store/packages/constants";
import { FetchedData } from "../types/pages/Package";
import { 
    fetchDataFail,
    fetchDataLoading,
    fetchDataSuccess
} from "../store/packages/packageReducer";
import { fetchPackageApiData } from "./api/packages/fetchPackageApiData";

const userState = (state: RootState) => state.userState;
const packageState = (state: RootState) => state.packageState;

function* handleFetchData() {

    const { user }: UserState  = yield select(userState);
    const { 
        searchText,
        skip,
        limit,
        fetchTreatmentData
    }: PackageState = yield select(packageState);

    try {

        yield put(fetchDataLoading())
        const result: FetchedData = yield call(fetchPackageApiData, {
            tenantId: user?.tenantId,
            searchText,
            skip,
            limit,
            fetchTreatmentData
        });
        
        yield put(fetchDataSuccess(result))

    } catch(error) {
        yield put(fetchDataFail())
    }

}

export default function* packageSaga() {
    yield takeLatest(FETCH_PACKAGE_DATA, handleFetchData);
}
