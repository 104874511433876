import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { ClientItem } from "../../../types/pages/Calendar";
import { bookClientAppointment } from "../../../store/calendar/calendarReducer";
import styles from "./ClientSearch.module.css";

const Buttons: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const clientInAction = calendarState.clientInAction as ClientItem

    const handleClick = () => {
        const { name, email, phone } = clientInAction;
        dispatch(bookClientAppointment({ name, email, phone }));
    }

    return (
        <div className={styles.buttons}>
            <button onClick={handleClick}>
                Book New Appointment
            </button>
        </div>
    );

}

export default Buttons;