export const hours = [
    // { _id: 1, hour: "12:00" },
    // { _id: 2, hour: "1:00" },
    // { _id: 3, hour: "2:00" },
    // { _id: 4, hour: "3:00" },
    { _id: 5, hour: "4:00" },
    { _id: 6, hour: "5:00" },
    { _id: 7, hour: "6:00" },
    { _id: 8, hour: "7:00" },
    { _id: 9, hour: "8:00" },
    { _id: 10, hour: "9:00" },
    { _id: 11, hour: "10:00" },
    { _id: 12, hour: "11:00" },
    { _id: 13, hour: "12:00" },
    { _id: 14, hour: "13:00" },
    { _id: 15, hour: "14:00" },
    { _id: 16, hour: "15:00" },
    { _id: 17, hour: "16:00" },
    { _id: 18, hour: "17:00" },
    { _id: 19, hour: "18:00" },
    { _id: 20, hour: "19:00" },
    { _id: 21, hour: "20:00" },
    { _id: 22, hour: "21:00" },
    { _id: 23, hour: "22:00" },
    { _id: 24, hour: "23:00" }
]