import styles from "./Calendar.module.css";

interface TimeIndicatorProps {
    totalMinutes: number
    style?: { [key: string]: string }
}

const TimeIndicator: React.FC<TimeIndicatorProps> = ({ totalMinutes, style = {} }) => {

    return (
        <div 
            className={styles.cal_horizontal_line}
            style={{
                ...style,
                top: `${((totalMinutes - 240) * 1.6667) + 49.2}px`
            }}
        >
        </div>
    );

}

export default TimeIndicator;