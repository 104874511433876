import styles from "./DataTable.module.css";

interface ProductSalesItemInfoProps {
    title: string
    content: string
}

const ProductSalesItemInfo: React.FC<ProductSalesItemInfoProps> = ({ title, content }) => {

    return (
        <div className={styles.product_sales_item_info}>
            <p>{title}</p>
            <p>{content}</p>
        </div>
    );

}

export default ProductSalesItemInfo;