import { useSelector } from "react-redux";
import { RootState } from "../../store";
import styles from "./index.module.css";
import ActionButtons from "./ActionButtons";

interface ResponsiveDataTableProps {
    handleClick: (actionType: string, appointmentClientId: string ) => void
}

const ResponsiveDataTable: React.FC<ResponsiveDataTableProps> = ({ handleClick }) => {

    const appointmentClientState = useSelector((state: RootState) => state.appointmentClientState);
    const { appointmentClientList } = appointmentClientState;
   
    return (
        <div className={styles.responsive_product_table}>
            {appointmentClientList.map(client => {
                const { _id, fullName, email, phone, note } = client;
                return (
                    <div 
                        key={_id}
                        className={styles.responsive_item}
                    >
                        <div className={styles.responsive_item_header}>
                            <p className={styles.responsive_item_name}>{fullName}</p>
                            <ActionButtons 
                                itemId={_id}
                                showButtonText={false}
                                handleClick={handleClick}
                            />
                        </div>
                        <div className={styles.responsive_other_info}>
                            <p>{email}</p>
                            <p>{phone}</p>
                            <p>{note}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );

}

export default ResponsiveDataTable;
