import { RootState } from "../store";
import { select, call, put, takeLatest } from "redux-saga/effects";
import { 
    FetchedData,
    FetchedAppointmentData, 
    CalendarState
} from "../types/pages/Calendar";
import { fetchCalendarApiData } from "./api/calendar/fetchCalendarApiData";
import { fetchAppointments } from "./api/calendar/fetchAppointments";
import { 
    fetchDataSuccess, 
    fetchDataFail,
    updateState 
} from "../store/calendar/calendarReducer";
import { FETCH_APPOINTMENT_DATA, FETCH_CALENDAR_API_DATA } from "../store/calendar/constants";
import { calculateStartEndDateStrings } from "../helpers/calendar/calculateStartEndDateStrings";
import { handleApiError } from "../helpers/error-handlers/handleApiError";

const calendarState = (state: RootState) => state.calendarState;

function* handleFetchData() {

    try {
        const result: FetchedData  = yield call(fetchCalendarApiData);
        yield put(fetchDataSuccess(result));  
    } catch(error: any) {
        yield put(fetchDataFail());
    }

}

function* handleFetchAppointments() {

    const { 
        calendarViewType,
        dayViewDate,
        weekViewDates,
        monthViewDates,
        selectedLocationIds,
        selectedStylistIds 
    }: CalendarState = yield select(calendarState);

    const { startDateString, endDateString } = calculateStartEndDateStrings({
        calendarViewType,
        dayViewDate,
        weekViewDates,
        monthViewDates
    });

    try {
        const result: FetchedAppointmentData  = yield call(
            fetchAppointments, 
            { 
                startDateString,
                endDateString,
                selectedLocationIds,
                selectedStylistIds 
            }
        );
        yield put(updateState({
            name: "appointmentList",
            value: result.appointmentList
        }));
    } catch(error: any) {
        const { message } = handleApiError(error)
        yield put(updateState({
            name: "appointmentFetchError",
            value: message
        }));
    }

}


export default function* calendarSaga() {
    yield takeLatest(FETCH_CALENDAR_API_DATA, handleFetchData);
    yield takeLatest(FETCH_APPOINTMENT_DATA, handleFetchAppointments);
}

