import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { updateState } from "../../../store/calendar/calendarReducer";
import CheckboxInputField from "../common/CheckboxInputField";


const EmailCheckbox = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { doNotSendEmailToClient } = calendarState;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        dispatch(updateState({
            name: "doNotSendEmailToClient",
            value: checked
        }))
    } 
    

    return (
        <>
            <CheckboxInputField 
                name = "doNotSendEmailToClient"
                value = "Do not send email to client"
                handleChange = {handleChange}
                checked = {doNotSendEmailToClient}
            />
        </>
    );

}

export default EmailCheckbox;