import { OffPeakDiscount } from "../../types/models/Discount";

export const getOffPeakDiscountList = () => {

    const times = [
        { time: "morning", timeText: "Morning (unitl 12:00)" },
        { time: "afternoon", timeText: "Afternoon (12:00 - 17:00)" },
        { time: "evening", timeText: "Evening (17:00 and later)" }
    ];

    const defaultOffPeakDiscountList: OffPeakDiscount[] = [];

    for(let x = 0; x < times.length; x++) {

        const discountItem = {
            _id: `${x + 1}`,
            time: times[x].time,
            timeText: times[x].timeText,
            mondayDiscountRate: 0, 
            tuesdayDiscountRate: 0, 
            wednesdayDiscountRate: 0, 
            thursdayDiscountRate: 0, 
            fridayDiscountRate: 0, 
            saturdayDiscountRate: 0, 
            sundayDiscountRate: 0
        };

        defaultOffPeakDiscountList.push(discountItem);
        
    }

    return defaultOffPeakDiscountList;

}