import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import {  
    editDiscount, 
    toggleActivationStatus, 
    toggleDeletePrompt 
} from "../../store/discounts/discountReducer";
import useWindowWidth from "../../hooks/useWindowWidth";
import styles from "./DiscountTable.module.css";
import TableMessageTR from "./TableMessageTR";
import ActionButtons from "../../components/common/buttons/ActionButtons";
import ResponsiveDiscountTable from "./ResponsiveDiscountTable";

const DiscountTable = () => {

    const dispatch = useDispatch();
    const discountState = useSelector((state: RootState) => state.discountState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();

    const { loading, discountList } = discountState;

    const handleClick = (discountId: string, actionType: string) => {

        const discount = discountList.find(item => item._id === discountId);
        if(!discount) return;

        if(actionType === "edit") {
            dispatch(editDiscount(discount));
            return;
        }

        if(actionType === "delete") {
            dispatch(toggleDeletePrompt(discountId))
            return;
        }

        dispatch(toggleActivationStatus(discount))
      
    }
  
    return (
        <div className={styles.discount_table}> 
            {
                isLargeDevice
                ?
                <table>
                    <thead>
                        <tr className={styles.heading_tr}>
                            <th>Title</th>
                            <th>Locations</th>
                            <th>Stylists</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading
                            ?
                            <TableMessageTR 
                                colSpan={6}
                                message="Loading..."
                            />
                            :
                            discountList.length < 1
                            ?
                            <TableMessageTR 
                                colSpan={6}
                                message="No Discounts To Show"
                            />
                            :
                            discountList.map(discount => {

                                const { _id, title, locationList, stylistList, isActive } = discount;

                                return (
                                    <tr key={_id} className={styles.data_tr}>
                                        <td>{title}</td>
                                        <td>{locationList.map(item => item.title).join(", ")}</td>
                                        <td>{stylistList.map(item => item.fullName).join(", ")}</td>
                                        <td>{isActive ? "Active" : "Inactive"}</td>
                                        <td>
                                            <ActionButtons 
                                                actionTypeList={["toggle", "edit", "delete"]}
                                                itemId={_id}
                                                showButtonText={true}
                                                handleClick={handleClick}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                :
                <ResponsiveDiscountTable
                    handleClick={handleClick}
                />
            }
        </div>
    );

}

export default DiscountTable;
