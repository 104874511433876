import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { togglePOProductsReceiveModal } from "../../store/products/productReducer";
import useWindowWidth from "../../hooks/useWindowWidth";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./DataTable.module.css";
import TableMessageTR from "./TableMessageTR";
import ActionButtons from "../../components/common/buttons/ActionButtons";
import ResponsivePOTable from "./ResponsivePOTable";

const POTable = () => {

    const dispatch = useDispatch();
    const productState = useSelector((state: RootState) => state.productState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();

    const { loading, purchaseOrderList } = productState;

    const handleClick = (purchaseOrderId: string ) => {

        const foundPO = purchaseOrderList.find(item => item._id === purchaseOrderId);
        if(!foundPO) return;

        if(foundPO.productsReceived) return;

        const newReceivedPOProductList = foundPO.productList.map(item => {
            return {
                productId: item.productItem._id,
                quantity: item.quantity,
                currentProductStock: item.productItem.stockAvailable,
                receivedQuantity: item.quantity
            }
        });

        dispatch(togglePOProductsReceiveModal({
            purchaseOrderInAction: foundPO,
            receivedPOProductList: newReceivedPOProductList
        }));

    }
  
    return (
        <>
            {
                isLargeDevice
                ?
                <table>
                    <thead>
                        <tr className={styles.heading_tr}>
                            <th>Issue Date</th>
                            <th>Party Name</th>
                            <th>Number Of Products</th>
                            <th>Products Received</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading
                            ?
                            <TableMessageTR 
                                colSpan={5}
                                message="Loading..."
                            />
                            :
                            purchaseOrderList.length < 1
                            ?
                            <TableMessageTR 
                                colSpan={5}
                                message="No PO To Show"
                            />
                            :
                            purchaseOrderList.map(purchaseOrder => {

                                const { 
                                    _id, 
                                    createdAt,
                                    issuedToPartyName, 
                                    productsReceived, 
                                    productList 
                                } = purchaseOrder;

                                const { day, month, year } = formatDateTime(new Date(createdAt * 1000));

                                return (
                                    <tr key={_id} className={styles.data_tr}>
                                        <td>{`${month} ${day}, ${year}`}</td>
                                        <td>{issuedToPartyName}</td>
                                        <td>{productList.length}</td>
                                        <td>{productsReceived ? "Yes" : "No"}</td>
                                        <td>
                                            <ActionButtons 
                                                actionTypeList={["receive"]}
                                                itemId={_id}
                                                showButtonText={true}
                                                disableReceiveButton={productsReceived}
                                                handleClick={handleClick}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                :
                <ResponsivePOTable 
                    handleClick={handleClick}
                />
            }
        </>
    );

}

export default POTable;
