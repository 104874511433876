import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { config } from "../../Constants";
import { RootState } from "../../store";
import { IAppointmentClientSearched } from "../../types/models/AppointmentClient";
import { ClientPackagePaymentInstallment } from "../../types/models/ClientPackage";
import { 
    addPackageBuyInfo, 
    updateState,
    togglePackageBuyModal 
} from "../../store/packages/packageReducer";
import useClickOutside from "../../hooks/useClickOutside";
import useFetchSearchedClients from "../../hooks/useFetchSearchedClients";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./BuyPackage.module.css";
import InputField from "../../components/common/input-fields/InputField";
import DropdownList from "./DropdownList";
import Button from "./Button";
import InstallmentTable from "./InstallmentTable";
import ClientList from "./ClientList";
import { PackageBuyInfo } from "../../types/pages/Package";

const BuyPackage = () => {

    const { 
        elementRef, 
        isVisible, 
        setIsVisible 
    } = useClickOutside({ exceptions: [] });
    const { 
        clientList, 
        setClientList, 
        fetchClients 
    } = useFetchSearchedClients();

    const { tenantId } = useParams();
    const dispatch = useDispatch();
    const packageState = useSelector((state: RootState) => state.packageState);
    const { 
        validationError, 
        packageItemInAction,
        packageBuyInfo,
    } = packageState;
    const { paymentMethod, clientName, clientEmail, clientPhone } = packageBuyInfo;
    const [paymentType, setPaymentType] = useState<string>("single");
    const [installment, setInstallment] = useState<Record<string, any>>({
        date: null,
        dateString: "",
        amount: "",
        method: ""
    });
    const [installmentList, setInstallmentList] = useState<Record<string, any>[]>([]);
    const [clientInputFieldBeingUpdated, setClientInputFieldBeingUpdated] = useState<string>();

    useEffect(() => {

        if(clientInputFieldBeingUpdated === "paymentMethod") return;

        const { clientName, clientEmail, clientPhone } = packageBuyInfo;

        // If none of the clientInfo field is filled then we must not make the api call.
        // This is for the first time when users opens up BuyPackage modal. Because
        // we definitely don't want to make an api call with empty searchText
        if(!clientName && !clientEmail && !clientPhone) return;

        // If all three fields of clientInfo are filled then we must not make the api call.
        if(clientName && clientEmail && clientPhone) return;

        const searchText = packageBuyInfo[clientInputFieldBeingUpdated as keyof PackageBuyInfo]

        // If user is backspacing on a clientInfo field to empty it then we must not make the api call.
        if(!searchText) return;

        // Calling setTimeout directly will make api call for each character typed. But assigning
        // this function to a variable re-initializes the timer. So, just one api call will be made
        // when user stops typing.
        const delayAPICall = setTimeout(async () => {
            setIsVisible(true);
            fetchClients(searchText);
        }, 500);

        return () => clearTimeout(delayAPICall);

    }, [
        packageBuyInfo, 
        clientInputFieldBeingUpdated, 
        fetchClients,
        setIsVisible
    ]);
   
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = event.target;

        if(name === "amount" || name === "method") {
            setInstallment(prevValue => {
                return {
                    ...prevValue,
                    [name]: value
                }
            });
            return;
        }
        
        dispatch(addPackageBuyInfo({ name, value }));

        setClientInputFieldBeingUpdated(name);

        if(value.length < 2) {
            setClientList([]);
            setIsVisible(false)
        }

    }

    const handleClick = (event: React.MouseEvent<HTMLLIElement>, client: IAppointmentClientSearched) => {
        event.preventDefault();
        dispatch(updateState({
            name: "packageBuyInfo",
            value: {
                ...packageBuyInfo,
                clientName: client.name,
                clientEmail: client.email,
                clientPhone: client.phone
            }
        }))
        setClientList([])
        setIsVisible(false);
    }

    const handleDateChange = (date: Date) => {

        // We are taking a detour because react-datepicker selected date causing issues.
        // User selects June 2 but somehow it becomes June 1. 
        const { dateString } = formatDateTime(date);
        const selectedDate = new Date(dateString);

        setInstallment(prevValue => {
            return {
                ...prevValue,
                date: selectedDate,
                dateString
            }
        });

    } 

    const handleDropdownChange = (payType: Record<string, any>) => {
        setPaymentType(payType.title)
    }

    const addInstallment = () => {
        const isAddedAlready = installmentList.some(item => item.dateString === installment.dateString);
        if(isAddedAlready) return alert("One installment has been added of this date")
        setInstallmentList([...installmentList, installment]);
        setInstallment({
            date: null,
            dateString: "",
            amount: "",
            method: ""
        });
    }

    const removeInstallment = (dateString: string) => {
        const filteredInstallmentList = installmentList.filter(item => item.dateString !== dateString);
        setInstallmentList(filteredInstallmentList)
    }

    const purchasePackage = async() => {

        if(
            !clientName || 
            !clientEmail || 
            !clientPhone ||
            (paymentType === "single" && !paymentMethod)
        ) {
            dispatch(updateState({
                name: "validationError",
                value: true
            }));
            return;
        }

        const newInstallmentList: ClientPackagePaymentInstallment[] = [];

        if(paymentType === "single") {
            newInstallmentList.push({
                date: formatDateTime(new Date()).dateString,
                method: paymentMethod,
                amount: packageItemInAction?.price ?? 0
            })
        }

        if(paymentType === "installment") {
            for(const installment of installmentList) {
                newInstallmentList.push({
                    date: installment.dateString,
                    method: installment.method !== "" ? installment.method : null,
                    amount: Number(installment.amount)
                })
            }
        }

        const totalInstallmentPrice = newInstallmentList.reduce((total, installment) => installment.amount + total, 0);
   
        if(totalInstallmentPrice.toFixed(2) !== packageItemInAction?.price.toFixed(2)) {
            return alert("Total installment amount must equal package price");
        }

        const endpoint = `${config.url.BACKEND_API_URL}/packages/${tenantId}/${packageItemInAction?._id}/purchase`;

        const authConfig = getAuthTokenConfig();
        const requestBody = {
            clientName,
            clientEmail,
            clientPhone,
            paymentType: paymentType === "single" ? "one_time" : paymentType,
            fromAdminPanel: true,
            installmentList: newInstallmentList
        };

        dispatch(togglePackageBuyModal(null));

        dispatch(updateState({
            name: "actionMessage",
            value: "Buying..."
        }));

        try {

            await axios.post(endpoint, requestBody, authConfig);
            dispatch(updateState({
                name: "actionMessage",
                value: "Package buy is successful"
            }));

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(updateState({
                name: "actionMessage",
                value: message
            }));
        }
    
    }

    return (
        <div className={styles.buy_package}>
            <h2>You are buying {packageItemInAction?.title} (€{packageItemInAction?.price.toFixed(2)})</h2>
            <div className={styles.buyer_info}>
                <div className={styles.client_input_container}>
                    <InputField 
                        labelText="Full Name"
                        name="clientName"
                        required={true}
                        value={packageBuyInfo.clientName}
                        handleChange={handleChange}
                        validationError={validationError}
                        validationErrorMessage="name can't be blank"
                    />
                    {
                        (clientInputFieldBeingUpdated === "clientName" && isVisible && clientList.length > 0)
                        ?
                        <ClientList 
                            elementRef={elementRef}
                            clientList={clientList}
                            handleClick={handleClick}
                        />
                        :
                        null
                    }
                </div>
                <div className={styles.client_input_container}>
                    <InputField 
                        labelText="Email"
                        type="email"
                        name="clientEmail"
                        required={true}
                        value={packageBuyInfo.clientEmail}
                        handleChange={handleChange}
                        validationError={validationError}
                        validationErrorMessage="email can't be blank"
                    />
                    {
                        (clientInputFieldBeingUpdated === "clientEmail" && isVisible && clientList.length > 0)
                        ?
                        <ClientList 
                            elementRef={elementRef}
                            clientList={clientList}
                            handleClick={handleClick}
                        />
                        :
                        null
                    }
                </div>
                <div className={styles.client_input_container}>
                    <InputField 
                        labelText="Phone"
                        name="clientPhone"
                        required={true}
                        value={packageBuyInfo.clientPhone}
                        handleChange={handleChange}
                        validationError={validationError}
                        validationErrorMessage="phone can't be blank"
                    />
                    {
                        (clientInputFieldBeingUpdated === "clientPhone" && isVisible && clientList.length > 0)
                        ?
                        <ClientList 
                            elementRef={elementRef}
                            clientList={clientList}
                            handleClick={handleClick}
                        />
                        :
                        null
                    }
                </div>
                <div className={styles.dropdown_container}>
                    <p>Payment Type</p>
                    <DropdownList 
                        customClassName="payment_type_dropdown_list"
                        data={[
                            { _id: 1, title: "single" },
                            { _id: 2, title: "installment" }
                        ]}
                        nameKey="title"
                        selectedValue={paymentType}
                        clickHandler={handleDropdownChange}
                    />
                </div>
                {
                    paymentType === "single"
                    ?
                    <InputField 
                        labelText="Payment Method"
                        name="paymentMethod"
                        required={true}
                        value={packageBuyInfo.paymentMethod}
                        handleChange={handleChange}
                        validationError={validationError}
                        validationErrorMessage="payment method can't be blank"
                    />
                    :
                    null
                }
                {
                    paymentType === "installment"
                    ?
                    <>
                        <p className={styles.installment_instruction}>
                            * Please enter payment method to mark an installment as paid
                        </p>
                        <div className={styles.add_installment_container}>
                            <div className={styles.add_installment}>
                                <div className={styles.select_date}>
                                    <label>Date <span>*</span></label>
                                    <DatePicker 
                                        selected={installment.date}
                                        onChange={handleDateChange} 
                                    />
                                </div>
                                <InputField 
                                    labelText="Amount"
                                    name="amount"
                                    required={true}
                                    value={installment.amount}
                                    handleChange={handleChange}
                                />
                                <InputField 
                                    labelText="Payment Method"
                                    name="method"
                                    value={installment.method}
                                    handleChange={handleChange}
                                />
                                <div className={styles.add_installment_button}>
                                    <button onClick={addInstallment}>
                                        Add
                                    </button>
                                </div>
                            </div>
                            {
                                installmentList.length > 0
                                ?
                                <InstallmentTable 
                                    installmentList={installmentList}
                                    removeInstallment={removeInstallment}
                                />:
                                null
                            }
                        </div>
                    </>
                    :
                    null
                }
            </div>
            <div className={styles.save_btn_wrapper}>
                <Button 
                    customClassName="save_btn"
                    buttonText="Buy"
                    handleClick={purchasePackage}
                />
            </div>
        </div>
    );

}

export default BuyPackage;