import { useSelector } from "react-redux";
import { IGiftCard } from "../../types/models/GiftCard";
import { RootState } from "../../store";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./index.module.css";
import GiftCardItemInfo from "./GiftCardItemInfo";

const GiftCardDetails: React.FC = () => {

    const giftCardState = useSelector((state: RootState) => state.giftCardState);
    const giftCardInAction = giftCardState.giftCardInAction as IGiftCard;
    const { code, buyDate, amount, usedAmount, buyerInfo, receiverInfo } = giftCardInAction;

    const { day, month, year, hours, minutes } = formatDateTime(new Date(buyDate * 1000));

    const itemInfoList = [
        { title: "Buy Date", content: `${day} ${month}, ${year} @ ${hours}:${minutes}` },
        { title: "Code", content: code ?? "N/A" },
        { title: "Amount", content: `€${amount.toFixed(2)}` },
        { title: "Amount Available", content: `€${(amount - usedAmount).toFixed(2)}` },
        { title: "Buyer Name", content: buyerInfo.fullName },
        { title: "Buyer Email", content: buyerInfo.email },
        { title: "Buyer Phone", content: buyerInfo.phone },
        { title: "Buyer Address", content: buyerInfo.address ?? "N/A" },
    ];

    if(!receiverInfo) {
        itemInfoList.push({ title: "Receiver Info", content: "N/A" },)
    }

    if(receiverInfo) {
        const receiverInfoList = [
            { title: "Receiver Name", content: receiverInfo.fullName },
            { title: "Receiver Email", content: receiverInfo.email },
            { title: "Receiver Phone", content: receiverInfo.phone },
            { title: "Receiver Address", content: receiverInfo.address ?? "N/A" },
        ];
        itemInfoList.push.apply(itemInfoList, receiverInfoList);
    }

    return (
        <div className={styles.gift_card_details}>
            {itemInfoList.map(item => {
                return (
                    <GiftCardItemInfo 
                        key={item.title}
                        title={item.title}
                        content={item.content}
                    />
                );
            })}
        </div>
    );

}

export default GiftCardDetails;
