import { IPolicy } from "./types";
import styles from "./PolicyTable.module.css";
import ActionButtons from "../../components/common/buttons/ActionButtons";

interface RespondsivePolicyTableProps {
    policyList: IPolicy[]
    handleClick: (locationId: string, actionType: string) => void
}

const ResponsivePolicyTable: React.FC<RespondsivePolicyTableProps> = ({ policyList, handleClick }) => {
   
    return (
        <div className= {styles.responsive_location_table}>
            {policyList.map(policy => {
                
                return (
                    <div 
                        key={policy._id}
                        className={styles.responsive_location_item}
                    >
                        <div className={styles.responsive_title_actions}>
                            <p className={styles.responsive_location_title}>
                                {policy.item}
                            </p>
                            <ActionButtons
                                actionTypeList={["edit", "delete"]}
                                showButtonText={false} 
                                itemId={policy._id}
                                handleClick={handleClick}
                            />
                        </div>
                        <p className={styles.responsive_location_country}>
                            {policy.type}
                        </p>
                        
                    </div>
                );
            })}
        </div>
    );

}

export default ResponsivePolicyTable;