import { formatDateTime } from "../date-time/dateTimeHelpers"

export const getDefaultStartEndDateString = () => {
    
    const today = new Date();
    const tomorrow = new Date((today.getTime() + 86400000));

    const { monthInNumber, year } = formatDateTime(today);
    const firstDateString = `${year}-${monthInNumber}-01`
    const { dateString: tomorrowDateString } = formatDateTime(tomorrow);

    return {
        defaultStartDateString: firstDateString,
        defaultEndDateString: tomorrowDateString
    }
}