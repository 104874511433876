import { useSelector } from "react-redux";
import { RootState } from "../../store";
import useWindowWidth from "../../hooks/useWindowWidth";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import ResponsiveStylistInvoiceTable from "./ResponsiveStylistInvoiceTable";
import StylistInvoicePDFButton from "./StylistInvoicePDFButton";

const StylistInvoiceTable: React.FC = () => {

    const reportState = useSelector((state: RootState) => state.reportState);
    const { freelanceStylistInvoiceList } = reportState;
    const { windowWidth } = useWindowWidth()

    return (
        <>
            {
                windowWidth >= 1000
                ?
            
                <table>
                    <thead>
                        <tr>
                            <th>Number</th>
                            <th>Stylist</th>
                            <th>Period</th>
                            <th>Amount</th>
                            <th>Fee</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {freelanceStylistInvoiceList.map(stylistInvoice => {
                            const { 
                                _id,
                                createdAt,
                                invoiceNumber,
                                stylistItem,
                                invoiceAmount,
                                invoiceFeeAmount,
                                isPaid
                            } = stylistInvoice;

                            const { month, year } = formatDateTime(new Date(createdAt * 1000));

                            return (
                                <tr key = {_id}>
                                    <td>{invoiceNumber}</td>
                                    <td>{stylistItem.fullName}</td>
                                    <td>{`${month} ${year}`}</td>
                                    <td>€{invoiceAmount.toFixed(2)}</td>
                                    <td>€{invoiceFeeAmount.toFixed(2)}</td>
                                    <td>{isPaid ? "Paid" : "Not Paid"}</td>
                                    <td>
                                        <StylistInvoicePDFButton 
                                            stylistInvoice={stylistInvoice}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                :
            <ResponsiveStylistInvoiceTable />
            }
        </>
    );

}

export default StylistInvoiceTable;
