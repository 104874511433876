import { IUser } from "../../types/models/User";
import { userAccessTypeGroupNames, userAccessTypeValues } from "../../data/users";

export const defaultAccessItems = [
    { 
        groupName: userAccessTypeGroupNames.agendas, 
        checkboxValueName: "Maintain Own Agendas", 
        keyName: "canMaintainOwnAgendas", 
        keyStringValue: userAccessTypeValues.maintainOwnAgendas,
        keyValue: false 
    },
    { 
        groupName: userAccessTypeGroupNames.agendas, 
        checkboxValueName: "View All Agendas", 
        keyName: "canViewAllAgendas", 
        keyStringValue: userAccessTypeValues.viewAllAgendas,
        keyValue: false 
    },
    { 
        groupName: userAccessTypeGroupNames.agendas, 
        checkboxValueName: "Maintain All Agendas", 
        keyName: "canMaintainAllAgendas", 
        keyStringValue: userAccessTypeValues.maintainAllAgendas,
        keyValue: false 
    },
    { 
        groupName: userAccessTypeGroupNames.features, 
        checkboxValueName: "Maintain Reports", 
        keyName: "canMaintainReports", 
        keyStringValue: userAccessTypeValues.maintainReports,
        keyValue: false 
    },
    { 
        groupName: userAccessTypeGroupNames.features, 
        checkboxValueName: "Maintain Treatments", 
        keyName: "canMaintainTreatments", 
        keyStringValue: userAccessTypeValues.maintainTreatments,
        keyValue: false 
    },
    { 
        groupName: userAccessTypeGroupNames.features, 
        checkboxValueName: "Maintain Discounts", 
        keyName: "canMaintainDiscounts", 
        keyStringValue: userAccessTypeValues.maintainDiscounts,
        keyValue: false 
    },
    { 
        groupName: userAccessTypeGroupNames.features, 
        checkboxValueName: "Maintain Coupons", 
        keyName: "canMaintainCoupons", 
        keyStringValue: userAccessTypeValues.maintainCoupons,
        keyValue: false 
    },
    { 
        groupName: userAccessTypeGroupNames.features, 
        checkboxValueName: "Maintain Packages", 
        keyName: "canMaintainPackages", 
        keyStringValue: userAccessTypeValues.maintainPackages,
        keyValue: false 
    },
    { 
        groupName: userAccessTypeGroupNames.features, 
        checkboxValueName: "Maintain Emails", 
        keyName: "canMaintainEmails", 
        keyStringValue: userAccessTypeValues.maintainEmails,
        keyValue: false 
    },
    { 
        groupName: userAccessTypeGroupNames.features, 
        checkboxValueName: "Maintain Intake Forms", 
        keyName: "canMaintainIntakeForms", 
        keyStringValue: userAccessTypeValues.maintainIntakeForms,
        keyValue: false 
    },
    { 
        groupName: userAccessTypeGroupNames.features, 
        checkboxValueName: "Maintain Gift Cards", 
        keyName: "canMaintainGiftCards", 
        keyStringValue: userAccessTypeValues.maintainGiftCards,
        keyValue: false 
    },
    { 
        groupName: userAccessTypeGroupNames.features, 
        checkboxValueName: "Maintain Waiting List", 
        keyName: "canMaintainWaitingList", 
        keyStringValue: userAccessTypeValues.maintainWaitingList,
        keyValue: false 
    },
    { 
        groupName: userAccessTypeGroupNames.features, 
        checkboxValueName: "Maintain Products", 
        keyName: "canMaintainProducts", 
        keyStringValue: userAccessTypeValues.maintainProducts,
        keyValue: false 
    },
    { 
        groupName: userAccessTypeGroupNames.clients, 
        checkboxValueName: "View Own Client Details", 
        keyName: "canViewOwnClientDetails", 
        keyStringValue: userAccessTypeValues.viewOwnClientDetails,
        keyValue: false 
    },
    { 
        groupName: userAccessTypeGroupNames.clients, 
        checkboxValueName: "Maintain Own Client Details", 
        keyName: "canMaintainOwnClientDetails", 
        keyStringValue: userAccessTypeValues.maintainOwnClientDetails,
        keyValue: false 
    },
    { 
        groupName: userAccessTypeGroupNames.clients, 
        checkboxValueName: "Maintain Others' Client Details", 
        keyName: "canMaintainOthersClientDetails", 
        keyStringValue: userAccessTypeValues.maintainAllClientDetails,
        keyValue: false 
    }
];

export const getUserAccessItems = (user?: IUser) => {

    // This function is being called when a user is being created or edited. When being edited user object
    // will be passed as parameter. If not passed then user is being added and we need to return defaultAccessItems
    if(!user) return defaultAccessItems;

    // User is being updated so we need to return the modified version of it. 
    const updatedAccessItems = defaultAccessItems.map(item => {
        const hasAccess = user.accessTypeList.some(accessType => item.keyStringValue === accessType);
        return { ...item, keyValue: hasAccess }
    });

    return updatedAccessItems;

}