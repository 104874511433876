export const validateAppointmentTime = (appointmentTime: string) => {

    const workTimes: string[] = [];
    let isValid = true;

    for(let x = 0; x < 24; x++) {

        let hour: string | number = x;
        if(hour < 10) hour = "0" + hour;
    
        for(let y = 0; y < 12; y++) {
    
            let intervalMinute: string | number = 5 * y; 
            if(intervalMinute < 10) intervalMinute = "0" + intervalMinute;

            workTimes.push(`${hour}:${intervalMinute}`);
        
        }
    
    }
    
    if(!workTimes.includes(appointmentTime)) isValid = false;

    return isValid;

}