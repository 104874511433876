import axios from "axios";
import Cookies from "universal-cookie";
import { FetchLocationDataQueryParams } from "../../../types/pages/Location";
import { config } from "../../../Constants";

export const fetchLocationData = async(payload: FetchLocationDataQueryParams) => {

    const { tenantId, searchText, skip, limit } = payload;

    const cookies = new Cookies();

    const params = `tenantId=${tenantId}&searchText=${searchText}&skip=${skip}&limit=${limit}`;
    const endpoint = `${config.url.BACKEND_API_URL}/locations?${params}`;
    const axiosConfig = {
        headers: {
            "auth-token": cookies.get("auth_token")
        }
    }
  
    const response = await axios.get(endpoint, axiosConfig);
    return response.data;

}