import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { toggleDeletePrompt, updateState } from "../../store/wait-list/waitListReducer";
import { FETCH_WAIT_LIST_DATA } from "../../store/wait-list/constants";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import DeletePrompt from "../../components/common/prompts/DeletePrompt";


const DeleteWaitList = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const waitListState = useSelector((state: RootState) => state.waitListState);
    const { waitListItemInAction } = waitListState;

    const closeDeletePrompt = () => {
        dispatch(toggleDeletePrompt(null))
    }

    const deleteWaitListItem = async() => {

        if(!waitListItemInAction) return alert("Please select a wait list item");

        dispatch(toggleDeletePrompt(null));
        dispatch(updateState({
            name: "actionMessage",
            value: "Deleting..."
        }));

        const endpoint = config.url.BACKEND_API_URL + "/wait-list/delete/" + waitListItemInAction._id;
        const headers = { "auth-token": cookies.get("auth_token") };

        try {

            const response = await axios.delete(endpoint, { headers });

            dispatch(updateState({
                name: "actionMessage",
                value: response.data
            }));

            dispatch({ type: FETCH_WAIT_LIST_DATA });

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(updateState({
                name: "actionMessage",
                value: message
            }));
        }

    }
  
    return (
        <>
            <DeletePrompt 
                warningText={<p>Are you sure?</p>}
                closeDeletePrompt={closeDeletePrompt}
                deleteItem={deleteWaitListItem}
            />
        </>
    );

}


export default DeleteWaitList;