import useClickOutside from "../../hooks/useClickOutside";
import styles from "./DropdownList.module.css";
import ArrowDown from "../../components/icons/ArrowDown";


interface IProps {
    data: any [], 
    nameKey: string, 
    selectedValue: string | number, 
    clickHandler: (event: React.MouseEvent<HTMLLIElement>, limit: number | string) => void,
}


const DropdownList: React.FC<IProps> = (props) => {

    const { data, nameKey, selectedValue, clickHandler } = props;

    const { elementRef, isVisible, setIsVisible } = useClickOutside({ exceptions: [] });
    const toggleDropdown = () => setIsVisible(!isVisible);

    return (
        <div className = {styles.dropdown_list} >
            <div 
                className = {styles.hidden_div}
                ref = {elementRef}
                onClick = {toggleDropdown}
            >
            </div>
            {/* <HiddenDiv elementRef = {elementRef} text = "" clickHandler = {toggleDropdown} /> */}
            <p>Show {selectedValue}</p>
            <ArrowDown />
            {/* <Icon iconClassName = "fas fa-chevron-down" style = {iconStyle} /> */}
            <div 
                className={styles.dropdown_item_list} 
                style={{ display: isVisible ? "block" : "none" }}
            >
                <ul>
                    {data.map(item => {
                        return (
                            <li 
                                key = {item._id} 
                                onClick = {event => clickHandler(event, item[nameKey])}
                            >
                                {item[nameKey]}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );

}


export default DropdownList;