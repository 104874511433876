import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { UserAuth } from "../../auth/UserAuth";
import { FETCH_FOLLOW_UP_EMAIL_DATA } from "../../store/emails/constants";
import { 
    updateState, 
    closeAddEditFollowUpEmailModal,
    toggleFollowUpEmailDeletePrompt 
} from "../../store/emails/emailReducer";
import styles from "./index.module.css";
import ActionMessage from "../../components/common/messages/ActionMessage";
import Modal from "../../components/common/modal/Modal";
import DataTable from "./DataTable";
import AddEditFollowUpEmail from "./AddEditFollowUpEmail";
import DeleteFollowUpEmail from "./DeleteFollowUpEmail";

const Emails: React.FC = () => {

    const navigate = useNavigate();
   
    const dispatch = useDispatch();
    const emailState = useSelector((state: RootState) => state.emailState);
    const userState = useSelector((state: RootState) => state.userState);
    const { 
        isAdding,
        isEditing,
        actionMessage,
        searchText,
        isDeleting,
        followUpEmailItemInAction
    } = emailState;
    const { user } = userState;

    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // User must be on trial period or must have bought a sub plan to access the products
        if(!user.stripeSubscription && !user.isOnTrialPeriod) {
            navigate("/");
            return;
        }

        const delayAPICall = setTimeout(() => {
            dispatch({ type: FETCH_FOLLOW_UP_EMAIL_DATA })
        }, 500);

        return () => clearTimeout(delayAPICall);

    }, [user, searchText, dispatch, navigate])

    const closeModal = () => {
      
        if(isAdding || isEditing) {
            dispatch(closeAddEditFollowUpEmailModal());
            return;
        }

        if(actionMessage) {
            dispatch(updateState({
                name: "actionMessage",
                value: null
            }));
            return;
        }

        dispatch(toggleFollowUpEmailDeletePrompt(null));
        
    }

    return (
        <div className={styles.emails_page}>
            {
                actionMessage
                &&
                <ActionMessage 
                    actionMessage={actionMessage}
                    hideActionMessage={closeModal}
                />
            }
            <DataTable />
            {
                (isAdding || isEditing)
                ?
                <Modal 
                    modalContent={<AddEditFollowUpEmail />}
                    onCloseModal={closeModal}
                />
                :
                null
            }
            {(isDeleting && followUpEmailItemInAction) ? <DeleteFollowUpEmail /> : null}
        </div>
    );
}

export default Emails;