import { ITenantInvoice } from "../../types/models/TenantInvoice";
import { formatDateTime } from "../date-time/dateTimeHelpers";

export const getInvoiceCSVContent = (tenantInvoiceList: ITenantInvoice[]) => {
 
    const headers = "Number, Period, Amount, Fee, Status";
   
    const data = tenantInvoiceList.map(item => {

        const { createdAt, invoiceNumber, invoiceAmount, invoiceFeeAmount, isPaid } = item;

        const { month, year } = formatDateTime(new Date(createdAt * 1000));
        const period = `${month} ${year}`;
        const status = isPaid ? "Paid" : "Not Paid";

        return `${invoiceNumber}, ${period}, ${invoiceAmount.toFixed(2)}, ${invoiceFeeAmount.toFixed(2)}, ${status}`;

    });

    return `${headers}\n${data.join("\n")}`;

}