import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { config } from "../../Constants";
import { RootState } from "../../store";
import { ITreatment } from "../../types/models/Treatment";
import { IUser } from "../../types/models/User";
import { FETCH_APPOINTMENT_DATA } from "../../store/calendar/constants";
import { updateState, closeChangeTreatmentModal } from "../../store/calendar/calendarReducer";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./ChangeTreatment.module.css";
import Dropdown from "../../components/common/dropdown/Dropdown";
import InputField from "../../components/common/input-fields/InputField";

const ChangeTreatment: React.FC = () => {
    
    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { treatmentList, changeTreatmentInfo } = calendarState;

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [activityBy, setActivityBy] = useState<string>();
    const [selectedTreatment, setSelectedTreatment] = useState<ITreatment | null>(null);
    const [selectedStylist, setSelectedStylist] = useState<IUser | null>(null);

    const handleDropdownClick = (args: any) => {

        const { item, dropdownListFor } = args;

        switch(dropdownListFor) {

            case "treatment":

                setSelectedTreatment(item);

                // User might be selecting a different treatment after selecting one. So stylist must be
                // null because different treatment has different stylists
                setSelectedStylist(null); 

                dispatch(updateState({
                    name: "changeTreatmentInfo",
                    value: {
                        ...changeTreatmentInfo,
                        treatmentId: item._id
                    }
                }));

                break;


            case "stylist":

                setSelectedStylist(item);

                dispatch(updateState({
                    name: "changeTreatmentInfo",
                    value: {
                        ...changeTreatmentInfo,
                        stylistId: item._id
                    }
                }));

                break;

            default:
                break;

        }

    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = event.target;

        if(name === "activityBy") {
            setActivityBy(value);
            return;
        }

        dispatch(updateState({
            name: "changeTreatmentInfo",
            value: {
                ...changeTreatmentInfo,
                paymentMethod: event.target.value
            }
        }));

    }

    const saveAppointment = async () => {

        const endpoint = config.url.BACKEND_API_URL + "/appointments/change-treatment/" + changeTreatmentInfo.appointmentId;
        const requestBody = {
            treatmentId: changeTreatmentInfo.treatmentId,
            stylistId: changeTreatmentInfo.stylistId,
            paymentMethod: changeTreatmentInfo.paymentMethod,
            activityBy
        };
        const axiosConfig = getAuthTokenConfig();
        setLoading(true);

        try {

            const response = await axios.put(endpoint, requestBody, axiosConfig);
            const { appointment } = response.data;
            setLoading(false);
            dispatch(closeChangeTreatmentModal());
            dispatch(updateState({
                name: "appointmentInAction",
                value: appointment
            }));
            dispatch({ type: FETCH_APPOINTMENT_DATA })

        } catch(error) {
            const { message } = handleApiError(error);
            setLoading(false);
            setErrorMsg(message);
        }

    }

    const filteredTreatmentList = treatmentList.filter(treatment => {
        return treatment._id !== changeTreatmentInfo.appointmentTreatmentId
    });

    const disabled = (!selectedTreatment || !selectedStylist || !activityBy || loading) ? true : false;

    return (
        <div className={styles.change_treatment}>
            <div className={styles.select_treatment}>
                <Dropdown 
                    dropdownLabel="Select Treatment"
                    dropdownListFor="treatment"
                    data={filteredTreatmentList} 
                    nameKey="title" 
                    selectedValue={selectedTreatment ? selectedTreatment.title : ""} 
                    clickHandler={handleDropdownClick}
                />
            </div>
            <div className={styles.select_stylist}>
                <Dropdown 
                    dropdownLabel="Select Stylist"
                    dropdownListFor="stylist"
                    data={selectedTreatment?.stylists ?? []} 
                    nameKey="fullName" 
                    selectedValue={selectedStylist?.fullName ?? ""} 
                    clickHandler={handleDropdownClick}
                />
            </div>
            <InputField 
                customClassName="no_margin_bottom"
                labelText="Payment Method"
                name="paymentMethod" 
                value={changeTreatmentInfo.paymentMethod} 
                handleChange={handleChange} 
            />
            <InputField 
                customClassName="no_margin_bottom"
                labelText="Who is changing treatment"
                name="activityBy" 
                value={activityBy ?? ""} 
                handleChange={handleChange} 
            />
            {
                errorMsg
                ?
                <p className={styles.error_msg}>{errorMsg}</p>
                :
                null
            }
            <div className={styles.save_button}>
                <button
                    disabled={disabled}
                    onClick={saveAppointment}
                >
                    {loading ? "Changing..." : "Change Treatment"}
                </button>
            </div>
        </div>
    );

}

export default ChangeTreatment;