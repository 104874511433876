import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { updateState } from "../../../store/calendar/calendarReducer";
import { formatDateTime } from "../../../helpers/date-time/dateTimeHelpers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./AppointmentModal.module.css";

const SelectDate: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { selectedDateForCustomRescheduling } = calendarState;

    const handleChange = (date: Date) => {

        // We are taking a detour because react-datepicker selected date causing issues.
        // User selects June 2 but somehow it becomes June 1. 
        const { dateString } = formatDateTime(date);
        const { hours, minutes } = formatDateTime(new Date());

        const selectedDate = new Date(`${dateString}T${hours}:${minutes}`);
      
        dispatch(updateState({
            name: "selectedDateForCustomRescheduling",
            value: selectedDate
        }))
    } 

    return (   
        <div className = {styles.select_date}>
            <label>Custom Appointment Date</label>
            <div className = {styles.select_date_input}>
                <DatePicker 
                    selected = {selectedDateForCustomRescheduling}
                    onChange = {handleChange} 
                />
            </div>
        </div>
    );

}

export default SelectDate;