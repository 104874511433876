const PaymentIcon = (): JSX.Element => {

    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.332 10H2.33203M11.332 14H6.33203M2.33203 8.2L2.33203 15.8C2.33203 16.9201 2.33203 17.4802 2.55002 17.908C2.74176 18.2843 3.04773 18.5903 3.42405 18.782C3.85187 19 4.41193 19 5.53203 19L19.132 19C20.2521 19 20.8122 19 21.24 18.782C21.6163 18.5903 21.9223 18.2843 22.114 17.908C22.332 17.4802 22.332 16.9201 22.332 15.8V8.2C22.332 7.0799 22.332 6.51984 22.114 6.09202C21.9223 5.7157 21.6163 5.40974 21.24 5.21799C20.8122 5 20.2521 5 19.132 5L5.53203 5C4.41193 5 3.85187 5 3.42405 5.21799C3.04773 5.40973 2.74177 5.71569 2.55002 6.09202C2.33203 6.51984 2.33203 7.07989 2.33203 8.2Z" stroke="#67748E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );

}

export default PaymentIcon;
