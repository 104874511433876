import { useSelector, useDispatch } from "react-redux";
import { ICoupon } from "../../types/models/Coupon";
import { RootState } from "../../store";
import { handleChange, openEditCouponModal, toggleDeletePrompt } from "../../store/coupons/couponReducer";
import { FETCH_COUPON_DATA } from "../../store/coupons/constants";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import useWindowWidth from "../../hooks/useWindowWidth";
import styles from "./CouponTable.module.css";
import DropdownList from "./DropdownList";
import ActionButtons from "./ActionButtons";
import ResponsiveCouponTable from "./ResponsiveCouponTable";

const CouponTable = () => {

    const dispatch = useDispatch();
    const couponState = useSelector((state: RootState) => state.couponState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();
    const { couponList, searchText, skip, limit, totalCouponCount } = couponState;

    const handleClick = (args: { actionType: string, coupon: ICoupon}) => {

        const { actionType, coupon } = args;

        if(actionType === "Delete") {
            dispatch(toggleDeletePrompt(coupon));
            return;
        }

        dispatch(openEditCouponModal(coupon))

    }

    const handleDropdownChange = (viewItem: { _id: number, limit: number | string }) => {

        if(totalCouponCount <= 5) return;

        dispatch(handleChange({
            name: "limit",
            value: viewItem.limit
        }))

        dispatch({ 
            type: FETCH_COUPON_DATA, 
            payload: { 
                searchText, 
                skip, 
                limit: viewItem.limit,
                fetchTreatmentData: false 
            } 
        });

    }

  
    return (
        <div className = {styles.coupon_table}> 
            <div className = {styles.table_header}>
                <h2>Coupons</h2>
                <DropdownList 
                    customClassName="view_dropdown_list"
                    dropdownListFor="view_item"
                    data = {[
                        { _id: 1, limit: 5 },
                        { _id: 2, limit: 10 },
                        { _id: 3, limit: "All" },
                    ]}
                    nameKey="limit"
                    selectedValue = {limit}
                    clickHandler={handleDropdownChange}
                />
            </div>
            {
                isLargeDevice
                ?
                <table>
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Use Limit</th>
                            <th>Use Count</th>
                            <th>Expiry Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {couponList.map(coupon => {

                            const { _id, code, maximumUseLimit, usageCount, expiresOn } = coupon;

                            const { day, month, year } = formatDateTime(new Date(expiresOn * 1000))

                            return (
                                <tr key = {_id}>
                                    <td>{code}</td>
                                    <td>
                                        <span className={styles.maximum_use_limit}>{maximumUseLimit} times</span>
                                    </td>
                                    <td>{usageCount} times</td>
                                    <td>{day} {month}, {year}</td>
                                    <td>
                                        <ActionButtons 
                                            coupon={coupon}
                                            showButtonText = {true}
                                            handleClick={handleClick}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                :
                <ResponsiveCouponTable 
                    handleClick={handleClick}
                />
            }
        </div>
    );

}

export default CouponTable;