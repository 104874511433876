import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { updateState } from "../../../store/profile-tenant/tenantProfileReducer";
import useWindowWidth from "../../../hooks/useWindowWidth";
import styles from "./UpdatePaymentMethod.module.css";
import CardImage from "../stripe-elements/CardImage";
import LoadingError from "../../../components/common/loading-error/LoadingError";
import SelectOptions from "../stripe-elements/SelectOptions";
import CreditCard from "../stripe-elements/CreditCard";
import SepaDebit from "../stripe-elements/SepaDebit";
import ButtonDiv from "./ButtonDiv";
import { Modal, ModalBackground, ModalContent } from "../../../components/common/Modal";


const UpdatePaymentMethod: React.FC = () => {

    const dispatch = useDispatch();
    const tenantProfileState = useSelector((state: RootState) => state.tenantProfileState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth()
    const { selectedPaymentMethod } = tenantProfileState;

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [validationErrorMsg, setValidationErrorMsg] = useState<string>();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const closeModal = () => {
        setIsSuccess(false);
        setErrorMsg("");
        dispatch(updateState({
            name: "isUpdatingPaymentMethod",
            value: false
        }));
    }

    return (
        <Modal>
            <ModalBackground />
            <ModalContent 
                width={isLargeDevice ? "50%" : "95%"} 
                height={(errorMsg || isSuccess) ? "20%" : "95%"}
            >
                {
                    errorMsg
                    ?
                    <LoadingError 
                        message={errorMsg}
                        withCloseButton={true}
                        onClick={closeModal}
                    />
                    :
                    isSuccess
                    ?
                    <LoadingError 
                        message={"Payment method has been updated successfully"}
                        withCloseButton={true}
                        onClick={closeModal}
                    />
                    :
                    <>
                        <CardImage />
                        <SelectOptions />
                        {selectedPaymentMethod === "Credit Card" && <CreditCard />}
                        {selectedPaymentMethod === "Sepa Debit" && <SepaDebit />}
                        {
                            validationErrorMsg
                            ?
                            <p className={styles.val_err_msg}>{validationErrorMsg}</p>
                            :
                            null
                        }
                        <ButtonDiv
                            loading={loading} 
                            setLoading={setLoading}
                            setValidationErrorMsg={setValidationErrorMsg}
                            setErrorMsg={setErrorMsg}
                            setIsSuccess={setIsSuccess}
                        />
                    </>
                }
            </ModalContent>
        </Modal>
    );

}


export default UpdatePaymentMethod;