import { useSelector } from "react-redux";
import { ICoupon } from "../../types/models/Coupon";
import { RootState } from "../../store";
import styles from "./CouponTable.module.css";
import ActionButtons from "./ActionButtons";

interface IProps {
    handleClick: (args: { actionType: string, coupon: ICoupon}) => void
}

const ResponsiveCouponTable: React.FC<IProps> = ({ handleClick }) => {

    const couponState = useSelector((state: RootState) => state.couponState);
    const { couponList } = couponState;
   
    return (
        <div className = {styles.responsive_coupon_table}>
            {couponList.map(coupon => {
                const { _id, code, maximumUseLimit, usageCount } = coupon;
                
                return (
                    <div 
                        key = {_id}
                        className = {styles.responsive_coupon_item}
                    >
                        <div className={styles.responsive_code_actions}>
                            <p className = {styles.responsive_coupon_code}>
                                {code}
                            </p>
                            <ActionButtons 
                                showButtonText = {false}
                                coupon={coupon}
                                handleClick={handleClick}
                            />
                        </div>
                        <p className = {styles.responsive_coupon_limit}>
                            Maximum Use Limit: {maximumUseLimit} times
                        </p>
                        <p>Total used so far: {usageCount} times</p>
                    </div>
                );
            })}
        </div>
    );

}

export default ResponsiveCouponTable;