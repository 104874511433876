import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { FETCH_PRODUCT_DATA } from "../../store/products/constants";
import { updateState, addNewProductInfo, closeAddEditProductModal } from "../../store/products/productReducer";
import { uploadFile, UploadFileReturnType } from "../../helpers/file-handlers/uploadFile";
import { removeFile } from "../../helpers/file-handlers/removeFile";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./AddEditProduct.module.css";
import InputField from "../../components/common/input-fields/InputField";
import TextAreaInputField from "../../components/common/input-fields/TextAreaInputField";
import FileInputField from "../../components/common/input-fields/FileInputField";
import SelectCategory from "./SelectCategory";
import SelectBrand from "./SelectBrand";
import ValidationErrorMessage from "../../components/common/messages/ValidationErrorMessage";
import SaveButton from "./SaveButton";

const AddEditProduct: React.FC = () => {

    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userState);
    const productState = useSelector((state: RootState) => state.productState);
    const {
        productPhoto, 
        isAdding,
        isEditing,
        productInAction,
        newProductInfo,
        validationError, 
    } = productState;

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    const uploadProductPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {

        if(!event.target.files) return;

        const file = event.target.files[0];

        dispatch(updateState({
            name: "productPhoto",
            value: file
        }));

    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {

        const { name, value } = event.target;

        if(name === "category") {

            dispatch(updateState({
                name: "doNotShowProductCategoryList",
                value: false
            }));

            dispatch(addNewProductInfo({
                name,
                value: { _id: null, name: value }
            }));

            if(!value) {
                dispatch(updateState({
                    name: "productCategoryList",
                    value: []
                }));
            }

            return;

        }

        if(name === "brand") {

            dispatch(updateState({
                name: "doNotShowProductBrandList",
                value: false
            }));


            dispatch(addNewProductInfo({
                name,
                value: { _id: null, name: value }
            }));

            if(!value) {
                dispatch(updateState({
                    name: "productBrandList",
                    value: []
                }));
            }

            return;

        }

        dispatch(addNewProductInfo({ name, value }));

    }

    const saveProduct = async(event: React.MouseEvent<HTMLButtonElement> | SubmitEvent) => {
        
        event.preventDefault();

        if(errorMsg) setErrorMsg(undefined);

        const { 
            name, 
            description, 
            eanCode, 
            category, 
            brand,
            buyingPrice,
            sellingPrice,
            discountedPrice,
            tags,
            stockAvailable,
            minStock,
            maxStock
        } = newProductInfo;

        if(!name || !sellingPrice || !minStock || !maxStock || !brand.name) {
            dispatch(updateState({
                name: "validationError",
                value: true
            }));
            return;
        }

        setLoading(true);

        // If user has choosen a product photo, upload it
        let fileInfo: UploadFileReturnType = { 
            imageUrl: null, 
            imageFilename: null 
        };

        // If user is editing product info and changing the product photo then old photo must be deleted
        if(isEditing && productInAction && productPhoto && productInAction.imageList[0]?.imageFilename) {

            const args = { 
                url: "/products/photo/delete",
                filePath: `products/${userState.user?.tenantId}`,
                filename: productInAction.imageList[0].imageFilename
            }

            const { success } = await removeFile(args);

            if(!success) {
                setErrorMsg("Something went wrong while removing old photo");
                setLoading(false);
                return;
            }
            
        }

        if(productPhoto) {

            const args = { 
                url: "/products/photo/upload",
                filePath: `products/${userState.user?.tenantId}`,
                file: productPhoto
            }

            fileInfo = await uploadFile(args);

            if(!fileInfo.imageUrl) {
                setErrorMsg("Something went wrong while uploading photo");
                setLoading(false);
                return;
            }

        }

        // If user hasn't put anything for category then both _id and name should be null
        // Because this is how backend processes it
        const newCategory = category.name === "" ? { _id: null, name: null } : category;

        const requestBody = {
            productId: productInAction?._id,
            ...fileInfo,
            name,
            description: description === "" ? null : description,
            eanCode: eanCode === "" ? null : eanCode,
            category: newCategory,
            brand,
            buyingPrice: buyingPrice ? Number(buyingPrice) : null,
            sellingPrice: Number(sellingPrice),
            discountedPrice: discountedPrice ? Number(discountedPrice) : Number(sellingPrice),
            tags: tags ? [tags] : [],
            stockAvailable: stockAvailable ? Number(stockAvailable) : 0,
            minStock: Number(minStock),
            maxStock: Number(maxStock)
        };

        let url = isAdding ? "/create" : "/edit"
        const endpoint = config.url.BACKEND_API_URL + "/products" + url;
        const authConfig = getAuthTokenConfig();

        try {

            const response = await axios.post(endpoint, requestBody, authConfig);
            setLoading(false);
            dispatch(closeAddEditProductModal());
            dispatch(updateState({
                name: "actionMessage",
                value: response.data.message
            }));

            dispatch({ type: FETCH_PRODUCT_DATA })
          
        } catch(error) {
            const { message } = handleApiError(error);
            setErrorMsg(message);
            setLoading(false);
        }

    }


    return (
        <div className={styles.add_edit_product}>
            <h2>{isAdding ? "Create New Product" : "Edit Product"}</h2>
            <div className={styles.file_input_container}>
                <FileInputField 
                    file={productPhoto}
                    imageUrl={productInAction?.imageList[0]?.imageUrl ?? undefined}
                    text="Upload Photo"
                    forPerson={false}
                    handleFile={uploadProductPhoto}
                />
            </div>
            <form>
                <InputField 
                    labelText="Name"
                    name="name"
                    value={newProductInfo.name}
                    required={true}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="name can't be blank"
                />
                <TextAreaInputField 
                    labelText="Description"
                    rows={4}
                    columns={3}
                    name="description"
                    value={newProductInfo.description}
                    handleChange={handleChange}
                />
                <SelectCategory 
                    handleChange={handleChange}
                />
                <InputField 
                    labelText="EAN Code"
                    name="eanCode"
                    value={newProductInfo.eanCode}
                    handleChange={handleChange}
                />
                <div className={styles.price_stock}>
                    <InputField 
                        labelText="Selling Price"
                        type="number"
                        name="sellingPrice"
                        value={newProductInfo.sellingPrice}
                        required={true}
                        handleChange={handleChange}
                        validationError={validationError}
                        validationErrorMessage="selling price can't be blank"
                    />
                    <InputField 
                        labelText="Buying Price"
                        type="number"
                        name="buyingPrice"
                        value={newProductInfo.buyingPrice}
                        handleChange={handleChange}
                    />
                    <InputField 
                        labelText="Discounted Price"
                        type="number"
                        name="discountedPrice"
                        value={newProductInfo.discountedPrice}
                        handleChange={handleChange}
                    />
                </div>
                <SelectBrand 
                    handleChange={handleChange}
                />
                <InputField 
                    labelText="Tags"
                    name="tags"
                    value={newProductInfo.tags}
                    handleChange={handleChange}
                />
                <div className={styles.price_stock}>
                    <InputField 
                        labelText="Available Stock"
                        type="number"
                        name="stockAvailable"
                        value={newProductInfo.stockAvailable}
                        handleChange={handleChange}
                    />
                    <InputField 
                        labelText="Min Stock"
                        type="number"
                        name="minStock"
                        value={newProductInfo.minStock}
                        required={true}
                        handleChange={handleChange}
                        validationError={validationError}
                        validationErrorMessage="min stock must be at least 1"
                    />
                    <InputField 
                        labelText="Max Stock"
                        type="number"
                        name="maxStock"
                        value={newProductInfo.maxStock}
                        required={true}
                        handleChange={handleChange}
                        validationError={validationError}
                        validationErrorMessage="max stock must be at least 2"
                    />
                </div>
                {errorMsg ? <ValidationErrorMessage message={errorMsg}/> : null}
                <SaveButton 
                    disabled={loading}
                    onClick={saveProduct}
                />
            </form>
        </div>
    );

}

export default AddEditProduct;