import { useSelector } from "react-redux";
import { IWaitList } from "../../types/models/WaitList";
import { RootState } from "../../store";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./WaitListTable.module.css";
import ActionButtons from "./ActionButtons";

interface ResponsiveWaitListTableProps {
    handleActionButtonClick: (actionType: string, waitListItem: IWaitList) => void
}

const ResponsiveWaitListTable: React.FC<ResponsiveWaitListTableProps> = ({ handleActionButtonClick }) => {

    const waitListState = useSelector((state: RootState) => state.waitListState);
    const { waitList } = waitListState;
  
    return (
        <div className = {styles.responsive_wait_list_table}>
            {waitList.map(waitListItem => {
                
                const { 
                    _id, 
                    clientEmail, 
                    targetAppointmentDate, 
                    selectedTreatmentStylistList  
                } = waitListItem;

                const treatmentStylistNames = selectedTreatmentStylistList.map(item => {
                    return `${item.treatment.title} - ${item.stylist.fullName}`
                });

                const { day, year, month } = formatDateTime(new Date(targetAppointmentDate * 1000))


                return (
                    <div 
                        key = {_id}
                        className = {styles.responsive_wait_list_item}
                    >
                        <div className={styles.responsive_title_actions}>
                            <p>{`${day} ${month}, ${year}`}</p>
                            <ActionButtons 
                                showButtonText={false}
                                waitListItem={waitListItem}
                                handleActionButtonClick={handleActionButtonClick}
                            />
                        </div>
                        <p className = {styles.responsive_client_email}>
                            {clientEmail}
                        </p>
                        <ul className={styles.treatment_stylist_list}>
                            {treatmentStylistNames.map(item => {
                                return (
                                    <li key={item}>{item}</li>
                                );
                            })}
                        </ul>
                    </div>
                );
            })}
        </div>
    );

}

export default ResponsiveWaitListTable;