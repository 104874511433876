import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./index.module.css";

interface SelectDateProps {
    date: Date | null
    dateType: string
    handleDateChange: (date: Date | null, dateType: string) => void 
}

const SelectDate: React.FC<SelectDateProps> = ({ date, dateType, handleDateChange }) => {
  
    return (
        <div className={styles.select_date}>
            <DatePicker 
                placeholderText={`Select ${dateType} Date`}
                selected={date}
                onChange={(selectedDate) => handleDateChange(selectedDate, dateType)} 
            />
        </div>
    );

}

export default SelectDate;