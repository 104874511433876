import { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import useWindowWidth from "../hooks/useWindowWidth";
import useUser from "../hooks/useUser";
import { getSideNavItems } from "../helpers/layouts/getSideNavItems";
import styles from "./Layouts.module.css";
import TopNav from "./TopNav";
import { MainLogo } from "../components/icons/MainLogo";
import LogoutButton from "./LogoutButton";
import MyProfileNavItem from "./MyProfileNavItem";
import NavItemList from "./NavItemList";
import NavItem from "./NavItem";
import Footer from "./Footer";

const UserLayout: React.FC = () => {

    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    useUser();
    const location = useLocation();
    const currentUrl = location.pathname + location.search;
    const { devicesWidth } = useWindowWidth();
    const { isLargeDevice, isSmartphone } = devicesWidth;

    const sideNavItems = useMemo(() => getSideNavItems(user), [user]);
    const foundNavItem = sideNavItems.find(item => item.url === currentUrl);

    let userType = "";
    if(user) userType = user.userType;

    let myProfileUrl = `/user/${user?.tenantId}/profile-tenant`;
    if(user && userType !== "Tenant") myProfileUrl = `/user/${user?.tenantId}/profile-user`;

    return (
        <>
            {
                isLargeDevice
                ?
                <div className = {styles.sidenav}>
                    <MainLogo /> 
                    <NavItemList 
                        currentUrl = {currentUrl}
                    />
                    <MyProfileNavItem 
                        currentUrl = {currentUrl}
                        myProfileUrl = {myProfileUrl}
                    />
                    <LogoutButton />
                </div>
                :
                null
            }
            <div className = {styles.App_Content}>
                {
                    !isLargeDevice
                    ?
                    <TopNav 
                        user={user}
                        currentUrl={currentUrl}
                        myProfileUrl={myProfileUrl}
                    />
                    :
                    null
                }
                
                {
                    (!isLargeDevice  && foundNavItem)
                    ?
                    <div className = {styles.responsive_nav_item}>
                        <NavItem 
                            isUrl = {false}
                            icon = {foundNavItem.icon}
                            linkHref = {foundNavItem.url}
                            linkText = {foundNavItem.text}
                        />
                    </div>
                    :
                    null
                }
                <Outlet />
            </div>
        </>
    );

}

export default UserLayout;