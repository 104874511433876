import { useSelector, useDispatch } from "react-redux";
import { IProduct } from "../../types/models/Product";
import { RootState } from "../../store";
import { PurchaseOrderProduct } from "../../types/pages/Product";
import { updateState, openEditProductModal, toggleProductDeletePrompt } from "../../store/products/productReducer";
import useWindowWidth from "../../hooks/useWindowWidth";
import styles from "./DataTable.module.css";
import TableMessageTR from "./TableMessageTR";
import ActionButtons from "../../components/common/buttons/ActionButtons";
import AddToPOButton from "./AddToPOButton";
import ResponsiveProductTable from "./ResponsiveProductTable";

const ProductTable = () => {

    const dispatch = useDispatch();
    const productState = useSelector((state: RootState) => state.productState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();

    const { loading, productList, purchaseOrderProductList } = productState;
    
    const handleClick = (productId: string, actionType: string) => {

        const product = productList.find(item => item._id === productId);
        if(!product) return;

        if(actionType === "edit") {
            dispatch(openEditProductModal(product));
            return;
        }

        if(actionType === "sell") {
            dispatch(updateState({
                name: "isSelling",
                value: true
            }));
            dispatch(updateState({
                name: "productInAction",
                value: product
            }));
            return;
        }

        dispatch(toggleProductDeletePrompt(product));
      
    }

    const addProductToPO = (product: IProduct) => {
        const isProductAddedAlready = purchaseOrderProductList.some(item => item.productId === product._id);
        if(isProductAddedAlready) {
            alert("Product has been added already");
            return;
        }
        
        const price = product.buyingPrice ?? 0;
        const quantity = product.maxStock - product.stockAvailable;

        const newPOProduct: PurchaseOrderProduct = {
            productId: product._id,
            name: product.name,
            quantity: quantity.toString(),
            price: price.toString(),
            totalPrice: (quantity * price)
        }

        dispatch(updateState({
            name: "purchaseOrderProductList",
            value: [...purchaseOrderProductList, newPOProduct]
        }))
    }
  
    return (
        <>
            {
                isLargeDevice
                ?
                <table>
                    <thead>
                        <tr className={styles.heading_tr}>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Available Stock</th>
                            <th>Minimum Stock</th>
                            <th>Action</th>
                            <th>Purchase Order</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading
                            ?
                            <TableMessageTR 
                                colSpan={6}
                                message="Loading..."
                            />
                            :
                            productList.length < 1
                            ?
                            <TableMessageTR 
                                colSpan={6}
                                message="No Products To Show"
                            />
                            :
                            productList.map(product => {

                                const { _id, name, stockAvailable, minStock, discountedPrice } = product;

                                return (
                                    <tr key={_id} className={styles.data_tr}>
                                        <td>{name}</td>
                                        <td>€{discountedPrice.toFixed(2)}</td>
                                        <td>{stockAvailable}</td>
                                        <td>{minStock}</td>
                                        <td>
                                            <ActionButtons 
                                                actionTypeList={["sell", "edit", "delete"]}
                                                itemId={_id}
                                                showButtonText={true}
                                                handleClick={handleClick}
                                            />
                                        </td>
                                        <td className={styles.plus_button_td}>
                                            <AddToPOButton 
                                                product={product}
                                                addProductToPO={addProductToPO}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                :
                <ResponsiveProductTable
                    handleClick={handleClick}
                    addProductToPO={addProductToPO}
                />
            }
        </>
    );

}

export default ProductTable;
