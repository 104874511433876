import { IAppointment } from "../../types/models/Appointment";
import { ITreatment } from "../../types/models/Treatment";
import { IUser } from "../../types/models/User";

type ArgsType = {
    appointment: IAppointment | null
    treatments: ITreatment[]
    stylists: IUser[]
}

export const checkStylistTreatmentExist = (args: ArgsType) => {

    const { appointment, treatments, stylists } = args;

    const foundTreatment = treatments.find(item => item._id === appointment?.treatmentItem?._id);
    const foundStylist = stylists.find(item => item._id === appointment?.stylistItem?._id);

    let message: null | string = null;

    if(!foundTreatment) message = "Treatment doesn't exist anymore";
    if(!foundStylist) message = "Stylist doesn't exist anymore";
       
    return { message };

}