type ParamReturnObj = { [key: string]: string };

export const trimUserInputObject = (inputObj: ParamReturnObj) => {

    let trimmedObj: ParamReturnObj = {}

    Object.entries(inputObj).forEach(([key, value]) => {

        if(key === "email") {
            trimmedObj[key] = value.trim().toLowerCase();
            return;
        }

        trimmedObj[key] = value.trim();

    });

    return trimmedObj;

}