import { IAppointment } from "../../types/models/Appointment";
import { formatDateTime } from "../date-time/dateTimeHelpers";

export const getOnlinePaymentCSVContent = (appointmentList: IAppointment[]) => {
  
    const headers = "Date, Time, Treatment, Price, Paid Amount, Refund Amount, Client Name, Payment Method";

    const onlinePaidAppointments = appointmentList.filter(appointment => {
        return appointment.isFullyPaidOnline || appointment.isPartiallyPaidOnline
    });

    const data = onlinePaidAppointments.map(appointment => {

        const { 
            treatmentTitle,
            treatmentPrice,
            clientName,
            isFullyPaidOnline, 
            priceAfterDiscount, 
            partiallyPaidAmount,
            partiallyPaidAmountPaymentMethod,
            paymentMethod,
            refundAmount 
        } = appointment;

        const { day, month, year, hours, minutes } = formatDateTime(new Date(appointment.appointment_date * 1000));
        const date = `${day} ${month} ${year}`;
        const time = `${hours}:${minutes}`;

        const price = `${treatmentPrice.toFixed(2)}`;

        const paidAmount = isFullyPaidOnline ? priceAfterDiscount : partiallyPaidAmount;
        const newPaymentMethod = isFullyPaidOnline ? paymentMethod : partiallyPaidAmountPaymentMethod;

        const newPaidAmount = `${paidAmount.toFixed(2)}`;
        const newRefundAmount = `${refundAmount.toFixed(2)}`

        return `${date}, ${time}, ${treatmentTitle}, ${price}, ${newPaidAmount}, ${newRefundAmount}, ${clientName}, ${newPaymentMethod}`;

    });

    return `${headers}\n${data.join("\n")}`;

}