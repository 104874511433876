import styles from "./ResponsiveItemDetails.module.css";

interface ItemContentProps {
    title: string
    content: string
    otherInfo?: string[]
}

const ItemContent: React.FC<ItemContentProps> = ({ title, content, otherInfo = [] }) => {
  
    return (
        <>
            <div className={styles.item_content}>
                <p>{title}</p>
                <p>{content}</p>
            </div>
            {otherInfo.map(item => {
                return (
                    <p 
                        key = {item}
                        className={styles.other_info}
                    >
                        {item}
                    </p>
                );
            })}
        </>
    );

}

export default ItemContent;