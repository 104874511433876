import { useState } from "react";
import axios from "axios";
import { config } from "../../../Constants";
import { decodeJWTToken } from "../../../helpers/others/decodeJWTToken";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";

const useCreateStripeAccountLink = () => {
    
    const [loading, setLoading] = useState<boolean>(false);

    const createStripeAccountLink = async(accountId?: string) => {

        setLoading(true);

        const endpoint = `${config.url.BACKEND_API_URL}/users/create-stripe-account-link`;
        const authConfig = getAuthTokenConfig();

        const user = decodeJWTToken("auth_token");
        if(!user || !user.hasOwnProperty("tenantId")) return;

        const { host, protocol } = window.location;
        
        const requestBody = {
            accountId,
            origin: `${protocol}//${host}/user/${user.tenantId}/integrations`
        }

        try {

            const response = await axios.post(endpoint, requestBody, authConfig);
            const accountLink = response.data.accountLink;
            window.location.replace(accountLink.url);
            
        } catch (error) {
            let { message } = handleApiError(error);
            alert(message);
            window.location.reload();
        } 

    }

    return { 
        loading, 
        createStripeAccountLink
    };

}

export default useCreateStripeAccountLink;