import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { config } from "../../Constants";
import { RootState } from "../../store";
import { FETCH_LOCATION_DATA } from "../../store/locations/constants";
import { 
    updateState,
    addNewLocationInfo, 
    closeAddEditLocationModal
} from "../../store/locations/locationReducer";
import { trimUserInputObject } from "../../helpers/others/trimUserInputObject";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./index.module.css";
import InputField from "../../components/common/input-fields/InputField";
import Button from "./Button";

const AddEditLocation = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const locationState = useSelector((state: RootState) => state.locationState);
    const userState = useSelector((state: RootState) => state.userState);
    const { 
        newLocationInfo, 
        validationError, 
        isAdding, 
        isEditing, 
        locationItemInAction,
        locationDataFetchInfo 
    } = locationState;
    const { title, address, city, country, phone, zipCode } = newLocationInfo;
    const { searchText, skip, limit } = locationDataFetchInfo;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        dispatch(addNewLocationInfo({ name, value }));
    }

    const saveLocation = async() => {

        if(
            !title ||
            !address ||
            !city ||
            !country ||
            !phone ||
            !zipCode
        ) {
            dispatch(updateState({
                name: "validationError",
                value: true
            }));
            return;
        }

        dispatch(closeAddEditLocationModal());

        let endpoint = config.url.BACKEND_API_URL + "/locations/create";
        let actionMessage = "Creating...";

        if(isEditing) {
            endpoint = config.url.BACKEND_API_URL + "/locations/edit";
            actionMessage = "Updating...";
            dispatch(closeAddEditLocationModal());
        } 

        dispatch(updateState({
            name: "actionMessage",
            value: actionMessage
        }));

        const locationInfo = trimUserInputObject(newLocationInfo);

        const headers = { "auth-token": cookies.get("auth_token") };
        const requestBody = {
            locationId: locationItemInAction?._id,
            ...locationInfo
        }

        try {

            const response = await axios.post(endpoint, requestBody, { headers });

            dispatch(updateState({
                name: "actionMessage",
                value: response.data
            }));
            
            dispatch({ 
                type: FETCH_LOCATION_DATA, 
                payload: { 
                    tenantId: userState.user?.tenantId,
                    searchText,
                    skip,
                    limit,
                } 
            })

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(updateState({
                name: "actionMessage",
                value: message
            }));
        }
    
    }
  
    return (
        <div className={styles.add_edit_location}>
            <h1>{isAdding ? "Add Location" : "Edit Location"}</h1>
            <InputField 
                labelText="Title"
                type="text"
                name="title"
                value={title}
                required={true}
                handleChange={handleChange}
                validationError={validationError}
                validationErrorMessage="title can't be blank"
            />
            <InputField 
                labelText="Address"
                type="text"
                name="address"
                value={address}
                required={true}
                handleChange={handleChange}
                validationError={validationError}
                validationErrorMessage="address can't be blank"
            />
            <InputField 
                labelText="Phone"
                type="text"
                name="phone"
                value={phone}
                required={true}
                handleChange={handleChange}
                validationError={validationError}
                validationErrorMessage="phone can't be blank"
            />
            <InputField 
                labelText="City"
                type="text"
                name="city"
                value={city}
                required={true}
                handleChange={handleChange}
                validationError={validationError}
                validationErrorMessage="city can't be blank"
            />
            <InputField 
                labelText="Country"
                type="text"
                name="country"
                value={country}
                required={true}
                handleChange={handleChange}
                validationError={validationError}
                validationErrorMessage="country can't be blank"
            />
            <InputField 
                labelText="Zip Code"
                type="text"
                name="zipCode"
                value={zipCode}
                required={true}
                handleChange={handleChange}
                validationError={validationError}
                validationErrorMessage="zip code can't be blank"
            />
            <div className={styles.save_btn_wrapper}>
                <Button 
                    customClassName="save_btn"
                    buttonText="Save"
                    handleClick={saveLocation}
                />
            </div>
        </div>
    );

}


export default AddEditLocation;