import styled from "styled-components"

interface IButtonProps {
    border?: string,
    color?: string,
    backgroundColor?: string,
    marginRight?: string,
    padding?: string,
}

const Button = styled.button<IButtonProps>`
    background-color: ${({ backgroundColor }) => backgroundColor ?? "white"};
    border: ${({ border }) => border};
    border-radius: 6px;
    padding: ${({ padding }) => padding ?? "10px 30px"};
    cursor: pointer;
    color: ${({ color }) => color ?? "white"};
    margin-right: ${({ marginRight }) => marginRight}
`

export default Button;