import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { IAppointment } from "../../../types/models/Appointment";
import { updateState } from "../../../store/calendar/calendarReducer";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { getUserAccess } from "../../../helpers/calendar/getUserAccess";
import styles from "./AppointmentModal.module.css";
import { Modal, ModalBackground, ModalContent } from "../../../components/common/Modal";
import Header from "./Header";
import DeleteButton from "./DeleteButton";
import ClientDetails from "./ClientDetails";
import AppointmentDetails from "./AppointmentDetails";
import AppointmentNote from "./AppointmentNote";
import PaymentStatus from "./PaymentStatus";
import CouponGiftCard from "./CouponGiftCard";

const AppointmentModal: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const userState = useSelector((state: RootState) => state.userState);
    const { paymentAmount, appointmentList } = calendarState;
    const { user } = userState;
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();

    const appointmentInAction = calendarState.appointmentInAction as IAppointment

    const closeModal = () =>  {

        dispatch(updateState({
            name: "appointmentInAction",
            value: null
        }))

        // Because we are auto-filling make payment amount in the appointment modal if
        // the appointment was partially paid before.
        if(paymentAmount) {
            dispatch(updateState({
                name: "paymentAmount",
                value: ""
            }));
        }
       
    };

    const { 
        canUserMaintainAgendas, 
        canViewClientInfo 
    } = useMemo(() => getUserAccess(user, appointmentInAction), [user, appointmentInAction])

    return (
        <Modal>
            <ModalBackground />
            <ModalContent
                width = {isLargeDevice ? "50%" : "95%"}
                height="90%"
                padding="0px"
            >
                <div className = {styles.appointment_modal}>
                    <Header 
                        appointment={appointmentInAction} 
                        isLargeDevice={isLargeDevice}
                        closeModal={closeModal}
                    />
                    {
                        (appointmentInAction.type === "time_block")
                        ?
                        <DeleteButton />
                        :
                        null
                    }
                    <div className = {styles.appointment_modal_content}>
                        <ClientDetails 
                            canViewClientInfo={canViewClientInfo}
                            appointment={appointmentInAction}
                        />
                        <AppointmentDetails 
                            appointment = {appointmentInAction}
                            canUserMaintainAgendas={canUserMaintainAgendas}
                        />
                        <AppointmentNote 
                            appointment={appointmentInAction}
                            appointmentList={appointmentList}
                        />
                        <PaymentStatus 
                            appointment={appointmentInAction}
                            canUserMaintainAgendas={canUserMaintainAgendas}
                        />

                        {/* If Payment status is not Paid then we might show this. But there could also be a scenario
                        when payment status is unpaid but appointment has been discounted by both coupon and gift card.
                        In such cases CouponGiftCard component should not be visible*/}
                        {
                            (appointmentInAction.type === "regular" && appointmentInAction?.paymentStatus !== "Paid") 
                            && 
                            (!(appointmentInAction?.couponDiscountAmount && appointmentInAction?.giftCardDiscountAmount)) 
                            && 
                            (canUserMaintainAgendas)
                            &&
                            <CouponGiftCard />
                        }
                    </div>
                </div>
            </ModalContent>
        </Modal>
    );

}

export default AppointmentModal;