import { MdDashboard } from "react-icons/md";

const DashboardIcon = (): JSX.Element => {

    return (
        <MdDashboard 
            size="1.3rem"
            color="#99B2C6"
            style={{
                marginRight: "3px"
            }}
        />
    );

}

export default DashboardIcon;


