import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { updateState } from "../../../store/user-list/userListReducer";
import styles from "./AddEditUser.module.css";
import CheckboxInputField from "../CheckboxInputField";


const AddUserAccess: React.FC = () => {

    const dispatch = useDispatch();
    const userListState = useSelector((state: RootState) => state.userListState);
    const { userAccessItems } = userListState;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const { name, checked } = event.target;

        const updatedUserAccessItems = userAccessItems.map(item => {
            if(item.keyName === name) {
                return {
                    ...item,
                    keyValue: checked
                }
            }
            return item;
        });

        dispatch(updateState({
            name: "userAccessItems",
            value: updatedUserAccessItems
        }));
       
    }


    return (
        <div className={styles.access_types}>
            <div className={styles.access_group_item}>
                <h2>Agendas</h2>
                {userAccessItems.map(accessItem => {

                    const { groupName, checkboxValueName, keyName, keyValue } = accessItem;

                    if(groupName === "agendas") {
                        return (
                            <CheckboxInputField
                                key={keyName}
                                name={keyName}
                                value={checkboxValueName}
                                handleChange={handleChange}
                                checked={keyValue}
                            />
                        );
                    }

                    return null;

                })}
            </div>
            <div className={styles.access_group_item}>
                <h2>Features</h2>
                {userAccessItems.map(accessItem => {

                    const { groupName, checkboxValueName, keyName, keyValue } = accessItem;

                    if(groupName === "features") {
                        return (
                            <CheckboxInputField
                                key={keyName}
                                name={keyName}
                                value={checkboxValueName}
                                handleChange={handleChange}
                                checked={keyValue}
                            />
                        );
                    }

                    return null;

                })}
            </div>
            <div className={styles.access_group_item}>
                <h2>Clients</h2>
                {userAccessItems.map(accessItem => {

                    const { groupName, checkboxValueName, keyName, keyValue } = accessItem;

                    if(groupName === "clients") {
                        return (
                            <CheckboxInputField
                                key={keyName}
                                name={keyName}
                                value={checkboxValueName}
                                handleChange={handleChange}
                                checked={keyValue}
                            />
                        );
                    }

                    return null;

                })}
            </div>
        </div>
    );

}

export default AddUserAccess;