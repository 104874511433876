import { formatDateTime } from "../date-time/dateTimeHelpers";
import { CalendarState } from "../../types/pages/Calendar";

type Args = Pick<CalendarState, "dayViewDate" | "weekViewDates" | "monthViewDates" | "calendarViewType">

export const calculateStartEndDateStrings = (args: Args) => {

    const { 
        calendarViewType,
        dayViewDate,
        weekViewDates,
        monthViewDates
    } = args;

    const oneDayMS = 86400000;

    let startDate = dayViewDate;
    let endDatePlusOne = new Date(dayViewDate.getTime() + oneDayMS);

    // If user is coming from a page and before leaving the calendar page viewType was changed to 
    // month or week then we need to update accordingly.

    if(calendarViewType === "month" && monthViewDates.length) {
        startDate = monthViewDates[0];
        endDatePlusOne = new Date(monthViewDates[monthViewDates.length - 1].getTime() + oneDayMS);
    }

    if(calendarViewType === "week" && weekViewDates.length) {
        startDate = weekViewDates[0];
        endDatePlusOne = new Date(weekViewDates[weekViewDates.length - 1].getTime() + oneDayMS);
    }

    const { dateString: startDateString } = formatDateTime(startDate);
    const { dateString: endDateString } = formatDateTime(endDatePlusOne);

    return { startDateString, endDateString };

}