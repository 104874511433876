import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { toggleDeletePrompt, updateActionMessage } from "../../store/user-list/userListReducer";
import { FETCH_USER_LIST_DATA } from "../../store/user-list/constants";
import { removeFile } from "../../helpers/file-handlers/removeFile";
import DeletePrompt from "../../components/common/prompts/DeletePrompt";


const DeleteUser = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const userListState = useSelector((state: RootState) => state.userListState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { userItemInAction, userDataFetchInfo } = userListState;
    const { searchText, skip, limit } = userDataFetchInfo;


    const closeDeletePrompt = () => {
        dispatch(toggleDeletePrompt(null))
    }

    const deleteUser = async() => {

        // This check is most likely unnecessary but still putting it here.
        if(!user || !userItemInAction) return;

        dispatch(toggleDeletePrompt(null));
        dispatch(updateActionMessage("Deleting..."));

        // If user had a profile photo, delete it.
        if(userItemInAction.imageFilename) {
            const args = { 
                url: "/users/photo/delete",
                filePath: `users/${userItemInAction.tenantId}`,
                filename: userItemInAction.imageFilename
            }
            const { success } = await removeFile(args);
            if(!success) return alert("Something went wrong while removing old photo");
        }

        const endpoint = config.url.BACKEND_API_URL + "/users/delete/" + userItemInAction._id;
        const headers = { "auth-token": cookies.get("auth_token") };

        try {

            // Axios delete method is unique. Request body must be specified with data property.  
            const response = await axios.delete(endpoint, { headers } );

            dispatch(updateActionMessage(response.data));

            dispatch({ 
                type: FETCH_USER_LIST_DATA, 
                payload: { 
                    tenantId: user.tenantId,
                    searchText,
                    skip,
                    limit,
                    fetchLocationData: "No"
                } 
            })

        } catch(error) {
            dispatch(updateActionMessage("Something went wrong"));
        }

    }
    
  
    return (
        <>
            <DeletePrompt 
                warningText = {<p>Are you sure want to delete <span>{userItemInAction?.fullName}</span></p>}
                closeDeletePrompt={closeDeletePrompt}
                deleteItem = {deleteUser}
            />
        </>
    );

}


export default DeleteUser;