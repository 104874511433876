import styles from "./index.module.css";

interface ContentListTableProps {
    contentList: Record<string, any>[],
    removeContent: (content: Record<string, any>) => void
}

const ContentListTable: React.FC<ContentListTableProps> = ({ contentList, removeContent }) => {

    return (
        <div className={styles.added_content_list}>
            <table className={styles.content_list_table} >
                <thead>
                    <tr>
                        <th>Hours</th>
                        <th>Rate</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {contentList.map(content => {
                        return (
                            <tr key={content.hoursBeforeAppointmentTime}>
                                <td>{content.hoursBeforeAppointmentTime}</td>
                                <td>{content.applicableAppointmentChargeRate}</td>
                                <td>
                                    <button onClick={() => removeContent(content)}>
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default ContentListTable;
