import { ModelExtended as Model } from "./Model";
import { IPackage } from "./Package";
import { DateMetaData } from "../common/DateTime";

export enum ClientPackagePaymentMethod {
    CreditCard = "credit_card",
    Ideal = "ideal",
    Paypal = "paypal",
    Card = "card",
    Cash = "cash",
    Online = "online"
}

export enum ClientPackagePaymentStatus {
    Paid = "paid",
    PartiallyPaid = "partially_paid",
    Due = "due"
}

export enum ClientPackagePaymentType {
    OneTime = "one_time",
    Installment = "installment"
}

export enum ClientPackageStatus {
    Active = "active",
    UsedUp = "used_up"
}

export interface ClientPackageTreatment {
    _id: string
    title: string
    price: number
    duration: number
    sessions: number
    sessionsUsed: number
    sessionsLeft: number
}

export interface ClientPackagePaymentData {
    date: DateMetaData
    type: ClientPackagePaymentType
    method: ClientPackagePaymentMethod | null
    stripePaymentIntentId: string | null
    paypalPaymentCaptureId: string | null
    online: boolean
    amount: number
    status: ClientPackagePaymentStatus
}

// Take the last payment's method to populate method field
// Status will be partially_paid if some of the installments have been paid but not all
export interface ClientPackagePayment {
    type: ClientPackagePaymentType
    online: boolean
    isTansferredToStripeConnectedAccount: boolean
    status: ClientPackagePaymentStatus 
    data: ClientPackagePaymentData[]
}

export interface ClientPackagePaymentInstallment {
    date: string
    method: string | null
    amount: number
}

export interface ClientPackageModel {
    parentPackage: string
    title: string
    description: string
    price: number
    clientName: string
    clientEmail: string
    clientPhone: string
    payment: ClientPackagePayment
    treatmentList: ClientPackageTreatment[]
    status: ClientPackageStatus
}

export type IClientPackage = Model<ClientPackageModel>
export type IClientPackagePopulated = Omit<IClientPackage, "parentPackage"> & {
    parentPackage: IPackage
}

