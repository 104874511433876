import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { RootState } from "../../../store";
import { config } from "../../../Constants";
import { 
    updateState,
    closeAddAppointmentModal
} from "../../../store/calendar/calendarReducer";
import { FETCH_APPOINTMENT_DATA } from "../../../store/calendar/constants";
import { formatDateTime } from "../../../helpers/date-time/dateTimeHelpers";
import { validateAppointmentTime } from "../../../helpers/validators/validateAppointmentTime";
import { validateEmailAddress } from "../../../helpers/validators/validateEmailAddress";
import { calculateAppointmentDateList } from "../../../helpers/calendar/calculateAppointmentDateList";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";
import styles from "./AddAppointmentModal.module.css";

const SaveButton: React.FC = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userState);
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { user } = userState;
    const { 
        calendarViewType,
        dayViewDate,
        weekViewDates,
        newAppointmentType,
        newAppointmentInfo, 
        activityBy,
        isRepeatingAppointment,
        appointmentDateList,
        repeatingAppointmentDateType,
        repeatingAppointmentStartDate,
        repeatingAppointmentEndDate,
        doNotSendEmailToClient 
    } = calendarState;
    const {
        newAppointmentTitle,
        selectedLocation, 
        selectedTreatment, 
        selectedStylist,
        appointment_date,
        startTime,
        endTime,
        clientName,
        clientEmail,
        clientPhone
    } = newAppointmentInfo;

    const saveRepeatingAppointments = async() => {

        let newAppointmentDateList: string[] = [];

        const { dateString: todayDateString } = formatDateTime(new Date());
        const todayInMS = new Date(todayDateString).getTime();

        if(repeatingAppointmentDateType === "everyday" && (!repeatingAppointmentStartDate || !repeatingAppointmentEndDate)) {
            return alert("Please select both start and end date");
        }

        if(repeatingAppointmentDateType === "selected_dates" && !appointmentDateList.length) {
            return alert("Please select at least one date")
        }

        if(repeatingAppointmentStartDate && repeatingAppointmentEndDate) {

            const { dateString: startDateString } = formatDateTime(repeatingAppointmentStartDate);
            const { dateString: endDateString } = formatDateTime(repeatingAppointmentEndDate);

            const startDateInMS = new Date(startDateString).getTime();
            const endDateInMS = new Date(endDateString).getTime();

            if(startDateInMS < todayInMS || startDateInMS > endDateInMS) {
                return alert("Start date must be greater than or equal to today and end date must be greater than start date");
            }
            
            newAppointmentDateList = calculateAppointmentDateList({
                startDate: repeatingAppointmentStartDate,
                endDate: repeatingAppointmentEndDate
            });

        }

        let msg = null;

        if(appointmentDateList.length) {

            for(let x = 0; x < appointmentDateList.length; x++) {

                const { dateString } = formatDateTime(appointmentDateList[x]);

                const dateInMS = new Date(dateString).getTime();

                if(dateInMS < todayInMS) {
                    msg = "Dates must be greater than or equal to today";
                    break;
                } 
              
                newAppointmentDateList.push(dateString);
                
            }

        }

        if(msg) return alert(msg);

        const requestBody = {
            appointmentType: newAppointmentType,
            appointmentDateList: newAppointmentDateList,
            appointmentTitle: newAppointmentTitle === "" ? null : newAppointmentTitle,
            ...newAppointmentInfo,
            activityBy,
            doNotSendEmailToClient
        };

        const endpoint = config.url.BACKEND_API_URL + "/appointments/add-repeating-appointments";
        const headers = { 
            "auth-token": cookies.get("auth_token") 
        };

        dispatch(closeAddAppointmentModal());
        dispatch(updateState({
            name: "serverResponseMessage",
            value: "Saving Appointments..."
        }));

        try {

            const response = await axios.post(endpoint, requestBody, { headers });
            const { message } = response.data;

            dispatch(updateState({
                name: "serverResponseMessage",
                value: message
            }));

        } catch(error) {
            alert("Something went wrong")
        }

    }

    const saveNewAppointment = async (event: any) => {

        event.preventDefault();

        if(!user) return;

        // If appointment is being booked for searched client then user must manually select appointment_date
        // and stylist. And she might forget to do it. So return with input validation error if this is the case.
        if(!appointment_date || !selectedStylist || !startTime) {
            dispatch(updateState({
                name: "validationError",
                value: true
            }))
            return;
        }

        // startTime format validation
        const isStartTimeValid = validateAppointmentTime(startTime);
        if(!isStartTimeValid) return alert("Start time is not valid");
       
        const isTimeBlockAppointment = newAppointmentType === "time_block";

        // Time Block Appointment
        if(isTimeBlockAppointment) {
            if(!newAppointmentTitle || !endTime) {
                dispatch(updateState({
                    name: "validationError",
                    value: true
                }))
                return;
            }

            // endTime format validation
            const isEndTimeValid = validateAppointmentTime(endTime);
            if(!isEndTimeValid) return alert("End time is not valid");

        }
       
        // Regular Appointment
        if(!isTimeBlockAppointment) {

            if(
                !selectedLocation || 
                !selectedTreatment || 
                clientName === "" || 
                clientPhone === "" || 
                clientEmail === ""
            ) {
                dispatch(updateState({
                    name: "validationError",
                    value: true
                }))
                return;
            }
            
            // Validate Email Address
            const { isEmailValid, emailValMessage } = validateEmailAddress(clientEmail);
            if(!isEmailValid) return alert(emailValMessage);

        }

        // The name of the user who is scheduling the appointment is a must.
        if(!activityBy) return alert("Please enter who is scheduling the appointment");

        if(isRepeatingAppointment) {
            saveRepeatingAppointments();
            return;
        }

        dispatch(closeAddAppointmentModal());
        dispatch(updateState({
            name: "serverResponseMessage",
            value: "Saving Appointment..."
        }));

        const endpoint = config.url.BACKEND_API_URL + "/appointments/add-appointment";

        const requestBody = {
            ...newAppointmentInfo,
            newAppointmentType,
            activityBy,
            doNotSendEmailToClient
        };
        const headers = { 
            "auth-token": cookies.get("auth_token") 
        };

        try {

            const response = await axios.post(endpoint, requestBody, { headers });
            const { message } = response.data;

            dispatch(updateState({
                name: "serverResponseMessage",
                value: message
            }))

            let dates: Date[] = [dayViewDate];
            if(calendarViewType === "week") dates = [...weekViewDates];

            const endDatePlusOne = new Date(dates[dates.length - 1].getTime() + 86400000);

            const { dateString: startDateString } = formatDateTime(dates[0]);
            const { dateString: endDateString } = formatDateTime(endDatePlusOne);
        
            dispatch({ 
                type: FETCH_APPOINTMENT_DATA, 
                payload: {
                    tenantId: user?.tenantId,
                    startDateString,
                    endDateString
                }
            })

        } catch(error: any) {
            const { message } = handleApiError(error);
            dispatch(updateState({
                name: "serverResponseMessage",
                value: message
            }))
        }
        
    }

    return (
        <div className = {styles.save_button}>
            <button onClick = {saveNewAppointment}>Save Appointment</button>
        </div>
    );

}

export default SaveButton;