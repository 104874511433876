import styles from "./ClientSearch.module.css";

const ClientInfo: React.FC<{ infoTitle: string, info: string }> = ({ infoTitle, info }) => {

    return (
        <div className = {styles.client_info}>
            <span>{infoTitle}</span>
            <span>{info}</span>
        </div>
    );

}

export default ClientInfo;