export const daysOfTheWeek = [
    { _id: 1, name: "Monday"},
    { _id: 2, name: "Tuesday"},
    { _id: 3, name: "Wednesday"},
    { _id: 4, name: "Thursday"},
    { _id: 5, name: "Friday"},
    { _id: 6, name: "Saturday"},
    { _id: 7, name: "Sunday"}
];

export const workScheduleTimes = [
    { _id: 1, time: "00:00" },
    { _id: 2, time: "01:00" },
    { _id: 3, time: "02:00" },
    { _id: 4, time: "03:00" },
    { _id: 5, time: "04:00" },
    { _id: 6, time: "05:00" },
    { _id: 7, time: "06:00" },
    { _id: 8, time: "07:00" },
    { _id: 9, time: "08:00" },
    { _id: 10, time: "09:00" },
    { _id: 11, time: "10:00" },
    { _id: 12, time: "11:00" },
    { _id: 13, time: "12:00" },
    { _id: 14, time: "13:00" },
    { _id: 15, time: "14:00" },
    { _id: 16, time: "15:00" },
    { _id: 17, time: "16:00" },
    { _id: 18, time: "17:00" },
    { _id: 19, time: "18:00" },
    { _id: 20, time: "19:00" },
    { _id: 21, time: "20:00" },
    { _id: 22, time: "21:00" },
    { _id: 23, time: "22:00" },
    { _id: 24, time: "23:00" }
];