import axios from "axios";
import { ProductState } from "../../../types/pages/Product";
import { config } from "../../../Constants";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";

type Payload = Pick<ProductState, "searchText" | "skip" | "limit">

export const fetchProductSales = async(payload: Payload) => {

    const { searchText, skip, limit } = payload;

    const param = `searchText=${searchText}&skip=${skip}&limit=${limit}`;
    
    const endpoint = `${config.url.BACKEND_API_URL}/products/sales?${param}`;
    const authConfig = getAuthTokenConfig();
  
    const response = await axios.get(endpoint, authConfig);
    return response.data;

}