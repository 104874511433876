import { SubscriptionPrice } from "../../../types/models/Subscription";
import styles from "./SubscriptionPlans.module.css";

interface IPriceListProps {
    prices: SubscriptionPrice[]
}

const PriceList: React.FC<IPriceListProps> = ({ prices }) => {

    return (
        <div className = {styles.sub_plan_prices}>
            {prices.map(price => {

                const { amount, recurringPaymentType, stripePriceId } = price;

                const recurringPaymentTypeString = recurringPaymentType.charAt(0).toUpperCase() + recurringPaymentType.slice(1)

                return (
                    <ul key = {stripePriceId} className="">
                       <li>{`€${amount.toFixed(2)} / ${recurringPaymentTypeString}`}</li>
                    </ul>
                );
            })}
        </div>
            
    );
}


export default PriceList;