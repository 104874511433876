import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { config } from "../../Constants";
import { RootState } from "../../store";
import { FETCH_APPOINTMENT_DATA } from "../../store/calendar/constants";
import { updateState } from "../../store/calendar/calendarReducer";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import { decodeJWTToken } from "../../helpers/others/decodeJWTToken";
import { validateAppointmentTime } from "../../helpers/validators/validateAppointmentTime";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./Calendar.module.css";
import InputField from "./common/InputField";

const DragRescheduleModal: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { 
        calendarViewType, 
        dragRescheduleAppointmentId,
        dragRescheduleInfo,
        dayViewDate,
        weekViewDates,
        monthViewDates 
    } = calendarState;
    const { startTime, rescheduledBy } = dragRescheduleInfo;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        dispatch(updateState({
            name: "dragRescheduleInfo",
            value: {
                ...dragRescheduleInfo,
                [name]: value
            }
        }))
    }

    const refetchAppointmentData = () => {

        let dates: Date[] = [];

        if(calendarViewType === "day") dates = [dayViewDate];
        if(calendarViewType === "week") dates = [...weekViewDates];
        if(calendarViewType === "month") dates = [...monthViewDates];

        const endDatePlusOne = new Date(dates[dates.length - 1].getTime() + 86400000);

        const { dateString: startDateString } = formatDateTime(dates[0]);
        const { dateString: endDateString } = formatDateTime(endDatePlusOne);

        const { tenantId } = decodeJWTToken("auth_token")

        dispatch({ 
            type: FETCH_APPOINTMENT_DATA, 
            payload: {
                tenantId,
                startDateString,
                endDateString
            }
        });

    }

    const rescheduleAppointment = async() => {

        const isStartTimeValid = validateAppointmentTime(startTime);
        if(!isStartTimeValid) return alert("Invalid Time");

        const { dateString } = formatDateTime(dragRescheduleInfo.date ?? new Date());
        const endpoint = config.url.BACKEND_API_URL + "/appointments/reschedule";
        const authConfig = getAuthTokenConfig();
        const requestBody = {
            activityBy: dragRescheduleInfo.rescheduledBy, 
            doNotSendEmailToClient: false,
            appointment_id: dragRescheduleAppointmentId, 
            selectedStylist: dragRescheduleInfo.stylist?.fullName,
            selectedStylistId: dragRescheduleInfo.stylist?._id,
            selectedDateTime: `${dateString}T${dragRescheduleInfo.startTime}`
        }

        dispatch(updateState({
            name: "dragRescheduleInfo",
            value: {
                isDropped: false,
                stylist: null,
                date: null,
                startTime: "",
                rescheduledBy: ""
            }
        }));

        try {
            await axios.post(endpoint, requestBody, authConfig);
            refetchAppointmentData()
        } catch(error) {
            const { message } = handleApiError(error);
            alert(message);
        }
    }

    return (
        <div className={styles.drag_reschedule_modal}>
            <InputField 
                labelText="Reschedule Start Time"
                type="text" 
                name="startTime" 
                value={dragRescheduleInfo.startTime} 
                handleChange={handleChange} 
            />
            <InputField 
                labelText="Rescheduled By"
                type="text" 
                name="rescheduledBy" 
                value={dragRescheduleInfo.rescheduledBy} 
                handleChange={handleChange} 
            />
            <button 
                onClick={rescheduleAppointment}
                disabled={startTime === "" || rescheduledBy === ""}
            >
                Reschedule
            </button>
        </div>
    );

}

export default DragRescheduleModal;