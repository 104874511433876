import { Link } from "react-router-dom";
import styles from "./Layouts.module.css";


interface IProps {
    icon: JSX.Element,
    linkText: string,
    linkHref: string,
    isUrl?: boolean,
    style?: {
        [key: string]: string | boolean
    }
}

const NavItem: React.FC<IProps> = (props) => {

    const { isUrl = true, icon, linkText, linkHref, style = {} } = props;

    return (
        <div 
            className = {styles.snv_item}
            style = {{
                marginBottom: isUrl ? "15px" : "0px"
            }}
        >
            <div className = {styles.snv_item_icon}>
                {icon}
            </div>
            {
                !isUrl
                ?
                <p>{linkText}</p>
                :
                <Link to = {linkHref} style = {style}>
                    {linkText}
                </Link>
            }
        </div>
    );

}

export default NavItem;