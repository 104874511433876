import styles from "./LoadingError.module.css";

interface LoadingErrorProps {
    customClassName?: string
    withCloseButton?: boolean
    message: string
    onClick?: () => void
}


const LoadingError: React.FC<LoadingErrorProps> = (props) => {

    const { 
        customClassName = "", 
        withCloseButton = false,
        message,
        onClick 
    } = props;

    return (
        <div className = {`${styles.loading_error} ${styles[customClassName]}`}>
            <p>{message}</p>
            {
                withCloseButton
                ?
                <button onClick={onClick}>
                    Close
                </button>
                :
                null
            }
        </div>
    );
   
}

export default LoadingError;