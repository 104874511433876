import { IAppointment } from "../../types/models/Appointment";

export const getTimes = (timeCount: number, startHours: number, startMinutes: number) => {

    const times: string[] = [];

    let hours = startHours;
    let minutes: string | number = startMinutes;

    for(let x = 0; x < timeCount; x++) {

        if(minutes === 60) {
            hours += 1;
            minutes = 0;
        }
        if(minutes < 10) minutes = "0" + minutes;

        const time = `${hours}:${minutes}`;
        times.push(time)

        minutes = Number(minutes) + 5;

    }

    return times;

}

export const separateAppointmentHoursMinutes = (appointmentDateTime: string) => {

    const appointmentTime = appointmentDateTime.substring(appointmentDateTime.indexOf("T") + 1, appointmentDateTime.length);

    const appointmentDateString = appointmentDateTime.substring(0, appointmentDateTime.indexOf("T"));

    const appointmentHours = Number(appointmentTime.substring(0, 2));
    const appointmentMinutes = Number(appointmentTime.substring(3, 5));

    return {
        dateString: appointmentDateString,
        startHours: appointmentHours,
        startMinutes: appointmentMinutes
    }

}


export const calculateAppointmentPosition = (appointment: IAppointment, appointmentList: IAppointment[]) => {

    const commonTimeAppointments: IAppointment[] = [appointment];

    const { startHours, startMinutes, dateString } = separateAppointmentHoursMinutes(appointment.startTime)

    const appointmentTimes = getTimes(appointment.duration / 5, startHours, startMinutes);
    
    for(let x = 0; x < appointmentList.length; x++) {

        const listAppointmentItem = appointmentList[x];

        const { 
            dateString: listAppointmentDateString,
            startHours: listAppointmentItemStartHours, 
            startMinutes: listAppointmentItemStartMinutes 
        } = separateAppointmentHoursMinutes(listAppointmentItem.startTime)

        // If appointment dates do not match or listAppointment and appointment are same then continue. 
        const isAppointmentDateMatch = dateString === listAppointmentDateString;
        const isAppointmentMatch = listAppointmentItem._id === appointment._id;

        if(!isAppointmentDateMatch || isAppointmentMatch) continue;

        const listAppointmentItemTimes = getTimes(listAppointmentItem.duration / 5, listAppointmentItemStartHours, listAppointmentItemStartMinutes);

        for(let y = 0; y < appointmentTimes.length; y++) {

            const time = appointmentTimes[y];
         
            if(listAppointmentItemTimes.includes(time)) {
                commonTimeAppointments.push(listAppointmentItem);
                break;
            }
        }
        
    }

    const sortedCommonTimeAppointments = commonTimeAppointments.sort((a, b) => {
        return b.duration - a.duration
    })

    const width = 100 / sortedCommonTimeAppointments.length;

    const foundIndex = sortedCommonTimeAppointments.findIndex(item => item._id === appointment._id);
    const leftPosition = (100 / sortedCommonTimeAppointments.length) * foundIndex;

    return {
        width, 
        leftPosition
    };

}