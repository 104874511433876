import { useState } from "react";
import { AppointmentActivity } from "../../../types/models/Appointment";
import { getFormattedDateTime } from "../../../helpers/date-time/getFormattedDateTime";
import styles from "./AppointmentModal.module.css";

const AppointmentActivityList: React.FC<{ activities: AppointmentActivity[] }> = ({ activities }) => {

    const [showAllActivities, setShowAllActivities] = useState<boolean>(false);
    
    return (
        <div className = {styles.appointment_activity}>
            <ul>
               {activities.map((activity, index) => {

                    const { createdAt, description } = activity;
                    const formattedDateTime = getFormattedDateTime(new Date(createdAt * 1000));
                    const { day, month, year, hours, minutes } = formattedDateTime;
                    const activityTimeString = `${hours}:${minutes}`

                    if(index === 0) {
                        return (
                            <li key = {createdAt}>
                                {`${month} ${day}, ${year} at ${activityTimeString}`} - {description}
                            </li>
                        );
                    }

                    if(index > 0 && showAllActivities) {
                        return (
                            <li key = {createdAt}>
                                {`${month} ${day}, ${year} at ${activityTimeString}`} - {description}
                            </li>
                        );
                    } else {
                        return null;
                    }
               })} 
            </ul>
            {
                activities.length > 1
                &&
                <button onClick = {() => setShowAllActivities(!showAllActivities)}>
                    {showAllActivities ? "Hide Activity" : "See Activity"}
                </button>
            }
        </div>
    );

}

export default AppointmentActivityList;