import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import useWindowWidth from "../../hooks/useWindowWidth";
import { updateState, switchTableUI } from "../../store/products/productReducer";
import styles from "./DataTable.module.css";
import DropdownList from "./DropdownList";

type ButtonItem = {
    _id: number
    text: string
    buttonFor: string
}

const buttonList: ButtonItem[] = [
    { _id: 1, text: "Products", buttonFor: "products" },
    { _id: 2, text: "Sales", buttonFor: "product_sales" },
    { _id: 3, text: "Purchase Orders", buttonFor: "purchase_order" }
];

const DataTableButtons: React.FC = () => {

    const dispatch = useDispatch();
    const productState = useSelector((state: RootState) => state.productState);
    const { showTableFor } = productState;
    const { windowWidth } = useWindowWidth();

    const [dropdownSelectedValue, setDropdownSelectedValue] = useState<string>("Products");

    const handleClick = (buttonItem: ButtonItem) => {

        if(buttonItem.buttonFor === showTableFor) return;

        dispatch(switchTableUI());

        setTimeout(() => {
            dispatch(updateState({
                name: "showTableFor",
                value: buttonItem.buttonFor
            }));
        }, 100)
    
        setDropdownSelectedValue(buttonItem.text);

    }

    return (
        <>
            {
                windowWidth >= 1000
                ?
                <div className={styles.data_table_buttons}>
                    {buttonList.map(item => {
                            return (
                                <button 
                                    key={item.buttonFor}
                                    onClick={() => handleClick(item)}
                                    style={{
                                        color: showTableFor === item.buttonFor ? "#FFAAA5" : "#3ead7da1",
                                        borderBottom: showTableFor === item.buttonFor ? "4px solid #FFAAA5" : "none"
                                    }}
                                >
                                    {item.text}
                                </button>
                            );
                    })}
                </div>
                :
                <DropdownList 
                    data={buttonList}
                    nameKey="text"
                    selectedValue={dropdownSelectedValue}
                    clickHandler={handleClick}
                />
            }
        </>
    );

}

export default DataTableButtons;
