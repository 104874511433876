import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { updateState } from "../../store/packages/packageReducer";
import { FETCH_PACKAGE_DATA } from "../../store/packages/constants";
import styles from "./index.module.css";
import InputField from "./InputField";
import Button from "./Button";

const SearchAdd = () => {

    const dispatch = useDispatch();
    const packageState = useSelector((state: RootState) => state.packageState);
    const { searchText, skip, limit } = packageState;
    const [isSearchTextBackspacedToEmpty, setIsSearchTextBackspacedToEmpty] = useState(false);
    
    useEffect(() => {

        if(!isSearchTextBackspacedToEmpty && !searchText) return; 
      
        // Calling setTimeout directly will make api call for each character typed. But assigning
        // this function to a variable re-initializes the timer. So, just one api call will be made
        // when user stops typing.
        const delayAPICall = setTimeout(() => {

            dispatch({ 
                type: FETCH_PACKAGE_DATA,
                payload: { 
                    searchText, 
                    skip, 
                    limit,
                    fetchTreatmentData: false 
                } 
            })

        }, 500);

        if(isSearchTextBackspacedToEmpty) setIsSearchTextBackspacedToEmpty(false);

        return () => clearTimeout(delayAPICall);

    }, [searchText])


    const searchPackages = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        dispatch(updateState({
            name: "searchText",
            value
        }))
        if(!value) setIsSearchTextBackspacedToEmpty(true);
    }

    const openModal = () => {
        dispatch(updateState({
            name: "isAdding",
            value: true
        }));
    } 

    return (
        <div className={styles.search_add_btn}>
            <InputField
                customClassName="search_input" 
                type="text"
                name="search"
                value={searchText}
                placeholder="Search"
                handleChange={searchPackages}
            />
            <Button
                customClassName="add_btn"
                buttonText="Add New Package"
                handleClick={openModal}
            />
        </div>
    );

}

export default SearchAdd;