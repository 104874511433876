import React from "react";
import { IUser } from "../../types/models/User";
import { StartEndHourMinutes } from "../../types/pages/Calendar";
import { ICalendarAppointment } from "../../types/pages/Calendar";
import styles from "./Calendar.module.css";
import TimeIndicator from "./TimeIndicator";
import Events from "./Events";
import TimeSlotGroup from "./TimeSlotGroup";

interface WeekViewProps {
    selectedStylistList: IUser[]
    appointmentList: ICalendarAppointment[]
    weekViewDates: Date[]
    totalMinutes: number
    switchToDayView: (date: Date) => void
    handleMouseUp: (stylist: IUser) => void
    handleMouseDown: (startEndHourMinute: StartEndHourMinutes, stylist: IUser, date: Date) => void
    handleMouseEnter: (startEndHourMinute: StartEndHourMinutes) => void
    handleDragOver: (event: React.DragEvent) => void
    handleDrop: (startHourMinutes: string, stylistId: string, date: Date) => void
}

const WeekView: React.FC<WeekViewProps> = ({ 
    selectedStylistList, 
    appointmentList, 
    weekViewDates,
    totalMinutes,
    switchToDayView,
    handleMouseUp,
    handleMouseDown,
    handleMouseEnter,
    handleDragOver,
    handleDrop 
}) => {

    return (  
        <div className={styles.week_view}>
              <TimeIndicator 
                    totalMinutes={totalMinutes} 
                    style={{
                        minWidth: `${selectedStylistList.length * 100}%`
                    }}
                />
            {
                (totalMinutes - 240) > 0
                ?
                <TimeIndicator 
                    totalMinutes={totalMinutes} 
                    style={{
                        minWidth: `${selectedStylistList.length * 100}%`
                    }}
                />
                :
                null
            }
            {selectedStylistList.map(stylist => {

                const stylistAppointments = appointmentList.filter(item => item.stylistItem?._id === stylist._id);

                return (
                    <div className = {styles.week_times_container} key = {stylist._id}>
                        <div className = {styles.week_stylist_name_container} >
                            <div className = {styles.week_stylist_name}>
                                {stylist.fullName}
                            </div>
                            <div className = {styles.week_dates_container}>
                                {weekViewDates.map((weekDate, index) => {

                                    const day = weekDate.getDate();
                                    const dayName = weekDate.toLocaleString("en-us", {  weekday: "short" });

                                    return (
                                        <div 
                                            key={index} 
                                            className={styles.week_date}
                                            onClick={() => switchToDayView(weekDate)}
                                        >
                                            {day} {dayName}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className = {styles.week_events_container}>
                            {weekViewDates.map((weekDate, index) => {

                                return (
                                    <div 
                                        key={index} 
                                        className={styles.week_events}
                                    >
                                        <div className = {styles.timeslot_groups_container}>
                                            <Events 
                                                events = {stylistAppointments}
                                                date = {weekDate}
                                            />
                                            <TimeSlotGroup 
                                                date={weekDate}
                                                stylist={stylist}
                                                selectedStylistList={selectedStylistList}
                                                handleMouseUp={handleMouseUp}
                                                handleMouseDown={handleMouseDown}
                                                handleMouseEnter={handleMouseEnter}
                                                handleDragOver={handleDragOver}
                                                handleDrop={handleDrop}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );

}

export default React.memo(WeekView);
