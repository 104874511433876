import styles from "./LoadingError.module.css";

interface LoadingErrorProps {
    customClassName?: string
    message: string
}

const LoadingError: React.FC<LoadingErrorProps> = (props) => {

    const { 
        customClassName = "", 
        message,
    } = props;

    return (
        <div className={`${styles.loading_error} ${styles[customClassName]}`}>
            <p>{message}</p>
        </div>
    );
   
}

export default LoadingError;