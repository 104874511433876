import { IoMdClose } from "react-icons/io";
import { IAppointment } from "../../../types/models/Appointment";
import { getFormattedDateTime } from "../../../helpers/date-time/getFormattedDateTime";
import styles from "./AppointmentModal.module.css";

interface HeaderProps {
    appointment: IAppointment | null
    isLargeDevice: boolean
    closeModal: () => void
}

const Header: React.FC<HeaderProps> = ({ appointment, isLargeDevice, closeModal }) => {

    const appointment_date = appointment?.appointment_date ?? 0;
    const { day, weekDay, month, year } = getFormattedDateTime(new Date(appointment_date * 1000))

    const startTime = appointment?.startTime ?? "";
    const endTime = appointment?.endTime ?? "";

    // Separating only time from startTime and endTime
    const newStartTime = startTime.substring(startTime.indexOf("T") + 1, startTime.length);
    const newEndTime = endTime.substring(endTime.indexOf("T") + 1, endTime.length);

    return (
        <div className = {styles.header}>
            {isLargeDevice ? <p>Appointment Details</p> : null}
            <p>{day} {month}, {weekDay}, {year}</p>
            <p>{`${newStartTime} - ${newEndTime}`}</p>
            <button 
                className={styles.header_button}
                onClick={closeModal}
            >
                <IoMdClose />
            </button>
        </div>
    );

}

export default Header;