import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { IUser } from "../../../types/models/User";
import { config } from "../../../Constants";
import { RootState } from "../../../store";
import { FETCH_USER_LIST_DATA } from "../../../store/user-list/constants";
import { 
    updateState,
    closeAddUserModal, 
    closeEditUserModal,
    updateActionMessage 
} from "../../../store/user-list/userListReducer";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { uploadFile, UploadFileReturnType } from "../../../helpers/file-handlers/uploadFile";
import { removeFile } from "../../../helpers/file-handlers/removeFile";
import { validateEmailAddress } from "../../../helpers/validators/validateEmailAddress";
import { validatePassword } from "../../../helpers/validators/validatePassword";
import { trimUserInputObject } from "../../../helpers/others/trimUserInputObject";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";
import { getUserAccessItems } from "../../../helpers/user-list/getUserAccessItems";
import { modifyUserAccessItems } from "../../../helpers/user-list/modifyUserAccessItems";
import { Modal, ModalBackground, ModalContent } from "../../../components/common/Modal";
import styles from "./AddEditUser.module.css";
import Button from "../Button";
import SelectButtons from "./SelectButtons";
import AddUserInfo from "./AddUserInfo";
import AddUserAccess from "./AddUserAccess";


const AddEditUser: React.FC = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const userListState = useSelector((state: RootState) => state.userListState);
    const userState = useSelector((state: RootState) => state.userState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();
    const user = userState.user as IUser;
    const { 
        newUserInfo, 
        userAccessItems,
        userPhoto,
        userType,
        isFreelanceStylist,
        newWorkSchedule,
        isAdding, 
        isEditing, 
        userItemInAction,
        userDataFetchInfo 
    } = userListState;
    const { searchText, skip, limit } = userDataFetchInfo;

    const [isDefiningAccess, setIsDefiningAccess] = useState<boolean>(false)

    const updateUserAccessItems = () => {
        const userAccessItems = getUserAccessItems();
        dispatch(updateState({
            name: "userAccessItems",
            value: userAccessItems
        }));
    }

    const closeModal = () => {

        updateUserAccessItems();
        
        if(isAdding) {
            dispatch(closeAddUserModal());
            return;
        }

        dispatch(closeEditUserModal());
    }

    const saveUser = async() => {

        const { fullName, email, phone, password, password2 } = newUserInfo;

        if(!fullName || !email || !phone) {
            dispatch(updateState({
                name: "validationError",
                value: true
            }));
            return;
        }

        const { isEmailValid, emailValMessage } = validateEmailAddress(email);
        if(!isEmailValid) {
            return alert(emailValMessage);
        }

        if(isAdding) {
            if(!password || !password2) return alert("Please enter password")
            const { isPassValid, passValMessage } = validatePassword(password, password2);
            if(!isPassValid) return alert(passValMessage);
        }

        if(isEditing && password !== "") {
            if(!password2) return alert("Please enter password again");
            const { isPassValid, passValMessage } = validatePassword(password, password2);
            if(!isPassValid) return alert(passValMessage);
        }

        const modifiedUserAccessItems = modifyUserAccessItems(userAccessItems);

        const accessTypeList: string[] = [];

        modifiedUserAccessItems.forEach(item => {
            if(item.keyValue) accessTypeList.push(item.keyStringValue);
        })

        if(userType === "Stylist" && !newWorkSchedule.length) return alert("Please add at least one work schedule");

        if(isAdding) dispatch(closeAddUserModal());

        let endpoint = config.url.BACKEND_API_URL + "/users/create";
        let actionMessage = "Creating...";

        // If user has choosen a profile photo, upload it
        let fileInfo: UploadFileReturnType = { 
            imageUrl: null, 
            imageFilename: null 
        };

        if(isEditing && userItemInAction) {

            fileInfo.imageUrl = userItemInAction.imageUrl;
            fileInfo.imageFilename = userItemInAction.imageFilename;

            if(userItemInAction.imageUrl && userItemInAction.imageFilename && userPhoto) {

                const args = { 
                    url: "/users/photo/delete",
                    filePath: `users/${user.tenantId}`,
                    filename: userItemInAction.imageFilename
                }

                const { success } = await removeFile(args);
                if(!success) return alert("Something went wrong while removing old photo");
                
            }

            endpoint = config.url.BACKEND_API_URL + "/users/edit";
            actionMessage = "Updating...";
            dispatch(closeEditUserModal());

        }

        dispatch(updateActionMessage(actionMessage));

        if(userPhoto) {
            const args = { 
                url: "/users/photo/upload",
                filePath: `users/${user.tenantId}`,
                file: userPhoto
            }
            fileInfo = await uploadFile(args);
        }

        const headers = { "auth-token": cookies.get("auth_token") };

        const userInfo = trimUserInputObject(newUserInfo);

        let requestBody = {
            ...userInfo,
            accessTypeList,
            ...fileInfo,
            tenantId: user.tenantId,
            userId: userItemInAction?._id,
            userType,
            isFreelanceStylist,
            workSchedule: newWorkSchedule
        }

        try {

            const response = await axios.post(endpoint, requestBody, { headers });

            dispatch(updateActionMessage(response.data.message));

            updateUserAccessItems();
            
            dispatch({ 
                type: FETCH_USER_LIST_DATA, 
                payload: { 
                    tenantId: user.tenantId,
                    searchText,
                    skip,
                    limit,
                    fetchLocationData: "No"
                } 
            })

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(updateActionMessage(message));
        }
    
    }

  
    return (
        <Modal>
            <ModalBackground />
            <ModalContent 
                width = {isLargeDevice ? "65%" : "95%"} 
                height="90%"
            >
                <Button 
                    customClassName="modal_cls_btn"
                    buttonText="X"
                    handleClick={closeModal}
                />
                <div className = {styles.add_edit_user}>
                    <h1>{isAdding ? "Add New User" : "Edit User"}</h1>
                    <SelectButtons 
                        isTenant={userItemInAction?.userType === "Tenant"}
                        isDefiningAccess = {isDefiningAccess}
                        setIsDefiningAccess = {setIsDefiningAccess}
                    />
                    {!isDefiningAccess && <AddUserInfo />}
                    {isDefiningAccess && <AddUserAccess />}
                    <div className={styles.save_btn_wrapper}>
                        <Button 
                            customClassName="save_btn"
                            buttonText="Save"
                            handleClick={saveUser}
                        />
                    </div>
                </div>
            </ModalContent>
        </Modal>
    );

}


export default AddEditUser;