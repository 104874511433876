import axios from "axios";
import Cookies from "universal-cookie";
import { config } from "../../../Constants";

export const fetchDiscountAndOtherData = async() => {

    const cookies = new Cookies();

    const endpoint = `${config.url.BACKEND_API_URL}/discounts`;
    const axiosConfig = {
        headers: {
            "auth-token": cookies.get("auth_token")
        }
    }
  
    const response = await axios.get(endpoint, axiosConfig);
    return response.data;

}