import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReportState, FetchedData } from "../../types/pages/Report";

const initialState: ReportState = {
    loading: false,
    error: false,
    categoryList: [],
    locationList: [],
    treatmentList: [],
    stylistList: [],
    tenantInvoiceList: [],
    freelanceStylistInvoiceList: [],
    appointmentList: [],
    clientList: [],
    selectedCategoryList: [],
    selectedLocationList: [],
    selectedTreatmentList: [],
    selectedStylistList: [],
    selectedStartDate: null,
    selectedEndDate: null,
    selectedAppointmentStatuses: [],
    showingTableFor: "client"
}

const reportSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {

        fetchDataLoading: (state) => {
            return { ...state, loading: true }
        },

        fetchDataSuccess: (state, action: PayloadAction<FetchedData>) => {

            const { 
                clientList, 
                locationList, 
                categoryList, 
                treatmentList, 
                stylistList, 
                tenantInvoiceList,
                freelanceStylistInvoiceList,
                appointmentList 
            } = action.payload;

            return {
                ...state,
                loading: false,
                appointmentList,
                clientList,
                locationList: locationList ?? state.locationList,
                categoryList: categoryList ?? state.categoryList,
                treatmentList: treatmentList ?? state.treatmentList,
                stylistList: stylistList ?? state.stylistList,
                tenantInvoiceList: tenantInvoiceList ?? state.tenantInvoiceList,
                freelanceStylistInvoiceList: freelanceStylistInvoiceList ?? state.freelanceStylistInvoiceList
            }

        },

        fetchDataFail: (state) => {
            return {
                ...state,
                loading: false,
                error: true
            }
        },

        handleChange: (state, action: PayloadAction<any>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            }
        }

    }
})

export const {
    fetchDataLoading,
    fetchDataSuccess,
    fetchDataFail,
    handleChange
} = reportSlice.actions;

export default reportSlice.reducer;