import axios from "axios";
import { config } from "../../../Constants";
import { FetchDataQueryParams, FetchedData } from "../../../types/pages/Package";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";

export const fetchPackageApiData = async(payload: FetchDataQueryParams) => {

    const { 
        tenantId,
        searchText,
        skip, 
        limit,
        fetchTreatmentData
    } = payload;

    const newLimit = limit === "All" ? 10000 : limit;

    const params1 = `searchText=${searchText}&skip=${skip}`;
    const params2 = `&limit=${newLimit}&fetchTreatmentData=${fetchTreatmentData}`;
    const queryParams = params1 + params2;

    const authConfig = getAuthTokenConfig();

    const endpoint = config.url.BACKEND_API_URL + `/packages/${tenantId}/private?${queryParams}`;
  
    const response = await axios.get(endpoint, authConfig);

    const data: FetchedData = response.data;

    return data;

}