import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { fillClientInfo, updateState } from "../../../store/calendar/calendarReducer";
import { ClientItem } from "../../../types/pages/Calendar";
import styles from "./AddAppointmentModal.module.css";

interface ClientListProps {
    elementRef: React.RefObject<HTMLDivElement>
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const ClientList: React.FC<ClientListProps> = ({ elementRef, setIsVisible }) => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { clientList } = calendarState;

    const handleClick = (event: React.MouseEvent<HTMLLIElement>, client: ClientItem ) => {
        event.preventDefault();
        dispatch(fillClientInfo(client))
        dispatch(updateState({
            name: "clientList",
            value: []
        }));
        setIsVisible(false);
    }

    return (    
        <div ref={elementRef} className={styles.client_list}>
            <ul>
                {clientList.map(client => {
                    return (
                        <li key={client.email} onClick={(event) => handleClick(event, client)}>
                            {client.name} - {client.phone}
                        </li>
                    );
                })}
            </ul>
        </div>
    );

}

export default ClientList;