import { getFormattedDateTime } from "../../../helpers/date-time/getFormattedDateTime";

interface IProps {
    title: string
    paymentDate: number
    method: string
    amount: number
}

const PaymentInfo: React.FC<IProps> = ({ title, method, amount, paymentDate }) => {

    const { day, month, year, hours, minutes } = getFormattedDateTime(new Date(paymentDate * 1000));

    const paragraphStyle = {
        opacity: "0.7",
        fontWeight: "normal",
        fontSize: "0.7rem"
    }

    return (  
        <tr>
            <td>
                <p>{title}</p>
                <p style = {paragraphStyle}>{`${day} ${month}, ${year} - ${hours}:${minutes}`}</p>
                <p style = {paragraphStyle}>{method}</p>
            </td>
            <td>
                €{amount.toFixed(2)}
            </td>
        </tr>
    );

}

export default PaymentInfo;