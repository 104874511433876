import { useSelector, useDispatch } from "react-redux";
import { IGiftCard } from "../../types/models/GiftCard";
import { RootState } from "../../store";
import { updateState } from "../../store/gift-card/giftCardReducer";
import { FETCH_GIFT_CARD_DATA } from "../../store/gift-card/constants";
import useWindowWidth from "../../hooks/useWindowWidth";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./GiftCardTable.module.css";
import ActionButtons from "./ActionButtons";
import DropdownList from "./DropdownList";
import ResponsiveGiftCardTable from "./ResponsiveGiftCardTable";

const GiftCardTable = () => {

    const dispatch = useDispatch();
    const giftCardState = useSelector((state: RootState) => state.giftCardState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();

    const { giftCardList, totalGiftCardCount, limit } = giftCardState;
    
    const handleClick = (giftCard: IGiftCard) => {
        dispatch(updateState({
            name: "giftCardInAction",
            value: giftCard
        }));
    }

    const handleDropdownChange = (viewItem: { _id: number, limit: number | string }) => {

        if(totalGiftCardCount <= 5) return;

        dispatch(updateState({
            name: "limit",
            value: viewItem.limit
        }));

        setTimeout(() => {
            dispatch({ type: FETCH_GIFT_CARD_DATA });
        }, 10);

    }
  
    return (
        <div className={styles.gift_card_table}> 
            <div className={styles.table_header}>
                <h2>Gift Cards</h2>
                <DropdownList 
                    customClassName="view_dropdown_list"
                    dropdownListFor="view_item"
                    data={[
                        { _id: 1, limit: "All" },
                        { _id: 2, limit: 5 },
                        { _id: 3, limit: 10 }
                    ]}
                    nameKey="limit"
                    selectedValue = {limit}
                    clickHandler={handleDropdownChange}
                />
            </div>
            {
                isLargeDevice
                ?
                <table>
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Buy Date</th>
                            <th>Amount</th>
                            <th>Amount Available</th>
                            <th>Buyer Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {giftCardList.map(giftCard => {

                            const { code, buyDate, amount, usedAmount, buyerInfo } = giftCard;

                            const { day, month, year } = formatDateTime(new Date(buyDate * 1000));

                            return (
                                <tr key={code}>
                                    <td>{code}</td>
                                    <td>{`${day} ${month}, ${year}`}</td>
                                    <td>€{amount.toFixed(2)}</td>
                                    <td>€{(amount - usedAmount).toFixed(2)}</td>
                                    <td>{buyerInfo.fullName}</td>
                                    <td>
                                        <ActionButtons 
                                            handleClick={handleClick}
                                            showButtonText={true}
                                            giftCard={giftCard}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                :
                <ResponsiveGiftCardTable
                    handleClick={handleClick}
                />
            }
        </div>
    );

}

export default GiftCardTable;
