import styles from "./ResponsiveItemDetails.module.css";

interface ItemHeaderProps {
    title: React.ReactNode
    content: React.ReactNode
}

const ItemHeader: React.FC<ItemHeaderProps> = ({ title, content }) => {
  
    return (
        <div className={styles.item_header}>
            <p>{title}</p>
            <p>{content}</p>
        </div>
    );

}

export default ItemHeader;