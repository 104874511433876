import { IUser } from "../../types/models/User";
import { ITreatment } from "../../types/models/Treatment";

type ArgsType = {
    stylistList: IUser[],
    treatmentList: ITreatment[]
}

export const filterTreatmentList = ({ stylistList, treatmentList }: ArgsType) => {

    // If no stylist has been selected then return all treatmentList.
    if(!stylistList.length) return treatmentList;

    const stylistIds = stylistList.map(stylist => stylist._id);

    const newFilteredTreatments: ITreatment[] = [];

    treatmentList.forEach(treatment => {

        for(let x = 0; x < treatment.stylists.length; x++) {

            const treatmentStylist = treatment.stylists[x];

            if(stylistIds.includes(treatmentStylist._id)) {
                newFilteredTreatments.push(treatment);
                break;
            }

        }

    });

    return newFilteredTreatments;

}