import { ICoupon } from "../../types/models/Coupon";
import styles from "./CouponTable.module.css";
import EditIcon from "../../components/icons/EditIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import IconButton from "./IconButton";

interface IProps {
    handleClick: (args: { actionType: string, coupon: ICoupon}) => void
    coupon: ICoupon,
    showButtonText: boolean
}

const ActionButtons: React.FC<IProps> = ({ coupon, showButtonText, handleClick }) => {

    return (
        <div className = {styles.action_buttons}> 
            <IconButton 
                customClassName="edit_icon_button"
                buttonText = {showButtonText ? "Edit" : ""}
                icon = {<EditIcon />}
                handleClick={handleClick}
                args = {{ actionType: "Edit", coupon }}
            />
            <IconButton 
                customClassName="delete_icon_button"
                buttonText = {showButtonText ? "Delete" : ""}
                icon = {<DeleteIcon />}
                handleClick={handleClick}
                args = {{ actionType: "Delete", coupon }}
            />
        </div>            
    );

}

export default ActionButtons;