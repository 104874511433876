import styles from "./LoginSignup.module.css";


const ErrorMessage: React.FC<{ message: string }> = ({ message }) => {

    return (
        <div className = {styles.error_message}>
            <p>{message}</p>
        </div>
    );

}

export default ErrorMessage;