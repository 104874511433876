import { IPolicy } from "./types";
import React from "react";
import useWindowWidth from "../../hooks/useWindowWidth";
import styles from "./PolicyTable.module.css";
import LoadingError from "../../components/common/loading-error/LoadingError";
import ActionButtons from "../../components/common/buttons/ActionButtons";
import ResponsivePolicyTable from "./ResponsivePolicyTable";

interface PolicyTableProps {
    loading: boolean
    errorMsg?: string
    policyList: IPolicy[]
    toggleCreateEditPolicyModal: (policyId: string, actionType: string) => void
}

const PolicyTable: React.FC<PolicyTableProps> = ({ loading, errorMsg, policyList, toggleCreateEditPolicyModal }) => {

    const { devicesWidth } = useWindowWidth();
    const { isLargeDevice } = devicesWidth;

    const handleClick = (policyId: string, actionType: string) => {
        toggleCreateEditPolicyModal(policyId, actionType)
    }

    return (
        <div className={styles.policy_table}> 
            <div className={styles.table_content}>
                {
                    loading
                    ?
                    <LoadingError 
                        message="loading..."
                    />
                    :
                    errorMsg
                    ?
                    <LoadingError 
                        message={errorMsg}
                    />
                    :
                    isLargeDevice
                    ?
                    <table>
                        <thead>
                            <tr>
                                <th>Policy Item</th>
                                <th>Policy Type</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {policyList.map(policy => {
                                return (
                                    <tr key={policy._id}>
                                        <td>{policy.item}</td>
                                        <td>{policy.type}</td>
                                        <td>
                                            <div className={styles.action_buttons_container}>
                                                <ActionButtons 
                                                    actionTypeList={["edit", "delete"]}
                                                    itemId={policy._id}
                                                    showButtonText={true}
                                                    handleClick={handleClick}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    :
                    <ResponsivePolicyTable 
                        policyList={policyList}
                        handleClick={handleClick}
                    />
                }
            </div>
        </div>
    );

}

export default PolicyTable;