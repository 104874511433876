import styles from "./BuyPackage.module.css";

interface InstallmentTableProps {
    installmentList: Record<string, any>[]
    removeInstallment: (dateString: string) => void
}

const InstallmentTable: React.FC<InstallmentTableProps> = ({ installmentList, removeInstallment }) => {

    return (
        
        <div className={styles.installment_table}>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Method</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {installmentList.map(installment => {

                        const { dateString, amount, method } = installment;

                        return (
                            <tr key={dateString}>
                                <td>{dateString}</td>
                                <td>{amount}</td>
                                <td>{method}</td>
                                <td>{method !== "" ? "paid" : "due"}</td>
                                <td>
                                    <button onClick={() => removeInstallment(dateString)}>
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}


export default InstallmentTable;