import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { config } from "../../Constants";
import { decodeJWTToken } from "../../helpers/others/decodeJWTToken";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./index.module.css"

const MailchimpAuthCode: React.FC = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code");

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    useEffect(() => {

        if(!code) return;

        (async function saveTokenInDB() {

            setLoading(true);
            
            const endpoint = config.url.BACKEND_API_URL + "/emails/save-mailchimp-token";
            const requestBody = { mailchimpAuthCode: code };
            const authConfig = getAuthTokenConfig();

            try {

                await axios.post(endpoint, requestBody, authConfig);
                setLoading(false);
                setIsSuccess(true);
                searchParams.delete("code");
                setSearchParams(searchParams);
                
            } catch(error) {
                const { message } = handleApiError(error);
                setLoading(false);
                setErrorMsg(message);
                searchParams.delete("code");
                setSearchParams(searchParams);
            }

        })();

    }, [code, searchParams, setSearchParams])

    const redirectToEmails = () => {
        const user = decodeJWTToken("auth_token");
        if(!user.hasOwnProperty("tenantId")) return;
        window.location.href = `/user/${user?.tenantId}/emails`;
    }

    return (
        <div className={styles.mailchimp_code}>
            {
                loading
                ?
                <p>Integrating mailchimp ....</p>
                :
                errorMsg
                ?
                <p>{errorMsg}</p>
                :
                isSuccess
                ?
                <div className={styles.mailchimp_code_success}>
                    <p>Mailchimp integration successful</p>
                    <button onClick={redirectToEmails}>
                        Back To Emails
                    </button>
                </div>
                :
                <>No action taken</>
            }
        </div>
    );
}

export default MailchimpAuthCode;