import styles from "./DeletePrompt.module.css";


interface DeletePromptProps {
    warningText?: React.ReactNode,
    closeDeletePrompt: () => void,
    deleteItem: () => void
}


const DeletePrompt: React.FC<DeletePromptProps> = ({ warningText, closeDeletePrompt, deleteItem }) => {

    return (
        <div className = {styles.delete_prompt}>
            <div className = {styles.background}></div>
            <div className = {styles.delete_prompt_content}>
                <button 
                    className = {styles.close_btn}
                    onClick={closeDeletePrompt}
                >
                    X
                </button>
                {warningText}
                {/* <p>Are you sure want to delete <span>{warningText}</span>?</p> */}
                <button
                    className = {styles.confirm_button}
                    onClick = {deleteItem}
                >
                    Yes, I am sure
                </button>
            </div>
        </div>
    );

}


export default DeletePrompt;