import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import userReducer from "./user/userReducer";
import tenantProfileReducer from "./profile-tenant/tenantProfileReducer";
import locationReducer from "./locations/locationReducer";
import treatmentReducer from "./treatments/treatmentReducer";
import userListReducer from "./user-list/userListReducer";
import calendarReducer from "./calendar/calendarReducer";
import couponReducer from "./coupons/couponReducer";
import discountReducer from "./discounts/discountReducer";
import reportReducer from "./reports/reportReducer";
import packageReducer from "./packages/packageReducer";
import waitListReducer from "./wait-list/waitListReducer";
import giftCardReducer from "./gift-card/giftCardReducer";
import productReducer from "./products/productReducer";
import appointmentClientReducer from "./appointment-clients/appointmentClientReducer";
import emailReducer from "./emails/emailReducer";

import rootSaga from "../sagas/rootSaga";


export type RootState = ReturnType<typeof store.getState>;

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        userState: userReducer,
        tenantProfileState: tenantProfileReducer,
        locationState: locationReducer,
        treatmentState: treatmentReducer,
        userListState: userListReducer,
        calendarState: calendarReducer,
        couponState: couponReducer,
        discountState: discountReducer,
        reportState: reportReducer,
        packageState: packageReducer,
        waitListState: waitListReducer,
        giftCardState: giftCardReducer,
        productState: productReducer,
        appointmentClientState: appointmentClientReducer,
        emailState: emailReducer
    },
    middleware: [sagaMiddleware]
});


sagaMiddleware.run(rootSaga);


export default store;