import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { closeCheckoutForm } from "../../../store/profile-tenant/tenantProfileReducer";
import styles from "./CheckoutForm.module.css";
import Button from "../common/Button";


const SubSuccessMsg: React.FC<{ isUpdatingSubscription: boolean }> = ({ isUpdatingSubscription }) => {

    const dispatch = useDispatch();
    const tenantProfileState = useSelector((state: RootState) => state.tenantProfileState);
    const { selectedPaymentMethod } = tenantProfileState;

    const handleClick = () => {
        dispatch(closeCheckoutForm());
        setTimeout(() => {
            window.location.reload();
        }, 100)
    } 
    

    return (
        <div className = {styles.sub_success_msg}>
            {
                isUpdatingSubscription
                ?
                <h3>Your subscription plan has been updated successfully</h3>
                :
                selectedPaymentMethod === "Credit Card"
                ?
                <h3>Congratulations! Your subscription is active</h3>
                :
                <h3>Your first subscription payment is under processing. You will receive a confirmation email once it is done</h3>
            }
            <Button 
                customClassName="success_message_close_btn"
                buttonText="OK"
                handleClick={handleClick}
            />
        </div>
    );

}


export default SubSuccessMsg;