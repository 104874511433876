import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./ResponsiveItemDetails.module.css";
import ItemHeader from "./ItemHeader";
import ItemContent from "./ItemContent";
import TenantInvoicePDFButton from "./TenantInvoicePDFButton";

const ResponsiveInvoiceTable: React.FC = () => {

    const reportState = useSelector((state: RootState) => state.reportState);
    const { tenantInvoiceList } = reportState;

    return (
        <>
            {tenantInvoiceList.map(tenantInvoice => {
                const { 
                    _id,
                    createdAt,
                    invoiceNumber,
                    invoiceAmount,
                    invoiceFeeAmount,
                    isPaid
                } = tenantInvoice;

                const { month, year } = formatDateTime(new Date(createdAt * 1000));
                const status = isPaid ? "Paid" : "Not Paid";

                return (
                    <div 
                        key = {_id}
                        className = {styles.responsive_appointment_item}
                    >
                        <ItemHeader 
                            title={invoiceNumber}
                            content={`€${invoiceAmount.toFixed(2)}`}
                        />
                        <ItemContent 
                            title={`${month} ${year}`}
                            content={`€${invoiceFeeAmount.toFixed(2)}`}
                            otherInfo={[status]}
                        />
                        <TenantInvoicePDFButton 
                            tenantInvoice={tenantInvoice}
                        />
                    </div>
                );
            })}
        </>
    );

}

export default ResponsiveInvoiceTable;