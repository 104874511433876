import axios from "axios";
import Cookies from "universal-cookie";
import { config } from "../../../Constants";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { 
    fetchUserDataFail,
    fetchUserDataLoading,
    fetchUserDataSuccess 
} from "../../../store/user/userReducer";
import { 
    addTenantInfo, 
    toggleEditTenantInfoModal, 
    toggleInputValidationError 
} from "../../../store/profile-tenant/tenantProfileReducer";
import { IUser } from "../../../types/models/User";
import { AddressCountry, countryList } from "../../../data/countryList";
import styles from "./EditTenantInfo.module.css";
import EditInfoHeader from "./EditInfoHeader";
import InputField from "../../../components/common/input-fields/InputField";
import DropdownList from "../common/DropdownList";
import CheckboxInputField from "../common/CheckboxInputField";
import Button from "../common/Button";

const EditTenantInfo: React.FC = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const tenantProfileState = useSelector((state: RootState) => state.tenantProfileState);
    const userState = useSelector((state: RootState) => state.userState);
    const user = userState.user as IUser
    const { tenantInfo, validationError } = tenantProfileState;
    const { 
        firstName, 
        lastName, 
        email, 
        phone, 
        companyName, 
        companyAddress,
        allowOnlineDepositPaymentOnly 
    } = tenantInfo;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value, checked, dataset } = event.target;

        if(dataset.type === "tenant_address") {
            dispatch(addTenantInfo({ 
                name: "companyAddress", 
                value: {
                    ...companyAddress,
                    [name]: value
                } 
            }));
            return;
        }

        dispatch(addTenantInfo({ 
            name, 
            value: name === "allowOnlineDepositPaymentOnly" ? checked : value 
        }));
        
    }

    const handleDropdownClick = (country: AddressCountry) => {
        dispatch(addTenantInfo({ 
            name: "companyAddress", 
            value: {
                ...companyAddress,
                country: country.name,
                isoCountryCode: country.isoTwoCharacterCountryCode
            } 
        }));
    }

    const submit = async() => {

        const { addressLine1, addressLine2, city, postalCode, country, isoCountryCode } = companyAddress;

        const isBasicInfoEmpty = !firstName || !lastName || !email || !phone || !companyName;
        const isAddressEmpty = !city || !country || !isoCountryCode || !postalCode;
        
        if(isBasicInfoEmpty || isAddressEmpty) {
            dispatch(toggleInputValidationError());
            return;
        }

        let newCompanyAddress = {
            addressLine1: addressLine1 === "" ? null : addressLine1,
            addressLine2: addressLine2 === "" ? null : addressLine2,
            city,
            postalCode,
            country,
            isoCountryCode
        };
        
        const endpoint = config.url.BACKEND_API_URL + "/users/update-info";
        const headers = { "auth-token": cookies.get("auth_token") };
        const requestBody = {
            userId: user._id,
            firstName, 
            lastName, 
            email, 
            phone, 
            companyName, 
            companyAddress: newCompanyAddress,
            allowOnlineDepositPaymentOnly 
        };

        dispatch(toggleEditTenantInfoModal(null));
        dispatch(fetchUserDataLoading());

        try {

            const response = await axios.post(endpoint, requestBody, { headers });
            dispatch(fetchUserDataSuccess(response.data));

        } catch(error) {
            dispatch(fetchUserDataFail());
        }

    }

    return (
        <div className={styles.edit_tenant_info}>
            <EditInfoHeader />
            <div className={styles.flexbox_input_fields}>
                <InputField 
                    labelText="First Name"
                    name="firstName"
                    value={firstName}
                    required={true}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage = "first name is required"
                />
                <InputField 
                    labelText="Last Name"
                    name="lastName"
                    value={lastName}
                    required={true}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="last name is required"
                />
            </div>
            <div className={styles.flexbox_input_fields}>
                <InputField 
                    labelText="Email"
                    type="email"
                    name="email"
                    value={email}
                    required={true}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="email is required"
                />
                <InputField 
                    labelText="Phone"
                    name="phone"
                    value={phone}
                    required={true}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="phone is required"
                />
            </div>
            <InputField 
                labelText="Salon Name"
                name="companyName"
                value={companyName}
                required={true}
                handleChange={handleChange}
                validationError={validationError}
                validationErrorMessage="salon name is required"
            />
            <InputField 
                labelText="Address Line 1"
                datatype="tenant_address"
                name="addressLine1"
                value={companyAddress.addressLine1}
                handleChange={handleChange}
            />
            <InputField 
                labelText="Address Line 2"
                datatype="tenant_address"
                name="addressLine2"
                value={companyAddress.addressLine2}
                handleChange={handleChange}
            />
            <div className={styles.flexbox_input_fields}>
                <InputField 
                    labelText="City"
                    datatype="tenant_address"
                    name="city"
                    value={companyAddress.city}
                    required={true}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="city is required"
                />
                <InputField 
                    labelText="Postal Code"
                    datatype="tenant_address"
                    name="postalCode"
                    value={companyAddress.postalCode}
                    required={true}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="postal code is required"
                />
            </div>
            <div className={styles.country_dropdown}>
                <label>Country <span>*</span></label>
                <DropdownList 
                    data={countryList}
                    nameKey="name"
                    selectedValue={companyAddress.country}
                    clickHandler={handleDropdownClick}
                />
                {
                    validationError && !companyAddress.country
                    ?
                    <p>Warning message</p>
                    :
                    null
                }
            </div>
            <CheckboxInputField 
                name="allowOnlineDepositPaymentOnly"
                value={"Allow online payment for deposits only"}
                checked={allowOnlineDepositPaymentOnly}
                handleChange={handleChange}
            />
            <div className={styles.save_btn_wrapper}>
                <Button 
                    customClassName="update_info_btn" 
                    buttonText="Update Info"
                    handleClick={submit}
                />
            </div>
        </div>
    );

}

export default EditTenantInfo;
