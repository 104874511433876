import { useSelector, useDispatch } from "react-redux";
import { IUser } from "../../../types/models/User";
import { toggleEditTenantInfoModal } from "../../../store/profile-tenant/tenantProfileReducer";
import { RootState } from "../../../store";
import { MdModeEditOutline } from "react-icons/md";
import styles from "./TenantInfo.module.css";


const CompanyName: React.FC = () => {

    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userState);
    const user = userState.user as IUser;

    const openEditUserInfoModal = (event: React.MouseEvent<HTMLDivElement>) => {
        dispatch(toggleEditTenantInfoModal(user))
    }


    return (
        <div className = {styles.company_name}>
            <h3>{user.companyName ? user.companyName : "No company name"}</h3>
            <div className = {styles.edit_button_container}>
                <div className={styles.edit_button} onClick={openEditUserInfoModal}></div>
                <div className = {styles.edit_icon}>
                    <MdModeEditOutline />
                </div>
                <span>Edit</span>
            </div>
        </div>
    );

}


export default CompanyName;