import styles from "./InputFields.module.css";

interface CustomCheckboxInputProps {
    name: string
    value: string
    classes?: string
    checked: boolean
    args: any
    handleChange: (args: any) => void
}

const CustomCheckboxInput: React.FC<CustomCheckboxInputProps> = (props) => {

    const { 
        name, 
        value, 
        classes = "", 
        checked, 
        args, 
        handleChange 
    } = props;

    return (
        <div className={`${styles.custom_checkbox_wrapper} ${styles[classes]}`}>
            <div className={styles.checkbox_round}>
                <input 
                    name={name}
                    type="checkbox" 
                    readOnly
                    checked={checked}
                />
                <label 
                    htmlFor="custom_checkbox" 
                    onClick={() => handleChange(args)}
                >
                </label>
                <span>{value}</span>
            </div>
        </div>
    );

}

export default CustomCheckboxInput;
