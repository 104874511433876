import styles from "./Button.module.css";

interface SaveButtonProps {
    className?: string
    disabled?: boolean
    buttonText?: string
    onClick: (event: React.MouseEvent<HTMLButtonElement> | SubmitEvent) => void
}

const SaveButton: React.FC<SaveButtonProps> = (props) => {

    const {
        className = "",
        disabled = false,
        buttonText = "Save",
        onClick
    } = props;

    const classes = `${styles.save_button} ${styles[className]}`

    return (
        <div className={classes}>
            <button onClick={onClick} disabled={disabled}>
                {buttonText}
            </button>
        </div>
    );

}

export default SaveButton;
