import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { UserAuth } from "../../auth/UserAuth";
import { FETCH_LOCATION_DATA } from "../../store/locations/constants";
import { 
    updateState,
    closeAddEditLocationModal
} from "../../store/locations/locationReducer";
import styles from "./index.module.css";
import SearchAdd from "./SearchAdd";
import AddEditLocation from "./AddEditLocation";
import LocationTable from "./LocationTable";
import DeleteLocation from "./DeleteLocation";
import ActionMessage from "../../components/common/messages/ActionMessage";
import Modal from "../../components/common/modal/Modal";

const LocationList = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const locationState = useSelector((state: RootState) => state.locationState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { 
        locationDataFetchInfo, 
        isAdding, 
        isEditing, 
        isDeleting, 
        locationItemInAction,
        actionMessage 
    } = locationState;
    const { searchText, skip, limit } = locationDataFetchInfo;

    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // User must be on trial period or must have bought a sub plan to access the locations
        if(!user.stripeSubscription && !user.isOnTrialPeriod) {
            navigate("/");
            return;
        }

        dispatch({ 
            type: FETCH_LOCATION_DATA, 
            payload: { 
                tenantId: user?.tenantId, 
                searchText, 
                skip, 
                limit 
            } 
        });
        
    }, [user?._id]);

    const closeModal = () => {
        if(actionMessage) {
            dispatch(updateState({
                name: "actionMessage",
                value: null
            }));
            return;
        }
        dispatch(closeAddEditLocationModal());
    }
  
    return (
        <div className={styles.locations_page}>
            {
                actionMessage
                &&
                <ActionMessage 
                    actionMessage={actionMessage}
                    hideActionMessage={closeModal}
                />
            }
            <SearchAdd />
            <LocationTable />
            {
                isAdding || isEditing
                ?
                <Modal 
                    modalContent={
                        <AddEditLocation />
                    }
                    onCloseModal={closeModal}
                />
                :
                null
            }
            {(isDeleting && locationItemInAction) && <DeleteLocation />}
        </div>
    );

}


export default LocationList;