import { IbanElement } from "@stripe/react-stripe-js";
import styles from "./StripeElements.module.css";


const SepaDebit: React.FC = () => {

    return (
        <div className = {styles.sepa_element_wrapper}>
            <IbanElement 
                options={{ supportedCountries: ["SEPA"]}}
            />
        </div>
    );

}


export default SepaDebit;