import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import styles from "./index.module.css";
import AppointmentIcon from "../../components/icons/AppointmentIcon";
import ClientIcon from "../../components/icons/ClientIcon";
import PaymentIcon from "../../components/icons/PaymentIcon";
import RevenueIcon from "../../components/icons/RevenueIcon";
import OverviewCard from "./OverviewCard";

const ReportOverview: React.FC = () => {

    const reportState = useSelector((state: RootState) => state.reportState);
    const { clientList, appointmentList } = reportState;

    const onlinePaymentList = useMemo(() => appointmentList.filter(item => {
        return item.isFullyPaidOnline || item.isPartiallyPaidOnline;
    }), [appointmentList]);

    const totalOnlinePayment = useMemo(() => onlinePaymentList.reduce((total, appointment) => {
        if(appointment.isPartiallyPaidOnline) {
            return total + appointment.partiallyPaidAmount;
        }
        return total + appointment.priceAfterDiscount
    }, 0), [onlinePaymentList])

    const totalRevenue = useMemo(() =>appointmentList.reduce((total, appointment) => {
        if(appointment.status !== "Cancelled") {
            return total + appointment.priceAfterDiscount;
        }
        return total;
    }, 0), [appointmentList])
  
    return (
        <div className={styles.report_overview}>
            <OverviewCard 
                icon={<ClientIcon />}
                heading={`${clientList.length}`}
                content="Clients"
            />
            <OverviewCard 
                icon={<AppointmentIcon />}
                iconBgColor="#FFAAA5"
                heading={`${appointmentList.length}`}
                content="Appointments"
            />
            <OverviewCard 
                icon={<PaymentIcon />}
                heading={`€${totalOnlinePayment.toFixed(2)}`}
                content="Online payments"
            />
            <OverviewCard 
                icon={<RevenueIcon />}
                iconBgColor="#FFAAA5"
                heading={`€${totalRevenue.toFixed(2)}`}
                content="Revenue"
            />
        </div>
    );

}


export default ReportOverview;