import { call, put, takeLatest } from "redux-saga/effects";
import { FetchedApiData } from "../types/pages/TenantProfile";
import { fetchData } from "./api/profile-tenant/fetchData";
import { activateSubscription } from "./api/profile-tenant/activateSubscription";
import { 
    fetchDataLoading,
    fetchDataSuccess, 
    fetchDataFail, 
    activateSubFail, 
    activateSubSuccess 
} from "../store/profile-tenant/tenantProfileReducer";
import { ACTIVATE_SUBSCRIPTION, FETCH_DATA } from "../store/profile-tenant/constants";
import { IUser } from "../types/models/User";
import { handleApiError } from "../helpers/error-handlers/handleApiError";

function* handleFetchData() {

    try {
        yield put(fetchDataLoading());
        const result: FetchedApiData  = yield call(fetchData);
        yield put(fetchDataSuccess(result));  
    } catch(error: any) {
        yield put(fetchDataFail());
    }

}

function* handleActivateSubscription(action: { type: string, payload: string }) {

    try {
        const result: IUser  = yield call(activateSubscription, action.payload);
        yield put(activateSubSuccess(result));  
    } catch(error: any) {
        const { message } = handleApiError(error);
        yield put(activateSubFail(message));
    }

}

export default function* tenantProfileSaga() {
    yield takeLatest(FETCH_DATA, handleFetchData);
    yield takeLatest(ACTIVATE_SUBSCRIPTION, handleActivateSubscription);
}

