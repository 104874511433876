import styles from "./Dropdown.module.css";


const DropdownErrorMessage: React.FC<{ errorMessage: string }> = ({ errorMessage }) => {

    return (
        <p className = {styles.error_msg}>
            {errorMessage}
        </p>
    );

}


export default DropdownErrorMessage;