const DownloadIcon: React.FC = () => {

    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 11H2.5V10H9.5V11ZM6 9L3 6L3.705 5.295L5.5 7.085V1H6.5V7.085L8.295 5.295L9 6L6 9Z" fill="#FFAAA5"/>
        </svg>
    );
}


export default DownloadIcon;