import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { UserAuth } from "../../auth/UserAuth";
import { FETCH_DISCOUNT_AND_OTHER_DATA } from "../../store/discounts/constants";
import { handleChange, closeAddEditDiscount, toggleActivationStatus } from "../../store/discounts/discountReducer";
import useWindowWidth from "../../hooks/useWindowWidth";
import styles from "./index.module.css";
import Modal from "../../components/common/modal/Modal";
import AddDiscountButton from "./AddDiscountButton";
import DiscountTitle from "./DiscountTitle";
import SelectLocation from "./SelectLocation";
import SelectStylist from "./SelectStylist";
import SelectTreatment from "./SelectTreatment";
import LastMinuteDiscount from "./LastMinuteDiscount";
import OffPeakDiscount from "./OffPeakDiscount";
import SaveButton from "./SaveButton";
import CloseDiscountButton from "./CloseDiscountButton";
import DeleteDiscountPrompt from "./DeleteDiscountPrompt";
import ActionMessage from "../../components/common/messages/ActionMessage";
import DiscountTable from "./DiscountTable";
import ToggleDiscountStatus from "./ToggleDiscountStatus";

const TimeDiscount = () => {

    const navigate = useNavigate();
    const { windowWidth } = useWindowWidth();

    const dispatch = useDispatch();
    const discountState = useSelector((state: RootState) => state.discountState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { 
        actionMessage,
        isAddingUpdatingDiscount,
        isTogglingStatus,
        showDeletePrompt,
        discount,
        discountId 
    } = discountState;

    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // User must be on trial period or must have bought a sub plan to access the locations
        if(!user.stripeSubscription && !user.isOnTrialPeriod) {
            navigate("/");
            return;
        }

        dispatch({ type: FETCH_DISCOUNT_AND_OTHER_DATA });
        
    }, [user?._id]);

    const closeModal = () => {
        if(isTogglingStatus) {
            dispatch(toggleActivationStatus(null));
            return;
        }
        dispatch(closeAddEditDiscount())
    }

    const hideActionMessage = () => {
        dispatch(handleChange({
            name: "actionMessage",
            value: ""
        }));
    }
  
    return (
        <div className={styles.time_discounts}>
            {
                actionMessage !== ""
                ?
                <ActionMessage 
                    actionMessage = {actionMessage}
                    hideActionMessage = {hideActionMessage} 
                />
                :
                null
            }
            {
                isAddingUpdatingDiscount
                ?
                <Modal 
                    minWidth={windowWidth <= 800 ? "98%" : "50%"}
                    modalContent={
                        <div className = {styles.add_discount}>
                            <DiscountTitle />
                            <SelectLocation />
                            <SelectStylist />
                            <LastMinuteDiscount />
                            <OffPeakDiscount />
                            <SelectTreatment />
                            <div className = {styles.save_close_buttons}>
                                <SaveButton />
                                <CloseDiscountButton />
                            </div>
                        </div>
                    }
                    onCloseModal={closeModal}
                />
                :
                null
            }
            {
                (isTogglingStatus && discount)
                ?
                <Modal 
                    minHeight="40%"
                    modalContent={
                        <ToggleDiscountStatus />
                    }
                    onCloseModal={closeModal}
                />
                :
                null
            }
            <AddDiscountButton />
            <DiscountTable />
            {(showDeletePrompt && discountId) ? <DeleteDiscountPrompt /> : null}
        </div>
    );

}

export default TimeDiscount;