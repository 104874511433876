import { useRoutes } from "react-router-dom";
import UserLayout from "./layouts";
import Signup from "./pages/signup";
import Login from "./pages/login";
import ResetPassword from "./pages/reset-password";
import TenantProfile from "./pages/profile-tenant";
import UserProfile from "./pages/profile-user";
import LocationList from "./pages/locations";
import TreatmentList from "./pages/treatments";
import UserList from "./pages/user-list";
import Calendar from "./pages/calendar";
import CouponList from "./pages/coupons";
import TimeDiscount from "./pages/discounts";
import Reports from "./pages/reports";
import PackageList from "./pages/packages";
import WaitList from "./pages/wait-list";
import GiftCardList from "./pages/gift-card";
import ProductList from "./pages/products";
import AppointmentClientList from "./pages/appointment-clients";
import Emails from "./pages/emails";
import MailchimpAuthCode from "./pages/emails/MailchimpAuthCode";
import Integrations from "./pages/integrations";
import StripeRefreshUrl from "./pages/integrations/StripeRefreshUrl";
import StripeReturnUrl from "./pages/integrations/StripeReturnUrl";
import PolicyList from "./pages/policies";


function Router() {

    const routesConfig = [
        { path: "/", element: <Login /> },
        { path: "/signup", element: <Signup /> },
        { path: "/reset-password", element: <ResetPassword /> },
        { path: "/mailchimp-code", element: <MailchimpAuthCode /> },
        { 
            path: "/user/:tenantId/integrations/stripe-return/:accountId", 
            element: <StripeReturnUrl /> 
        },
        { 
            path: "/user/:tenantId/integrations/stripe-refresh/:accountId", 
            element: <StripeRefreshUrl /> 
        },
        {
            element: <UserLayout />,
            children: [
                { 
                    path: "/user/:tenantId/profile-tenant", 
                    element: <TenantProfile /> 
                },
                { 
                    path: "/user/:tenantId/profile-user", 
                    element: <UserProfile /> 
                },
                { 
                    path: "/user/:tenantId/location-list", 
                    element: <LocationList /> 
                },
                { 
                    path: "/user/:tenantId/emails", 
                    element: <Emails /> 
                },
                { 
                    path: "/user/:tenantId/treatment-list", 
                    element: <TreatmentList /> 
                },
                { 
                    path: "/user/:tenantId/user-list", 
                    element: <UserList /> 
                },
                { 
                    path: "/user/:tenantId/appointment-client-list", 
                    element: <AppointmentClientList /> 
                },
                { 
                    path: "/user/:tenantId/coupon-list", 
                    element: <CouponList /> 
                },
                { 
                    path: "/user/:tenantId/discount-list", 
                    element: <TimeDiscount /> 
                },
                { 
                    path: "/user/:tenantId/package-list", 
                    element: <PackageList /> 
                },
                { 
                    path: "/user/:tenantId/reports", 
                    element: <Reports /> 
                },
                { 
                    path: "/user/:tenantId/calendar", 
                    element: <Calendar /> 
                },
                { 
                    path: "/user/:tenantId/wait-list", 
                    element: <WaitList /> 
                },
                { 
                    path: "/user/:tenantId/gift-card-list", 
                    element: <GiftCardList /> 
                },
                { 
                    path: "/user/:tenantId/product-list", 
                    element: <ProductList /> 
                },
                { 
                    path: "/user/:tenantId/integrations", 
                    element: <Integrations /> 
                },
                { 
                    path: "/user/:tenantId/policies", 
                    element: <PolicyList /> 
                }
            ]
        }
    ]

    const routes = useRoutes(routesConfig);
    return routes;

}


export default Router;


// Notes About react-router-dom v6
// 1. Switch is no longer available.
// 2. exact is not needed. 
// 3. <Outlet /> component is like children prop of react. It should be used inside layout components to 
// have effect. Like in the above routes array's 3rd object has an element property. this element
// property takes layout components as value. 