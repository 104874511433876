// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
import { useState, useEffect, useRef } from "react";

function useClickOutside(args: { exceptions: string[] }) {

    const { exceptions } = args;

    const elementRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);


    const handleClickOutside = (event: any) => {

        // if elementRef is true (has been used as a ref to an element) and event.target is not the parent element
        // (clicking on which cause the hidden child element to appear) then hide the child element again
        if(elementRef.current && !elementRef.current.contains(event.target)) setIsVisible(false);

        // exceptions here are ids of the elements that will not hide the popup/modal when clicked.
        if(exceptions) {

            // We need to get all the parent element's ids of the clicked element to find if
            // any of the id is matching with any of the exception. if match found then
            // clicking on it will not hide the popup/modal
            let eventTarget = event.target;
            const eventTargetParentIds: string[] = [];

            // We are changing eventTarget on each iteration to break out of the loop finally.
            // Because there can't be infinite number of parent elements of an element.
            while(eventTarget) {
                eventTargetParentIds.push(eventTarget.id);
                eventTarget = eventTarget.parentElement;
            }

            for(let x = 0; x < exceptions.length; x++) {
                const matchedException = eventTargetParentIds.find(item => item === exceptions[0]);
                if(matchedException) setIsVisible(true);
            }
            
        }

    };
    
    useEffect(() => {

        // Adding eventListener inside useEffect hook was very confusing to me. Without any dependency inside useEffect's 
        // dependency array it runs only once - on component mount. So eventListener will be attached to the document just
        // once and won't be available on subsequent re-renders. For this instance - without showDropdown as a dependency
        // click event on document will not be fired at all, which means clicking outside(on document) will not fire the event
        // listener, as it was only available just once. But whenever value of showDropdown changes useEffect will run again 
        // and re-attach eventListener to the document
        document.addEventListener("click", handleClickOutside);

        // Clear event listener on component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
        
    }, [isVisible]);


    return { elementRef, isVisible, setIsVisible };
}


export default useClickOutside;