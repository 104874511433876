import axios from "axios";
import Cookies from "universal-cookie";
import { config } from "../../Constants";


export interface RemoveFileArgsType {
    url: string,
    filePath: string,
    filename: string
}

export interface RemoveFileReturnType {
    success: boolean
}

const cookies = new Cookies();

export const removeFile = async(args: RemoveFileArgsType): Promise<RemoveFileReturnType> => {

    const { url, filePath, filename } = args;

    const endpoint = `${config.url.IMAGE_API_URL}${url}?filePath=${filePath}&filename=${filename}` ;
    const axiosConfig = {
        headers: { 
            "auth-token": cookies.get("auth_token")
        },
    };

    try {

        const response = await axios.delete(endpoint, axiosConfig );

        // result is an object - { success: true }
        const result: RemoveFileReturnType = response.data;
        return result;

    } catch(error) {
        return { success: false };
    }
    
}