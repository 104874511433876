import { useSelector } from "react-redux";
import { RootState } from "../../store";
import styles from "./DiscountTable.module.css";
import ActionButtons from "../../components/common/buttons/ActionButtons";

interface ResponsiveDiscountTableProps {
    handleClick: (productId: string, actionType: string) => void
}

const ResponsiveDiscountTable: React.FC<ResponsiveDiscountTableProps> = ({ handleClick }) => {

    const discountState = useSelector((state: RootState) => state.discountState);
    const { discountList } = discountState;
   
    return (
        <div className={styles.responsive_product_table}>
            {discountList.map(discount => {

                const { _id, title, locationList, stylistList, isActive } = discount;
                
                return (
                    <div 
                        key={_id}
                        className={styles.responsive_item}
                    >
                        <div className={styles.responsive_item_header}>
                            <p className={styles.responsive_item_name}>{title}</p>
                            <ActionButtons 
                                actionTypeList={["toggle", "edit", "delete"]}
                                itemId={_id}
                                showButtonText={false}
                                handleClick={handleClick}
                            />
                        </div>
                        <div className={styles.responsive_other_info}>
                            <p>Locations: {locationList.map(item => item.title).join(", ")}</p>
                            <p>Stylists: {stylistList.map(item => item.fullName).join(", ")}</p>
                            <p>{isActive ? "Active" : "Inactive"}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );

}

export default ResponsiveDiscountTable;
