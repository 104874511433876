import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../types/models/User";
import { UserListState, NewWorkSchedule } from "../../types/pages/UserList";

const initialState: UserListState = {
    loading: false,
    error: false,
    validationError: false,
    locationList: [],
    userList: [],
    userItemInAction: null,
    newUserInfo: {
        fullName: "",
        email: "",
        phone: "",
        password: "",
        password2: ""
    },
    userPhoto: null,
    userType: "Admin",
    isFreelanceStylist: false,
    userAccessItems: [],
    newWorkSchedule: [],
    userDataFetchInfo: {
        searchText: "",
        skip: 0, 
        limit: 5,
        totalUserCount: 0
    },
    isAdding: false,
    isEditing: false,
    isDeleting: false,
    actionMessage: ""
}

const userListSlice = createSlice({
    name: "user-list",
    initialState,
    reducers: {

        fetchUserListDataLoading: (state) => {
            return { ...state, loading: true }
        },

        fetchUserListDataSuccess: (state, action: PayloadAction<any>) => {

            const { totalUserCount, userList, locationList } = action.payload;

            return {
                ...state,
                loading: false,
                userDataFetchInfo: {
                    ...state.userDataFetchInfo,
                    totalUserCount
                },
                userList,
                locationList: state.locationList.length ? state.locationList : locationList
            }

        },

        fetchUserListDataFail: (state) => {
            return {
                ...state,
                loading: false,
                error: true
            }
        },

        updateState: (state, action: PayloadAction<{ name: string, value: any }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            }
        },

        updateSearchText: (state, action: PayloadAction<string>) => {

            return {
                ...state,
                userDataFetchInfo: {
                    ...state.userDataFetchInfo,
                    searchText: action.payload
                }
            }

        },

        updateViewLimit: (state, action: PayloadAction<string | number>) => {
            return {
                ...state,
                userDataFetchInfo: {
                    ...state.userDataFetchInfo,
                    limit: action.payload
                }
            }
        },

        addNewUserInfo: (state, action: PayloadAction<{ name: string, value: string }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                newUserInfo: {
                    ...state.newUserInfo,
                    [name]: value
                }
            }
        },

        uploadUserPhoto: (state, action: PayloadAction<File>) => {
            return {
                ...state,
                userPhoto: action.payload
            }
        },

        setUserType: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                userType: action.payload
            }
        },

        addRemoveWorkSchedule: (state, action: PayloadAction<NewWorkSchedule[]>) => {
            return {
                ...state,
                newWorkSchedule: action.payload
            }
        },

        openAddUserModal: (state) => {
            return { ...state, isAdding: true }
        },

        closeAddUserModal: (state) => {
            return { 
                ...state, 
                isAdding: false,
                newUserInfo: {
                    fullName: "",
                    email: "",
                    phone: "",
                    password: "",
                    password2: ""
                },
                userType: "Admin",
                isFreelanceStylist: false,
                newWorkSchedule: [],
                userPhoto: null 
            }
        },

        // If userType is Admin then he/she will not have workSchedule property. So we need to check that and update
        // the newWorkSchedule state with an empty array. We also need to update userType state because if userType
        // is Stylist then we need to show her existing workSchedule. Admin and Stylist will always have accessType,
        // but we are still doing a check to make sure everything works smoothly
        openEditUserModal: (state, action: PayloadAction<IUser>) => {
            const userItem = action.payload;
            const { userType, fullName, email, phone, workSchedule, isStylist, isFreelanceStylist } = userItem;
            return { 
                ...state, 
                isEditing: true,
                userItemInAction: userItem,
                newUserInfo: { 
                    ...state.newUserInfo,
                    fullName, 
                    email, 
                    phone 
                },
                userType: (userType === "Tenant" && isStylist) ? "Stylist" : userType,
                isFreelanceStylist,
                newWorkSchedule: (workSchedule && workSchedule.length) ? workSchedule : []
            }
        },

        closeEditUserModal: (state) => {
            return { 
                ...state, 
                isEditing: false,
                userItemInAction: null,
                newUserInfo: {
                    fullName: "",
                    email: "",
                    phone: "",
                    password: "",
                    password2: ""
                },
                userType: "Admin",
                isFreelanceStylist: false,
                newWorkSchedule: [],
                userPhoto: null 
            }
        },

        toggleDeletePrompt: (state, action: PayloadAction<null | IUser>) => {
            return {
                ...state,
                userItemInAction: action.payload,
                isDeleting: !state.isDeleting
            }
        },

        updateActionMessage: (state, action: PayloadAction<string>) => {
            return { ...state, actionMessage: action.payload }
        }

    }
})

export const {
    fetchUserListDataLoading,
    fetchUserListDataSuccess,
    fetchUserListDataFail,
    updateState,
    updateSearchText,
    updateViewLimit,
    addNewUserInfo,
    uploadUserPhoto,
    setUserType,
    addRemoveWorkSchedule,
    openAddUserModal,
    closeAddUserModal,
    openEditUserModal,
    closeEditUserModal,
    toggleDeletePrompt,
    updateActionMessage
} = userListSlice.actions;

export default userListSlice.reducer;