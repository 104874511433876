import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { updateState, updateSearchText, openAddUserModal } from "../../store/user-list/userListReducer";
import { FETCH_USER_LIST_DATA } from "../../store/user-list/constants";
import { getUserAccessItems } from "../../helpers/user-list/getUserAccessItems";
import styles from "./index.module.css";
import InputField from "./InputField";
import Button from "./Button";


const SearchAdd = () => {

    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const userListState = useSelector((state: RootState) => state.userListState);
    const { userList, userDataFetchInfo } = userListState;
    const { searchText, skip, limit } = userDataFetchInfo;
    const [isSearchTextBackspacedToEmpty, setIsSearchTextBackspacedToEmpty] = useState(false);
    

    useEffect(() => {

        if(!isSearchTextBackspacedToEmpty && !searchText) return; 
      
        // Calling setTimeout directly will make api call for each character typed. But assigning
        // this function to a variable re-initializes the timer. So, just one api call will be made
        // when user stops typing.
        const delayAPICall = setTimeout(() => {

            dispatch({ 
                type: FETCH_USER_LIST_DATA,
                payload: { 
                    tenantId: user?.tenantId, 
                    searchText, 
                    skip, 
                    limit,
                    fetchLocationData: "No" 
                } 
            })

        }, 500);

        if(isSearchTextBackspacedToEmpty) setIsSearchTextBackspacedToEmpty(false);

        return () => clearTimeout(delayAPICall);

    }, [searchText])


    const searchUser = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        dispatch(updateSearchText(value))
        if(!value) setIsSearchTextBackspacedToEmpty(true);
    }

    const checkUserLimit = (): boolean => {

        const subPlanId = user?.stripeSubscription?.subscriptionPlan.subscriptionId;

        if(
            (subPlanId === 100 && userList.length >= 1) ||
            (subPlanId === 101 && userList.length >= 3) ||
            (subPlanId === 102 && userList.length >= 25)
        ) return true;
       
        return false;

    }

    const openModal = () => {

        // If user is on trial period he/she can create as many users as he/she likes. But if not on trial period
        // anymore, can create as per the limit of bought subscription plan 
        if(!user?.isOnTrialPeriod) {
            const isUserLimitCrossed = checkUserLimit();
            if(isUserLimitCrossed) {
                const message = "You have already created all the users as per your subscription plan limit";
                return alert(message);
            } 
        }

        const defaultUserAccessItems = getUserAccessItems();

        dispatch(updateState({
            name: "userAccessItems",
            value: defaultUserAccessItems
        }))

        dispatch(openAddUserModal());

    } 
   
    return (
        <div className={styles.search_add_btn}>
            <InputField
                customClassName="search_input" 
                type="text"
                name="search"
                value={searchText}
                placeholder="Search"
                handleChange={searchUser}
            />
            <Button
                customClassName="add_btn"
                buttonText="Add New User"
                handleClick={openModal}
            />
        </div>
    );

}


export default SearchAdd;