import { AccessItem } from "../../types/pages/UserList";

export const modifyUserAccessItems = (userAccessItems: AccessItem[]) => {

    const canMaintainAllAgendas = userAccessItems[2].keyValue;
    const canMaintainOthersClientDetails = userAccessItems[14].keyValue;
    const canMaintainOwnClientDetails = userAccessItems[13].keyValue;

   const modifiedUserAccessItems = userAccessItems.map(item => {

        if(canMaintainAllAgendas  && item.groupName ===  "agendas") {
            return {
                ...item,
                keyValue: true
            }
        }

        if(canMaintainOthersClientDetails && item.groupName === "clients") {
            return {
                ...item,
                keyValue: true
            }
        }

        if(canMaintainOwnClientDetails && !canMaintainOthersClientDetails && item.keyName === "canViewOwnClientDetails") {
            return {
                ...item,
                keyValue: true
            }
        }

        return item;
        
    });

    return modifiedUserAccessItems;

}

