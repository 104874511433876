import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { updateState } from "../../store/calendar/calendarReducer";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import DeletePrompt from "../../components/common/prompts/DeletePrompt";

const DeleteAppointment = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { appointmentInAction, appointmentList } = calendarState;


    const closeDeletePrompt = () => {
        dispatch(updateState({
            name: "isDeleting",
            value: false
        }));
    }

    const deleteTimeBlockAppointment = async() => {

        if(!appointmentInAction) return;

        const appointmentId = appointmentInAction._id;

        dispatch(updateState({
            name: "isDeleting",
            value: false
        }));
        
        dispatch(updateState({
            name: "appointmentInAction",
            value: null
        }));

        dispatch(updateState({
            name: "serverResponseMessage",
            value: "Deleting..."
        }));

        const endpoint = config.url.BACKEND_API_URL + "/appointments/delete/" + appointmentId;
        const axiosConfig = {
            headers: {
                "auth-token": cookies.get("auth_token")
            }
        }

        try {

            // Axios delete method is unique. Request body must be specified with data property.  
            const response = await axios.delete(endpoint, axiosConfig);

            const filteredAppointmentList = appointmentList.filter(item => item._id !== appointmentId);

            dispatch(updateState({
                name: "appointmentList",
                value: filteredAppointmentList
            }))

            dispatch(updateState({
                name: "serverResponseMessage",
                value: response.data
            }));

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(updateState({
                name: "serverResponseMessage",
                value: message
            }));
        }

    }
    
    return (
        <>
            <DeletePrompt 
                warningText={<p>Are you sure want to delete <span>{appointmentInAction?.treatmentTitle}</span></p>}
                closeDeletePrompt={closeDeletePrompt}
                deleteItem={deleteTimeBlockAppointment}
            />
        </>
    );

}


export default DeleteAppointment;