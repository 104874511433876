import axios from "axios";
import Cookies from "universal-cookie";
import { IWaitList } from "../../../types/models/WaitList";
import { config } from "../../../Constants";

export const fetchWaitListData = async() => {

    const cookies = new Cookies();

    const headers = { "auth-token": cookies.get("auth_token") };
    const endpoint = config.url.BACKEND_API_URL + `/wait-list`;
  
    const response = await axios.get(endpoint, { headers });

    const data: IWaitList[] = response.data.waitList;

    return data;

}