import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./DataTable.module.css";
import ActionButtons from "../../components/common/buttons/ActionButtons";

interface ResponsivePOTableProps {
    handleClick: (actionType: string, purchaseOrderId: string ) => void
}

const ResponsivePOTable: React.FC<ResponsivePOTableProps> = ({ handleClick }) => {

    const productState = useSelector((state: RootState) => state.productState);
    const { purchaseOrderList } = productState;
   
    return (
        <div className={styles.responsive_product_table}>
            {purchaseOrderList.map(po => {

                const { _id, createdAt, issuedToPartyName, productList, productsReceived } = po;

                const { day, month, year } = formatDateTime(new Date(createdAt * 1000));
                
                return (
                    <div key={_id} className={styles.responsive_item}>
                        <div className={styles.responsive_item_header}>
                            <p className={styles.responsive_item_name}>{issuedToPartyName}</p>
                            <ActionButtons 
                                actionTypeList={["receive"]}
                                itemId={_id}
                                showButtonText={false}
                                disableReceiveButton={productsReceived}
                                handleClick={handleClick}
                            />
                        </div>
                        <div className={styles.responsive_other_info}>
                            <p>PO Date: {`${month} ${day}, ${year}`}</p>
                            <p>Num of products: {productList.length}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );

}

export default ResponsivePOTable;
