import styles from "./InputFields.module.css";

interface InputFieldProps {
    customClassName?: string
    withMarginBottom?: boolean
    labelText?: string
    datatype?: string
    type?: string
    name: string
    placeholder?: string
    autoComplete?: string
    value: string
    disabled?: boolean
    required?: boolean
    validationError?: boolean
    validationErrorMessage?: string
    args?: any
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, args?: any) => void
}

const InputField: React.FC<InputFieldProps> = (props) => {

    const {
        customClassName = "",
        withMarginBottom = true,
        labelText,
        datatype,
        type = "text", 
        name, 
        placeholder,
        autoComplete = "on", 
        value, 
        disabled = false,
        required = false,
        validationError,
        validationErrorMessage,
        args,
        handleChange
    } = props;

    const classes = `${!withMarginBottom ? "no_margin_bottom" : ""}`;

    return (
        <div className={`${styles.input_field} ${styles[customClassName]} ${styles[classes]}`}>
            {
                labelText 
                ? 
                <label className={styles.label}>
                    {labelText} {required ? <span>*</span> : null}
                </label> 
                : 
                null
            }
            <div className={styles.input_container}>
                <input
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    value={value}
                    disabled={disabled}
                    data-type={datatype}
                    onChange={(event) => handleChange(event, args)}
                />
            </div>
            {
                (validationError && !value) 
                ?
                <p className={styles.input_val_error_msg}>
                    {validationErrorMessage}
                </p>
                :
                null
            }
        </div>
    );

}

export default InputField;
