const LogoutIcon: React.FC = () => {

    return (
        <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0922 25.0001H3.27318C2.40485 25.0001 1.57229 24.6554 0.958504 24.0416C0.344738 23.4279 0 22.5953 0 21.7273V4.27132C0 3.40337 0.344748 2.57082 0.958504 1.95704C1.57227 1.34327 2.40486 0.998535 3.27318 0.998535H13.0922C13.9605 0.998535 14.7931 1.34328 15.4069 1.95704C16.0206 2.5708 16.3654 3.40339 16.3654 4.27132V8.09012C16.3654 8.69247 15.8768 9.18102 15.2745 9.18102C14.6717 9.18102 14.1836 8.69245 14.1836 8.09012V4.27132C14.1836 3.98213 14.0686 3.70474 13.8639 3.50003C13.6592 3.29531 13.3818 3.1804 13.0923 3.1804H3.27325C2.67052 3.1804 2.18195 3.66897 2.18195 4.2713V21.7273C2.18195 22.0165 2.29687 22.2939 2.50158 22.4986C2.7063 22.7033 2.98368 22.8182 3.27326 22.8182H13.0923C13.3818 22.8182 13.6592 22.7033 13.8639 22.4986C14.0687 22.2939 14.1836 22.0165 14.1836 21.7273V17.9085C14.1836 17.3062 14.6718 16.8176 15.2745 16.8176C15.8768 16.8176 16.3654 17.3062 16.3654 17.9085V21.7273C16.3654 22.5953 16.0206 23.4278 15.4069 24.0416C14.7931 24.6554 13.9605 25.0001 13.0922 25.0001Z" fill="#99B2C6"/>
            <path d="M22.3664 14.0905H8.08504C7.48232 14.0905 6.99414 13.6023 6.99414 12.9996C6.99414 12.3969 7.48233 11.9087 8.08504 11.9087H22.3664C22.9688 11.9087 23.4573 12.3969 23.4573 12.9996C23.4573 13.6023 22.9688 14.0905 22.3664 14.0905Z" fill="#99B2C6"/>
            <path d="M19.6374 17.3629C19.1975 17.361 18.8018 17.095 18.6336 16.6886C18.4653 16.2822 18.5578 15.8146 18.868 15.5025L21.3663 12.9988L18.8626 10.495C18.4364 10.0685 18.4364 9.37743 18.8626 8.95126C19.2888 8.52471 19.9802 8.52471 20.4064 8.95126L23.6796 12.2245V12.2241C23.8843 12.4288 23.9992 12.7066 23.9992 12.9961C23.9992 13.2857 23.8843 13.5635 23.6796 13.7678L20.4064 17.041C20.2028 17.2457 19.9262 17.3617 19.6374 17.3629Z" fill="#99B2C6"/>
        </svg>
    );

}

export default LogoutIcon;


