import { hours } from "../../data/calendar/hours";
import styles from "./Calendar.module.css";

const Hours: React.FC = () => {

    return (
        <div className={styles.hours_container}>
            <div className={styles.hour_blank}></div>
            {hours.map(item => {
                return(
                    <div className = {styles.hour} key = {item._id}>
                        {item.hour}
                    </div>
                );
            })}
        </div>
    );

}

export default Hours;