import { call, put, takeLatest } from "redux-saga/effects";
import { FetchedData } from "../types/pages/Discount";
import { 
    fetchDataLoading, 
    fetchDataSuccess, 
    fetchDataFail, 
} from "../store/discounts/discountReducer";
import { fetchDiscountAndOtherData } from "./api/discounts/fetchDiscountAndOtherData";
import { FETCH_DISCOUNT_AND_OTHER_DATA } from "../store/discounts/constants";

function* handleFetchData() {

    try {
        yield put(fetchDataLoading());
        const result: FetchedData  = yield call(fetchDiscountAndOtherData);
        yield put(fetchDataSuccess(result));  
    } catch(error: any) {
        yield put(fetchDataFail());
    }

}

export default function* discountSaga() {
    yield takeLatest(FETCH_DISCOUNT_AND_OTHER_DATA, handleFetchData);
}

