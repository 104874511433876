import styles from "./EditTenantInfo.module.css";


const EditInfoHeader: React.FC = () => {

    return (
        <div className = {styles.edit_user_info_header}>
            <h1>Edit Profile</h1>
            <p>It will take a couple of minutes</p>
            <p>Change your profile settings</p>
            <h3>Your personal data</h3>
        </div>
    );

}


export default EditInfoHeader;