const SellIcon: React.FC = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
            <path d="M21.9153 6.21612H19.6004L16.2086 0.956738C15.9732 0.593097 15.4884 0.488343 15.1235 0.722543C14.7599 0.956738 14.654 1.44277 14.8893 1.80762L17.7315 6.2162H6.26518L9.1073 1.80762C9.3415 1.44398 9.23676 0.957931 8.87311 0.722543C8.50947 0.488349 8.02342 0.593088 7.78803 0.956738L4.39867 6.21612H2.08379C1.44475 6.21612 0.850421 6.50327 0.452646 7.00345C0.0548707 7.50362 -0.0898926 8.14736 0.0548707 8.76992L2.61219 19.7912C2.83227 20.7409 3.66666 21.4035 4.64111 21.4035H19.3591C20.3335 21.4035 21.1679 20.7398 21.388 19.7912L23.9453 8.76871C24.0901 8.14616 23.9441 7.50242 23.5475 7.00224C23.1486 6.50327 22.5531 6.21612 21.9153 6.21612ZM22.4154 8.41568L19.8581 19.4382C19.804 19.6724 19.598 19.8359 19.3568 19.8359H4.64002C4.39994 19.8359 4.19398 19.6724 4.13867 19.4382L1.58134 8.41568C1.53191 8.20502 1.62135 8.05204 1.67902 7.97907C1.73669 7.9061 1.86614 7.78489 2.0815 7.78489H21.9127C22.1293 7.78489 22.2575 7.9061 22.3152 7.97907C22.3752 8.05203 22.4649 8.20503 22.4154 8.41568Z" fill="#FFAAA5"/>
            <path d="M11.9988 9.84766C11.5658 9.84766 11.2139 10.1984 11.2139 10.6326V16.9878C11.2139 17.4208 11.5646 17.7727 11.9988 17.7727C12.4319 17.7727 12.7838 17.422 12.7838 16.9878V10.6326C12.7838 10.1995 12.4319 9.84766 11.9988 9.84766Z" fill="#FFAAA5"/>
            <path d="M17.4903 9.86003C17.0631 9.79059 16.6582 10.0754 16.5853 10.5014L15.5025 16.8565C15.4296 17.2837 15.7167 17.6886 16.1439 17.7615C16.1886 17.7686 16.2334 17.7721 16.2769 17.7721C16.6523 17.7721 16.9842 17.5015 17.0501 17.119L18.1328 10.7638C18.2046 10.3378 17.9175 9.933 17.4903 9.86003Z" fill="#FFAAA5"/>
            <path d="M7.41221 10.5017C7.33925 10.0745 6.93204 9.78973 6.50721 9.86035C6.08 9.93332 5.79285 10.3381 5.86582 10.7654L6.94855 17.1205C7.01327 17.5029 7.34515 17.7736 7.72174 17.7736C7.76529 17.7736 7.81001 17.7701 7.85473 17.7631C8.28194 17.6901 8.56909 17.2853 8.49612 16.8581L7.41221 10.5017Z" fill="#FFAAA5"/>
        </svg>
    );

}

export default SellIcon;
