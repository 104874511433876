const EditIcon: React.FC = () => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_166_8036)">
                <mask id="mask0_166_8036"  maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <path d="M0 0H24V24H0V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_166_8036)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M21.707 7.29308C21.8945 7.48061 21.9998 7.73492 21.9998 8.00008C21.9998 8.26525 21.8945 8.51955 21.707 8.70708L8.707 21.7071C8.51951 21.8946 8.26519 22 8 22.0001H4C3.73478 22.0001 3.48043 21.8947 3.29289 21.7072C3.10536 21.5197 3 21.2653 3 21.0001V17.0001C3.00006 16.7349 3.10545 16.4806 3.293 16.2931L16.293 3.29308C16.3852 3.19757 16.4956 3.12139 16.6176 3.06898C16.7396 3.01657 16.8708 2.98898 17.0036 2.98783C17.1364 2.98668 17.2681 3.01198 17.391 3.06226C17.5138 3.11254 17.6255 3.18679 17.7194 3.28069C17.8133 3.37458 17.8875 3.48623 17.9378 3.60913C17.9881 3.73202 18.0134 3.8637 18.0123 3.99648C18.0111 4.12926 17.9835 4.26048 17.9311 4.38249C17.8787 4.50449 17.8025 4.61483 17.707 4.70708L5 17.4141V20.0001H7.586L20.293 7.29308C20.4805 7.10561 20.7348 7.00029 21 7.00029C21.2652 7.00029 21.5195 7.10561 21.707 7.29308Z" fill="#FFAAA5"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.293 3.29303C16.4805 3.10556 16.7348 3.00024 17 3.00024C17.2651 3.00024 17.5194 3.10556 17.707 3.29303L21.707 7.29303C21.8891 7.48163 21.9899 7.73424 21.9876 7.99643C21.9854 8.25863 21.8802 8.50944 21.6948 8.69485C21.5094 8.88026 21.2586 8.98543 20.9964 8.9877C20.7342 8.98998 20.4816 8.88919 20.293 8.70703L16.293 4.70703C16.1055 4.5195 16.0002 4.26519 16.0002 4.00003C16.0002 3.73487 16.1055 3.48056 16.293 3.29303ZM17.293 11.707L13.293 7.70703L14.707 6.29303L18.707 10.293L17.293 11.707Z" fill="#FFAAA5"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_166_8036">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );

}

export default EditIcon;