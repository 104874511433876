import axios from "axios";
import Cookies from "universal-cookie";
import { config } from "../../../Constants";

export const activateSubscription = async(userId: string) => {

    const cookies = new Cookies();

    const headers = { "auth-token": cookies.get("auth_token") };

    const endpoint = config.url.BACKEND_API_URL + "/subscriptions/activate/" + userId

    const response = await axios.get(endpoint, { headers });
    return response.data; // response.data is the updated user after subscription activation

}