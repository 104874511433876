import { formatDateTime } from "../date-time/dateTimeHelpers";
import { ONE_DAY_IN_MS } from "../../data/constants";

export const getTrialPeriodRemainingDays = (trialPeriodEnd: number) => {

    const today = new Date();

    const { dateString: todayDateString } = formatDateTime(today);

    const todayInMs = new Date(todayDateString).getTime();

    const trialPeriodEndDate = new Date(trialPeriodEnd * 1000);
    const { dateString: trialPeriodEndDateString } = formatDateTime(trialPeriodEndDate);
    const trialPeriodEndDateInMs = new Date(trialPeriodEndDateString).getTime();

    const differenceInDays = (trialPeriodEndDateInMs - todayInMs) / ONE_DAY_IN_MS;

    return differenceInDays;
    
}