import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { 
    addNewAppointmentInfo, 
    updateState
} from "../../../store/calendar/calendarReducer";
import styles from "./AddAppointmentModal.module.css";
import NewAppointmentType from "./NewAppointmentType";
import SelectTime from "./SelectTime";
import SelectItem from "./SelectItem";
import SelectDate from "./SelectDate";
import ClientInfo from "./ClientInfo";
import RedeemCoupon from "./RedeemCoupon";
import InputField from "../common/InputField";
import CheckboxInputField from "../common/CheckboxInputField";
import EmailCheckbox from "../common/EmailCheckbox";
import AppointmentDateList from "./AppointmentDateList";
import SaveButton from "./SaveButton";

const AddAppointmentModal: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { 
        validationError,
        activityBy,
        newAppointmentType,
        isRepeatingAppointment,
        newAppointmentInfo,
        isBookingAppointmentForSearchedClient
    } = calendarState;
    const { newAppointmentTitle, isCouponRedeemed, paymentMethod } = newAppointmentInfo;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value, checked } = event.target;

        if(name === "newAppointmentTitle" || name === "paymentMethod") {
            dispatch(addNewAppointmentInfo({ name, value }));
            return;
        }

        if(name === "isRepeatingAppointment") {
            dispatch(updateState({
                name: "isRepeatingAppointment",
                value: checked
            }));
            return;
        }

        dispatch(updateState({
            name: "activityBy",
            value
        }));

    }

    return (
        <div className={styles.add_appointment_modal}>
            <NewAppointmentType />
            {isBookingAppointmentForSearchedClient && <SelectDate />}
            <SelectTime />
            {
                newAppointmentType === "time_block" 
                ?
                <InputField 
                    labelText="Appointment Title"
                    type="text" 
                    name="newAppointmentTitle" 
                    placeholder="e.g. day off, break etc"
                    value={newAppointmentTitle} 
                    handleChange={handleInputChange} 
                    validationError={validationError}
                    validationErrorMessage="appointment title can't be blank" 
                />
                : null
            }
            {
                newAppointmentType === "regular"
                &&
                <div className = {styles.regular_appointment}>
                    <div className = {styles.appointment_basic_info}>
                        <SelectItem />
                    </div>
                    <div className={styles.payment_method_container}>
                        <InputField 
                            labelText="Payment Method"
                            type="text" 
                            name="paymentMethod" 
                            placeholder="e.g. card, cash etc"
                            value={paymentMethod} 
                            handleChange={handleInputChange} 
                        />
                    </div>
                    <ClientInfo />
                    {!isCouponRedeemed ? <RedeemCoupon /> : null}
                </div>
            }
            <CheckboxInputField 
                name="isRepeatingAppointment" 
                value="Make it a repeating appointment"
                checked={isRepeatingAppointment}
                handleChange={handleInputChange} 
            />
            {
                isRepeatingAppointment
                ?
                <AppointmentDateList />
                :
                null
            }
            <InputField 
                customClassName="activity_by_input"
                labelText="Who is scheduling appointment?"
                type="text"
                name="activityBy"
                value={activityBy}
                handleChange={handleInputChange}
            />
            <EmailCheckbox />
            <SaveButton />
           
        </div>
    );

}

export default AddAppointmentModal;