import { 
    CardNumberElement, 
    CardExpiryElement, 
    CardCvcElement 
} from "@stripe/react-stripe-js";
import styles from "./StripeElements.module.css";
import Label from "./Label";


const CreditCard: React.FC = () => {

    return (
        <div className = {styles.credit_card}>
            <div className = {styles.card_number_elmt}>
                <Label 
                    labelText="Card Number"
                />
                <div className = {styles.element_wrapper}>
                    <CardNumberElement />
                </div>
            </div>
            <div className={styles.card_other_elmts}>
                <div className = {styles.card_expiry}>
                    <Label 
                        labelText="Expiry"
                    />
                    <div className = {styles.element_wrapper}>
                        <CardExpiryElement />
                    </div>
                </div>
                <div className = {styles.card_cvc}>
                    <Label 
                        labelText="CVC"
                    />
                    <div className = {styles.element_wrapper}>
                        <CardCvcElement />
                    </div>
                </div>
            </div>
        </div>
    );

}


export default CreditCard;