import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { config } from "../../Constants";
import { RootState } from "../../store";
import { handleChange, toggleActivationStatus } from "../../store/discounts/discountReducer";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./index.module.css";
import CustomCheckboxInput from "../../components/common/input-fields/CustomCheckboxInput";

const ToggleDiscountStatus: React.FC = () => {

    const dispatch = useDispatch();
    const discountState = useSelector((state: RootState) => state.discountState);
    const { discountList, discount, activationStatus } = discountState;
    
    const handleInputChange = (status: string) => {
        dispatch(handleChange({
            name: "activationStatus",
            value: status
        }));
    }

    const toggleDiscountStatus = async() => {

        dispatch(handleChange({
            name: "actionMessage",
            value: activationStatus === "activated" ? "De-activating...." : "Activating...." 
        }));

        const endpoint = config.url.BACKEND_API_URL + "/discounts/toggle";

        const authConfig = getAuthTokenConfig()
        const requestBody = { 
            discountId: discount?._id, 
            isActive: !discount?.isActive 
        };

        dispatch(toggleActivationStatus(null));
      
        try {

            const response = await axios.post(endpoint, requestBody, authConfig);
            const { updatedDiscount } = response.data;

            const updatedDiscountList = discountList.map(discountItem => {
                if(discountItem._id === updatedDiscount._id) {
                    return updatedDiscount;
                } 
                return discountItem
            });

            dispatch(handleChange({
                name: "actionMessage",
                value: discount?.isActive ? "De-activated Successfully" : "Activated Successfully" 
            }));

            dispatch(handleChange({
                name: "discountList",
                value: updatedDiscountList
            }));
           
        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(handleChange({
                name: "actionMessage",
                value: message
            }));
        }

    }
   
    return (
        <div className={styles.toggle_discount_status}>
            <h2>Toggle Activation Status</h2>
            <CustomCheckboxInput 
                name="discount_status"
                value="Activate"
                args={"activated"}
                checked={activationStatus === "activated"}
                handleChange={handleInputChange}
            />
             <CustomCheckboxInput 
                name="discount_status"
                value="De-activate"
                args={"de-activated"}
                checked={activationStatus === "de-activated"}
                handleChange={handleInputChange}
            />
            <button className={styles.button} onClick={toggleDiscountStatus}>
               Save
            </button>
        </div>
    );
    
}

export default ToggleDiscountStatus;