import { useSelector, useDispatch } from "react-redux";
import { MdDelete } from "react-icons/md";
import Cookies from "universal-cookie";
import axios from "axios";
import { RootState } from "../../../store";
import { updateState } from "../../../store/calendar/calendarReducer";
import { FETCH_APPOINTMENT_DATA } from "../../../store/calendar/constants";
import { config } from "../../../Constants";
import { formatDateTime } from "../../../helpers/date-time/dateTimeHelpers";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";
import styles from "./AppointmentModal.module.css";

const DeleteButton: React.FC = () => {

    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userState);
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { user } = userState;
    const { 
        calendarViewType, 
        dayViewDate,
        weekViewDates,
        monthViewDates,
        appointmentInAction 
    } = calendarState;

    const refetchAppointmentData = () => {

        if(!user) return;

        let dates: Date[] = [];

        if(calendarViewType === "day") dates = [dayViewDate];
        if(calendarViewType === "week") dates = [...weekViewDates];
        if(calendarViewType === "month") dates = [...monthViewDates];

        const endDatePlusOne = new Date(dates[dates.length - 1].getTime() + 86400000);

        const { dateString: startDateString } = formatDateTime(dates[0]);
        const { dateString: endDateString } = formatDateTime(endDatePlusOne);

        dispatch({ 
            type: FETCH_APPOINTMENT_DATA, 
            payload: {
                tenantId: user.tenantId,
                startDateString,
                endDateString
            }
        });

    }
   
    const deleteAppointment = async() => {

        const cookies = new Cookies();

        const endpoint = config.url.BACKEND_API_URL + "/appointments/delete/" + appointmentInAction?._id;
        const axiosConfig = {
            headers: {
                "auth-token": cookies.get("auth_token")
            }
        }

        dispatch(updateState({
            name: "appointmentInAction",
            payload: null
        }));

        dispatch(updateState({
            name: "serverResponseMessage",
            value: "Deleting..."
        }));

        try {

            const response = await axios.delete(endpoint, axiosConfig);
            dispatch(updateState({
                name: "serverResponseMessage",
                value: response.data
            }));
    
            refetchAppointmentData();

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(updateState({
                name: "serverResponseMessage",
                value: message
            }));
        }

    } 

    return (
        <div className={styles.delete_button}>
            <button onClick={deleteAppointment}>
                <MdDelete />
            </button>
        </div>
    );

}

export default DeleteButton;