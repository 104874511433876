const ReportIcon = (): JSX.Element => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="24" height="24" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#99B2C6" stroke="#99B2C6">
                <path d="M696 5099 l-26 -20 0 -1815 c0 -1801 0 -1814 20 -1834 25 -25 71 -26 101 -1 l24 19 3 1761 2 1761 1740 0 1740 0 0 -244 c0 -242 0 -245 23 -272 31 -35 82 -38 109 -5 17 21 18 47 18 326 l0 304 -26 20 -27 21 -1837 0 -1837 0 -27 -21z"/>
                <path d="M1730 4793 c-48 -18 -50 -25 -50 -228 0 -175 2 -195 19 -216 l19 -24 842 0 842 0 19 24 c17 21 19 41 19 211 0 170 -2 190 -19 211 l-19 24 -829 2 c-455 1 -835 -1 -843 -4z m1560 -233 l0 -90 -730 0 -730 0 0 90 0 90 730 0 730 0 0 -90z"/>
                <path d="M4324 4199 c-19 -21 -19 -71 -22 -2035 l-2 -2014 -1740 0 -1740 0 0 501 0 501 -29 29 c-35 34 -59 36 -95 8 l-26 -20 0 -564 0 -564 26 -20 27 -21 1837 0 1837 0 27 21 26 20 0 2070 c0 2056 0 2069 -20 2089 -27 27 -81 26 -106 -1z"/>
                <path d="M1443 4125 c-107 -23 -189 -70 -278 -160 -91 -90 -137 -171 -160 -282 -28 -131 -12 -260 47 -381 45 -93 168 -216 262 -261 166 -79 346 -79 513 0 94 44 216 167 262 263 79 167 79 349 -1 514 -45 94 -169 217 -261 261 -122 58 -255 74 -384 46z m57 -372 c0 -197 2 -229 17 -245 15 -16 36 -18 246 -18 l230 0 -7 -32 c-21 -107 -117 -228 -221 -279 -167 -82 -350 -56 -487 70 -169 155 -182 428 -27 596 54 59 88 83 158 111 98 41 91 56 91 -203z m266 187 c100 -49 193 -164 219 -272 l7 -28 -171 0 -171 0 0 171 0 171 28 -6 c15 -4 54 -20 88 -36z"/>
                <path d="M2512 4120 c-14 -14 -22 -33 -22 -55 0 -22 8 -41 22 -55 22 -20 31 -20 795 -20 l774 0 24 25 c14 13 25 36 25 50 0 14 -11 37 -25 50 l-24 25 -774 0 c-764 0 -773 0 -795 -20z"/>
                <path d="M2512 3784 c-30 -21 -31 -83 -1 -110 20 -18 50 -19 778 -22 416 -1 766 0 779 3 32 8 62 41 62 69 0 13 -9 35 -21 50 l-20 26 -778 0 c-687 0 -779 -2 -799 -16z"/>
                <path d="M2540 3463 c-33 -12 -50 -37 -50 -74 0 -73 -49 -69 821 -69 l778 0 20 26 c34 43 26 86 -20 109 -24 13 -139 15 -781 14 -414 0 -760 -3 -768 -6z"/>
                <path d="M2512 3110 c-14 -14 -22 -33 -22 -55 0 -22 8 -41 22 -55 21 -20 33 -20 493 -20 l472 0 21 23 c29 31 29 77 -1 105 -23 22 -24 22 -493 22 -459 0 -471 0 -492 -20z"/>
                <path d="M1141 2604 c-27 -35 -26 -57 3 -91 l24 -28 710 0 c684 0 711 1 731 19 27 25 29 86 3 109 -17 16 -82 17 -735 17 l-716 0 -20 -26z"/>
                <path d="M1163 2290 c-47 -19 -56 -78 -18 -115 l24 -25 711 0 c697 0 710 0 730 20 30 30 27 83 -6 109 -26 21 -33 21 -723 20 -383 0 -706 -4 -718 -9z"/>
                <path d="M3481 2271 c-11 -11 -23 -35 -26 -53 -3 -18 -5 -441 -3 -940 l3 -907 23 -23 c22 -23 28 -23 275 -26 138 -2 262 0 275 3 12 3 31 14 42 25 19 19 20 36 20 644 0 343 -3 632 -6 641 -10 24 -60 46 -89 39 -56 -14 -55 1 -55 -626 l0 -578 -167 2 -168 3 -3 833 -2 832 170 0 170 0 0 -108 c0 -100 2 -110 23 -130 33 -31 80 -29 106 4 19 24 21 40 21 181 0 150 -1 155 -25 178 l-24 25 -271 0 c-254 0 -271 -1 -289 -19z"/>
                <path d="M1145 1935 c-30 -29 -32 -64 -6 -96 l19 -24 411 -3 411 -3 25 25 c32 32 32 70 0 101 l-24 25 -406 0 -406 0 -24 -25z"/>
                <path d="M2677 1870 l-27 -21 0 -743 c0 -743 0 -743 21 -762 20 -18 40 -19 296 -19 l275 0 19 24 c18 22 19 49 19 756 0 706 -1 734 -19 756 l-19 24 -269 3 c-261 3 -270 2 -296 -18z m453 -765 l0 -635 -165 0 -165 0 0 635 0 635 165 0 165 0 0 -635z"/>
                <path d="M1862 1257 c-22 -23 -22 -26 -22 -454 0 -409 1 -432 19 -454 l19 -24 275 0 c256 0 276 1 296 19 21 19 21 25 21 457 0 438 0 438 -22 459 -21 19 -34 20 -293 20 -271 0 -272 0 -293 -23z m458 -457 l0 -330 -165 0 -165 0 0 330 0 330 165 0 165 0 0 -330z"/>
                <path d="M1075 789 c-43 -24 -45 -35 -45 -231 0 -174 1 -189 20 -208 11 -11 30 -22 42 -25 13 -3 137 -5 275 -3 326 4 298 -19 298 238 0 257 30 234 -308 237 -179 2 -268 -1 -282 -8z m440 -229 l0 -85 -167 -3 -168 -2 0 90 0 90 168 -2 167 -3 0 -85z"/>
            </g>
        </svg>
    );

}

export default ReportIcon;