import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { IProduct } from "../../types/models/Product";
import styles from "./DataTable.module.css";
import ActionButtons from "../../components/common/buttons/ActionButtons";
import AddToPOButton from "./AddToPOButton";

interface ResponsiveProductTableProps {
    handleClick: (actionType: string, productId: string ) => void
    addProductToPO: (product: IProduct) => void
}

const ResponsiveProductTable: React.FC<ResponsiveProductTableProps> = ({ handleClick, addProductToPO }) => {

    const productState = useSelector((state: RootState) => state.productState);
    const { productList } = productState;
   
    return (
        <div className={styles.responsive_product_table}>
            {productList.map(product => {

                const { _id, name, discountedPrice, stockAvailable, minStock } = product;
                
                return (
                    <div 
                        key={_id}
                        className={styles.responsive_item}
                    >
                        <div className={styles.responsive_item_header}>
                            <p className={styles.responsive_item_name}>{name}</p>
                            <ActionButtons 
                                actionTypeList={["sell", "edit", "delete"]}
                                itemId={_id}
                                showButtonText={false}
                                handleClick={handleClick}
                            />
                        </div>
                        <div className={styles.responsive_other_info}>
                            <p>Price: €{discountedPrice.toFixed(2)}</p>
                            <p>Stock Available: {stockAvailable}</p>
                            <p>Minimum Stock: {minStock}</p>
                            <AddToPOButton 
                                withText={true}
                                product={product}
                                addProductToPO={addProductToPO}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );

}

export default ResponsiveProductTable;
