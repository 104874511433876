import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProduct, IProductPurchaseOrder } from "../../types/models/Product";
import { 
    ProductState,
    ReceivedPOProduct, 
    FetchedData, 
    NewProductBrandCategory, 
    FetchedProductSalesData, 
    FetchedPurchaseOrderData
} from "../../types/pages/Product";

const initialState: ProductState = {
    loading: true,
    apiErrorMsg: null,
    validationError: false,
    showTableFor: "products",
    productList: [],
    productSaleList: [],
    purchaseOrderList: [],
    productCategoryList: [],
    productBrandList: [],
    productInAction: null,
    purchaseOrderInAction: null,
    productSaleInAction: null,
    productPhoto: null,
    newProductInfo: {
        category: { _id: null, name: "" },
        brand: { _id: null, name: "" },
        name: "",
        description: "",
        eanCode: "",
        sellingPrice: "",
        buyingPrice: "",
        discountedPrice: "",
        tags: "",
        stockAvailable: "",
        minStock: "",
        maxStock: ""
    },
    productSaleInfo: {
        quantity: "1",
        saleAdvisor: "",
        buyerName: "",
        buyerEmail: "",
        buyerPhone: "",
        giftCard: "",
        paymentMethod: ""
    },
    newPurchaseOrderInfo: {
        issuedToPartyName: "",
        issuedToPartyEmail: "",
        issuedToPartyPhone: "",
        issuedToPartyAddress: ""
    },
    purchaseOrderProductList: [],
    receivedPOProductList: [],
    searchText: "",
    skip: 0, 
    limit: 10,
    totalProductCount: 0,
    totalProductSalesCount: 0,
    totalPurchaseOrderCount: 0,
    isAdding: false,
    isEditing: false,
    isSelling: false,
    isCreatingPurchaseOrder: false,
    savedPurchaseOrder: null,
    isReceivingPurchaseOrderProducts: false,
    isDeleting: false,
    doNotShowProductCategoryList: false,
    doNotShowProductBrandList: false,
    actionMessage: null
}


const productSlice = createSlice({
    name: "products",
    initialState,
    reducers: {

        fetchDataLoading: (state) => {
            return { ...state, loading: true }
        },

        fetchDataSuccess: (state, action: PayloadAction<FetchedData>) => {
            const { productList, totalProductCount } = action.payload;
            return {
                ...state,
                loading: false,
                productList,
                totalProductCount
            }
        },

        fetchProductSalesDataSuccess: (state, action: PayloadAction<FetchedProductSalesData>) => {
            const { productSaleList, totalProductSalesCount } = action.payload;
            return {
                ...state,
                loading: false,
                productSaleList,
                totalProductSalesCount
            }
        },

        fetchPurchaseOrderDataSuccess: (state, action: PayloadAction<FetchedPurchaseOrderData>) => {
            const { purchaseOrderList, totalPurchaseOrderCount } = action.payload;
            return {
                ...state,
                loading: false,
                purchaseOrderList,
                totalPurchaseOrderCount
            }
        },

        fetchDataFail: (state, action:PayloadAction<string | null>) => {
            return {
                ...state,
                loading: false,
                apiErrorMsg: action.payload
            }
        },

        updateState: (state, action: PayloadAction<{ name: string, value: any }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            }
        },

        addNewProductInfo: (state, action: PayloadAction<{ name: string, value: string | NewProductBrandCategory }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                newProductInfo: {
                    ...state.newProductInfo,
                    [name]: value
                }
            }
        },

        addProductSaleInfo: (state, action: PayloadAction<{ name: string, value: string }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                productSaleInfo: {
                    ...state.productSaleInfo,
                    [name]: value
                }
            }
        },

        addNewPurchaseOrderInfo: (state, action: PayloadAction<{ name: string, value: string }>) => {
            const { name, value } = action.payload;
            return {
                ...state,
                newPurchaseOrderInfo: {
                    ...state.newPurchaseOrderInfo,
                    [name]: value
                }
            }
        },

        closeAddEditProductModal: (state) => {
            return { 
                ...state, 
                validationError: false,
                isAdding: false,
                isEditing: false,
                doNotShowProductCategoryList: false,
                doNotShowProductBrandList: false,
                productCategoryList: [],
                productBrandList: [],
                productInAction: null,
                productPhoto: null,
                newProductInfo: {
                    category: { _id: null, name: "" },
                    brand: { _id: null, name: "" },
                    name: "",
                    description: "",
                    eanCode: "",
                    sellingPrice: "",
                    buyingPrice: "",
                    discountedPrice: "",
                    tags: "",
                    stockAvailable: "",
                    minStock: "",
                    maxStock: ""
                } 
            }
        },

        closeSaleModal: (state) => {
            return { 
                ...state, 
                isSelling: false,
                productInAction: null,
                productSaleInfo: {
                    quantity: "1",
                    saleAdvisor: "",
                    buyerName: "",
                    buyerEmail: "",
                    buyerPhone: "",
                    giftCard: "",
                    paymentMethod: ""
                }
            }
        },

        closePurchaseOrderModal: (state) => {
            return { 
                ...state, 
                validationError: false,
                isCreatingPurchaseOrder: false,
                savedPurchaseOrder: null,
                purchaseOrderProductList: [],
                newPurchaseOrderInfo: {
                    issuedToPartyName: "",
                    issuedToPartyEmail: "",
                    issuedToPartyPhone: "",
                    issuedToPartyAddress: ""
                }
            }
        },

        openEditProductModal: (state, action: PayloadAction<IProduct>) => {

            const product = action.payload;

            return { 
                ...state, 
                isEditing: true,
                doNotShowProductCategoryList: true,
                doNotShowProductBrandList: true,
                productInAction: product,
                newProductInfo: { 
                    category: { 
                        _id: null, 
                        name: product.category ? product.category.toLowerCase() : "" 
                    },
                    brand: { 
                        _id: null, 
                        name: product.brand.toLowerCase()
                    },
                    name: product.name,
                    description: product.description ?? "",
                    eanCode: product.eanCode ?? "",
                    sellingPrice: `${product.sellingPrice}`,
                    buyingPrice: product.buyingPrice ? `${product.buyingPrice}` : "",
                    discountedPrice: `${product.discountedPrice}`,
                    tags: product.tags.join(","),
                    stockAvailable: `${product.stockAvailable}`,
                    minStock: `${product.minStock}`,
                    maxStock: `${product.maxStock}`
                } 
            }
        },

        toggleProductDeletePrompt: (state, action: PayloadAction<null | IProduct>) => {
            return {
                ...state,
                productInAction: action.payload,
                isDeleting: !state.isDeleting
            }
        },

        switchTableUI: (state) => {
            return {
                ...state,
                loading: true,
                apiErrorMsg: null,
                productList: [],
                productSaleList: [],
                purchaseOrderList: [],
                searchText: "",
                skip: 0, 
                limit: 10,
                totalProductCount: 0,
                totalProductSalesCount: 0,
                totalPurchaseOrderCount: 0
            }
        },

        togglePOProductsReceiveModal: (
            state, 
            action: PayloadAction<{
                purchaseOrderInAction: IProductPurchaseOrder | null
                receivedPOProductList: ReceivedPOProduct[]
            }>
        ) => {
            return {
                ...state,
                isReceivingPurchaseOrderProducts: !state.isReceivingPurchaseOrderProducts,
                purchaseOrderInAction: action.payload.purchaseOrderInAction,
                receivedPOProductList: action.payload.receivedPOProductList
            }
        }

    }
})

export const {
    fetchDataLoading,
    fetchDataSuccess,
    fetchProductSalesDataSuccess,
    fetchPurchaseOrderDataSuccess,
    fetchDataFail,
    updateState,
    addNewProductInfo,
    addProductSaleInfo,
    addNewPurchaseOrderInfo,
    closeAddEditProductModal,
    closeSaleModal,
    closePurchaseOrderModal,
    openEditProductModal,
    toggleProductDeletePrompt,
    switchTableUI,
    togglePOProductsReceiveModal
} = productSlice.actions;

export default productSlice.reducer;