import { IPackage } from "../../types/models/Package";
import styles from "./PackageTable.module.css";
import EditIcon from "../../components/icons/EditIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import IconButton from "./IconButton";
import SellIcon from "../../components/icons/SellIcon";

interface IProps {
    handleClick: (args: { actionType: string, packageItem: IPackage}) => void
    packageItem: IPackage,
    showButtonText: boolean
}

const ActionButtons: React.FC<IProps> = ({ packageItem, showButtonText, handleClick }) => {

    return (
        <div className = {styles.action_buttons}> 
            <IconButton 
                customClassName="buy_icon_button"
                buttonText={showButtonText ? "Buy" : ""}
                icon={<SellIcon />}
                handleClick={handleClick}
                args={{ actionType: "buy", packageItem }}
            />
            <IconButton 
                customClassName="edit_icon_button"
                buttonText={showButtonText ? "Edit" : ""}
                icon={<EditIcon />}
                handleClick={handleClick}
                args={{ actionType: "edit", packageItem }}
            />
            <IconButton 
                customClassName="delete_icon_button"
                buttonText={showButtonText ? "Delete" : ""}
                icon={<DeleteIcon />}
                handleClick={handleClick}
                args={{ actionType: "delete", packageItem }}
            />
        </div>            
    );

}

export default ActionButtons;