interface CheckIconProps {
    width?: number
    height?: number
    color?: string
}

const CheckIcon: React.FC<CheckIconProps> = ({ color = "black", width = 16, height = 16 }) => {

    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
            <path d="M5.52495 11.657L0.574951 6.707L1.98895 5.293L5.52645 8.8265L5.52495 8.828L14.01 0.343002L15.424 1.757L6.93895 10.243L5.52595 11.656L5.52495 11.657Z" fill={color}/>
        </svg>
    );

}

export default CheckIcon;