import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { updateState, toggleProductDeletePrompt } from "../../store/products/productReducer";
import { FETCH_PRODUCT_DATA } from "../../store/products/constants";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import DeletePrompt from "../../components/common/prompts/DeletePrompt";

const DeleteProduct = () => {

    const dispatch = useDispatch();
    const productState = useSelector((state: RootState) => state.productState);
    const { productInAction } = productState;

    const closeDeletePrompt = () => {
        dispatch(toggleProductDeletePrompt(null))
    }

    const deleteProduct = async() => {

        dispatch(toggleProductDeletePrompt(null));

        dispatch(updateState({
            name: "actionMessage",
            value: "Deleting..."
        }));

        const endpoint = config.url.BACKEND_API_URL + "/products/delete/" + productInAction?._id;
        const authConfig = getAuthTokenConfig();

        try {

            const response = await axios.delete(endpoint, authConfig);

            dispatch(updateState({
                name: "actionMessage",
                value: response.data
            }));

            dispatch({ type: FETCH_PRODUCT_DATA })

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(updateState({
                name: "actionMessage",
                value: message
            }));
        }

    }
    
    return (
        <DeletePrompt 
            warningText={<p>Are you sure want to delete <span>{productInAction?.name}</span></p>}
            closeDeletePrompt={closeDeletePrompt}
            deleteItem={deleteProduct}
        />
    );

}


export default DeleteProduct;