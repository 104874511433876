import Cookies from "universal-cookie";


export const UserAuth = () => {

    const cookies = new Cookies();
    
    const auth_token = cookies.get("auth_token");

    if(!auth_token) return false;

    return true;
    
}