export const getFormattedDateTime = (date: Date) => {

    let day: number | string = date.getDate();
    const weekDay = date.toLocaleString("en-us", {  weekday: "long" });
    const month = date.toLocaleString("en-us", { month: "long" });
    let monthInNumber: number | string = date.getMonth() + 1;
    const year = date.getFullYear();

    if(day < 10) day = "0" + day;
    if(monthInNumber < 10) monthInNumber = "0" + monthInNumber;

    let hours: string | number = date.getHours();
    let minutes: string | number = date.getMinutes();

    if(hours < 10) hours = "0" + hours;
    if(minutes < 10) minutes = "0" + minutes;

    return { day, weekDay, month, monthInNumber, year, hours, minutes };

}