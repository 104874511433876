import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { RootState } from "../../../store";
import { ICoupon } from "../../../types/models/Coupon";
import { 
    updateState,
    addNewAppointmentInfo, 
    applyCouponDiscount 
} from "../../../store/calendar/calendarReducer";
import { config } from "../../../Constants";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";
import styles from "./AddAppointmentModal.module.css";
import InputField from "../common/InputField";

const RedeemCoupon: React.FC = () => {

    const cookies = new Cookies();

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { newAppointmentInfo } = calendarState;
    const { selectedTreatment, couponCode } = newAppointmentInfo;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        dispatch(addNewAppointmentInfo({
            name: "couponCode",
            value
        }))
    }

    const handleCouponDiscount = async(event: React.MouseEvent<HTMLButtonElement>) => {

        // RedeemCoupon component has been placed inside a form, so, need to prevent default submission.
        event.preventDefault();

        // Input Validation
        if(!couponCode) {
            dispatch(updateState({
                name: "validationError",
                value: true
            }))
            return;
        }

        // If a treatment hasn't been selected yet
        if(!selectedTreatment) return alert("Please select a treatment first");

        try {

            const endpoint = config.url.BACKEND_API_URL + "/coupons/" + couponCode;
            const headers = { "auth-token": cookies.get("auth_token") };

            const response = await axios.get(endpoint, { headers });
            const foundCoupon: ICoupon = response.data.coupon;

            // Destructuring foundCoupon and selectedTreatment
            const { treatmentsWithDiscountRate } = foundCoupon;
            const { _id, title, price } = selectedTreatment;

            // Need to check if entered coupon code is applicable to the selected treatment 
            const foundTreatment = treatmentsWithDiscountRate.find(item => item.treatmentItem._id === _id);
            if(!foundTreatment) {
                const message = `Coupon ${couponCode} is not applicable to ${title}`;
                return alert(message);
            }

            const discountRate = foundTreatment.discountRate;
            let discountAmount: number | string = (price * discountRate) / 100;

            discountAmount = discountAmount.toFixed(2);
            discountAmount = Number(discountAmount);

            alert(`${discountRate}% discount has been applied to ${title}`);
            dispatch(applyCouponDiscount(discountAmount));

        } catch(error) {
            const { message } = handleApiError(error);
            alert(message);
        }

    }
   
    return (
        <div className={styles.redeem_coupon}>
            <h3>Redeem Coupon</h3>
            <InputField 
                labelText="Enter Coupon Code"
                type="text" 
                name="couponCode" 
                placeholder="HT5858HT67TY"
                value={couponCode} 
                handleChange={handleChange} 
            />
            <div className={styles.redeem_coupon_button}>
                <button 
                    onClick={handleCouponDiscount}
                    disabled={couponCode === ""}
                >
                    Redeem
                </button>
            </div>
        </div>
    );

}

export default RedeemCoupon;