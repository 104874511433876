import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./DataTable.module.css";

const ResponsiveProductSalesTable: React.FC = () => {

    const productState = useSelector((state: RootState) => state.productState);
    const { productSaleList } = productState;
   
    return (
        <div className={styles.responsive_product_table}>
            {productSaleList.map(sale => {

                const { _id, createdAt, product, price, quantity, saleAdvisor } = sale;

                const { day, month, year } = formatDateTime(new Date(createdAt * 1000));
                
                return (
                    <div key={_id} className={styles.responsive_item}>
                        <div className={styles.responsive_item_header}>
                            <p className={styles.responsive_item_name}>{product.name}</p>
                            <p>€{product.discountedPrice.toFixed(2)}</p>
                        </div>
                        <div className={styles.responsive_other_info}>
                            <p>Sale Date: {`${month} ${day}, ${year}`}</p>
                            <p>Quantity: {quantity}</p>
                            <p>Total Price: €{price.toFixed(2)}</p>
                            <p>Sale Advisor: {saleAdvisor}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );

}

export default ResponsiveProductSalesTable;
