export const calculateMonthDates = (navigationType?: string, monthViewDates?: Date[]) => {

    const oneDayMS = 86400000;
    const newMonthViewDates: Date[] = [];

    // It means calendar is open for the first time and we need to find initial week dates based on today date
    if(!monthViewDates) {

        let today = new Date();
        const todayDate = today.getDate();
        let todayInMS = today.getTime();
        const todayWeekDay = today.toLocaleString("en-us", {  weekday: "long" });

        let loopStartDate = null;

        if(todayDate === 1 && todayWeekDay.toLowerCase() === "sunday") loopStartDate = today;

        if(!loopStartDate) {

            let firstDateOfTheMonth = null;
    
            for(let x = 42; x >= 1; x--) {

                const date = new Date(todayInMS - (oneDayMS * (42 - x)));
                const dateWeekDay = date.toLocaleString("en-us", {  weekday: "long" });

                if(date.getDate() === 1) firstDateOfTheMonth = date;

                if(date.getDate() === 1 && dateWeekDay.toLowerCase() === "sunday") {
                    loopStartDate = date;
                    break;
                } 

                if(firstDateOfTheMonth && dateWeekDay.toLowerCase() === "sunday") {
                    loopStartDate = date;
                    break;
                } 

            }

        }

        if(loopStartDate) {

            const loopStartDateInMS = loopStartDate.getTime();

            for(let x = 0; x < 42; x++) {
                const date = new Date(loopStartDateInMS + (oneDayMS * x));
                newMonthViewDates.push(date);
            }

            return newMonthViewDates;

        }
       
        return newMonthViewDates;

    }

    if(!navigationType || !monthViewDates) return newMonthViewDates;

    if(navigationType === "next") {

        const firstDateOfNextMonth = monthViewDates.find((item, index) => {
            return index > 25 && item.getDate() === 1;
        });

        if(!firstDateOfNextMonth) return newMonthViewDates;

        const dayNameOfFirstDateOfNextMonth = firstDateOfNextMonth.toLocaleString("en-us", {  weekday: "long" });

        let loopStartDate = null;

        if(dayNameOfFirstDateOfNextMonth.toLowerCase() === "sunday") loopStartDate = firstDateOfNextMonth;

        if(!loopStartDate) {

            for(let x = 6; x >= 1; x--) {

                const date = new Date(firstDateOfNextMonth.getTime() - (oneDayMS * x));

                const dateWeekDay = date.toLocaleString("en-us", {  weekday: "long" });

                if(dateWeekDay.toLowerCase() === "sunday") {
                    loopStartDate = date;
                    break
                }
                
            }

        }

        if(!loopStartDate) return newMonthViewDates;

        for(let x = 0; x < 42; x++) {
            const date = new Date(loopStartDate.getTime() + (oneDayMS * x));
            newMonthViewDates.push(date);
        }

        return newMonthViewDates;
    }


    const firstDateOfCurrentMonth = monthViewDates.find(item => {
        return item.getDate() === 1;
    });

    if(!firstDateOfCurrentMonth) return newMonthViewDates;

    const date = firstDateOfCurrentMonth.getDate();
    let month = firstDateOfCurrentMonth.getMonth();
    let year = firstDateOfCurrentMonth.getFullYear();

    if(month === 0) {
        month = 12;
        year = year -1; 
    } 
    
    const firstDateOfPreviousMonth = new Date(`${year}-${month}-${date}`);

    let loopStartDate = null;

    const dayName = firstDateOfPreviousMonth.toLocaleString("en-us", {  weekday: "long" });

    if(dayName.toLowerCase() === "sunday") loopStartDate = firstDateOfPreviousMonth;

    if(!loopStartDate) {

        for(let x = 6; x >= 1; x--) {

            const date = new Date(firstDateOfPreviousMonth.getTime() - (oneDayMS * x));

            const dateWeekDay = date.toLocaleString("en-us", {  weekday: "long" });

            if(dateWeekDay.toLowerCase() === "sunday") {
                loopStartDate = date;
                break;
            }
            
        }

    }

    if(!loopStartDate) return newMonthViewDates;

    for(let x = 0; x < 42; x++) {
        const date = new Date(loopStartDate.getTime() + (oneDayMS * x));
        newMonthViewDates.push(date);
    }

    return newMonthViewDates;
    
}